import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import ExternalProviderComponent from '@/components/fields/ExternalProvider.vue';
import { IdoStore } from '@/store/interfaces/IdoStore';
import withPropsInjected from '@evidentid/vue-commons/decorators/withPropsInjected';
import QuestionForm from '@/components/forms/QuestionForm.vue';
import UtilityCredentials from './interfaces/UtilityCredentials';

export default class ExternalProviderField extends Field<any> {
    public override form = withPropsInjected(QuestionForm, { submitLabel: 'Authorize' });
    public override component = ExternalProviderComponent;

    public isValid(_: Question, value: UtilityCredentials): boolean {
        const { provider, username, password } = value || {};
        return Boolean(provider && provider.canonicalName && username && password);
    }

    public async encode(question: Question, value: UtilityCredentials): Promise<AttributesValues> {
        const { provider, username, password } = value || {};
        return this._encode(question, {
            $objectType: 'UtilityCredentials',
            provider: (provider && provider.canonicalName) || '',
            username: username || '',
            password: password || '',
        });
    }

    protected override prepareData(store: Pick<IdoStore, 'state' | 'dispatch'>) {
        return store.state.utilityProviders
            ? null
            : store.dispatch('loadUtilityProviders');
    }
}
