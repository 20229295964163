export const licenseStateIdsWithCustomValidation = [
    'PhysicalTherapistLicenseIdAndStateList',
    'NursePractitionerLicenseIdAndStateList',
    'HealthcareLicenseIdAndStateList',
    'PhysicianAssistantLicenseIdAndStateList',
    'AdvancedPracticeRegisteredNurseLicenseIdAndStateList',
    'RegisteredNurseLicenseIdAndStateList',
    'LicensedPracticalNurseLicenseIdAndStateList',
];

export const licenseStateNamesWithCustomValidation = [
    'usPhysicalTherapistLicenseList',
    'usMassageTherapistLicenseList',
    'usPhysicianAssistantLicenseList',
    'usNursePractitionerLicenseList',
    'usAdvancedPracticeRegisteredNurseLicenseList',
    'usRegisteredNurseLicenseList',
    'usLicensedPracticalNurseLicenseList',
];
