import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import { IdoStore } from '@/store/interfaces/IdoStore';
import FileField from '@/fields/FileField';
import { coiInputAttributeRegex } from '@/utils/insurance';

export default class CoiScanField extends FileField {
    private coiInputAttributes: Question[] = [];

    protected override prepareData(store: Pick<IdoStore, 'state' | 'dispatch'>): Promise<any> | null {
        this.coiInputAttributes =
            store.state.questions.filter((question) => coiInputAttributeRegex.test(question.attrType));
        return null;
    }

    public override async encode(question: Question, value: File[]): Promise<AttributesValues> {
        const pages = await this.buildPages(value);

        return this.coiInputAttributes.reduce((o, question) =>
            Object.assign(o, {
                [question.attrType]: {
                    shareWith: question.shareWith || null,
                    value: {
                        $objectType: 'CapturedDocument',
                        content: pages,
                        description: null,
                        title: null,
                    },
                },
            }), {});
    }
}
