<template>
    <label class="LineInputBlock">
        <span v-if="!bare">
            {{ question.metadata.title }}
        </span>
        <input
            v-model="inputValue"
            type="text"
            :pattern="pattern"
            @keydown="preventKeys"
            @input="onChange"
        >
    </label>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import SingleLineTextField from '@/fields/SingleLineTextField';

    @Component
    export default class SingleLineText extends FieldComponent<string> {
        private inputValue = this.value || '';
        private allowedCharRegex = (this.field as SingleLineTextField)?.allowedCharRegex;

        @Watch('value')
        private onValueChange(value: string) {
            this.inputValue = value;
        }

        private get pattern(): string | null {
            return this.allowedCharRegex ? `${this.allowedCharRegex}+` : null;
        }

        private preventKeys(event: KeyboardEvent): void {
            if (this.allowedCharRegex && !this.allowedCharRegex.test(event.key)) {
                event.preventDefault();
            }
        }

        private onChange() {
            this.inputValue = this.allowedCharRegex
                ? [ ...this.inputValue ].filter((char) => this.allowedCharRegex?.test(char)).join('')
                : this.inputValue;
            this.$emit('change', this.inputValue);
        }
    }
</script>
