export default function ensureAbsoluteUrl(url: string | null): string | null {
    if (!url) {
        return null;
    }

    if (url.startsWith('/')) {
        return null;
    }

    if (/^(?:mailto|tel)/.test(url)) {
        return url;
    }

    if (!/^[^:]+:\/\//.test(url)) {
        // Use by default HTTP (without SSL),
        // most likely browser or server will redirect to HTTPS if available.
        return `http://${url}`;
    }

    return url;
}
