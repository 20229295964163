/**
 * Our previous ido-facing application was using URLs like, i.e. #!/overview.
 * Now we are using Vue (with vue-router), which doesn't allow to use such fragment URLs.
 *
 * To not affect new application, this function is invoked before application start,
 * and it changes URLs from old format to the new one.
 */
export default function handleLegacyMithrilLocation(location: { hash: string, search: string }): void {
    const hash = location.hash;

    if (hash.startsWith('#!')) {
        location.hash = `#${hash.substr(2)}`;
    } else if (hash.startsWith('#/!')) {
        location.hash = `#/${hash.substr(3).replace(/^\/+/, '')}`;
    }

    if (location.search) {
        if (location.hash) {
            location.hash += location.search;
        } else {
            location.hash = `#${location.search}`;
        }

        location.search = '';
    }
}
