<template>
    <div class="GlsForm">
        <div v-if="submitting" class="GlsForm__loader">
            <AlternativeSpinner>
                Submitting...
            </AlternativeSpinner>
        </div>
        <template v-if="sent">
            <CaseTimeline :progress="timelineProgress">
                <CaseTimelineItem counter="1" :type="stepStatuses[0]">
                    <template #description>
                        Collect Applicant Information
                    </template>
                </CaseTimelineItem>
                <CaseTimelineItem counter="2" :type="stepStatuses[1]">
                    <template #description>
                        Processing Background Checks
                    </template>
                    <template v-if="stepStatuses[1] === 'ongoing'" #footer>
                        <a
                            :href="learnMoreLink"
                            target="_blank"
                        >
                            Learn more
                        </a>
                    </template>
                </CaseTimelineItem>
                <CaseTimelineItem counter="3" :type="stepStatuses[2]">
                    <template #description>
                        <template v-if="stepStatuses[2] === 'error'">
                            Application Canceled
                        </template>
                        <template v-else>
                            Results sent to Google
                        </template>
                    </template>
                    <template v-if="stepStatuses[2]" #footer>
                        <a
                            v-if="stepStatuses[2] === 'error'"
                            :href="learnMoreLink"
                            target="_blank"
                        >
                            Learn more
                        </a>
                    </template>
                </CaseTimelineItem>
            </CaseTimeline>
            <div class="GlsForm__learnMore">
                <a
                    href="https://evidentidsupport.zendesk.com/hc/en-us/articles/1500008737542"
                    target="_blank"
                >
                    Click Here
                </a>
                <span class="ml-1">to Learn More about the Verification Process</span>
            </div>
        </template>

        <template v-if="businessOwnersAndFieldworkersQuestion && !shouldShowOnlyCaseStatus">
            <GlsEmailQuestion
                v-model="businessOwnersAndFieldworkers"
                class="GlsForm__question"
                :question="businessOwnersAndFieldworkersQuestion"
                :disabled="complete"
                :allow-add="allowAddDataSubject"
                :single-email="isSingleEmailQuestion"
                @delete="onFieldWorkerDelete"
                @add="onDataSubjectAdd($event, false)"
                @resend="resend"
            >
                <template #sendEmails>
                    <button
                        v-if="canSendEmails"
                        class="GlsForm__emailLinksToAll"
                        :disabled="!canResend"
                        type="button"
                        @click="resendAll"
                    >
                        Email Submission Links to All Users
                    </button>
                </template>
            </GlsEmailQuestion>
            <hr class="GlsForm__section-separator">
        </template>
        <template v-if="fieldWorkerQuestion && !shouldShowOnlyCaseStatus">
            <GlsEmailQuestion
                v-model="fieldWorkers"
                class="GlsForm__question"
                :question="fieldWorkerQuestion"
                :disabled="complete"
                :allow-add="allowAddDataSubject"
                @delete="onFieldWorkerDelete"
                @add="onDataSubjectAdd($event, false)"
                @resend="resend"
            >
                <template #sendEmails>
                    <button
                        v-if="canSendEmails && !businessOwnersAndFieldworkersQuestion"
                        class="GlsForm__emailLinksToAll"
                        :disabled="!canResend"
                        type="button"
                        @click="resendAll"
                    >
                        Email Submission Links to All Users
                    </button>
                </template>
            </GlsEmailQuestion>
            <hr class="GlsForm__section-separator">
        </template>

        <template v-if="businessOwnerQuestion && !shouldShowOnlyCaseStatus">
            <GlsEmailQuestion
                v-model="businessOwners"
                class="GlsForm__question"
                :question="businessOwnerQuestion"
                :disabled="complete"
                :can-delete="canDeleteBusinessOwner"
                :allow-add="allowAddBusinessOwner"
                :single-email="isSingleEmailQuestion"
                @delete="onBusinessOwnerDelete"
                @add="onDataSubjectAdd($event, true)"
                @resend="resend"
            >
                <template #sendEmails>
                    <button
                        v-if="canSendEmails && !fieldWorkerQuestion && !businessOwnersAndFieldworkersQuestion"
                        class="GlsForm__emailLinksToAll"
                        :disabled="!canResend"
                        type="button"
                        @click="resendAll"
                    >
                        Email Submission Links to All Users
                    </button>
                </template>
            </GlsEmailQuestion>
            <hr class="GlsForm__section-separator">
        </template>

        <template v-if="shouldShowBusinessAddress">
            <GlsAddressQuestion
                v-model="businessAddress"
                class="GlsForm__question"
                :question="businessAddressQuestion"
                :disabled="complete"
            />
            <hr class="GlsForm__section-separator">
        </template>

        <ConfirmBusinessOwnerModal
            v-if="businessOwnerToConfirm"
            :email="businessOwnerToConfirm"
            @confirm="onBusinessOwnerConfirm"
        />

        <SubmissionRedirectModal
            v-if="canTransitionUser && !submissionModalDecayed"
            @confirm="onTransitionConfirm"
        />

        <Button v-if="!sent" type="primary" :disabled="!submittable" @click="onSubmit">
            Submit and continue to the next step
        </Button>

        <GlsPrivacyFooter v-if="!sent" />
    </div>
</template>

<style lang="less">
.GlsForm {
    .CaseTimeline {
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .Button {
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    &__question {
        margin: 2rem 0;

        &:first-child {
            margin-top: 0;
        }
    }

    &__loader {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.9);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3333;
    }

    &__learnMore {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        margin: 20px -30px;
        padding: 10px;
        background: #f2f2f2;
        color: #6b6b6b;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
    }

    &__emailLinksToAll {
        border: 0;
        background: transparent;
        font-size: 12px;
        font-weight: bold;
        color: #2ab496;
        text-decoration: underline;
        cursor: pointer;
        padding: 0;

        &[disabled] {
            color: #bbb;
            cursor: not-allowed;
            text-decoration: none;
        }
    }

    .EntriesTable {
        font-size: 0.8em;

        @media (max-width: 650px) {
            font-size: 0.7em;
        }
    }

    &__section-separator {
        border: 1px solid #ddd;

        &:only-of-type, &:last-of-type {
            display: none;
        }
    }
}
</style>

<script lang="ts">
    import GlsPrivacyFooter from '@/components/google/GlsPrivacyFooter/GlsPrivacyFooter.vue';
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import uniq from 'lodash/uniq';
    import isEmpty from 'lodash/isEmpty';
    import {
        GlsCaseCaseStatus,
        GlsCaseSubmissionInput,
        GlsRequestSubmissionStatus,
        GlsServiceType,
    } from '@evidentid/gls-service-api-client/types';
    import Question from '@evidentid/ido-lib/interfaces/Question';
    import { RelyingPartyRequestStatusType } from '@evidentid/ido-lib/getIdoRequestStatus';
    import { IdoRequestStatusTimelineEntryStatus } from '@evidentid/ido-lib/interfaces/IdoRequestStatus';
    import AlternativeSpinner from '@/components/common/AlternativeSpinner.vue';
    import GlsEmailQuestion from '@/components/google/GlsEmailQuestion.vue';
    import GlsAddressQuestion from '@/components/google/GlsAddressQuestion.vue';
    import Icon from '@/components/common/Icon.vue';
    import ConfirmBusinessOwnerModal from '@/components/google/ConfirmBusinessOwnerModal.vue';
    import SubmissionRedirectModal from '@/components/google/SubmissionRedirectModal.vue';
    import CaseTimelineItem from '@/components/google/CaseTimelineItem.vue';
    import CaseTimeline from '@/components/google/CaseTimeline.vue';
    import PrivacyNotice from '@/components/interview/PrivacyNotice.vue';
    import Button from '@/components/common/Button.vue';
    import Alert from '@/components/common/Alert.vue';
    import Address from '@/fields/interfaces/Address';
    import getField from '@/fields/getField';
    import { GlsBasicCaseData, GlsData } from '@/services/getGlsSessionInterview';
    import { Status, ValuesMap } from '@/store/interfaces/IdoState';
    import { GlsSessionData } from '@/interfaces/SessionData';
    import UserData from '@/interfaces/UserData';
    import { GlsAttributeType } from '@/config/gls';

    @Component({
        components: {
            ConfirmBusinessOwnerModal,
            Icon, GlsAddressQuestion, GlsEmailQuestion, AlternativeSpinner, Alert, Button, PrivacyNotice,
            CaseTimelineItem, CaseTimeline,
            SubmissionRedirectModal,
            GlsPrivacyFooter,
        },
    })
    export default class GlsForm extends Vue {
        private businessOwnerToConfirm: string | null = null;
        private submissionModalDecayed: boolean = false;

        private get glsData(): GlsData {
            return this.$store.state.gls!;
        }

        private get caseData(): GlsBasicCaseData {
            return this.glsData.caseData;
        }

        private get user(): UserData {
            return this.$store.state.user!;
        }

        private get caseStatus(): GlsCaseCaseStatus {
            return this.glsData.caseStatus;
        }

        private get primaryBusinessOwner(): string | null {
            return this.glsData?.primaryBusinessOwner || null;
        }

        private get emailRequestStatuses(): Record<string, GlsRequestSubmissionStatus> {
            return this.glsData?.emailRequestStatuses || {};
        }

        private get allowAddDataSubject(): boolean {
            return !this.complete;
        }

        private get shouldShowOnlyCaseStatus(): boolean {
            return isEmpty(this.$store.state.gls?.emailRequestStatuses) && this.isInTerminalState;
        }

        private get shouldShowBusinessAddress(): boolean {
            const businessAddress = this.$store.state.gls?.businessAddress;
            return Boolean(this.businessAddressQuestion) && !(
                !businessAddress && this.isInTerminalState
            );
        }

        private get isInTerminalState(): boolean {
            return (
                this.caseStatus === GlsCaseCaseStatus.passed ||
                this.caseStatus === GlsCaseCaseStatus.failed ||
                this.caseStatus === GlsCaseCaseStatus.dropoff
            );
        }

        private get allowAddBusinessOwner(): boolean {
            return (
                !this.complete &&
                this.caseData.serviceType === GlsServiceType.homeServices
            );
        }

        private getSubmissionStatusFor(email: string): GlsRequestSubmissionStatus {
            return this.emailRequestStatuses[email] || GlsRequestSubmissionStatus.pending;
        }

        private getResendStatusFor(email: string): Status {
            return this.$store.state.glsResendLinkStatus[email] || Status.uninitialized;
        }

        private get emails(): string[] {
            return uniq([
                ...this.fieldWorkers,
                ...this.businessOwners,
                ...this.businessOwnersAndFieldworkers,
            ]);
        }

        private get learnMoreLink(): string {
            const isBusinessOwnerOnlyCase = this.questions.length === 1
                && this.questions[0].attrType === GlsAttributeType.businessOwners;
            const isBusinessAddressOnly = this.questions.length === 1
                && this.questions[0].attrType === GlsAttributeType.businessAddress;

            if (this.stepStatuses[2] === 'error') {
                if (isBusinessOwnerOnlyCase) {
                    return 'https://evidentidsupport.zendesk.com/hc/en-us/articles/8399925786647#h_01F8QY6W55WSCZKVSC5JGQ51MM';
                }
                if (isBusinessAddressOnly) {
                    return 'https://evidentidsupport.zendesk.com/hc/en-us/articles/8400590567575#h_01F8QY6W55WSCZKVSC5JGQ51MM';
                }
                return 'https://evidentidsupport.zendesk.com/hc/en-us/articles/4402945568663#h_01F8QY6W55WSCZKVSC5JGQ51MM';
            }

            if (this.stepStatuses[1] === 'ongoing') {
                if (isBusinessOwnerOnlyCase) {
                    return 'https://evidentidsupport.zendesk.com/hc/en-us/articles/8399925786647#h_01F8QY64FXTJVQJ38AMEMHQ6GQ';
                }
                if (isBusinessAddressOnly) {
                    return 'https://evidentidsupport.zendesk.com/hc/en-us/articles/8400590567575#h_01F8QY64FXTJVQJ38AMEMHQ6GQ';
                }
            }

            return 'https://evidentidsupport.zendesk.com/hc/en-us/articles/4402945568663#h_01F8QY64FXTJVQJ38AMEMHQ6GQ';
        }

        private getNotSubmittedEmails(): string[] {
            return this.emails
                .filter((email) => (this.getSubmissionStatusFor(email) === GlsRequestSubmissionStatus.pending));
        }

        private getEmailsToResend(): string[] {
            return this.getNotSubmittedEmails()
                .filter((email) => ![ Status.success, Status.loading ].includes(this.getResendStatusFor(email)));
        }

        private async resend(email: string): Promise<void> {
            await this.$store.dispatch('resendGlsSubmissionLink', [ email ]);
        }

        private async resendAll(): Promise<void> {
            const emails = this.getEmailsToResend();
            if (emails.length > 0) {
                await this.$store.dispatch('resendGlsSubmissionLink', emails);
            }
        }

        private get canResend(): boolean {
            return this.getEmailsToResend().length > 0;
        }

        private async addFieldWorker(email: string): Promise<void> {
            await this.$store.dispatch('addGlsFieldWorker', email);
        }

        private async addBusinessOwner(email: string): Promise<void> {
            await this.$store.dispatch('addGlsBusinessOwner', email);
        }

        private get session(): GlsSessionData {
            return this.$store.state.session as GlsSessionData;
        }

        private get questions(): Question[] {
            return this.$store.state.questions;
        }

        private get attributeTypes(): string[] {
            return this.questions.map((x) => x.attrType);
        }

        private get submitting(): boolean {
            return this.attributeTypes.some((attrType) => this.$store.state.submission[attrType]);
        }

        private get complete(): boolean {
            return this.$store.state.requestStatus?.type === RelyingPartyRequestStatusType.complete;
        }

        private get timeout(): boolean {
            return this.$store.state.requestStatus?.type === RelyingPartyRequestStatusType.timeout;
        }

        private get sent(): boolean {
            return this.complete || this.questions.every((x) => x.complete);
        }

        private get submittable(): boolean {
            return this.valid && !this.submitting && !this.sent;
        }

        private get canSendEmails(): boolean {
            return this.sent && !this.complete && !this.allInformationCollected;
        }

        private get canTransitionUser(): boolean {
            return (
                !this.complete &&
                this.user.transitionUrl != null &&
                this.getSubmissionStatusFor(this.user.email) === GlsRequestSubmissionStatus.pending
            );
        }

        private get valid(): boolean {
            const questions = this.questions || [];
            return questions.filter((question) => !question.complete).every((question) => {
                const field = getField(question.type);
                const value = this.$store.state.values[question.attrType];
                return field?.isValid(question, value);
            });
        }

        private canDeleteBusinessOwner(email: string): boolean {
            return this.primaryBusinessOwner !== email;
        }

        private getQuestion(attrType: string): Question | null {
            return this.questions.find((x) => x.attrType === attrType) || null;
        }

        private get fieldWorkerQuestion(): Question | null {
            return this.getQuestion(GlsAttributeType.fieldWorkers);
        }

        private get businessOwnerQuestion(): Question | null {
            return this.getQuestion(GlsAttributeType.businessOwners);
        }

        private get businessOwnersAndFieldworkersQuestion(): Question | null {
            return this.getQuestion(GlsAttributeType.businessOwnersAndFieldworkers);
        }

        private get businessAddressQuestion(): Question | null {
            return this.getQuestion(GlsAttributeType.businessAddress);
        }

        private get fieldWorkers(): string[] {
            const value = this.$store.state.values[GlsAttributeType.fieldWorkers];
            return value?.list || [];
        }

        private set fieldWorkers(emails: string[]) {
            const value = { list: emails, confirmed: true };
            this.$store.dispatch('changeValue', { name: GlsAttributeType.fieldWorkers, value });
        }

        private get businessOwners(): string[] {
            const value = this.$store.state.values[GlsAttributeType.businessOwners];
            return value?.list || [];
        }

        private set businessOwners(emails: string[]) {
            const value = { list: emails, confirmed: true };
            this.$store.dispatch('changeValue', { name: GlsAttributeType.businessOwners, value });
        }

        private get businessOwnersAndFieldworkers(): string[] {
            const value = this.$store.state.values[GlsAttributeType.businessOwnersAndFieldworkers];
            return value?.list || [];
        }

        private set businessOwnersAndFieldworkers(emails: string[]) {
            const value = { list: emails, confirmed: true };
            this.$store.dispatch('changeValue', { name: GlsAttributeType.businessOwnersAndFieldworkers, value });
        }

        private get businessAddress(): Address | null {
            return this.$store.state.values[GlsAttributeType.businessAddress] || null;
        }

        private set businessAddress(value: Address | null) {
            this.$store.dispatch('changeValue', { name: GlsAttributeType.businessAddress, value });
        }

        private onFieldWorkerDelete(email: string): void {
            this.$store.dispatch('deleteGlsFieldWorker', email);
        }

        private onBusinessOwnerDelete(email: string): void {
            this.$store.dispatch('deleteGlsBusinessOwner', email);
        }

        private get allInformationCollected(): boolean {
            return this.emails.every((x) => (this.getSubmissionStatusFor(x) === GlsRequestSubmissionStatus.submitted));
        }

        private async onDataSubjectAdd(email: string, isBusinessOwner: boolean) {
            if (isBusinessOwner) {
                await this.addBusinessOwner(email);
            } else {
                await this.addFieldWorker(email);
            }
        }

        private get stepStatuses(): IdoRequestStatusTimelineEntryStatus[] {
            if (this.caseStatus === GlsCaseCaseStatus.passed || this.caseStatus === GlsCaseCaseStatus.failed) {
                return [
                    IdoRequestStatusTimelineEntryStatus.success,
                    IdoRequestStatusTimelineEntryStatus.success,
                    IdoRequestStatusTimelineEntryStatus.success,
                ];
            } else if (this.caseStatus === GlsCaseCaseStatus.unspecified && this.allInformationCollected) {
                return [
                    IdoRequestStatusTimelineEntryStatus.success,
                    IdoRequestStatusTimelineEntryStatus.ongoing,
                    IdoRequestStatusTimelineEntryStatus.disabled,
                ];
            } else if (this.caseStatus === GlsCaseCaseStatus.unspecified) {
                return [
                    IdoRequestStatusTimelineEntryStatus.ongoing,
                    IdoRequestStatusTimelineEntryStatus.disabled,
                    IdoRequestStatusTimelineEntryStatus.disabled,
                ];
            } else {
                return [
                    IdoRequestStatusTimelineEntryStatus.success,
                    IdoRequestStatusTimelineEntryStatus.success,
                    IdoRequestStatusTimelineEntryStatus.error,
                ];
            }
        }

        private get timelineProgress(): number {
            if (this.caseStatus === GlsCaseCaseStatus.passed || this.caseStatus === GlsCaseCaseStatus.failed) {
                return 1;
            } else if (this.caseStatus !== GlsCaseCaseStatus.unspecified || this.allInformationCollected) {
                return 0.5;
            } else {
                return (1 - this.getNotSubmittedEmails().length / this.emails.length) * 0.4;
            }
        }

        private get isSingleEmailQuestion(): boolean {
            return this.questions.length === 1 &&
                (Boolean(this.businessOwnerQuestion) || Boolean(this.businessOwnersAndFieldworkersQuestion));
        }

        private onSubmit(): void {
            if (!this.submittable) {
                return;
            }

            const businessOwner = this.businessOwnersAndFieldworkers[0];
            const fieldWorkers = this.businessOwnersAndFieldworkers.slice(1);
            const restCaseSubmission = this.businessOwnersAndFieldworkersQuestion
                ? { businessOwners: [ businessOwner ], fieldWorkers }
                : { businessOwners: this.businessOwners, fieldWorkers: this.fieldWorkers };

            const caseSubmission: GlsCaseSubmissionInput = {
                caseId: this.session.caseId,
                businessAddress: this.businessAddress || null,
                ...restCaseSubmission,
            };
            this.$store.dispatch('submitGlsData', caseSubmission);
        }

        @Watch('businessOwners')
        private onBusinessOwnersChange(current: string[], previous: string[]): void {
            const newBusinessOwners = current.filter((email) => !previous.includes(email));

            // Don't do it, when only business owner is expected
            if (!this.fieldWorkerQuestion) {
                return;
            }

            // Handle situation when new business owner was added
            if (!this.sent && newBusinessOwners.length === 1) {
                this.businessOwnerToConfirm = newBusinessOwners[0];
            }
        }

        private onBusinessOwnerConfirm(confirmed: boolean): void {
            // Ignore when there is nothing to confirm
            if (!this.businessOwnerToConfirm) {
                return;
            }

            // Move to field workers
            if (!confirmed) {
                const fieldWorkers = this.fieldWorkers;
                if (!fieldWorkers.includes(this.businessOwnerToConfirm)) {
                    this.fieldWorkers = [ ...fieldWorkers, this.businessOwnerToConfirm ];
                }
                this.businessOwners = this.businessOwners.filter((email) => email !== this.businessOwnerToConfirm);
            }

            // Reset state
            this.businessOwnerToConfirm = null;
        }

        private onTransitionConfirm(confirmed: boolean): void {
            if (!confirmed || !this.canTransitionUser) {
                this.submissionModalDecayed = true;
                return;
            }
            this.$store.dispatch('requestTransition');
        }

        private mounted() {
            if (this.canTransitionUser) {
                this.submissionModalDecayed = false;
            }
        }
    }
</script>
