<template>
    <label class="LineInputBlock">
        <span v-if="!bare">
            {{ question.metadata.description }}
        </span>
        <input
            v-model="inputValue"
            type="text"
            :placeholder="field.format"
            @input="change"
        >
    </label>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';

    @Component
    export default class Birthday extends FieldComponent<string> {
        private inputValue = this.value || '';

        @Watch('value')
        private onValueChange(value: string) {
            this.inputValue = value;
        }

        private change() {
            this.$emit('change', this.inputValue);
        }
    }
</script>
