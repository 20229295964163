import keyExclude from 'lodash/omit';

export function curateUtilityProviders(aggregatedRequest) {
    const inputAttributes = aggregatedRequest.input;
    const namespace = 'identity_assurance.address_verification';
    const contained = (k) => k.startsWith(namespace);
    const types = Object.keys(inputAttributes);

    const namespacedTypes = types.filter(contained);

    if (namespacedTypes.length > 0) {
        const filtered = keyExclude(inputAttributes, namespacedTypes);
        const sampleType = namespacedTypes[0];

        filtered[namespace] = {
            attrType: namespace,
            complete: namespacedTypes.every((k) => (inputAttributes[k] || { complete: true }).complete),
            hasError: namespacedTypes.some((k) => Boolean((inputAttributes[k] || {}).hasError)),
            shareWith: inputAttributes[sampleType].shareWith,
            metadata: Object.assign({}, inputAttributes[sampleType].metadata, {
                submissionAttrType: sampleType,
                icon: 'gears',
            }),
        };

        aggregatedRequest.input = filtered;
    }

    return aggregatedRequest;
}
