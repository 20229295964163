import * as Sentry from '@sentry/browser';
import SentryLoggerAdapter from '@evidentid/logger/adapters/SentryLoggerAdapter';
import ConsoleLoggerAdapter from '@evidentid/logger/adapters/ConsoleLoggerAdapter';
import Logger from '@evidentid/logger';
import LoggerUserData from '@evidentid/logger/LoggerUserData';

export default function createLogger<L extends LoggerUserData = LoggerUserData>(sentry: typeof Sentry | null) {
    // eslint-disable-next-line
    // @ts-ignore: it may be different Sentry library
    const adapter = sentry ? new SentryLoggerAdapter(sentry) : new ConsoleLoggerAdapter();
    return new Logger<L>(adapter);
}
