import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import SelfieComponent from '@/components/fields/Selfie.vue';
import { mimeTypes } from '@evidentid/file-utils/mimeTypes';
import { buildBinaryDataObjectFromFile, isFileValid } from '@evidentid/file-utils/prepareFile';

export default class SelfieField extends Field<File> {
    public override layout = SelfieComponent;

    public isValid(_: Question, value: File): boolean {
        return isFileValid(value, mimeTypes.image);
    }

    public async encode(question: Question, value: File): Promise<AttributesValues> {
        return this._encode(question, await buildBinaryDataObjectFromFile(value, { cropped: false }));
    }
}
