<template>
    <div>
        <Checkbox v-model="selectedValue.value" extra-class="ConsentQuestion__acceptCheck" @input="onChange">
            {{ selectedValue.consentText }}
        </Checkbox>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import Checkbox from '@/components/forms/Checkbox.vue';
    import { defaultConsentText } from '@/utils/insuranceConsent';
    import StatementOfTruthWithConsent from '@/interfaces/insurance/StatementOfTruthWithConsent';

    @Component({
        components: { Checkbox },
    })
    export default class StatementOfTruth extends FieldComponent<StatementOfTruthWithConsent> {
        private selectedValue = { ...(this.value || { value: false, consentText: defaultConsentText }) };

        @Watch('value')
        private onValueChange(value: StatementOfTruthWithConsent) {
            this.selectedValue = { ...value };
        }

        private onChange() {
            this.$emit('change', { ...this.selectedValue });
        }
    }
</script>
