import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import SmartAttributeComponent from '@/components/fields/SmartAttribute.vue';
import { FormController } from '@evidentid/json-schema/FormController';

export default class SmartAttributeField extends Field<string> {
    public override component = SmartAttributeComponent;
    public smartAttributeName: string;
    public smartAttributeFormController: FormController | null = null;

    public constructor(smartAttributeName: string) {
        super();
        this.smartAttributeName = smartAttributeName;
    }

    public isValid(_: Question, value: any): boolean {
        return Boolean(this.smartAttributeFormController && this.smartAttributeFormController.form.isValid(value));
    }

    public async encode(question: Question, value: any): Promise<AttributesValues> {
        return this._encode(question, this.smartAttributeFormController
            ? this.smartAttributeFormController.form.getValue(value)
            : value);
    }

    protected override async prepareData(): Promise<any> {
        // Load asynchronously, to not inject big dependencies when user doesn't need smart attributes
        const { default: getSmartAttributeJsonFormController } = await import('./getSmartAttributeJsonFormController');
        this.smartAttributeFormController = await getSmartAttributeJsonFormController(this.smartAttributeName);
        if (this.smartAttributeFormController === null) {
            throw new Error(`Unknown smart attribute type: "${this.smartAttributeName}"`);
        }
    }
}
