<template>
    <GlsImmediateRedirectModal v-if="immediateData && !sent" />
    <PendingScreen v-else-if="immediateData && sent && user.transitionUrl" fullscreen />
    <ThankYou v-else-if="immediateData" disable-refresh />
    <InterviewCard v-else>
        <GlsForm />
    </InterviewCard>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { RelyingPartyRequestStatusType } from '@evidentid/ido-lib/getIdoRequestStatus';
    import Question from '@evidentid/ido-lib/interfaces/Question';
    import { ValuesMap } from '@/store/interfaces/IdoState';
    import Address from '@/fields/interfaces/Address';
    import UserData from '@/interfaces/UserData';
    import { GlsSessionData } from '@/interfaces/SessionData';
    import { GlsData } from '@/services/getGlsSessionInterview';
    import { buildImmediateGlsValues } from '@/services/buildImmediateGlsValues';
    import { GlsAttributeType } from '@/config/gls';
    import ThankYou from '@/views/ThankYou.vue';
    import InterviewCard from '@/layouts/InterviewCard.vue';
    import GlsImmediateRedirectModal from '@/components/google/GlsImmediateRedirectModal.vue';
    import GlsForm from '@/components/google/GlsForm.vue';
    import PendingScreen from '@/components/screens/PendingScreen.vue';

    @Component({
        components: { ThankYou, GlsImmediateRedirectModal, GlsForm, InterviewCard, PendingScreen },
    })
    export default class Gls extends Vue {
        private get session(): GlsSessionData {
            return this.$store.state.session as GlsSessionData;
        }

        private get glsData(): GlsData {
            return this.$store.state.gls!;
        }

        private get user(): UserData {
            return this.$store.state.user!;
        }

        private get questions(): Question[] {
            return this.$store.state.questions;
        }

        private get attributeTypes(): string[] {
            return this.questions.map((x) => x.attrType);
        }

        private get submitting(): boolean {
            return this.attributeTypes.some((attrType) => this.$store.state.submission[attrType]);
        }

        private get sent(): boolean {
            return (
                this.$store.state.requestStatus?.type !== RelyingPartyRequestStatusType.new &&
                this.questions.every((question) => question.complete)
            );
        }

        private get values(): ValuesMap {
            return {
                ...this.$store.state.values,
                ...this.immediateData,
            };
        }

        private get businessOwnersAndFieldworkers(): string[] | null {
            return this.values[GlsAttributeType.businessOwnersAndFieldworkers]?.list || null;
        }

        private get businessOwners(): string[] | null {
            return this.values[GlsAttributeType.businessOwners]?.list || null;
        }

        private get fieldWorkers(): string[] | null {
            return this.values[GlsAttributeType.fieldWorkers]?.list || null;
        }

        private get businessAddress(): Address | null {
            return this.values[GlsAttributeType.businessAddress] || null;
        }

        private get immediateData(): ValuesMap | null {
            return buildImmediateGlsValues(this.glsData.caseData, this.user, this.questions);
        }

        private submitImmediate(): void {
            if (this.sent || this.submitting) {
                return;
            }
            const businessOwner = this.businessOwnersAndFieldworkers?.[0];
            const fieldWorkers = this.businessOwnersAndFieldworkers?.slice(1);
            const restCaseSubmission = businessOwner && fieldWorkers
                ? { businessOwners: [ businessOwner ], fieldWorkers }
                : { businessOwners: this.businessOwners || [], fieldWorkers: this.fieldWorkers || [] };
            this.$store.dispatch('submitGlsData', {
                caseId: this.session.caseId,
                businessAddress: this.businessAddress,
                ...restCaseSubmission,
            });
        }

        @Watch('immediateData', { immediate: true })
        private handleImmediateCase(): void {
            if (!this.immediateData) {
                return;
            }

            if (this.sent) {
                this.$store.dispatch('requestTransition');
            } else {
                this.submitImmediate();
            }
        }
    }
</script>
