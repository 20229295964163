import { SESSION_STORAGE_KEY } from '@evidentid/ido-lib/idoWebClient';
import SessionData from '@/interfaces/SessionData';

export interface SessionStorage {
    getItem: (key: string) => string | null;
}

// eslint-disable-next-line max-params
function getSessionData(
    sessionStorage: SessionStorage | null,
    rprId?: string,
    canary?: string,
    continueUrl?: string | null,
    enableReturn?: boolean | null,
    caseId?: number | null,
    authCode?: string | null,
): SessionData | null {
    if (caseId) {
        return {
            caseId,
            authCode: authCode || null,
        };
    }

    if (rprId && canary) {
        return {
            rprId,
            canary,
            continueUrl: continueUrl || null,
            enableReturn: Boolean(enableReturn && continueUrl),
        };
    }

    if (!sessionStorage) {
        return null;
    }

    try {
        return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY) as string);
    } catch (e) {
        return null;
    }
}

export default getSessionData;
