<template>
    <div class="Spinner">
        <div class="Spinner__image">
            <!-- arms in the .Spinner -->
            <div v-for="n in 12" :key="n" />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class Spinner extends Vue {
    }
</script>
