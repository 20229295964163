import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import BooleanComponent from '@/components/fields/BooleanComponent.vue';

export default class BooleanField extends Field<boolean> {
    public override component = BooleanComponent;

    public isValid(_: Question, value: boolean): boolean {
        return typeof value === 'boolean';
    }

    public async encode(question: Question, value: boolean): Promise<AttributesValues> {
        return this._encode(question, value);
    }
}
