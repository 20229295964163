<template>
    <div class="DefaultQuestionForm">
        <slot />
        <QuestionFormControls v-bind="$props" v-on="$listeners" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop } from 'vue-property-decorator';
    import QuestionFormControls from '@/components/forms/QuestionFormControls.vue';
    import Button from '@/components/common/Button.vue';
    import FieldComponent from '@/fields/FieldComponent';

    @Component({
        inheritAttrs: false,
        components: { QuestionFormControls, Button },
    })
    export default class QuestionForm extends FieldComponent<any> {
        @Prop({ type: String, default: undefined })
        private submitLabel!: string | undefined;

        @Prop({ type: Boolean, default: true })
        private hasSubmit!: boolean;
    }
</script>
