<template>
    <div class="DocumentPageInput">
        <PhotoPreview v-if="value"
            :file="value"
            :flexible="flexiblePreview"
            @delete="onDelete"
        />
        <PhotoInput v-else
            :processing="processing"
            :images-only="true"
            :label="processing ? 'Processing...' : 'Click to browse'"
            :process="processImage"
            @input="onChange"
            @invalid="onCorruptedFile"
            @processing="onProcessing"
        />
        <span v-if="label">{{ label }}</span>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import { ACUANT_CARD_TYPES } from '@evidentid/acuant-sdk';
    import PhotoPreview from '@/components/forms/PhotoPreview.vue';
    import PhotoInput from '@/components/forms/PhotoInput.vue';
    import { createIdentityScanProcessor } from '@evidentid/browser-framework/canvases';

    type ImageProcessor = (file: File) => Promise<File>;

    @Component({
        components: { PhotoInput, PhotoPreview },
    })
    export default class DocumentPageInput extends Vue {
        @Prop({ type: File, default: () => undefined })
        private value!: File | null;

        @Prop({ type: String, default: undefined })
        private label!: string | undefined;

        @Prop({ type: Boolean, default: false })
        private flexiblePreview!: boolean;

        @Prop({ type: String })
        private acuantType!: ACUANT_CARD_TYPES;

        private processImage!: ImageProcessor;

        private processing = false;

        @Watch('acuantType', { immediate: true })
        private onAcuantTypeChange(acuantType: ACUANT_CARD_TYPES) {
            this.processImage = createIdentityScanProcessor({ acuantType });
        }

        private onProcessing() {
            this.processing = true;
        }

        private onChange(fileBlob: File) {
            this.processing = false;
            this.$emit('input', fileBlob);
        }

        private onCorruptedFile(file: File | null) {
            this.processing = false;
            this.$emit('invalid', file);
        }

        private onDelete() {
            this.$emit('input', null);
        }
    }
</script>

<style lang="less">
    .DocumentPageInput {
        .MobileSimplexPreview {
            margin: 0;
        }

        .MobileSimplexDropbox__body {
            flex-direction: column-reverse;
            border-style: dashed;
            border-radius: 2px;
            font-size: 13px;
            margin: 0 0 4px;
        }

        .MobileSimplexDropbox__icon .Spinner {
            margin-left: 0;
        }
    }
</style>
