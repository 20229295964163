<template>
    <div class="Button" :touch-action="disabled ? false : 'auto'" :class="modifierClass" @click="onClick">
        <slot />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class Button extends Vue {
        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Object, default: () => ({}) })
        private flags!: { [key: string]: boolean };

        @Prop({ type: String, default: undefined })
        private type!: string | undefined;

        private get modifierClass() {
            const flags = Object.keys(this.flags).filter((flag) => this.flags[flag]);
            const parts = [ this.disabled ? 'disabled' : '', this.type, ...flags ].filter(Boolean);
            return parts.length ? `Button--${parts.join('-')}` : null;
        }

        private onClick(event: MouseEvent) {
            if (!this.disabled) {
                this.$emit('click', event);
            }
        }
    }
</script>
