export enum DocumentType {
    passport = 'passport',
    idCard = 'id_card',
    driversLicense = 'drivers_license',
}

interface QuestionMetadata {
    title: string;
    inputType?: string;
    description: string;
    icon: string;
    docTypeAttr?: string;
    documentPrescribed?: DocumentType | false;
    countryPrescribed?: string | false;
    regionPrescribed?: string | false;
    isLegacy?: boolean; // Used for ID scan attributes matching /us_issued/ regexp.
    nextAttrTypes?: string[];
    selfieAttrType?: string;
    facemapAttrType?: string;
    hidden?: boolean;
    [key: string]: any;
}

interface Question {
    type: string;
    attrType: string;
    complete: boolean;
    hasError: boolean;
    shareWith: string[];
    metadata: QuestionMetadata;
}

export default Question;
