import CanvasExifOrientation from 'canvas-exif-orientation';
import { ACUANT_CARD_TYPES, shouldPreprocessOnTheClient } from '@evidentid/acuant-sdk';
import { buildFileFromBlob, readFile } from '@evidentid/file-utils/blobs';
import { getExifData, Orientation } from './images';
import { isFileValid } from '@evidentid/file-utils/prepareFile';
import { mimeTypes } from '@evidentid/file-utils/mimeTypes';
import features from './features';

const support = features(window);

export const draw2dImage = (ctx, img, {
    dw = img.width,
    dh = img.height,
}) => {
    const scale = Math.min(dw / img.width, dh / img.height);
    const x = (dw / 2) - (img.width / 2) * scale;
    const y = (dh / 2) - (img.height / 2) * scale;

    ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
};

export const srt2dPipeline = (ctx, {
    width,
    height,
    radians = 0,
    xOrigin = 0.5,
    yOrigin = 0.5,
    xScale = 1,
    yScale = 1,
    xTranslate = 0,
    yTranslate = 0,
} = {}) => {
    const hw = Math.floor(width * xOrigin);
    const hh = Math.floor(height * yOrigin);

    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.translate(hw, hh);
    ctx.scale(xScale, yScale);
    ctx.translate(
        (xScale >= 0) ? xTranslate : -xTranslate,
        (yScale >= 0) ? yTranslate : -yTranslate);
    ctx.rotate(radians);
    ctx.translate(-hw, -hh);
};

export function loadImage(dataUrl) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';

        img.onerror = (e) => reject(e);
        img.onload = () => resolve(img);
        img.src = dataUrl;
    });
}

export async function loadImageFile(file) {
    const objectUrl = URL.createObjectURL(file);
    const img = await loadImage(objectUrl);
    URL.revokeObjectURL(objectUrl);
    return img;
}

export async function isEmptyImage(file, exif = {}) {
    // Configure
    const minimumColors = 2;

    // Load image data
    const img = await loadImageFile(file);
    const orientation = exif?.Orientation || Orientation.regular;

    // Scale image - we will still get information about colors, and will be faster
    const width = Math.min(img.width, 30);
    const height = Math.round(width * img.height / img.width);

    // Draw image on canvas
    const canvas = CanvasExifOrientation.drawImage(img, orientation, 0, 0, width, height);

    // Set-up searching parameters
    let uniqueColorsFound = 0;
    const found = {};
    const imageData = canvas.getContext('2d').getImageData(0, 0, width, height).data;

    // Search pixel by pixel for different color
    for (let i = 0; i < imageData.length; i += 4) {
        const hash = 65536 * imageData[i] + 256 * imageData[i + 1] + imageData[i + 2];

        // Mark it as found
        if (!found[hash]) {
            uniqueColorsFound++;
            found[hash] = true;

            if (uniqueColorsFound >= minimumColors) {
                return false;
            }
        }
    }

    return true;
}

export function cloneCanvas(canvas) {
    const newCanvas = document.createElement('canvas');
    const newContext = newCanvas.getContext('2d');
    newCanvas.width = canvas.width;
    newCanvas.height = canvas.height;
    newContext.drawImage(canvas, 0, 0);
    return newCanvas;
}

export function rotateCanvas(canvas, degrees) {
    const canvasCopy = cloneCanvas(canvas);
    const context = canvas.getContext('2d');
    const { width, height } = canvas;
    const shouldSwitchDimensions = degrees % 180 > 30 && degrees % 180 < 120;

    // Change canvas dimensions
    if (shouldSwitchDimensions) {
        canvas.width = height;
        canvas.height = width;
    }

    // Clear canvas
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.save();

    // Rotate canvas
    context.translate(canvas.width / 2, canvas.height / 2);
    context.rotate(degrees * Math.PI / 180);

    // Draw previous canvas content
    context.drawImage(canvasCopy, width / -2, height / -2);

    // Clean up after changes
    context.setTransform(1, 0, 0, 1, 0, 0);
}

function rotateCanvasToLandscape(canvas, orientation = Orientation.regular) {
    // Don't do anything when it is already in landscape view
    if (canvas.height <= canvas.width) {
        return;
    }

    // Rotate canvas to landscape view
    if (orientation === Orientation.mirrorRotate90 || orientation === Orientation.rotate90) {
        rotateCanvas(canvas, 270);
    } else if (orientation === Orientation.mirrorRotate180 || orientation === Orientation.rotate180) {
        rotateCanvas(canvas, 180);
    } else {
        rotateCanvas(canvas, 90);
    }
}

function getBlobFromCanvas(canvas, mimeType = 'image/png', quality = 100) {
    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (blob) {
                resolve(blob);
            } else {
                reject(new Error('Processing error while rendering canvas to blob.'));
            }
        }, mimeType, quality * 0.01);
    });
}

/**
 * We don't need a very big images,
 * although, we want to scale only images which will finalize without semi-pixels.
 * Because of that, we allow scaling only by 0.25N multiplier, and to the image without semi-pixels.
 *
 * @param {Image|HTMLCanvasElement|{ width: number, height: number }} img
 * @param {number} preferredResolution
 * @returns {{ width: number, height: number }}
 */
export function getSafeScaledImageDimensions(img, preferredResolution) {
    const SCALE_FACTOR = 0.25;
    const resolution = img.width * img.height;
    const approxScale = Math.sqrt(Math.min(1, preferredResolution / resolution));
    const scale = Math.ceil(approxScale / SCALE_FACTOR) * SCALE_FACTOR;
    const scaledWidth = img.width * scale;
    const scaledHeight = img.height * scaledWidth / img.width;
    const isScalingSafe = Number.isInteger(scaledWidth) && Number.isInteger(scaledHeight);
    const [ width, height ] = isScalingSafe ? [ scaledWidth, scaledHeight ] : [ img.width, img.height ];
    return { width, height };
}

const defaultImageProcessingOptions = {
    exif: {},
    preferredResolution: null,
    landscape: false,
    quality: 80,
};

export async function processImage(file, options = {}) {
    // Set-up configuration details
    const config = { ...defaultImageProcessingOptions, ...options };
    const orientation = config.exif.Orientation || Orientation.regular;

    // Retrieve file details
    const img = await loadImageFile(file);

    // Do not process PNG files
    if (file.type === 'image/png') {
        return {
            file,
            width: img.width,
            height: img.height,
        };
    }

    // Prepare image on canvas
    const canvas = CanvasExifOrientation.drawImage(img, orientation);

    // Calculate dimensions
    const { width, height } = config.preferredResolution
        ? getSafeScaledImageDimensions(canvas, config.preferredResolution)
        : canvas;

    // Resize canvas
    if (width !== canvas.width || height !== canvas.height) {
        canvas.width = width;
        canvas.height = height;
    }

    // Rotate image to landscape (based on its orientation)
    if (config.landscape) {
        rotateCanvasToLandscape(canvas, orientation);
    }

    // Build final image after transformation
    const mimeType = file.type === 'image/png' ? 'image/png' : 'image/jpeg';
    const finalBlob = await getBlobFromCanvas(canvas, mimeType, config.quality);
    const name = file.type === mimeType ? file.name : 'processed-image.jpg';

    return {
        file: buildFileFromBlob(finalBlob, name),
        width: canvas.width,
        height: canvas.height,
    };
}

function createImageProcessingError(type, message) {
    const error = new Error(message);
    error.name = 'ImageProcessingError';
    error.type = type;
    return error;
}

export const ImageProcessingErrorType = {
    empty: 'empty',
    emptyProcessed: 'empty-processed',
    corrupted: 'corrupted',
};

export function createImageProcessor(options) {
    const {
        fallbackEmptyImage = true,
        isEmpty = isEmptyImage,
        process = processImage,
        ...processingImageOptions
    } = options || {};
    return async function processImageCustomWay(fileBlob) {
        try {
            // Immediately return empty file
            if (!isFileValid(fileBlob, mimeTypes.image)) {
                throw createImageProcessingError(
                    ImageProcessingErrorType.corrupted,
                    'Invalid file passed'
                );
            }

            // Load and analyze image
            const fileBuffer = await readFile.asArrayBuffer(fileBlob);
            const exifData = getExifData(fileBuffer);

            // Ensure that image is not empty
            if (await isEmpty(fileBlob, exifData)) {
                console.error('Empty original ID scan detected', {
                    type: fileBlob.type,
                    size: fileBlob.size,
                });
                throw createImageProcessingError(
                    ImageProcessingErrorType.empty,
                    'Empty original ID scan detected'
                );
            }

            // Process image
            const { file: finalFileBlob } = await process(fileBlob, {
                exif: exifData,
                ...processingImageOptions,
            });

            // Check if the image is empty after processing (ignore, when file is the same - it has been already done)
            if (finalFileBlob !== fileBlob && await isEmpty(finalFileBlob)) {
                console.error('Empty ID scan detected after processing', {
                    type: fileBlob.type,
                    size: fileBlob.size,
                    typeAfter: finalFileBlob.type,
                    sizeAfter: finalFileBlob.size,
                });
                if (!fallbackEmptyImage) {
                    throw createImageProcessingError(
                        ImageProcessingErrorType.emptyProcessed,
                        'Empty ID scan detected after processing'
                    );
                }
                return fileBlob;
            }

            // Return back the processed image
            return finalFileBlob;
        } catch (error) {
            // Handle 'error' listener for <img>: it may occur during processing, when image is invalid
            if (error?.type === 'error') {
                throw createImageProcessingError(
                    ImageProcessingErrorType.corrupted,
                    'Image could not be rendered'
                );
            }
            throw error;
        }
    };
}

export function createIdentityScanProcessor(options = {}) {
    const cardType = options.acuantType || ACUANT_CARD_TYPES.DRIVERS_LICENSE_DUPLEX;
    const isEmpty = options.isEmpty || isEmptyImage;
    const process = options.process || processImage;
    const shouldPreprocess = options.shouldPreprocessOnTheClient || shouldPreprocessOnTheClient;

    // Build image processors for different cases
    const basicImageProcessor = createImageProcessor({
        fallbackEmptyImage: true,
        landscape: true,
        isEmpty,
        process,
    });
    const regularImageProcessor = createImageProcessor({
        // TODO(PRODUCT-10525) - investigate scaling ID scan images
        // preferredResolution: 4000 * 3000, // 12 Mpx
        fallbackEmptyImage: true,
        landscape: true,
        isEmpty,
        process,
    });

    // Build image processors facade
    return async function processIdentityScanImage(fileBlob) {
        // Immediately return empty file
        if (!isFileValid(fileBlob, mimeTypes.image)) {
            throw createImageProcessingError(
                ImageProcessingErrorType.corrupted,
                'Invalid file passed'
            );
        }

        // Load and analyze image
        const fileBuffer = await readFile.asArrayBuffer(fileBlob);
        const exifData = getExifData(fileBuffer);
        const dpi = exifData.XResolution ? exifData.XResolution.numerator / exifData.XResolution.denominator : 1;
        const shouldBeProcessed = shouldPreprocess({ dpi, cardType, isiOS: support.isIOS });

        // Delegate to image processor
        const processor = shouldBeProcessed ? regularImageProcessor : basicImageProcessor;
        return processor(fileBlob);
    };
}
