import noop from 'lodash/noop';
import trackXhrUploadProgress from './trackXhrUploadProgress';

export interface XhrOptions {
    url: string;
    method?: string;
    data?: any;
    config?(extendedOptions): void;
}

export interface XhrExtendedOptions {
    withCredentials?: boolean;
    setRequestHeader?(name: string, value: string);
}

function normalizeMethod(method) {
    return (method || 'GET').toUpperCase();
}

function consideredIdempotent(method) {
    const toCheck = normalizeMethod(method);

    return toCheck === 'GET' || toCheck === 'TRACE' || toCheck === 'OPTIONS';
}

function _xhrImpl({
    method = 'GET',
    XMLHttpRequest = window.XMLHttpRequest,
    url,
    user,
    password,
    data,
    config = noop,
}: any = {}) {
    const applicableMethod = normalizeMethod(method);
    const xhrObj = new XMLHttpRequest();
    xhrObj.stack = new Error().stack;

    xhrObj.open(
        applicableMethod,
        url,
        true,
        typeof user === 'string' ? user : undefined,
        typeof password === 'string' ? password : undefined
    );

    if (typeof config === 'function') {
        config(xhrObj);
    }

    xhrObj.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    const uploadProgressListeners = [];
    const addUploadProgressListener = (listener) => {
        if (xhrObj.readyState === 4) {
            listener(1);
        }
        uploadProgressListeners.push(listener);
    };
    const onProgressChange = (progress) => uploadProgressListeners.forEach((listener) => listener(progress));

    trackXhrUploadProgress(xhrObj, onProgressChange);

    const vowResponse = new Promise((resolve, reject) => {
        xhrObj.onreadystatechange = () => {
            if (xhrObj.readyState === 4) {
                if (xhrObj.status >= 200 && xhrObj.status < 300 || xhrObj.status === 304) {
                    resolve(xhrObj);
                } else {
                    // Rejection includes abort.
                    const xhrError = new Error();
                    // @ts-ignore: using non-standard property
                    xhrError.xhrObj = xhrObj;

                    reject(xhrError);
                }
            }
        };

        xhrObj.send((consideredIdempotent(applicableMethod))
            ? undefined
            : data);
    });

    // @ts-ignore: using non-standard property
    vowResponse.abort = () => xhrObj.abort();
    // @ts-ignore: using non-standard property
    vowResponse.onUploadProgressChange = addUploadProgressListener;

    return vowResponse;
}

function xhr(o = {}) {
    try {
        return xhr._driver(o);
    } catch (e) {
        return Promise.reject(e);
    }
}

// The driver function is hot-swappable for mocking purposes.
xhr._driver = _xhrImpl;

// Use to create another client with more specialized options.
// Usage: xhr.extend(xhr.extend(xhr, () => {...}), () => {...})
xhr.extend = (baseXhr, override) => (o: Partial<XhrOptions> = {}) => {
    const overridden = Object.assign({}, o, override(o));

    const oldConfig = (o.config || (() => null));
    const newConfig = (overridden.config || (() => null));

    overridden.config = (xo) => {
        oldConfig(xo);
        newConfig(xo);
    };

    return baseXhr(overridden);
};

/*
 * Decorate XHR function such that all request URIs are prefixed
 * with a prescribed string.
 *
 * Example:
 *
 * xhr({url: '/bar'}) // GET /bar
 *
 * const withPrefix = xhr.decorateUrlPrefix(xhr, '/foo');
 *
 * withPrefix({url: '/bar'}); // GET /foo/bar
 */
xhr.decorateUrlPrefix = (baseXhr, url) => (o: Partial<XhrOptions> = {}) => {
    o.url = url + o.url;

    return baseXhr(o);
};

/*
 * Decorate XHR function such that all keyword arguments are
 * transformed by an override() function.
 */
xhr.decorateOptions = (baseXhr, override) => (o = {}) => baseXhr(override(o));

/*
 * Decorate XHR function such that all requests include the same headers
 * (overriding any conflicting headers).
 */
xhr.setHeaders = (xo, headers) => {
    for (const [ header, val ] of Object.entries(headers)) {
        xo.setRequestHeader(header, val);
    }
};

xhr.decorateComms = (baseXhr, {
    outgoing = (v) => v.data,
    incoming = (v) => v,
    incomingError = (v) => v,
} = {}) => (o = {}) => {
    try {
        const data = outgoing(o);

        const basePromise = baseXhr(Object.assign({}, o, { data }));
        const finalPromise = basePromise.then((xo) => incoming(xo)).catch((xhrError) => {
            throw incomingError(xhrError);
        });
        finalPromise.abort = basePromise.abort;
        finalPromise.onUploadProgressChange = basePromise.onUploadProgressChange;
        return finalPromise;
    } catch (e) {
        return Promise.reject(e);
    }
};

const _cache = {};
xhr.decorateCachePolicy = (
    baseXhr,
    predicate: (o: Partial<XhrOptions>) => boolean = () => true,
) => (o: Partial<XhrOptions> = {}) => {
    if (consideredIdempotent(o.method) && predicate(o) && o.url in _cache) {
        return Promise.resolve(_cache[o.url]);
    } else {
        return baseXhr(o).then((resp) => {
            _cache[o.url] = resp;

            return resp;
        });
    }
};

// Client supporting full JSON I/O.
xhr.decorateJsonComms = (baseXhr) => {
    // These functions may throw errors freely.
    // The Error objects will be caught by decorateComms.
    const outgoing = (o) => JSON.stringify(o.data);

    const incoming = (xo) => JSON.parse(xo.responseText);

    const incomingError = (xhrError) => {
        // Support situation when the error was thrown by XHR
        if (!xhrError.xhrObj) {
            return xhrError;
        }

        const respText = xhrError.xhrObj.responseText || '';

        // Don't throw JSON.parse errors, when the request was cancelled, due i.e. page refresh
        if (xhrError.xhrObj.status === 0) {
            const err = new Error('Network issue: request cancelled');
            // @ts-ignore: using non-standard property
            err.reason = 'cancelled-network-request';
            // @ts-ignore: using non-standard property
            err.xhrObj = xhrError.xhrObj;
            // @ts-ignore:
            err.initStack = xhrError.xhrObj.stack;
            return err;
        }

        try {
            const message = JSON.parse(respText);
            const err = new Error(respText);

            // @ts-ignore: using non-standard property
            err.jsonResponse = message;
            // @ts-ignore: using non-standard property
            err.xhrObj = xhrError.xhrObj;

            return err;
        } catch (e) {
            e.xhrObj = xhrError.xhrObj;

            return e;
        }
    };

    const withComms = xhr.decorateComms(baseXhr, {
        outgoing,
        incoming,
        incomingError,
    });

    const withHeaders = xhr.decorateOptions(withComms, (o) => {
        const old = o.config || noop;

        o.config = (xo) => {
            xo.setRequestHeader('Accept', 'application/json, text/*');

            if (!consideredIdempotent(o.method)) {
                xo.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
            }

            old(xo);
        };

        return o;
    });

    return withHeaders;
};

xhr.json = xhr.decorateJsonComms(xhr);

export default xhr;
