<template>
    <div v-if="!question.complete && disabled && empty" class="GlsFormQuestion">
        <header class="QuestionHeader">
            <h3>{{ title }}</h3>
            <div class="QuestionHeader__subtitle">
                {{ question.metadata.emptyInformation }}
            </div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="question.metadata.description" />
            <slot name="sendEmails" />
        </header>
    </div>
    <div v-else-if="!question.complete" class="GlsFormQuestion">
        <header class="QuestionHeader">
            <h3>{{ title }}</h3>
            <div class="QuestionHeader__subtitle">
                {{ disabled ? question.metadata.completedInformation : question.metadata.instructions }}
            </div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="question.metadata.description" />
            <slot name="sendEmails" />
        </header>
        <EmailList
            v-if="(!disabled || !empty) && !singleEmail"
            :disabled="disabled"
            :value="value"
            @input="onInput"
        />
        <GlsSingleEmailQuestion
            v-if="(!disabled || !empty) && singleEmail"
            :value="value[0] || ''"
            @input="onInput"
        />
    </div>
    <div v-else-if="empty && (disabled || !allowAdd)" class="GlsFormQuestion">
        <header class="QuestionHeader">
            <h3>{{ title }}</h3>
            <div class="QuestionHeader__subtitle">
                {{ question.metadata.emptyInformation }}
            </div>
            <slot name="sendEmails" />
        </header>
    </div>
    <div v-else class="GlsFormQuestion">
        <header class="QuestionHeader">
            <h3>{{ title }}</h3>
            <div class="QuestionHeader__subtitle">
                {{ empty ? question.metadata.emptyInformation : question.metadata.completedInformation }}
            </div>
            <slot name="sendEmails" />
        </header>
        <EntriesTable
            :value="value"
            :is-disabled="isEmailDisabled"
            :attr-type="attrType"
            :allow-add="allowAdd && !singleEmail"
            :single-email="singleEmail"
            @delete="deleteEmail"
            @add="addDataSubject"
            @resend="sendEmailLink"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import AddressInput from '@/components/forms/AddressInput.vue';
    import Question from '@evidentid/ido-lib/interfaces/Question';
    import EmailList from '@/components/forms/EmailList.vue';
    import EntriesTable from '@/components/google/EntriesTable.vue';
    import GlsSingleEmailQuestion from '@/components/google/GlsSingleEmailQuestion/GlsSingleEmailQuestion.vue';

    @Component({
        components: { EntriesTable, EmailList, AddressInput, GlsSingleEmailQuestion },
    })
    export default class GlsEmailQuestion extends Vue {
        @Prop({ type: Object })
        private question!: Question;

        @Prop({ type: Array, default: () => [] })
        private value!: string[];

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Function, default: () => true })
        private canDelete!: (email: string, index: number) => boolean;

        @Prop({ type: Boolean, default: true })
        private allowAdd!: boolean;

        @Prop({ type: Boolean, default: false })
        private singleEmail!: boolean;

        private get empty(): boolean {
            return Boolean(!this.value || this.value.length === 0);
        }

        private get title(): string {
            const { title, submittedTitle } = this.question.metadata;
            return this.disabled || this.question.complete ? submittedTitle || title : title;
        }

        private deleteEmail(email: string) {
            if (!this.disabled) {
                this.$emit('delete', email);
            }
        }

        private get attrType(): string {
            return this.question.attrType;
        }

        private isEmailDisabled(email: string, index: number): boolean {
            return this.disabled || !this.canDelete(email, index);
        }

        private sendEmailLink(email: string): void {
            if (!this.disabled) {
                this.$emit('resend', email);
            }
        }

        private addDataSubject(email: string): void {
            if (!this.disabled) {
                this.$emit('add', email);
            }
        }

        private onInput(email: string[]): void {
            if (!this.disabled) {
                this.$emit('input', email);
            }
        }
    }
</script>
