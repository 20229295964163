import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import SingleLineTextComponent from '@/components/fields/SingleLineText.vue';

export default class SingleLineTextField extends Field<string> {
    public override component = SingleLineTextComponent;
    public allowedCharRegex: RegExp | null = null;

    public isValid(_: Question, value: string): boolean {
        return typeof value === 'string' && value.trim().length > 0;
    }

    public async encode(question: Question, value: string): Promise<AttributesValues> {
        return this._encode(question, value.trim());
    }
}
