import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import { regularMimeTypes, mimeTypes } from '@evidentid/file-utils/mimeTypes';
import { buildBinaryDataObjectFromFile, isFileValid } from '@evidentid/file-utils/prepareFile';
import FileComponent from '@/components/fields/File.vue';

export default class FileField extends Field<File[]> {
    public override component = FileComponent;

    public isValid(question: Question, value: File[]): boolean {
        const imagesOnly = question.metadata && question.metadata.inputType === 'image';
        const mimeTypeWhitelist = imagesOnly ? mimeTypes.image : regularMimeTypes;

        if (!Array.isArray(value) || value.length === 0) {
            return false;
        }

        for (const file of value) {
            if (!isFileValid(file, mimeTypeWhitelist)) {
                return false;
            }
        }

        return true;
    }

    protected async buildPages(value: File[]) {
        const promises = (value || []).map((file) => buildBinaryDataObjectFromFile(file));
        const images = await Promise.all(promises);
        return images.map((content) => ({
            content,
            $objectType: 'DocumentPage',
            capture_method: null,
            captured_at: null,
        }));
    }

    public async encode(question: Question, value: File[]): Promise<AttributesValues> {
        const pages = await this.buildPages(value);

        return this._encode(question, {
            $objectType: 'CapturedDocument',
            content: pages,
            description: null,
            title: null,
        });
    }
}
