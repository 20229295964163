import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import flattenObject from '@evidentid/universal-framework/flattenObject';
import diffObject from '@evidentid/universal-framework/diffObject';
import formatLogData from '@evidentid/logger/formatLogData';
import TrackerAdapter from '../TrackerAdapter';
import TrackerUserData from '../TrackerUserData';
import TrackerEvent from '../TrackerEvent';

export interface HeapAnalyticsTrackerAdapterOptions {
    appId: string;
}

export default class HeapAnalyticsTrackerAdapter<
    T extends TrackerUserData = TrackerUserData
> implements TrackerAdapter<T> {
    private userData: T | null = null;
    private initialized: boolean = false;
    private options: HeapAnalyticsTrackerAdapterOptions;

    private get heap(): any {
        // eslint-disable-next-line
        // @ts-ignore: reaching global Heap
        return window.heap;
    }

    public constructor(options: HeapAnalyticsTrackerAdapterOptions) {
        this.options = options;
    }

    public initialize(): Promise<void> {
        this.initializeHeapTrackingCode();

        // Configure Heap Analytics
        this.heap.load(this.options.appId);
        this.initialized = true;

        return Promise.resolve();
    }

    public isReady(): boolean {
        return this.initialized;
    }

    public track(event: TrackerEvent, timestamp: number): void {
        const { name, ...eventData } = event;
        const data = {
            $time: timestamp,
            ...eventData,
        };
        this.heap.track(name, formatLogData(flattenObject(data), ', '));
    }

    public setUserData(userData: T | null): void {
        if (isEqual(this.userData, userData)) {
            // Do nothing, no user is set
            return;
        } else if (userData === null) {
            // Log out user
            this.userData = null;
            try {
                this.heap.resetIdentity();
            } catch (error) {
                console.error(error);
            }
            return;
        }

        const previousUserData = this.buildUserDataObject(this.userData);
        const nextUserData = this.buildUserDataObject(userData);
        const userDataDiff = diffObject(nextUserData, previousUserData);
        const finalUserDataDiff = omit(userDataDiff.id ? nextUserData : userDataDiff, 'id');

        if (userDataDiff.id) {
            this.heap.identify(userDataDiff.id);
        }

        if (Object.keys(finalUserDataDiff).length > 0) {
            this.heap.addUserProperties(finalUserDataDiff);
        }

        this.userData = userData;
    }

    private buildUserDataObject(userData: T | null): any {
        if (userData === null) {
            return {};
        }

        return formatLogData(flattenObject({
            ...userData,
            name: userData.name || userData.id,
        }), ', ');
    }

    private initializeHeapTrackingCode(): void {
        /* eslint-disable */
        // @ts-ignore: Heap global library
        if (!window.heap) {
            // @ts-ignore: Heap global library
            window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
        }
        /* eslint-enable */
    }
}
