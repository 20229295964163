import { buildDigitRegex } from './buildDigitRegex';

const ssnNumberRegex = buildDigitRegex(`
    ^
        # General SSN exceptions
        (?!111111111)(?!333333333)(?!123456789) # Prevented by TINS
        (?!98765432[0-9]) # For some reason not available - probably test SSNs

        # Specific SSN exceptions
        (?!002281852) (?!042103580) (?!062360749) (?!078051120) (?!095073645)
        (?!128036045) (?!135016629) (?!141186941) (?!165167999) (?!165187999)
        (?!165207999) (?!165227999) (?!165247999) (?!189092294) (?!212097694)
        (?!212099999) (?!306302348) (?!308125070) (?!468288779) (?!549241889)

        # Area number
        (?!9)        # ITIN numbers
        (?!666)      # Just never assigned
        (?!000)      # Null value
        [0-9]{3}

        # Group number
        (?!00)       # Null value
        [0-9]{2}

        # Serial number
        (?!0000)     # Null value
        [0-9]{4}
    $
`);

const ssnPatterns = [
    /^\d{9}$/,
    /^\d{3}-\d{2}-\d{4}$/,
    /^\d{3}\s+\d{2}\s+\d{4}$/,
];

// TODO: Get rid of contrived values on front-end
const ssnContrivedValues = [
    // SSNs commonly shared with RPs for testing purposes
    '000000001',
    '123456789',
    // Other SSNs crawled in eid-simulator
    '111111111',
    '000000000',
    '1234567891',
    '0000000000',
    '000000123',
    '000111111',
    '000111112',
    '000111113',
    '000111114',
    '000111115',
    '000111116',
    '000111117',
    '000111119',
    '000111120',
    '000111121',
    '000111122',
    '000111123',
    '000111124',
    '000111125',
    '000111126',
    '000111127',
    '000111128',
    '000111129',
    '000111130',
    '000111131',
    '000111132',
    '000111133',
    '000111134',
    '000111135',
    '000111136',
    '000111137',
    '000111138',
    '000111139',
    '000111140',
    '000111141',
    '000111142',
    '000000009',
    '100000002',
    '000000002',
    '000000099',
    '000555555',
    '000000014',
];

function isSanitizedSsnValid(sanitizedSsn: null): false;
function isSanitizedSsnValid(sanitizedSsn: string | null): boolean;
function isSanitizedSsnValid(sanitizedSsn: string | null): boolean {
    return sanitizedSsn !== null && (ssnNumberRegex.test(sanitizedSsn) || ssnContrivedValues.includes(sanitizedSsn));
}

export function sanitizeSsn(ssn: null): null;
export function sanitizeSsn(ssn: string | null): string | null;
export function sanitizeSsn(ssn: string | null): string | null {
    if (typeof ssn !== 'string') {
        return null;
    } else if (!ssnPatterns.find((pattern) => pattern.test(ssn))) {
        return null;
    }
    const sanitizedSsn = ssn.replace(/\D+/g, '');
    return isSanitizedSsnValid(sanitizedSsn) ? sanitizedSsn : null;
}

export function isValidSsn(ssn: null): false;
export function isValidSsn(ssn: string | null): boolean;
export function isValidSsn(ssn: string | null): boolean {
    return sanitizeSsn(ssn) !== null;
}
