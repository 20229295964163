<template>
    <div class="MobileIdScanForm">
        <div class="MobileWizardStep">
            <img class="IdScanVisualAid" :src="page.visualAidImageUrl">
            <h2 class="MobileWizardStep__heading">
                {{ page.heading }}
            </h2>
            <IdScanInstructions :instructions="page.instructions" />

            <IdScanPhotoInput
                :value="photo"
                :type="details.acuantType"
                :preview-notice="page.previewNotice"
                :upload-text="page.uploadText"
                :overlay="overlay"
                @input="changePhoto"
                @invalid="onCorruptedFile"
            />

            <QuestionFormControls
                v-bind="$props"
                :valid="isPhotoValid"
                :back-label="isPhotoValid ? 'Re-take photo' : undefined"
                :submit-label="submitLabel"
                @back="onBack"
                @submit="onSubmit"
            />
        </div>

        <ChangeDocumentControl
            :type="documentType"
            :available-document-types="availableDocumentTypes"
            @change="toggleDocumentType"
        />

        <IdScanLoadingOverlay
            v-if="submitting"
            v-bind="$props"
            :progress="uploadProgress"
        />
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import QuestionFormControls from '@/components/forms/QuestionFormControls.vue';
    import IdScanField from '@/fields/IdScanField';
    import documents, { DocumentDetails, DocumentDetailsPage } from '@/config/documents';
    import { DocumentType } from '@evidentid/ido-lib/interfaces/Question';
    import ChangeDocumentControl from '@/components/fields/IdScan/ChangeDocumentControl.vue';
    import IdScanLoadingOverlay from '@/components/fields/IdScan/IdScanLoadingOverlay.vue';
    import IdScanInstructions from '@/components/fields/IdScan/IdScanInstructions.vue';
    import IdScanPhotoPreview from '@/components/fields/IdScan/IdScanPhotoPreview.vue';
    import IdScanPhotoInput from '@/components/fields/IdScan/IdScanPhotoInput.vue';
    import { VerificationResult } from '@/store/interfaces/IdoState';
    import { isFileInstance } from '@evidentid/file-utils/prepareFile';

    @Component({
        inheritAttrs: false,
        components: {
            IdScanPhotoInput,
            IdScanPhotoPreview,
            IdScanInstructions,
            IdScanLoadingOverlay,
            ChangeDocumentControl,
            QuestionFormControls,
        },
    })
    export default class IdScanForm extends FieldComponent<any> {
        private uploadProgress = 0;

        private get isIdScanCaptureOverlayEnabled() {
            return Boolean(this.$store.state.featureFlags.idverify_user_media_overlay_enabled);
        }

        private get isIdScanVerificationEnabled() {
            return Boolean(this.$store.state.featureFlags.idverify_document_capture_retry_enabled);
        }

        private get submitLabel() {
            return this.isLastPage && this.questionsLeft === 1
                ? 'Submit and Complete'
                : 'Continue';
        }

        private get overlay() {
            if (!this.isIdScanCaptureOverlayEnabled) {
                return undefined;
            }

            const overlay = this.page.overlay;
            const country = this.value.country || '';

            if (!overlay) {
                return undefined;
            }

            const countryOverrides = overlay.overrides?.[country.toLowerCase()];
            return countryOverrides
                ? { ...overlay, ...countryOverrides }
                : overlay;
        }

        private get currentPage() {
            return (this.value?.currentPage) || 0;
        }

        private get availableDocumentTypes(): DocumentType[] {
            return (this.field as IdScanField).getPossibleDocumentTypes(this.question);
        }

        private get documentType(): DocumentType {
            return (this.value?.type) || this.availableDocumentTypes[0];
        }

        private get page(): DocumentDetailsPage {
            return this.details.pages[this.currentPage];
        }

        private get photo(): File | undefined {
            return this.value.pages[this.currentPage];
        }

        private get details(): DocumentDetails {
            return documents[this.documentType];
        }

        private get isLastPage() {
            return this.currentPage === this.details.pages.length - 1;
        }

        private get isPhotoValid() {
            const currentPage = this.value.pages[this.currentPage];
            return this.isLastPage ? this.valid : isFileInstance(currentPage);
        }

        private changePhoto(photo: File | undefined) {
            this.$emit('change', {
                ...this.value,
                pages: this.value.pages.slice(0, this.currentPage).concat(photo),
            });
        }

        private clearPhoto() {
            this.changePhoto(undefined);
        }

        private toggleDocumentType(nextDocumentType: DocumentType) {
            this.$emit('change', {
                ...this.value,
                type: nextDocumentType,
                currentPage: 0,
                pages: [],
            });
        }

        private onBack() {
            if (!this.submitting && this.isPhotoValid) {
                this.clearPhoto();
            } else if (!this.submitting) {
                this.$emit('back', this.isPhotoValid);
            }
        }

        private onSubmit() {
            if (this.isLastPage) {
                this.finish();
            } else {
                this.$emit('change', {
                    ...this.value,
                    currentPage: this.currentPage + 1,
                });
            }
        }

        private finish() {
            if (!this.isIdScanVerificationEnabled) {
                return this.legacySubmit();
            }

            const onFinish = () => {
                // Fall back to legacy submission, when there is something wrong with the back-end
                const result = this.verificationResult;
                if (result === VerificationResult.timeout || result === VerificationResult.error) {
                    console.error(`Falling back to legacy submission, result: ${result}`);
                    this.legacySubmit();
                }
            };

            this.$emit('verify', onFinish, this.updateUploadProgress.bind(this));
        }

        private legacySubmit() {
            this.resetUploadProgress();
            this.$emit('submit', undefined, this.updateUploadProgress.bind(this));
        }

        private resetUploadProgress() {
            this.uploadProgress = 0;
        }

        private updateUploadProgress(progress: number) {
            this.uploadProgress = progress;
        }

        private onCorruptedFile() {
            // Show a message to user
            this.$store.dispatch('displaySnackbar', {
                message: 'An error occurred processing this file. Please try again or use a different file',
            });
        }
    }
</script>
