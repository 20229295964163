import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import ConsentComponent from '@/components/fields/Consent.vue';
import Consent from './interfaces/Consent';
import QuestionForm from '@/components/forms/QuestionForm.vue';
import withPropsInjected from '@evidentid/vue-commons/decorators/withPropsInjected';

const INDEPENDENT_CONSENT_ATTRIBUTE_TYPES = [
    'consent.biometric.authorization',
];

const reviewedDisclosureRegEx = /^consent\.fcra(?:\..+|)\.reviewed_disclosure$/;
const stateResidentNoticeRegEx = /^consent\..+\.state_resident_notice$/;

export default class ConsentField extends Field<Consent> {
    public override form = withPropsInjected(QuestionForm, { submitLabel: 'Authorize' });
    public override component = ConsentComponent;

    public isValid(_: Question, value: Consent): boolean {
        return Boolean(value?.authorized);
    }

    public async encode(question: Question, value: Consent): Promise<AttributesValues> {
        const { attrType, metadata: { inputType } } = question;
        const requestedFreeCopy = Boolean(value?.requestedFreeCopy);
        const authorized = Boolean(value?.authorized);

        const consentSubmission = {
            value: authorized,
            shareWith: question.shareWith,
        };

        // Handle the Biometric Consent Authorization differently
        if (INDEPENDENT_CONSENT_ATTRIBUTE_TYPES.includes(attrType)) {
            return {
                [attrType]: consentSubmission,
            };
        }

        const encodedValue: AttributesValues<boolean> = {
            'consent.fcra.authorized': consentSubmission,
            'consent.fcra.reviewed_disclosure': consentSubmission,
        };

        if (reviewedDisclosureRegEx.test(attrType) || stateResidentNoticeRegEx.test(attrType)) {
            encodedValue['consent.fcra.requested_free_copy_state'] = {
                value: requestedFreeCopy,
                shareWith: question.shareWith,
            };
        }

        if (inputType === 'custom_fcra_marker' || attrType !== 'consent.fcra') {
            encodedValue[attrType] = consentSubmission;
        }

        return encodedValue;
    }
}
