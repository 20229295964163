<template>
    <FileUploadInput
        id="generic-document"
        v-model="inputValue"
        :description="description"
        :submitting="submitting"
        :images-only="imagesOnly"
        @input="onChange"
        @invalid="onCorruptedFiles"
    />
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import FileUploadInput from '@/components/forms/FileUploadInput.vue';

    @Component({
        components: { FileUploadInput },
    })
    export default class FileComponent extends FieldComponent<File[]> {
        private inputValue: File[] = this.value || [];

        private get imagesOnly() {
            return this.question.metadata?.inputType === 'image';
        }

        private get description() {
            return this.question.metadata?.description;
        }

        @Watch('value')
        private onValueChange(value: File[]) {
            this.inputValue = value;
        }

        private onChange() {
            this.$emit('change', this.inputValue);
        }

        private onCorruptedFiles(files: (File | null)[]) {
            const fileNames = files.map((file) => file?.name || '<unknown>');
            this.$store.dispatch('displaySnackbar', {
                message: `The following files are empty, corrupted or unsupported: ${fileNames.join(', ')}.`,
            });
        }
    }
</script>
