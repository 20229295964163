<template>
    <portal v-if="open" :to="target">
        <div :key="uuid" class="Modal" :class="additionalClassName" @click="closeModalOnBackdropClick">
            <component :is="form ? 'form' : 'div'" class="Modal__body" @submit="$emit('submit', $event)">
                <header class="Modal__header">
                    <button v-if="allowClose" type="button" class="Modal__headerClose" @click="$emit('close')">
                        <FontAwesomeIcon :icon="closeIcon" />
                    </button>
                    <div class="Modal__headerContent">
                        <slot name="header" />
                    </div>
                </header>
                <div class="Modal__content">
                    <slot />
                </div>
                <div v-if="$scopedSlots.footer" class="Modal__footer">
                    <slot name="footer" />
                </div>
            </component>
        </div>
    </portal>
</template>

<style lang="less">
.Modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: rgba(#2d3e50, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    &__header {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ddd;
        min-height: 60px;
    }

    &__headerClose {
        position: relative;
        display: block;
        border: 0;
        background: transparent;
        padding: 0;
        margin: 0 20px 0 0;
        min-height: 60px;
        width: 60px;
        border-right: 1px solid #ddd;
        justify-self: stretch;
        cursor: pointer;
        outline: none;
        color: #2ab496;
        font-size: 22px;
        line-height: 1em;

        &:hover {
            background: #eee;
        }
    }

    &__headerContent {
        font-size: 20px;

        &:only-child {
            padding: 0 20px;
        }
    }

    &__body {
        background: #fff;
        min-width: 400px;
        max-width: 90vw;
        max-height: 100vh;
        box-shadow: 0 2px 8px 2px rgba(#2d3e50, 0.2);
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        margin: 20px 0;

        > .Button {
            outline: none;
        }

        > .Button + .Button {
            margin-left: 10px;
        }
    }

    &__content {
        padding: 0 20px;
        margin: 20px 0;
        overflow: auto;
        max-height: calc(90vh - 120px);
    }
}
</style>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { IconDefinition, faTimes } from '@fortawesome/free-solid-svg-icons';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class Modal extends Vue {
        @Prop({ type: String, default: 'default-modal-target' })
        private target!: string;

        @Prop({ type: Boolean, default: true })
        private allowClose!: boolean;

        @Prop({ type: Boolean, default: false })
        private closeOnBackdrop!: boolean;

        @Prop({ type: Boolean, default: false })
        public readonly open!: boolean;

        @Prop({ type: Boolean, default: false })
        private form!: boolean;

        @Prop({ type: String, default: null })
        private additionalClassName!: string | null;

        @Prop({ default: () => faTimes })
        private closeIcon!: IconDefinition;

        private uuid = `${Math.random()}.${Math.random()}`;

        private closeModalOnBackdropClick(event: MouseEvent) {
            if (this.closeOnBackdrop && (event.target as HTMLElement).classList.contains('Modal')) {
                this.$emit('close');
            } else {
                event.stopPropagation();
            }
        }

        @Watch('target', { immediate: true })
        private onTargetChange(target: string): void {
            this.$modalManager.registerModal(this, target);
        }

        @Watch('open')
        private onOpenChange(): void {
            this.$modalManager.refresh(this.target);
        }

        private destroyed() {
            this.$modalManager.unregisterModal(this);
        }
    }
</script>
