<template>
    <div class="AddDataSubject" :class="{ 'AddDataSubject--opened': opened }">
        <AddDataSubjectInput
            v-model="email"
            ref="input"
            class="AddDataSubject__input"
            @submit="onSubmit"
            @reset="close"
        />
        <button
            class="AddDataSubject__button"
            type="button"
            @click="open"
        >
            <span class="AddDataSubject__icon">
                <Icon name="plus-circle" />
            </span>

            <span class="AddDataSubject___label">
                {{ label }}
            </span>
        </button>
    </div>
</template>

<style lang="less">
    .AddDataSubject {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &__input {
            flex: 1 0;
        }

        &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: 0;
            padding: 0;
            background: transparent;
            font: inherit;
            color: inherit;
            font-weight: bold;
        }

        &__icon {
            color: #2ab496;
            font-size: 2em;
            line-height: 1em;
            width: 1em;
            height: 1em;
            margin-right: 10px;
            border-radius: 50%;
            text-align: center;
        }

        &___label {
            align-self: center;
            color: #2ab496;
            border: none;
            background: none;
            font-size: 0.95em;
            line-height: 1em;
        }

        &:not(&--opened) .AddDataSubjectInput {
            display: none;
        }

        &--opened &__button {
            display: none;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';
    import AddDataSubjectInput from '@/components/google/AddDataSubjectInput.vue';

    @Component({
        components: { AddDataSubjectInput, Icon },
    })

    export default class AddDataSubject extends Vue {
        private opened: boolean = false;
        private email: string = '';

        @Prop({ type: String, default: 'Add new' })
        private label!: string;

        @Ref()
        private input!: AddDataSubjectInput;

        private open(): void {
            this.opened = true;
            this.input?.focus();
        }

        private close(): void {
            this.opened = false;
            this.email = '';
        }

        private onSubmit(): void {
            this.$emit('submit', this.email);
            this.close();
        }
    }
</script>
