<template>
    <Gls v-if="gls" />
    <InterviewCard v-else>
        <QuestionsList />
    </InterviewCard>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import InterviewCard from '@/layouts/InterviewCard.vue';
    import QuestionsList from '@/components/interview/QuestionsList.vue';
    import { isGlsSession } from '@/interfaces/SessionData';
    import GlsForm from '@/components/google/GlsForm.vue';
    import LogoFooter from '@/components/common/LogoFooter.vue';
    import Gls from '@/views/Gls.vue';

    @Component({
        components: { Gls, LogoFooter, GlsForm, QuestionsList, InterviewCard },
    })
    export default class Overview extends Vue {
        private get gls() {
            const session = this.$store?.state?.session;
            return Boolean(session && isGlsSession(session));
        }
    }
</script>
