<template>
    <label class="LineInputBlock">
        <span v-if="!bare">
            {{ question.metadata.description }}
        </span>
        <Dropdown
            v-model="inputValue"
            :options="[ true, false ]"
            :build-label="buildLabel"
            @input="onChange"
        />
    </label>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import Dropdown from '@/components/forms/Dropdown.vue';

    @Component({
        components: { Dropdown },
    })
    export default class BooleanComponent extends FieldComponent<boolean> {
        private inputValue = this.value;

        private get constraints(): { falseLabel?: string, trueLabel?: string } {
            return JSON.parse(this.question.metadata?.constraints || '{}');
        }

        private buildLabel(value: boolean): string {
            return value
                ? this.constraints?.trueLabel || 'Yes'
                : this.constraints?.falseLabel || 'No';
        }

        @Watch('value')
        private onValueChange(value: boolean) {
            this.inputValue = value;
        }

        private onChange() {
            this.$emit('change', this.inputValue);
        }
    }
</script>
