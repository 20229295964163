import FaceTecWrapper from './internals/FaceTecWrapper';
import { createFaceTecCustomizationOptions } from './internals/createFaceTecCustomizationOptions';
import { ZoomInitializationOptions } from './types';
import brandLogoFilePath from './core-sdk/FaceTec_images/Evident-Logo.png';

export { AbortError } from './internals/AbortError';

export async function initialize(options: ZoomInitializationOptions): Promise<FaceTecWrapper> {
    const wrapper = await FaceTecWrapper.create(options);

    await wrapper.initialize();

    // Customize branding settings
    wrapper.customize(createFaceTecCustomizationOptions({
        brandLogoFilePath,
        brandColorPrimary: '#2eb495',
        brandColorDark: '#2d3f50',
        colorPrimary: '#09b5a3',
        colorBackground: '#fff',
        colorOverlayBackground: 'rgba(45, 62, 80, 0.85)',
        vocalGuidance: true,
    }));

    // Configure localization
    wrapper.localize({
        FaceTec_instructions_message_ready: 'Frame Your Face in the Oval, Press “I’m Ready”, and Move in for Your Close-Up.',
        FaceTec_result_facescan_upload_message: 'Uploading your video selfie',
        FaceTec_result_success_message: 'Capture Succeeded',
        FaceTec_retry_ideal_image_label: 'Good Example',
        FaceTec_camera_permission_message: `
            Evident has detected that your camera permissions are not enabled.
            The button below launches a guide intended to help you troubleshoot camera permissions issues.
            <br>
            <a
                style="margin-top: 20px; font-size: 15px; padding-left: 30px; padding-right: 30px"
                class="Button Button--primary"
                href="https://evidentidsupport.zendesk.com/hc/en-us/articles/360044786613"
                target="_blank"
            >
                Launch Camera Help
            </a>
        `,
    });

    return wrapper;
}
