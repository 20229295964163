<template>
    <div class="StringView">
        <label class="LabeledControl" :for="id">
            <span class="LabeledControl__label">{{ form.schema.title }}</span>

            <input
                :id="id"
                type="text"
                class="StringView__input"
                :value="viewValue"
                :placeholder="form.schema.description"
                @input="onChange"
            >
        </label>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    @Component({
        inheritAttrs: false,
    })
    export default class JsonSchemaStringForm extends AbstractJsonSchemaForm<JsonFormBase<JsonSchemaString>, string> {
        private get viewValue() {
            return typeof this.value === 'string' || typeof this.value === 'number' ? `${this.value}` : '';
        }

        private onChange(event: InputEvent) {
            const inputValue = (event.target as HTMLInputElement).value;
            this.$emit('input', this.form.getValue(inputValue));
        }
    }
</script>
