<template>
    <div class="EinQuestion">
        <label class="LineInputBlock">
            {{ question.metadata.description }}
            <label class="EinQuestion__input" for="ein">
                <input
                    id="ein"
                    type="text"
                    v-model="inputValue"
                    @input="onChange"
                >
            </label>
        </label>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';

    @Component
    export default class Ein extends FieldComponent<string> {
        private inputValue: string = this.value || '';

        @Watch('value')
        private onValueChange(value: string) {
            this.inputValue = value;
        }

        private onChange() {
            this.$emit('change', this.inputValue);
        }
    }
</script>
