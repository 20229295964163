<template>
    <div class="CaseTimeline">
        <ResizeObserver @notify="throttledRecalculate" />
        <div class="CaseTimeline__wrapper">
            <ul class="CaseTimeline__list" ref="list">
                <slot />
            </ul>

            <div class="CaseTimeline__progress CaseTimeline__progress--desktop">
                <div
                    class="CaseTimeline__progressStart"
                    :style="{ minWidth: `${startPx}px`, maxWidth: `${startPx}px`, width: `${startPx}px` }"
                />
                <div class="CaseTimeline__progressContainer">
                    <div class="CaseTimeline__progressInner" :style="'width:' + baseProgress + '%'" />
                </div>
                <div
                    class="CaseTimeline__progressEnd"
                    :style="{ minWidth: `${endPx}px`, maxWidth: `${endPx}px`, width: `${endPx}px` }"
                />
            </div>

            <div class="CaseTimeline__progress CaseTimeline__progress--mobile">
                <div class="CaseTimeline__progressInner" :style="{ height: `${baseProgress}%` }"/>
            </div>
        </div>
    </div>
</template>

<style lang="less">
    .CaseTimeline {
        position: relative;
        padding: 0;
        margin: 0;
        font-size: 0.8em;

        &__wrapper {
            position: relative;
        }

        &__list {
            position: relative;
            display: flex;
            justify-content: space-between;
            list-style: none;
            font-size: 1em;
            margin: 0;
            padding: 0;
        }

        &__progress {
            position: absolute;
            display: flex;
            align-items: stretch;
            background: #e4e4e4;
            height: 4px;
            left: 0;
            right: 0;
            top: 1.6 * 2.3em / 2;
            transform: translateY(-50%);

            &--mobile {
                right: auto;
                left: 0.9em;
                top: 1.6em;
                bottom: 1.6em;
                width: 4px;
                height: auto;
                transform: translateX(-50%);
            }

            &--mobile &Inner {
                width: 100%;
            }
        }

        &__progressInner {
            height: 100%;
            background: #2ab496;
            transition: 300ms width linear;
        }

        &__progressContainer {
            flex: 1 auto;
        }

        .CaseTimelineItem {
            flex: 1 0;

            &__icon {
                box-shadow: 0 0 0 10px #fbfcfc;
            }

            &:first-child:before,
            &:last-child:before {
                content: "";
                z-index: 1;
                position: absolute;
                display: block;
                background: #fbfcfc;
                top: 0;
                bottom: 0;
                pointer-events: none;
            }

            &:first-child:before {
                left: 0;
                right: 50%;
            }

            &:last-child:before {
                left: 50%;
                right: 0;
            }
        }

        @media (min-width: 481px) {
            &__progress--mobile {
                display: none;
            }
        }

        @media (max-width: 480px) {
            display: flex;
            justify-content: center;

            &__wrapper {
                display: flex;
                justify-content: center;
            }

            &__list {
                flex-direction: column;
            }

            &__progress--desktop {
                display: none;
            }

            .CaseTimelineItem {
                flex-direction: row;
                text-align: left;
                margin: 0.8em 0;

                &__icon {
                    font-size: 0.8em;
                    font-weight: bold;
                    margin: 0 10px 0 0;
                    box-shadow: 0 0 0 2px #fbfcfc;
                }

                &__footer {
                    margin-top: 3px;
                }
            }
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    // @ts-ignore: no TS definitions
    import { ResizeObserver } from 'vue-resize';
    import debounce from 'lodash/debounce';
    import 'vue-resize/dist/vue-resize.css';

    @Component({
        components: { ResizeObserver },
    })
    export default class CaseTimeline extends Vue {
        @Prop({ type: Number, default: 0.1 })
        private progress!: number; // 0 - 1

        private startPx: number = 0;
        private endPx: number = 0;

        private get list(): HTMLUListElement {
            return this.$refs.list as HTMLUListElement;
        }

        // Valid progress in 0-100 scale
        private get baseProgress(): number {
            return Math.max(0, Math.min(isNaN(this.progress) ? 0 : this.progress * 100, 100));
        }

        private throttledRecalculate = debounce(this.recalculate.bind(this), 100);

        private recalculate() {
            // Find base width for correlation
            const steps = this.list?.querySelectorAll('.CaseTimelineItem') || [];

            // Exit early if something is wrong with the content
            if (steps.length === 0) {
                return;
            }

            // Find first and last step for guidance
            const firstStep = steps[0];
            const lastStep = steps[steps.length - 1];

            // Find icons for correlation
            const firstIcon = firstStep.querySelector('.CaseTimelineItem__icon') as HTMLDivElement;
            const lastIcon = lastStep.querySelector('.CaseTimelineItem__icon') as HTMLDivElement;

            // Get bounding client rects
            const firstStepRect = firstStep.getBoundingClientRect();
            const lastStepRect = lastStep.getBoundingClientRect();
            const firstIconRect = firstIcon.getBoundingClientRect();
            const lastIconRect = lastIcon.getBoundingClientRect();

            // Get dimensions
            this.startPx = firstIconRect.right - firstStepRect.left;
            this.endPx = lastStepRect.right - lastIconRect.left;
        }

        private mounted(): void {
            this.recalculate();
        }
    }
</script>
