import transformObject from 'lodash/transform';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';

/**
 * Build key for preflight check.
 * it should add `preflight_check` segment as the 3rd or last segment.
 *
 * Example:
 * - key: identity_assurance.document_verification.americas.us.drivers_license.image.back
 * - becomes: identity_assurance.document_verification.preflight_check.americas.us.drivers_license.image.back
 *
 * @param {string} originalType
 * @returns {string}
 */
export function buildPreflightAttributeType(originalType: string): string {
    return /^[^.]+\.[^.]+\./.test(originalType)
        ? originalType.replace(/^[^.]+\.[^.]+\./, (segments) => `${segments}preflight_check.`)
        : `${originalType}.preflight_check`;
}

export default function buildPreflightAttributesValues(attributesValues: AttributesValues): AttributesValues {
    return transformObject(attributesValues, (obj, value, key) => {
        obj[buildPreflightAttributeType(key)] = { ...value };
    });
}
