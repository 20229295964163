import isEqual from 'lodash/isEqual';
import TrackerAdapter from '../TrackerAdapter';
import TrackerUserData from '../TrackerUserData';
import TrackerEvent from '../TrackerEvent';
import formatLogData from '@evidentid/logger/formatLogData';
import flattenObject from '@evidentid/universal-framework/flattenObject';

export interface MixpanelTrackerAdapterOptions {
    token: string;
}

export default class MixpanelTrackerAdapter<T extends TrackerUserData = TrackerUserData> implements TrackerAdapter<T> {
    private userData: T | null = null;
    private mixpanel: any;
    private options: MixpanelTrackerAdapterOptions;

    public constructor(options: MixpanelTrackerAdapterOptions) {
        this.options = options;
    }

    public async initialize() {
        // eslint-disable-next-line
        // @ts-ignore: MixPanel is not detected correctly
        const { default: MixPanel } = await import('mixpanel-browser');
        this.mixpanel = MixPanel;
        this.mixpanel.init(this.options.token);
    }

    public isReady() {
        return Boolean(this.mixpanel);
    }

    public track(event: TrackerEvent, timestamp: number) {
        const { name, ...eventData } = event;
        const { id } = this.userData || {};
        const userData = id ? { $distinct_id: id } : {};
        const data = {
            $time: timestamp,
            ...userData,
            ...eventData,
        };
        this.mixpanel.track(name, formatLogData(flattenObject(data), ', '));
    }

    public setUserData(userData: T | null) {
        if (isEqual(this.userData, userData)) {
            // Do nothing, no user is set
            return;
        } else if (userData === null) {
            // Log out user
            this.userData = null;
            this.mixpanel.reset();
            return;
        }

        const { id, name, email, ...profile } = userData;

        if (this.userData?.id !== id) {
            this.mixpanel.identify(id);
        }

        const finalUserData = {
            $distinct_id: id,
            $email: email,
            $name: name || id,
            ...profile,
        };

        this.mixpanel.people.union(formatLogData(flattenObject(finalUserData), ', '));
        this.userData = userData;
    }
}
