<template>
    <label :class="disabled ? 'disabledRehabQ' : ''">
        <input type="checkbox" :checked="value" @input="onChange">
        <Icon :name="value ? 'checked' : 'unchecked'" /> <slot />
    </label>
</template>

<script lang="ts">
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';

    @Component({
        components: { Icon },
    })
    export default class ChargeCheckbox extends Vue {
        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Boolean, default: false })
        private value!: boolean;

        private onChange() {
            this.$emit('input', !this.value);
        }
    }
</script>
