import moment from 'moment';
import { CriminalOffenseDate } from '@/interfaces/OffenseCategory';

function formatCriminalOffenseDate(date: CriminalOffenseDate | null): string {
    if (!date) {
        return 'Date Not Found';
    }
    const nativeDate = new Date(date.year, date.month, date.day);
    return moment(nativeDate).format('MMMM Do, YYYY');
}

export default formatCriminalOffenseDate;
