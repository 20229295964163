import sortBy from 'lodash/sortBy';
import googleLogoImageUrl from '@evidentid/ido-lib/images/google-logo.svg';
import Identity from '@evidentid/ido-lib/interfaces/Identity';
import { GlsCaseData, GlsCaseSubmission } from '@evidentid/gls-service-api-client/types';
import Question from '@evidentid/ido-lib/interfaces/Question';
import UserData from '@/interfaces/UserData';
import { defaultPrivacyPolicy } from '@/config/issuer';
import { ValuesMap } from '@/store/interfaces/IdoState';

// Error link
export const glsErrorLinkUrl = 'https://www.evidentid.com/registration-not-found-google/';

// Attribute types for GLS-specific attributes
export enum GlsAttributeType {
    businessAddress = 'gls.businessaddress',
    businessOwners = 'gls.businessowners',
    businessOwnersAndFieldworkers = 'gls.businessownersandfieldworkers',
    fieldWorkers = 'gls.fieldworkers',
}

// Factory for GLS identity
export function createGlsIdentity(): Identity {
    return {
        id: 'internal\/CN=internal-gls-flow\/internal',
        displayName: 'Local Services Ads',
        primaryColor: '#5f97f5',
        wordmarkUri: googleLogoImageUrl,
        consentHtml: {},
        supportContactInfo: null,
        idoConfirmationMessage: null,
        idoStatusMessages: null,
        privacyPolicy: { ...defaultPrivacyPolicy },
    };
}

// Auth domain passed for UserData
export const glsUserAuthDomain = 'gls.evidentid.com';

// Build questions
export type GlsQuestion = Omit<Question, 'shareWith' | 'hasError' | 'complete'> & { attrType: GlsAttributeType };
type GlsQuestionFactory = () => GlsQuestion;

export const buildBusinessAddressQuestion: GlsQuestionFactory = () => ({
    attrType: GlsAttributeType.businessAddress,
    type: 'address',
    metadata: {
        required: true,
        icon: 'keyboard',
        title: 'Registered Business Address',
        description: 'Please provide the registered name of your business as found on license and legal documents.',
        completedInformation: 'Please provide the registered name of your business as found on license and legal documents.',
        emptyInformation: 'Please provide the registered name of your business as found on license and legal documents.',
    },
});

export const buildFieldworkersQuestion: GlsQuestionFactory = () => ({
    attrType: GlsAttributeType.fieldWorkers,
    type: 'bulk-email',
    metadata: {
        required: false,
        autoConfirmation: true,
        icon: 'keyboard',
        title: 'Employee Email Addresses',
        instructions: 'Please enter the email addresses of all employees at your business.',
        completedInformation: 'Below are the employee email addresses provided.',
        emptyInformation: 'You haven\'t provided any email addresses of employees.',
        description: `
            An employee is defined as any worker, contractor or subcontractor who provides services in customer’s homes, 
            workplaces or other properties. Office workers that do not enter homes or properties do not need a background check. 
            Not all business types require employee background checks. Please note that every email address listed here 
            will require a background check. Avoid submitting multiple email addresses that belong to the same 
            individual to avoid any potential delays. To learn more about authorized representatives and employees,
            <a href="https://support.google.com/google-ads/answer/6226575" target="_blank">
                click here
            </a>
        `,
    },
});

export const buildBusinessOwnersQuestion: GlsQuestionFactory = () => ({
    attrType: GlsAttributeType.businessOwners,
    type: 'bulk-email',
    metadata: {
        required: true,
        autoConfirmation: true,
        icon: 'keyboard',
        title: 'Business Owner Email Addresses',
        completedInformation: 'Below are the business owner and senior partner email addresses provided.',
        emptyInformation: 'Please enter all business owner email addresses for your business. ',
        instructions: 'Please enter all business owner email addresses for your business. ',
        description: `
                Ensure that all email addresses are business owners only. Employee, contractor or sub-contractor email addresses should not be included.
        `,
    },
});

export const buildPrimaryBusinessOwnerQuestion: GlsQuestionFactory = () => ({
    attrType: GlsAttributeType.businessOwners,
    type: 'bulk-email',
    metadata: {
        required: true,
        autoConfirmation: true,
        icon: 'keyboard',
        title: 'Primary Business Owner Email Address',
        completedInformation: 'Below is the email address of the primary business owner you provided.',
        emptyInformation: 'You haven\'t provided any email addresses of business owners.',
        description: `
            Please enter business owner email addresses for your business.
        `,
    },
});

export const buildBusinessOwnerOnlyQuestion: GlsQuestionFactory = () => ({
    attrType: GlsAttributeType.businessOwners,
    type: 'bulk-email',
    metadata: {
        required: true,
        autoConfirmation: true,
        icon: 'keyboard',
        title: 'Business Owner Email Address',
        completedInformation: 'Below is the email address of the primary business owner you provided.',
        emptyInformation: 'You haven\'t provided any email addresses of business owners.',
        description: `
            Please enter business owner email address for your business.
        `,
    },
});

export const buildSeniorPartnersQuestion: GlsQuestionFactory = () => ({
    attrType: GlsAttributeType.businessOwnersAndFieldworkers,
    type: 'bulk-email',
    metadata: {
        required: true,
        autoConfirmation: true,
        icon: 'keyboard',
        title: `
            Business Owners and Senior Partner Email Addresses
        `,
        instructions: 'Please enter all business owner and senior partner email addresses for your business. ',
        completedInformation: 'Below are the business owner and senior partner email addresses provided.',
        emptyInformation: 'Please enter all business owner email addresses for your business.',
        description: `
            All individuals will be required to pass to obtain Google’s Badge of Trust.
            <div style="font-style: italic">
                Note: The first email address will be considered the primary Business Owner and will receive status updates. The primary business owner's email address cannot be edited once set. Please ensure that you're using the correct primary business owner's email address to avoid any issues moving forward with your account.
            </div>
        `,
    },
});

export const buildProfessionalsQuestion: GlsQuestionFactory = () => ({
    attrType: GlsAttributeType.businessOwnersAndFieldworkers,
    type: 'bulk-email',
    metadata: {
        required: true,
        autoConfirmation: true,
        icon: 'keyboard',
        title: `
            Email Address of Business Owners and Customer-facing Professionals in Your Company
            (Only enter those email addresses that have not previously been submitted to Evident for Local Services Ads for Google)
        `,
        submittedTitle: 'Business Owners and Customer-facing Professionals in Your Company Email Address',
        completedInformation: `
            Below are the email addresses of the business owners and customer-facing professionals
            in your company you provided.
        `,
        emptyInformation: `
            You haven’t provided any email addresses for customer-facing professionals in your company.
        `,
        description: `
            Please enter the email addresses of all customer-facing professionals at your business.
            Professionals are employees of your firm such as a lawyers, real estate agents,
            or finance professionals who directly interact with customers and provide the core service of your company.
            It does not include business or administrative employees such as receptionists or office managers.
            <a href="https://support.google.com/google-ads/answer/6226575?hl=en&ref_topic=6224863" target="_blank">
                Learn more about who is a business owner and who is a customer-facing professional
            </a>
            <div style="margin-top: 10px">
                All individuals listed below will be required to pass the check for your business to obtain Google badge of trust.
            </div>
            <div style="margin-top: 10px; font-style: italic">
                Note: The first email address will be considered the primary Business Owner
                and will receive communications on status of this application.
            </div>
        `,
    },
});

type GlsValuesFactory = (
    caseData: GlsCaseData,
    submittedData: GlsCaseSubmission | null,
    userData: UserData,
) => ValuesMap;

const buildList = (list: string[]) => ({ list, confirmed: true });
const extractEmails = (list: { email: string }[] | undefined) => list?.map((x) => x.email);

export const buildBusinessAddressValues: GlsValuesFactory = (caseData, submittedData) => ({
    [GlsAttributeType.businessAddress]: submittedData?.businessAddress || caseData?.businessAddress || null,
});

export const buildFieldworkersValues: GlsValuesFactory = (caseData, submittedData) => ({
    [GlsAttributeType.fieldWorkers]:
        buildList(extractEmails(submittedData?.fieldWorkers) || []),
});

export const buildFieldworkerOnlyValues: GlsValuesFactory = (caseData, submittedData, userData) => ({
    [GlsAttributeType.fieldWorkers]: submittedData
        ? buildList(extractEmails(submittedData.fieldWorkers) || [])
        : buildList([ userData.email ]),
});

export const buildBusinessOwnersValues: GlsValuesFactory = (caseData, submittedData, userData) => ({
    [GlsAttributeType.businessOwners]: buildList(
        sortBy(
            (extractEmails(submittedData?.businessOwners) || [ userData.email ]),
            (x) => (x !== submittedData?.primaryBusinessOwner),
        ),
    ),
});

export const buildSeniorPartnersValues: GlsValuesFactory = (caseData, submittedData, userData) => ({
    [GlsAttributeType.businessOwnersAndFieldworkers]: submittedData
        ? buildList(extractEmails([ ...submittedData.businessOwners, ...submittedData.fieldWorkers ]) || [])
        : buildList([ userData.email ]),
});

export const buildProfessionalsValues: GlsValuesFactory = (caseData, submittedData, userData) => ({
    [GlsAttributeType.businessOwnersAndFieldworkers]: submittedData
        ? buildList(extractEmails(submittedData.fieldWorkers) || [])
        : buildList([ userData.email ]),
});
