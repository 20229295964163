<template>
    <span v-if="hasNextDocumentType" class="ChangeDocumentControl">
        {{ documentDetails.unavailableDocumentText }}
        <a href="javascript:void(0)" @click="toggleDocumentType">
            {{ nextDocumentDetails.useAsAlternativeDocumentText }}
        </a>
    </span>
</template>

<script lang="ts">
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import documents, { DocumentDetails } from '@/config/documents';
    import { DocumentType } from '@evidentid/ido-lib/interfaces/Question';

    @Component
    export default class ChangeDocumentControl extends Vue {
        @Prop({ type: String })
        private type!: DocumentType;

        @Prop({ type: Array, default: () => [] })
        private availableDocumentTypes!: DocumentType[];

        private get documentDetails(): DocumentDetails {
            return documents[this.type];
        }

        private get nextDocumentDetails(): DocumentDetails {
            return documents[this.nextDocumentType];
        }

        private get hasNextDocumentType(): boolean {
            return this.type && this.availableDocumentTypes.length > 0 && this.nextDocumentType !== this.type;
        }

        private get nextDocumentType(): DocumentType {
            const documentTypes = this.availableDocumentTypes;
            const currentIndex = documentTypes.indexOf(this.type);
            const nextIndex = (currentIndex + 1) % documentTypes.length;
            return documentTypes[nextIndex];
        }

        private toggleDocumentType() {
            this.$emit('change', this.nextDocumentType);
        }
    }
</script>
