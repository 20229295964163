<template>
    <div class="LegacyGoogleBusinessVerification">
        <img :src="logoUrl" alt="Evident ID" class="LegacyGoogleBusinessVerification__logo">

        <div class="LegacyGoogleBusinessVerification__content">
            <p>
                This site is no longer receiving background check applications for Local Services Ads.
                A new process is now available through your Local Services Ads dashboard.
                You can log in here <a href="https://ads.google.com/localservices">https://ads.google.com/localservices</a>
                and proceed with the Evident background check process with a direct link from the dashboard.
            </p>
            <p>
                Don't have a Local Services Ads account yet? Get started here
                <a href="https://g.co/localservices">[g.co/localservices]</a>
            </p>
        </div>

        <div class="LegacyGoogleBusinessVerification__footer">
            For further questions, contact Google's Local Services Ads team at: 1-833-272-1444.
        </div>
    </div>
</template>

<style lang="scss">
    $vertical-margin: 30px;

    .LegacyGoogleBusinessVerification {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 1000px;
        min-height: 100vh;
        min-height: calc(100vh - #{$vertical-margin * 2});
        max-width: 80%;
        margin: $vertical-margin auto;

        &__logo {
            margin-bottom: 50px;
        }

        &__footer {
            text-align: center;
            align-self: stretch;
            padding: 20px;
            margin-top: 50px;
            background: #2ab496;
            color: #fff;
        }

        p + p {
            margin-top: 20px;
        }
    }
</style>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import evidentWordmarkDarkSvgUrl from '@evidentid/assets-bridge/evident-wordmark.svg';

    // TODO: Remove when we will no longer support legacy GLS Flow
    @Component
    export default class LegacyGoogleBusinessVerification extends Vue {
        private logoUrl = evidentWordmarkDarkSvgUrl;
    }
</script>
