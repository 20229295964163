import formatBalanceDue from '@evidentid/stripe-pos/formatBalanceDue';
import { TrackerEventConsumersMap } from '@evidentid/tracker/TrackerEventConsumer';
import IdoEventHandlers from '@/tracking/IdoEventHandlers';
import IdoTrackerUserData from '@/tracking/IdoTrackerUserData';
import { VerificationResult } from '@/store/interfaces/IdoState';
import { isGlsSession } from '@/interfaces/SessionData';

function isPreflightCheckAttributeType(attributeType: string): boolean {
    return typeof attributeType === 'string' && attributeType.includes('.preflight_check');
}

const trackerEventConsumers: Partial<TrackerEventConsumersMap<IdoEventHandlers, IdoTrackerUserData>> = {
    // Base events
    onErrorChange: (_, error) => ({
        name: error ? 'Error shown' : 'Error hidden',
        description: error ? `Error shown: ${error.reason || 'unknown'}` : 'Error hidden',
        reason: error?.reason,
        message: error?.message,

    }),
    onSnackbarMessage: (_, message, success) => ({
        name: message ? 'Snackbar opened' : 'Snackbar closed',
        description: message ? `Snackbar opened (${success ? 'success' : 'error'}): ${message}` : 'Snackbar closed',
        message,
        success,
    }),

    // Routing events
    onRouteChange: (_, route) => ({
        name: 'Route change',
        description: `Route change: ${route || '<unknown>'}`,
        route: route || '<unknown>',
    }),
    onCurrentQuestionChange: (_, attributeType, fieldType) => {
        if (attributeType && fieldType) {
            return {
                name: 'Question opened',
                description: `Question opened: ${fieldType}`,
                attributeType,
                fieldType,
            };
        } else {
            return {
                name: 'Question closed',
                description: 'Question closed',
            };
        }
    },

    // User events
    onUserChange: (tracker, email, inGoogleFlow) => {
        tracker.appendUserData({ email: email || undefined, inGoogleFlow });
    },

    // Issuer events
    onIssuerChange: (tracker, id) => {
        tracker.appendUserData({ issuer: id || undefined });
    },

    // Basic interview events
    onLoadingStatusChange: (_, status) => ({
        name: 'Change interview loading status',
        description: `Change interview loading status: ${status}`,
        status,
    }),
    onInterviewRequest: (tracker, session) => {
        if (session && isGlsSession(session)) {
            tracker.appendUserData({ id: `GLS.${session.caseId}` });
        } else {
            tracker.appendUserData({ id: session?.rprId });
        }

        return {
            name: 'Loading interview details',
            description: 'Loading interview details',
        };
    },
    onFeatureFlagsChange: (tracker, featureFlags) => {
        tracker.appendUserData({ flags: featureFlags });
    },

    // Question manipulation events
    onValueChange: (_, attributeType, value) => {
        const valueType = value?.type || undefined;
        const valuePages = value?.pages ? value.pages.filter(Boolean).length : undefined;
        const valueCurrentPage = (value?.currentPage + 1) || undefined;
        const filled = valuePages === undefined ? undefined : valuePages === valueCurrentPage;
        const status = filled ? 'filled' : 'not filled';

        return ({
            name: 'Attribute value changed',
            description: valuePages === undefined
                ? `Attribute value changed: ${attributeType}`
                : `Attribute value changed: ${attributeType} (page: ${valueCurrentPage}) (current page: ${status})`,
            ignoreConsecutiveDuplicate: true,
            valueEmpty: !value,
            valueSinglePageUpload: value?.useSinglePageUpload,
            valueCurrentPageFilled: filled,
            valueCurrentPage,
            valuePages,
            valueType,
            attributeType,
        });
    },
    onSubmissionRequest: (_, attributeTypes) => ({
        name: 'Attribute submission started',
        description: `Attribute submission started: ${attributeTypes[0]}`,
        firstAttributeType: attributeTypes[0],
        attributeTypes,
    }),
    onQuestionsChange: (tracker, questions, prevQuestions) => {
        const completedQuestions = questions.filter((question) => question.complete);
        const newlyCompletedQuestions = completedQuestions.filter((question) => {
            const prevQuestion = prevQuestions?.find((q) => q.attrType === question.attrType);
            return prevQuestion && !prevQuestion.complete;
        });
        const questionsLeft = questions.length - completedQuestions.length;

        // Track change of questions number
        if (questions.length !== prevQuestions.length || newlyCompletedQuestions.length) {
            return {
                name: 'Number of questions change',
                description: `Number of questions change: ${questionsLeft} left`,
                questionsNumber: questions.length,
                questionsLeft,
            };
        }
    },
    onQuestionComplete: (_, attributeType) => ({
        name: 'Attribute completed',
        description: `Attribute completed: ${attributeType}`,
        attributeType,
    }),
    onSubmissionStatusChange: (tracker, initiated, finished) => {
        if (initiated.length) {
            const preflightCheck = Boolean(initiated.find(isPreflightCheckAttributeType));
            const label = preflightCheck ? 'Pre-flight attribute' : 'Attribute';
            tracker.track({
                name: `${label} processing initiated`,
                description: `${label} processing initiated`,
                attributeTypes: initiated,
                firstAttributeType: initiated[0],
            });
        }

        if (finished.length) {
            const preflightCheck = Boolean(finished.find(isPreflightCheckAttributeType));
            const label = preflightCheck ? 'Pre-flight attribute' : 'Attribute';
            tracker.track({
                name: `${label} processing finished`,
                description: `${label} processing finished`,
                attributeTypes: finished,
                firstAttributeType: finished[0],
            });
        }
    },
    onVerificationRequest: (_, attributeTypes) => ({
        name: 'Attribute verification requested',
        description: 'Attribute verification requested',
        firstAttributeType: attributeTypes[0],
        attributeTypes,
    }),
    onVerificationResultChange: (_, attributeTypes, result, failureReason) => {
        if (result !== VerificationResult.uninitialized) {
            return {
                name: 'Attribute verification result',
                description: `Attribute verification result: ${result} (reason: ${failureReason || 'none'})`,
                firstAttributeType: attributeTypes[0],
                attributeTypes,
                failureReason,
                result,
            };
        }
    },
    onDelegationPhoneNumberChange: (_, value, country, valid) => {
        const name = value ? 'Update delegation phone number' : 'Clear delegation phone number';
        return {
            name,
            description: value ? `${name}: ${valid ? 'valid' : 'invalid'}` : name,
            ignoreConsecutiveDuplicate: true,
            country,
            valid,
        };
    },
    onDelegationRequest: (_, attributeType) => ({
        name: 'Delegation requested',
        description: 'Delegation requested',
        attributeType,
    }),
    onDelegationStart: (_, attributeType) => ({
        name: 'Delegation start',
        description: 'Delegation start',
        attributeType,
    }),
    onDelegationFinish: (_, attributeType) => ({
        name: 'Delegation sent',
        description: 'Delegation sent',
        attributeType,
    }),
    onDelegationFailure: (_, attributeType) => ({
        name: 'Delegation failed',
        description: 'Delegation failed',
        attributeType,
    }),

    // Payment events
    onPaymentRequest: (_, balance) => ({
        name: 'Payment requested',
        description: 'Payment requested',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        readableBalance: balance ? formatBalanceDue(balance.currency!, balance.amount) : null,
        balanceAmount: balance?.amount,
        balanceCurrency: balance?.currency,
    }),
    onPaymentFinish: () => ({
        name: 'Payment finished',
        description: 'Payment finished',
    }),

    // GLS
    onAddGlsBusinessOwner: (_, email, { businessOwners, fieldWorkers }) => ({
        name: 'Adding GLS business owner requested',
        description: 'Adding GLS business owner requested',
        existsBo: businessOwners.includes(email),
        existsFw: fieldWorkers.includes(email),
        numberBo: businessOwners.length,
        numberFw: fieldWorkers.length,
    }),
    onAddGlsFieldWorker: (_, email, { businessOwners, fieldWorkers }) => ({
        name: 'Adding GLS field worker requested',
        description: 'Adding GLS field worker requested',
        existsBo: businessOwners.includes(email),
        existsFw: fieldWorkers.includes(email),
        numberBo: businessOwners.length,
        numberFw: fieldWorkers.length,
    }),
    onDeleteGlsBusinessOwner: (_, email, { businessOwners, fieldWorkers }) => ({
        name: 'Deleting GLS business owner requested',
        description: 'Deleting GLS business owner requested',
        existsBo: businessOwners.includes(email),
        existsFw: fieldWorkers.includes(email),
        numberBo: businessOwners.length,
        numberFw: fieldWorkers.length,
    }),
    onDeleteGlsFieldWorker: (_, email, { businessOwners, fieldWorkers }) => ({
        name: 'Deleting GLS field worker requested',
        description: 'Deleting GLS field worker requested',
        existsBo: businessOwners.includes(email),
        existsFw: fieldWorkers.includes(email),
        numberBo: businessOwners.length,
        numberFw: fieldWorkers.length,
    }),
    onGlsUpdate: (_, data) => {
        if (data) {
            return {
                name: 'GLS data updated',
                description: 'GLS data updated',
                submissionStatus: data.submissionStatus,
                caseStatus: data.caseStatus,
                caseSubmitted: Boolean(data.caseData),
                ignoreConsecutiveDuplicate: true,
            };
        }
    },
    onResendGlsSubmissionLinkRequest: (_, fieldWorkers, businessOwners, unknownEmails) => ({
        name: 'Resend GLS submission requested',
        description: 'Resend GLS submission requested',
        numberFw: fieldWorkers.length,
        numberBo: businessOwners.length,
        numberUnknown: unknownEmails.length,
    }),
    onResendGlsSubmissionLinkFinish: (_, fieldWorkers, businessOwners, unknownEmails) => ({
        name: 'Resend GLS submission finished',
        description: 'Resend GLS submission finished',
        numberFw: fieldWorkers.length,
        numberBo: businessOwners.length,
        numberUnknown: unknownEmails.length,
    }),
    onResendGlsSubmissionLinkFailure: (_, fieldWorkers, businessOwners, unknownEmails) => ({
        name: 'Resend GLS submission failed',
        description: 'Resend GLS submission failed',
        numberFw: fieldWorkers.length,
        numberBo: businessOwners.length,
        numberUnknown: unknownEmails.length,
    }),
};

export default trackerEventConsumers;
