import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import SelectMultipleOptionsComponent from '@/components/fields/SelectMultipleOptions.vue';

export default class SelectMultipleOptionsField extends Field<string[]> {
    public override component = SelectMultipleOptionsComponent;

    public isValid(question: Question, value: string[]): boolean {
        const options = JSON.parse(question.metadata?.constraints || '{}')?.options || [];
        return Array.isArray(value) && value.length > 0 && value.every((x) => options.includes(x));
    }

    public async encode(question: Question, value: string[]): Promise<AttributesValues> {
        return this._encode(question, value);
    }

    public override getDefaultValue(question: Question): string[] {
        return [];
    }
}
