<template>
    <InterviewCard>
        <SuccessScreen>
            <h3>{{ heading }}</h3>
            <p class="RequestComplete__CmsCopy">
                {{ message }}
            </p>
            <a v-if="continueUrl" class="RequestComplete__continue" :href="continueUrl">Continue</a>
        </SuccessScreen>
    </InterviewCard>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import SuccessScreen from '@/components/screens/SuccessScreen.vue';
    import InterviewCard from '@/layouts/InterviewCard.vue';
    import { isGlsSession } from '@/interfaces/SessionData';

    @Component({
        components: { InterviewCard, SuccessScreen },
    })
    export default class ThankYou extends Vue {
        @Prop({ type: Boolean, default: false })
        private disableRefresh!: boolean;

        private scheduledRefresh: any = null;

        private get issuer() {
            return this.$store.state.issuer;
        }

        private get continueUrl() {
            const session = this.$store.state.session;

            if (!session || isGlsSession(session)) {
                return null;
            }

            return session.continueUrl || null;
        }

        private get confirmationMessages(): { headingText?: string, messageText?: string } {
            const issuer = this.issuer;
            return issuer?.idoConfirmationMessage || {};
        }

        private get heading() {
            if (!this.issuer) {
                return 'Thank you for submitting your information to us';
            }

            return this.confirmationMessages.headingText ||
                `Thank you for submitting your information to ${this.issuer.displayName}`;
        }

        private get message() {
            return this.confirmationMessages.messageText ||
                'You will be notified once your submission has finished processing.';
        }

        private mounted() {
            this.scheduleRefresh();
        }

        private destroyed() {
            this.scheduledRefresh = null;
            clearTimeout(this.scheduledRefresh);
        }

        private scheduleRefresh() {
            if (this.disableRefresh || this.scheduledRefresh || this.continueUrl) {
                return;
            }

            this.scheduledRefresh = setTimeout(() => {
                this.$store.dispatch('reloadInterview', { blockImmediateFinish: false });
            }, 30000);
        }
    }
</script>
