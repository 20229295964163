<template>
    <div class="SsnQuestion">
        <label class="LineInputBlock">
            {{ question.metadata.description }}
            <label class="SsnQuestion__input" for="ssn">
                <input
                    id="ssn"
                    v-model="inputValue"
                    :type="showHiddenCharacters ? 'text' : 'password'"
                    @focus="onInputFocus"
                    @blur="onInputBlur"
                    @input="onChange"
                >
                <Icon
                    touch-action="auto"
                    :name="showHiddenCharacters ? 'no-eye' : 'eye'"
                    @click="toggleVisibility"
                />
            </label>
            <div v-if="errorVisible" class="SsnQuestion__error">
                SSN must be 9 numeric characters ex ###-##-#### and a valid SSN
            </div>
        </label>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';
    import FieldComponent from '@/fields/FieldComponent';

    @Component({
        components: { Icon },
    })
    export default class Ssn extends FieldComponent<string> {
        private inputValue: string = this.value || '';
        private showHiddenCharacters: boolean = false;

        private focused = false;

        private get errorVisible(): boolean {
            return Boolean(this.value) && !this.valid && (
                !this.focused || (this.value.replace(/\D+/g, '').length >= 9)
            );
        }

        private toggleVisibility(): void {
            this.showHiddenCharacters = !this.showHiddenCharacters;
        }

        @Watch('value')
        private onValueChange(value: string): void {
            this.inputValue = value;
        }

        private onChange(): void {
            this.$emit('change', this.inputValue);
        }

        private onInputFocus(): void {
            this.focused = true;
        }

        private onInputBlur(): void {
            this.focused = false;
        }
    }
</script>
