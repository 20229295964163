// @ts-ignore: changing "errors" to TS is not required yet, while it will take additional time
import { definition } from './errors';

// eslint-disable-next-line max-len
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isUrl(url: string): boolean {
    try {
        return Boolean(new URL(url));
    } catch (error) {
        return false;
    }
}

export function isEmail(eml: string): boolean {
    return typeof eml === 'string' && eml.length <= 254 && EMAIL_REGEX.test(eml);
}

export const RequiredValue = definition(null, {
    reason: 'validation/value-required',
    message: 'This value is required.',
});

export const NotAnEmail = definition(null, {
    reason: 'validation/invalid-email',
    message: 'Invalid email',
});
