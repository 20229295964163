import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import AddressComponent from '@/components/fields/Address.vue';
import Address from './interfaces/Address';

export default class AddressField extends Field<Address> {
    public override component = AddressComponent;

    public isValid(_: Question, value: Address): boolean {
        const { street, city, state, postcode } = value || {};
        return Boolean(street && city && state && postcode);
    }

    public async encode(question: Question, value: Address): Promise<AttributesValues> {
        if (!value) {
            return this._encode(question, null);
        }
        const { street, city, state, postcode } = value || {};
        return this._encode(question, `${street}, ${city}, ${state} ${postcode}`);
    }
}
