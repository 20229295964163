import { Component } from 'vue';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import { IdoStore } from '@/store/interfaces/IdoStore';
import Question from '@evidentid/ido-lib/interfaces/Question';
import QuestionView from '@/components/interview/QuestionView.vue';
import QuestionForm from '@/components/forms/QuestionForm.vue';
import InterviewCard from '@/layouts/InterviewCard.vue';

type OptionallyAsyncComponent = Component | (() => Promise<Component> | Promise<{ default: Component }>);

export default abstract class Field<Value = any> {
    public layout: OptionallyAsyncComponent = InterviewCard;
    public viewController: OptionallyAsyncComponent = QuestionView;
    public form: OptionallyAsyncComponent = QuestionForm;
    public component: OptionallyAsyncComponent | null = null;
    private dataPromise?: Promise<any>;
    private isLoaded = false;

    public constructor() {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        if (!this.encode) {
            throw new Error('"encode" method is required for Field');
        }

        // eslint-disable-next-line @typescript-eslint/unbound-method
        if (!this.isValid) {
            throw new Error('"isValid" method is required for Field');
        }

        // eslint-disable-next-line @typescript-eslint/unbound-method
        if (!this.prepareData) {
            this.isLoaded = true;
        }
    }

    public get ready() {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        return this.isLoaded || !this.prepareData;
    }

    public initialize(store: Pick<IdoStore, 'state' | 'dispatch'>) {
        if (this.ready) {
            return Promise.resolve();
        }

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        if (this.dataPromise) {
            return this.dataPromise;
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const promise = this.prepareData!(store);

        if (promise === null) {
            this.isLoaded = true;
            this.dataPromise = Promise.resolve();
            return this.dataPromise;
        }

        this.dataPromise = promise;

        // Set-up side effects for data loading
        this.dataPromise.then(() => {
            this.isLoaded = true;
        }, (error) => {
            this.dataPromise = undefined;
            console.error(`${this.constructor.name}: initialization failed`, error);
        });

        return this.dataPromise;
    }

    public getDefaultValue(question: Question): Value | null {
        return null;
    }

    public abstract isValid(question: Question, value: Value): boolean;
    public abstract encode(question: Question, value: Value): Promise<AttributesValues>;

    protected prepareData?(store: Pick<IdoStore, 'state' | 'dispatch'>): Promise<any> | null;

    protected _encode(question: Question, value: any): AttributesValues {
        const attrType = (question.metadata && question.metadata.submissionAttrType) || question.attrType;
        return {
            [attrType]: {
                shareWith: question.shareWith || [],
                value,
            },
        };
    }
}
