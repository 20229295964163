import formatBalanceDue from '@evidentid/stripe-pos/formatBalanceDue';
import Severity from '@evidentid/logger/Severity';
import { LoggerEventConsumersMap } from '@evidentid/logger/LoggerEventConsumer';
import IdoEventHandlers from '@/tracking/IdoEventHandlers';
import IdoLoggerUserData from '@/tracking/IdoLoggerUserData';
import { RelyingPartyRequestFeatureFlags } from '@evidentid/ido-lib/interfaces/AttributeRelyingPartyRequest';
import { isGlsSession } from '@/interfaces/SessionData';

const loggerEventConsumers: Partial<LoggerEventConsumersMap<IdoEventHandlers, IdoLoggerUserData>> = {
    // Base events
    onErrorChange: (_, error) => {
        // Lift error when it is a JavaScript error
        if (error && error instanceof Error) {
            // Do not capture cancelled network requests as errors in Sentry, as we can't do anything about it
            // It needs to be inside, otherwise TypeScript will detect `error` type incorrectly
            if ((error as any).reason !== 'cancelled-network-request') {
                setTimeout(() => {
                    throw error;
                });
            }
        }

        // Add new breadcrumb
        return {
            level: error ? Severity.warning : Severity.info,
            category: 'error',
            message: error ? 'Error shown' : 'Error hidden',
            data: {
                reason: (error && error.reason) || '',
                message: (error && error.message) || '',
                xhr: Boolean(error?.xhr || error?.xhrObj),
                native: Boolean(error && error instanceof Error),
            },
        };
    },
    onSnackbarMessage: (_, message, success) => ({
        category: 'snackbar',
        message: message ? `Snackbar opened: ${message}` : 'Snackbar closed',
        data: { success },
    }),

    // Routing events
    onRouteChange: (scope, route) => {
        scope.setTag('route', route || '<unknown>');
    },
    onCurrentQuestionChange: (scope, attributeType, fieldType) => {
        scope.setTag('question', attributeType || '<none>');
        scope.setTag('field', fieldType || '<none>');
    },

    // User events
    onUserChange: (scope, email, inGoogleFlow) => {
        scope.setTag('google-flow', inGoogleFlow ? 'yes' : 'no');

        if (email) {
            scope.appendUserData({ email });
        } else {
            scope.setUser(null);
        }

        return {
            category: 'user',
            message: email ? 'Loaded user data' : 'Cleared user data',
        };
    },

    // Issuer events
    onIssuerChange: (scope, id) => {
        scope.setTag('rp', id || '<none>');
        scope.setTag('google-flow-worker', id && id.startsWith('gls-') ? 'yes' : 'no');
    },

    // Basic interview events
    onLoadingStatusChange: (_, status) => ({
        category: 'interview',
        message: 'Loading status updated',
        data: { status },
    }),
    onInterviewRequest: (scope, userSession) => {
        if (userSession) {
            if (isGlsSession(userSession)) {
                scope.appendUserData({ id: `GLS.${userSession.caseId}` });
            } else {
                scope.appendUserData({ id: userSession.rprId });
            }
        }

        return {
            category: 'interview',
            message: '[Requested] Loading interview',
            data: { session: Boolean(userSession), gls: Boolean(userSession && isGlsSession(userSession)) },
        };
    },
    onFeatureFlagsChange: (scope, featureFlags) => {
        for (const flag of Object.keys(featureFlags)) {
            scope.setTag(
                `flag.${flag}`,
                featureFlags[flag as keyof RelyingPartyRequestFeatureFlags] ? 'yes' : 'no'
            );
        }
    },

    // Question manipulation events
    onQuestionsChange: (_, questions) => ({
        category: 'interview',
        message: 'Questions updated',
        data: {
            questions: (questions || []).map((x) => `${x.complete ? '⦿' : '◦'} ${x.attrType}`),
        },
    }),
    onSubmissionRequest: (_, attributeTypes) => ({
        category: 'interview',
        message: '[Requested] Attributes submission',
        data: { attributeTypes },
    }),
    onSubmissionStatusChange: (_, initiatedAttributeTypes, finishedAttributeTypes) => ({
        category: 'interview',
        message: 'Change submission statuses',
        data: { initiatedAttributeTypes, finishedAttributeTypes },
    }),
    onVerificationRequest: (_, attributeTypes) => ({
        category: 'interview',
        message: '[Requested] Attributes verification',
        data: { attributeTypes },
    }),
    onVerificationResultChange: (_, attributeTypes, result, failureReason) => ({
        category: 'interview',
        message: 'Change verification statuses',
        data: { attributeTypes, result, failureReason },
    }),

    // Fetching additional data for questions
    onFetchAttributesDataRequest: (_, attributeTypes, fieldTypes) => ({
        category: 'fetch-attribute',
        message: '[Requested] Fetch attribute data',
        data: { attributeTypes, fieldTypes },
    }),
    onFetchAttributesDataFinish: (_, attributeTypes, fieldTypes) => ({
        category: 'fetch-attribute',
        message: '[Finished] Fetch attribute data',
        data: { attributeTypes, fieldTypes },
    }),
    onFetchAcademicProvidersRequest: (_) => ({
        category: 'fetch-attribute',
        message: '[Requested] Load academic providers',
    }),
    onFetchAcademicProvidersFinish: (_, providers) => ({
        category: 'fetch-attribute',
        message: providers && providers.length
            ? `Updated academic providers (${providers.length})`
            : 'Cleared academic providers',
    }),
    onFetchUtilityProvidersRequest: (_) => ({
        category: 'fetch-attribute',
        message: '[Requested] Load utility providers',
    }),
    onFetchUtilityProvidersFinish: (_, providers) => ({
        category: 'fetch-attribute',
        message: providers && providers.length
            ? `Updated utility providers (${providers.length})`
            : 'Cleared utility providers',
    }),
    onFetchCriminalOffensesRequest: (_) => ({
        category: 'fetch-attribute',
        message: '[Requested] Load criminal offenses',
    }),
    onFetchCriminalOffensesFinish: (_, offenses) => ({
        category: 'fetch-attribute',
        message: offenses && offenses.length
            ? `Updated criminal offenses (${offenses.length})`
            : 'Cleared criminal offenses',
    }),

    // Delegation events
    onDelegationPhoneNumberChange: (_, value, country, valid) => ({
        category: 'delegation',
        message: value ? 'Update delegation phone number' : 'Clear delegation phone number',
        data: { valid, country },
    }),
    onDelegationRequest: (_, attributeType) => ({
        category: 'delegation',
        message: '[Requested] Delegation',
        data: { attributeType },
    }),
    onDelegationStart: (_, attributeType) => ({
        category: 'delegation',
        message: 'Delegation started',
        data: { attributeType },
    }),
    onDelegationFinish: (_, attributeType) => ({
        category: 'delegation',
        message: 'Delegation finished',
        data: { attributeType },
    }),
    onDelegationFailure: (_, attributeType) => ({
        level: Severity.error,
        category: 'delegation',
        message: 'Delegation failed',
        data: { attributeType },
    }),

    // Consent & transition
    onTransitionInformationRequest: () => ({
        category: 'transition',
        message: '[Requested] Transition information',
    }),
    onTransitionInformationFinish: () => ({
        category: 'transition',
        message: '[Finished] Transition information',
    }),
    onSendConsentRequest: () => ({
        category: 'transition',
        message: '[Requested] Give IDO consent for sending data',
    }),
    onSendConsentStatusChange: (_, given) => ({
        category: 'transition',
        message: given ? 'Approved IDO consent for sharing' : 'Revoked IDO consent for sharing',
    }),

    // Payment events
    onStripeInitializationRequest: (_, balance) => ({
        category: 'payment',
        message: '[Requested] Stripe initialization',
        data: {
            balance: balance ? formatBalanceDue(balance.currency, balance.amount) : null,
        },
    }),
    onStripeInitializationFinish: () => ({
        category: 'payment',
        message: '[Finished] Stripe initialization',
    }),
    onPaymentRequest: (_, balance) => ({
        category: 'payment',
        message: '[Requested] Payment',
        data: {
            balance: balance ? formatBalanceDue(balance.currency, balance.amount) : null,
        },
    }),
    onPaymentFinish: () => ({
        category: 'payment',
        message: '[Finished] Payment',
    }),

    // GLS
    onAddGlsBusinessOwner: (_, email, { businessOwners, fieldWorkers }) => ({
        category: 'gls',
        message: '[Requested] Adding business owner',
        data: {
            existsBo: businessOwners.includes(email),
            existsFw: fieldWorkers.includes(email),
            numberBo: businessOwners.length,
            numberFw: fieldWorkers.length,
        },
    }),
    onAddGlsFieldWorker: (_, email, { businessOwners, fieldWorkers }) => ({
        category: 'gls',
        message: '[Requested] Adding field worker',
        data: {
            existsBo: businessOwners.includes(email),
            existsFw: fieldWorkers.includes(email),
            numberBo: businessOwners.length,
            numberFw: fieldWorkers.length,
        },
    }),
    onDeleteGlsBusinessOwner: (_, email, { businessOwners, fieldWorkers }) => ({
        category: 'gls',
        message: '[Requested] Deleting business owner',
        data: {
            existsBo: businessOwners.includes(email),
            existsFw: fieldWorkers.includes(email),
            numberBo: businessOwners.length,
            numberFw: fieldWorkers.length,
        },
    }),
    onDeleteGlsFieldWorker: (_, email, { businessOwners, fieldWorkers }) => ({
        category: 'gls',
        message: '[Requested] Deleting field worker',
        data: {
            existsBo: businessOwners.includes(email),
            existsFw: fieldWorkers.includes(email),
            numberBo: businessOwners.length,
            numberFw: fieldWorkers.length,
        },
    }),
    onGlsUpdate: (_, data) => {
        if (data) {
            return {
                category: 'gls',
                message: 'GLS data updated',
                data: {
                    submissionStatus: data.submissionStatus,
                    caseStatus: data.caseStatus,
                    caseSubmitted: Boolean(data.caseData),
                },
            };
        } else {
            return {
                category: 'gls',
                message: 'GLS data cleared',
            };
        }
    },
    onResendGlsSubmissionLinkRequest: (_, fieldWorkers, businessOwners, unknownEmails) => ({
        category: 'gls',
        message: '[Requested] Resend submission link',
        data: {
            numberFw: fieldWorkers.length,
            numberBo: businessOwners.length,
            numberUnknown: unknownEmails.length,
        },
    }),
    onResendGlsSubmissionLinkFinish: (_, fieldWorkers, businessOwners, unknownEmails) => ({
        category: 'gls',
        message: '[Finished] Resend submission link',
        data: {
            numberFw: fieldWorkers.length,
            numberBo: businessOwners.length,
            numberUnknown: unknownEmails.length,
        },
    }),
    onResendGlsSubmissionLinkFailure: (_, fieldWorkers, businessOwners, unknownEmails) => ({
        category: 'gls',
        message: '[Failed] Resend submission link',
        data: {
            numberFw: fieldWorkers.length,
            numberBo: businessOwners.length,
            numberUnknown: unknownEmails.length,
        },
    }),
};

export default loggerEventConsumers;
