<template>
    <SingleCol :footer-stretch-x="stretch" :is-chat-available="hasInsuranceQuestion">
        <div v-if="isMobile && !wordmarkUri" class="InterviewCard" :class="{ 'InterviewCard--stretch': stretch }">
            <div class="InterviewCard__body">
                <slot />
            </div>
        </div>
        <div v-else class="InterviewCard" :class="{ 'InterviewCard--stretch': stretch }">
            <div class="InterviewCard__header" :style="headerStyles">
                <div v-if="wordmarkUri" class="InterviewCard__wordmark">
                    <img :src="wordmarkUri" :alt="dbaNameText">
                </div>
                <template v-if="isMobile">
                    &nbsp;
                </template>
                <div v-else class="InterviewCard__message" :style="messageStyles">
                    {{ headingText }}
                </div>
                <div v-if="isUserInGoogleFlow" class="InterviewCard__logout">
                    <LogoutButton :email="user.email" />
                    <Icon name="caret-down" />
                </div>
            </div>
            <div class="InterviewCard__body">
                <slot />
            </div>
            <div>
                <slot name="contactFooter" />
            </div>
        </div>
        <template #footer>
            <slot name="footer" />
        </template>
    </SingleCol>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { isGlsSession } from '@/interfaces/SessionData';
    import Icon from '@/components/common/Icon.vue';
    import support from '@/config/support';
    import SingleCol from './SingleCol.vue';
    import { getBrandingColor } from '@/config/brandingColor';

    @Component({
        inheritAttrs: false,
        components: {
            LogoutButton: () => import('@/components/user/LogoutButton.vue'),
            SingleCol,
            Icon,
        },
    })
    export default class InterviewCard extends Vue {
        @Prop({ type: Boolean, default: support.isMobileBrowser })
        private isMobile!: boolean;

        @Prop({ type: Boolean, default: false })
        private stretch!: boolean;

        @Prop({ type: String, default: null })
        private title!: string | null;

        private get done() {
            return this.$store.state.questions
                .reduce((acc, question) => acc && question.complete, true);
        }

        private get issuer() {
            return this.$store.state.issuer;
        }

        private get user() {
            return this.$store.state.user;
        }

        private get headingText() {
            if (this.done) {
                return 'Your information has been submitted';
            }

            const brandingTitle = this.wordmarkUri
                ? 'is requesting information from you'
                : `${this.dbaNameText} is requesting information from you`;
            return this.title || brandingTitle;
        }

        private get dbaNameText() {
            return this.issuer?.displayName || '{{COMPANY}}';
        }

        private get brandingColor(): string {
            return getBrandingColor(this.issuer?.primaryColor);
        }

        private get wordmarkUri() {
            return this.issuer?.wordmarkUri || null;
        }

        private get isUserInGoogleFlow() {
            return Boolean(this.user && this.$store.state.session && isGlsSession(this.$store.state.session));
        }

        private get headerStyles() {
            return `border-bottom: 3px solid ${this.brandingColor}`;
        }

        private get messageStyles() {
            return `text-align: ${this.done ? 'right' : 'left'}`;
        }

        private get hasInsuranceQuestion() {
            const questions = this.$store.state?.questions || [];
            const attributeTypes = this.$store.state.attributeSharingAgreements
                ? this.$store.state.attributeSharingAgreements.map((asa) => asa.attribute_type)
                : questions.map((question) => question.attrType);
            return attributeTypes.some((attrType) => this.isInsuranceAttribute(attrType));
        }

        private isInsuranceAttribute(attribute: string): boolean {
            return Boolean(attribute.match(/risk_decisioning\.insurance_policy_verification\.self_attestation\.([^.]+).*\.(enterprise|advanced)/));
        }
    }
</script>
