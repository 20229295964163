<template>
    <div class="IdScanLoadingOverlay">
        <AlternativeSpinner v-if="progress === 1">
            {{ message }}
            <ProgressBar />
        </AlternativeSpinner>
        <AlternativeSpinner v-else-if="progress">
            {{ message }}
            <ProgressBar
                :value="progress"
                :label="(progress * 100).toFixed(1) + '%'"
            />
        </AlternativeSpinner>
        <AlternativeSpinner v-else>
            {{ message }}
        </AlternativeSpinner>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import AlternativeSpinner from '@/components/common/AlternativeSpinner.vue';
    import { VerificationFailureReason, VerificationResult } from '@/store/interfaces/IdoState';
    import * as verificationConfig from '@/config/attributesVerification';
    import ProgressBar from '@/components/common/ProgressBar.vue';

    @Component({
        components: { ProgressBar, AlternativeSpinner },
    })
    export default class IdScanLoadingOverlay extends Vue {
        @Prop(String)
        protected verificationResult!: VerificationResult;

        @Prop({ type: String, default: null })
        protected verificationFailureReason!: VerificationFailureReason | null;

        @Prop({ type: Number, default: 0 })
        protected verificationResolutionAttempts!: number;

        @Prop({ type: Number, default: 0 })
        protected progress!: number;

        @Prop(Boolean)
        protected submitting!: boolean;

        private get message() {
            if (this.verificationResult === VerificationResult.uploading) {
                // Image is being uploaded for preflight check.
                return verificationConfig.stateMessages.uploading;
            } else if (this.verificationResult === VerificationResult.error && this.submitting) {
                // There was some unknown problem, and the field is still submitting,
                // so we assume that it has fallback to the legacy way.
                return verificationConfig.stateMessages.fallback;
            } else if (this.verificationResult !== VerificationResult.verifying) {
                // Ensure that we will show some meaningful value,
                // even when the overlay is visible for unknown reason.
                return 'Please wait…';
            }

            // Show the proper message, based on the time user had to wait
            const progress = this.verificationResolutionAttempts / verificationConfig.maxPollingTimes;
            const messageIndex = Math.min(
                Math.floor(progress * verificationConfig.waitingMessages.length),
                verificationConfig.waitingMessages.length - 1,
            );
            return verificationConfig.waitingMessages[messageIndex];
        }
    }
</script>

<style lang="less">
    .IdScanLoadingOverlay .ProgressBar {
        max-width: 90vw;
        width: 300px;
        margin-top: 20px;
    }
</style>
