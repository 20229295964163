export interface SelfieContents {
    heading: string;
    reason: string;
    delegationInstructions: string;
}

const vanillaSelfieContent: SelfieContents = {
    heading: 'Smile! We need you to take a selfie.',
    reason: (
        `In order to verify your identity, we need to compare a
        photo of you to the photograph on your identity document.`
    ),
    delegationInstructions: (
        `By entering your phone number, you agree to receive a one-time text message with instructions for secure
        selfie image verification.`
    ),
};

const paymentCardSelfieContent: SelfieContents = {
    heading: 'We need your selfie with a payment card.',
    reason: (
        `In order to verify your identity, we need a photo of
        you holding your payment card. Please take a selfie with your
        face and the details of your card clearly visible.`
    ),
    delegationInstructions: (
        `By entering your phone number, you agree to receive a one-time text message with instructions for secure
        selfie image verification.`
    ),
};

const selfieContents: Record<string, SelfieContents> = {
    vanillaSelfieContent,
    paymentCardSelfieContent,
};

export default selfieContents;
