<template>
    <v-component
        :is="field.layout"
        v-bind="passedData"
        @back="onBack"
        @change="onChange"
        @verify="onVerify"
        @submit="onSubmit"
        @delegate="onDelegate"
        @delegationChange="onDelegationChange"
    >
        <v-component
            :is="field.form"
            v-bind="passedData"
            @back="onBack"
            @change="onChange"
            @verify="onVerify"
            @submit="onSubmit"
            @delegate="onDelegate"
            @delegationChange="onDelegationChange"
        >
            <v-component
                :is="field.component"
                v-bind="passedData"
                @back="onBack"
                @change="onChange"
                @verify="onVerify"
                @submit="onSubmit"
                @delegate="onDelegate"
                @delegationChange="onDelegationChange"
            />
        </v-component>
        <template v-if="shouldDisplayContactFooter" #contactFooter>
            <Footer :title="question.metadata.title" :licenses="smartAttributeLicenses" />
        </template>
    </v-component>
</template>

<script lang="ts">
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import LicenseQuestionContactFooter from '@/components/common/Footer/LicenseQuestionContactFooter.vue';
    import License from '@/interfaces/licenses/License';
    import { licenseStateNamesWithCustomValidation } from '@/utils/licenseValidation/constants';
    import { Status, VerificationFailureReason, VerificationResult } from '@/store/interfaces/IdoState';
    import Question from '@evidentid/ido-lib/interfaces/Question';
    import Field from '@/fields/Field';
    import { maxDelegationsPerAttribute } from '@/config/phoneDelegations';
    import PhoneNumber from '@/fields/interfaces/PhoneNumber';

    @Component({
        components: { Footer: LicenseQuestionContactFooter },
    })
    export default class QuestionView extends Vue {
        @Prop(Object)
        private question!: Question;

        @Prop(Object)
        private field!: Field;

        private get passedData() {
            const { field, value, question } = this;
            return {
                field,
                question,
                value,
                valid: this.valid,
                submitting: this.submitting,
                questionsLeft: this.questionsLeft,
                delegationStatus: this.delegationStatus,
                delegationsLeft: this.delegationsLeft,
                delegationPhoneNumber: this.$store.state.delegationPhoneNumber || null,
                verificationResult: this.verificationResult,
                verificationFailureReason: this.verificationFailureReason,
                verificationResolutionAttempts: this.verificationResolutionAttempts,
                verificationsFailed: this.verificationsFailed,
            };
        }

        private get questionsLeft() {
            return this.$store.state.questions
                .reduce((acc, question) => (question.complete ? acc : acc + 1), 0);
        }

        private get value() {
            const { field, question } = this;
            const value = this.$store.state.values[question.attrType];
            return value == null && field ? field.getDefaultValue(question) : value;
        }

        private get submitting() {
            return this.$store.state.submission[this.question.attrType];
        }

        private get delegationsLeft() {
            const delegation = this.$store.state.delegationsRequested[this.question.attrType];
            return maxDelegationsPerAttribute - (delegation ? delegation.requests : 0);
        }

        private get delegationStatus() {
            const delegation = this.$store.state.delegationsRequested[this.question.attrType];
            return delegation ? delegation.status : Status.uninitialized;
        }

        private get verification() {
            return this.$store.state.verificationsRequested[this.question.attrType];
        }

        private get verificationResolutionAttempts() {
            const verification = this.verification;
            return verification?.resolutionAttempts || 0;
        }

        private get verificationResult() {
            const verification = this.verification;
            return verification ? verification.result : VerificationResult.uninitialized;
        }

        private get verificationFailureReason() {
            const verification = this.verification;
            return verification?.result === VerificationResult.failed
                ? verification.failureReason || VerificationFailureReason.unknown
                : null;
        }

        private get verificationsFailed() {
            const verification = this.verification;
            return verification?.failures || 0;
        }

        private get valid() {
            return this.field.isValid(this.question, this.value);
        }

        private get shouldDisplayContactFooter(): boolean {
            return licenseStateNamesWithCustomValidation.some((name) => this.question.type === `smart-attribute:${name}`);
        }

        private get smartAttributeLicenses(): License[] {
            return this.value?.licenses ?? [];
        }

        private onChange(value: any) {
            return this.$store.dispatch('changeValue', {
                name: this.question.attrType,
                value,
            });
        }

        private async onSubmit(done: () => void, onUploadProgress?: (progress: number) => void) {
            const { field, question, value } = this;

            if (!field.isValid(question, value)) {
                return;
            }

            await this.$store.dispatch('submitAttributes', {
                attributes: await field.encode(question, value),
                onUploadProgress,
            });

            if (typeof done === 'function') {
                done();
            }
        }

        private async onVerify(done: () => void, onUploadProgress?: (progress: number) => void) {
            const { field, question, value } = this;

            if (!field.isValid(question, value)) {
                return;
            }

            await this.$store.dispatch('verifyAttributes', {
                attributes: await field.encode(question, value),
                onUploadProgress,
            });

            if (typeof done === 'function') {
                done();
            }
        }

        private onDelegate() {
            this.$store.dispatch('delegate', this.question.attrType);
        }

        private onDelegationChange(phoneNumber: PhoneNumber | null) {
            this.$store.dispatch('setDelegationPhoneNumber', phoneNumber);
        }

        private onBack() {
            this.$router.push({ name: 'overview' });
        }
    }
</script>
