import Question from '@evidentid/ido-lib/interfaces/Question';
import getSmartAttributeName from '@/fields/getSmartAttributeName';
import { isSelfieImageAttributeType, isVideoSelfieAttributeType } from '@evidentid/ido-lib/curation/videoSelfie';
import { coiInputAttributeRegex } from '@/utils/insurance';

export const specificTypes: Record<string, string> = {
    'core.ssn': 'ssn',
    'cpr.input': 'cpr',
    'business.ein': 'ein',
    'business.full_address': 'address',
    'identity_assurance.address_verification': 'external-provider',
    'core.fullname': 'full-name',
    'core.pastname': 'past-name',
    'core.dateofbirth': 'birthday',
    'identity.phonenumber': 'phone-number',
    'driverslicense.state': 'us-state',
    'risk_decisioning.insurance_policy_verification.ivaas.franchise_business.input.location_state': 'us-state',
    'license.watercraft.us.coast_guard.mmc.reference.number': 'mmc-reference-number',
    'identity_assurance.proofing.selfie_with_payment_card': 'selfie',
    'identity_assurance.document_verification.selfie_to_document.selfie_image': 'selfie',
    'business.employee_list.provided_email_addresses': 'bulk-email',
    'license.professional.us.attorney_bar.license_details': 'bar-license-details',
    'risk_decisioning.insurance_policy_verification.contact_broker_consent': 'consent',
    'risk_decisioning.insurance_policy_verification.broker_input.us.statement_of_truth': 'statement-of-truth',
};

export const inputTypeMappings: Record<string, string> = {
    PhoneNumber: 'phone-number',
    Address: 'address',
    file: 'file',
    image: 'file',
    boolean: 'boolean',
    date: 'date',
    integer: 'integer',
    string_enum: 'string-enum',
    string_enum_list: 'string-enum-list',
    email: 'email',
};

export default function getFieldType(question: Question): string {
    const attrType = question.attrType;
    if (specificTypes[attrType]) {
        return specificTypes[attrType];
    }

    if (/^risk_decisioning\.insurance_policy_verification\.broker_input\..+\.expiration_date$/.test(attrType)) {
        return 'future-date';
    }

    if (/^risk_decisioning\.insurance_policy_verification\.broker_input\..+\.policy_number/.test(attrType)) {
        return 'insurance-policy-number';
    }

    // Detect smart attributes
    const smartAttributeName = getSmartAttributeName(question);
    if (smartAttributeName !== null) {
        return `smart-attribute:${smartAttributeName}`;
    }

    // Does attr type represent ID scan
    if (attrType.startsWith('identity_assurance.document_verification') && !attrType.includes('selfie')) {
        return 'id-scan';
    }

    if (attrType.startsWith('consent')) {
        return 'consent';
    }

    if (attrType.startsWith('education.postsecondary.academic_institution')) {
        return 'academic-provider';
    }

    if (attrType.startsWith('background.criminal.individualized_assessment')) {
        return 'individualized-assessment';
    }

    if (isVideoSelfieAttributeType(attrType)) {
        return isSelfieImageAttributeType(attrType) ? 'selfie' : 'liveness-check';
    }

    if (coiInputAttributeRegex.test(attrType)) {
        return 'coi-scan';
    }

    const inputType = question.metadata.inputType || '';

    if (inputTypeMappings[inputType]) {
        return inputTypeMappings[inputType];
    }

    return 'text';
}
