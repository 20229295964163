export default function trackXhrUploadProgress(
    xhr: XMLHttpRequest,
    onProgressChange: (progress: number) => void
): void {
    const finish = () => onProgressChange(1);
    if (xhr.readyState === 4) {
        return finish();
    }

    xhr.upload.addEventListener('progress', (event: ProgressEvent) => {
        const precision = 0.001;
        if (event.lengthComputable) {
            onProgressChange(Math.min(1, Math.floor(event.loaded / precision / event.total) * precision));
        }
    });

    xhr.upload.addEventListener('load', finish);
    xhr.upload.addEventListener('error', finish);
    xhr.upload.addEventListener('abort', finish);
    xhr.upload.addEventListener('timeout', finish);
    xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === 4) {
            finish();
        }
    });
}
