<template>
    <input
        :id="id"
        ref="input"
        class="PhoneNumberInput"
        type="text"
        @input="onInput"
        @change="onChange"
        @countrychange="onChange"
    >
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import intlTelInput, { Plugin as IntlTelInputPlugin } from 'intl-tel-input';
    import 'intl-tel-input/build/js/utils';
    import PhoneNumberValue from '@/fields/interfaces/PhoneNumber';

    @Component
    export default class PhoneNumberInput extends Vue {
        @Prop({ type: String, default: undefined })
        private id!: string;

        @Prop({ default: '' })
        private value!: string | PhoneNumberValue;

        private intlTelInput: IntlTelInputPlugin | null = null;
        private internalChangeInProgress: boolean = false;

        private get telInput(): Element | null {
            return this.intlTelInput ? (this.intlTelInput as any).telInput : null;
        }

        private get input() {
            return this.$refs.input as HTMLInputElement;
        }

        private mounted() {
            this.intlTelInput = intlTelInput(this.input);
            this.setInternalValue(this.value);
        }

        private beforeDestroy() {
            if (this.intlTelInput && this.telInput) {
                // intlTelInput has problems with detaching from DOM on Vue component destroy,
                // So this small hack will isolate and remove the input safely.
                const div = document.createElement('div');
                div.appendChild(this.telInput);
                document.body.appendChild(div);
                this.intlTelInput.destroy();

                if (this.input && this.input.parentNode) {
                    this.input.parentNode.removeChild(this.input);
                }
            }
        }

        private setInternalValue(rawValue: string | PhoneNumberValue) {
            if (!this.intlTelInput) {
                throw new Error('IntlTelInput is not initialized.');
            }

            this.internalChangeInProgress = true;

            const value = typeof rawValue === 'string' ? { number: rawValue, country: null } : rawValue;

            if (value) {
                if (value.country) {
                    this.intlTelInput.setCountry(value.country);
                }

                if (value.number && value.number !== this.input.value) {
                    this.intlTelInput.setNumber(value.number);
                }
            } else if (!value) {
                this.intlTelInput.setNumber('');
            }

            this.internalChangeInProgress = false;
        }

        @Watch('value')
        private onExternalChange(value: string | PhoneNumberValue) {
            if (!this.intlTelInput) {
                throw new Error('IntlTelInput is not initialized.');
            }

            this.setInternalValue(value);
        }

        private onInput() {
            if (this.internalChangeInProgress) {
                return;
            }
            this.emitChangeEvent();
        }

        private onChange() {
            if (!this.intlTelInput) {
                throw new Error('IntlTelInput is not initialized.');
            }
            this.intlTelInput.setNumber(this.input.value);
            this.emitChangeEvent();
        }

        private emitChangeEvent() {
            if (!this.intlTelInput) {
                throw new Error('IntlTelInput is not initialized.');
            }

            if (this.internalChangeInProgress) {
                return;
            }

            const phone = this.input.value.replace(/\D/g, '');
            const valid = Boolean(this.intlTelInput.isValidNumber());
            const countryData = this.intlTelInput.getSelectedCountryData();

            this.$emit('input', {
                number: phone,
                dialCode: countryData.dialCode,
                country: countryData.iso2,
                qualifiedNumber: valid ? `+${countryData.dialCode || 1} ${phone}` : null,
                valid,
            });
        }
    }
</script>
