const fontawesome = (name: string) => `fa fa-${name}`;

const fontAwesomeMap: Record<string, string> = {
    'icon-alert': fontawesome('exclamation-triangle'),
    'icon-arrow-tailed-down': fontawesome('arrow-down'),
    'icon-arrow-tailed-up': fontawesome('arrow-up'),
    'icon-balance-scale': fontawesome('balance-scale'),
    'icon-building': fontawesome('building'),
    'icon-calendar': fontawesome('calendar'),
    'icon-camera': fontawesome('camera'),
    'icon-cancel': fontawesome('times-circle'),
    'icon-car': fontawesome('car'),
    'icon-caret-up': fontawesome('caret-up'),
    'icon-caret-down': fontawesome('caret-down'),
    'icon-check-circle-filled': fontawesome('check-circle'),
    'icon-check-circle-outlined': fontawesome('check-circle-o'),
    'icon-checked': fontawesome('check-square'),
    'icon-checkmark': fontawesome('check'),
    'icon-chevron-left': fontawesome('chevron-left'),
    'icon-chevron-up': fontawesome('chevron-up'),
    'icon-chevron-down': fontawesome('chevron-down'),
    'icon-chevron-right': fontawesome('chevron-right'),
    'icon-circle-outlined': fontawesome('circle-o'),
    'icon-circle': fontawesome('circle'),
    'icon-copy': fontawesome('files-o'),
    'icon-code': fontawesome('code'),
    'icon-credit-card': fontawesome('credit-card'),
    'icon-crop': fontawesome('crop'),
    'icon-delete': fontawesome('trash'),
    'icon-download': fontawesome('download'),
    'icon-ellipsis': fontawesome('ellipsis-h'),
    'icon-envelope': fontawesome('envelope-o'),
    'icon-envelope-solid': fontawesome('envelope'),
    'icon-external-link': fontawesome('external-link'),
    'icon-eye': fontawesome('eye'),
    'icon-facebook-logo': fontawesome('facebook-official'),
    'icon-file': fontawesome('file'),
    'icon-file-text': fontawesome('file-text'),
    'icon-info-circle': fontawesome('info-circle'),
    'icon-filter': fontawesome('filter'),
    'icon-flip-h': fontawesome('arrows-h'),
    'icon-flip-v': fontawesome('arrows-v'),
    'icon-folder-open': fontawesome('folder-open'),
    'icon-folder': fontawesome('folder'),
    'icon-gear': fontawesome('cog'),
    'icon-gears': fontawesome('cogs'),
    'icon-home': fontawesome('home'),
    'icon-image': fontawesome('image'),
    'icon-keyboard': fontawesome('keyboard-o'),
    'icon-link': fontawesome('link'),
    'icon-lock': fontawesome('lock'),
    'icon-minus': fontawesome('minus'),
    'icon-no-eye': fontawesome('eye-slash'),
    'icon-pencil': fontawesome('pencil'),
    'icon-pencil-square-o': fontawesome('pencil-square-o'),
    'icon-photo-id': fontawesome('id-card'),
    'icon-plus': fontawesome('plus'),
    'icon-plus-circle': fontawesome('plus-circle'),
    'icon-rotate-left': fontawesome('rotate-left'),
    'icon-rotate-right': fontawesome('rotate-right'),
    'icon-search': fontawesome('search'),
    'icon-shield': fontawesome('shield'),
    'icon-sliders': fontawesome('sliders'),
    'icon-sort': fontawesome('sort'),
    'icon-sort-up': fontawesome('sort-asc'),
    'icon-sort-down': fontawesome('sort-desc'),
    'icon-sort-numeric-asc': fontawesome('sort-numeric-asc'),
    'icon-sort-numeric-desc': fontawesome('sort-numeric-desc'),
    'icon-sort-alpha-asc': fontawesome('sort-alpha-asc'),
    'icon-sort-alpha-desc': fontawesome('sort-alpha-desc'),
    'icon-sort-asc': fontawesome('angle-up'),
    'icon-sort-desc': fontawesome('angle-down'),
    'icon-tooltip-hotspot': fontawesome('question-circle-o'),
    'icon-tooltip-hotspot-filled': fontawesome('question-circle'),
    'icon-unchecked': fontawesome('square-o'),
    'icon-upload': fontawesome('upload'),
    'icon-unlink': fontawesome('unlink'),
    'icon-user': fontawesome('user'),
    'icon-warning': fontawesome('exclamation-triangle'),
    'icon-exclamation-circle': fontawesome('exclamation-circle'),
    'icon-x': fontawesome('times'),
    'icon-phone': fontawesome('phone'),
    'icon-wrench': fontawesome('wrench'),
    'icon-briefcase': fontawesome('briefcase'),
    'icon-raquo': fontawesome('angle-double-right'),
    'icon-spinner': `${fontawesome('spinner')} fa-spin`,
    'icon-clock': `${fontawesome('clock-o')}`,
    'icon-times-circle': `${fontawesome('times-circle')}`,
    'icon-clone': `${fontawesome('clone')}`,
    'icon-share': `${fontawesome('share')}`,
    'icon-pause': `${fontawesome('pause')}`,
    'icon-paperclip': `${fontawesome('paperclip')}`,
};

const fallback = fontawesome('question-circle');

export default function getIconClassName(name: string) {
    return fontAwesomeMap[name] || fontAwesomeMap[`icon-${name}`] || fallback;
}
