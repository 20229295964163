import uniq from 'lodash/uniq';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import findQuestionsForAttributeTypes from '@/utils/findQuestionsForAttributeTypes';

export default function extractAttributeTypesFromValues(
    attributesValues: AttributesValues,
    questions: Question[],
): string[] {
    // Get list of attribute types
    const baseAttributeTypes = Object.keys(attributesValues);

    // Combine it with questions
    const attributeTypes = [
        ...findQuestionsForAttributeTypes(questions, baseAttributeTypes).map((question) => question.attrType),
        ...baseAttributeTypes,
    ];

    const ID_SCAN = 'identity_assurance.document_verification';
    if (attributeTypes.find((attrType) => attrType.startsWith(ID_SCAN) && !attrType.includes('selfie'))) {
        attributeTypes.push(
            ...questions
                .filter((question) => question.type === 'id-scan')
                .map((question) => question.attrType),
        );
    }

    return uniq(attributeTypes);
}
