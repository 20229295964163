// Universal types

export type GlsEmail = string;

export enum GlsCheck {
    fieldWorkerCriminal = 'needs_field_worker_criminal_check', // requires: fieldworker e-mails
    businessOwner = 'needs_business_owner_check', // requires: business owner e-mails
    businessEntity = 'needs_business_entity_check', // requires: business address
}

export enum GlsServiceType {
    homeServices = 'home_services',
    professionalServices = 'professional_services',
}

export enum GlsRequestSubmissionStatus {
    pending = 'pending',
    submitted = 'submitted',
}

export enum GlsCaseSubmissionStatus {
    new = 'New',
    pending = 'Pending',
    inProcess = 'In Process',
    complete = 'Complete',
    timeout = 'Timed Out',
}

export enum GlsCaseCaseStatus {
    unspecified = 'CASE_STATUS_UNSPECIFIED',
    failed = 'FAILED',
    passed = 'PASSED',
    dropoff = 'DROPOFF',
    withdrawn = 'WITHDRAWN',
}

// Types used internally for connection with back-end

/* eslint-disable camelcase */

export interface GlsInternalCaseStatus {
    case_status: GlsCaseCaseStatus;
    submission_status: GlsCaseSubmissionStatus;
}

export interface GlsInternalUserSession {
    email: string;
    case_id: number;
    interview_uri?: string | null;
}

export interface GlsInternalBusinessAddress {
    region_code: string;
    revision?: number | null;
    language_code?: string | null;
    postal_code?: string | null;
    sorting_code?: string | null;
    administrative_area?: string | null;
    locality?: string | null;
    sublocality?: string | null;
    address_lines?: string[] | null;
    recipients?: string[] | null;
    organization?: string | null;
}

export interface GlsInternalCheckResult {
    case_status: GlsCaseCaseStatus;
    check_completed_time: string | null; // ISO Date
    completed_business_entity_check_time: string | null; // ISO Date
    completed_checks_count: number;
    field_worker_count: number;
    final_adjudication_time: string | null; // ISO Date
    owner_count: number;
    required_checks_count: number;
    submitted_business_entity_info_time: string | null; // ISO Date
    submitted_checks_count: number;
}

export interface GlsInternalCaseData {
    service_type: GlsServiceType;
    case_id: number;
    name: string;
    business_name: string;
    service_categories: string[];
    required_check: GlsCheck[];
    business_address?: GlsInternalBusinessAddress | null;
    check_result?: GlsInternalCheckResult | null;
}

export interface GlsInternalEmailDetails {
    email: GlsEmail;
    rpr_submission_status: GlsRequestSubmissionStatus;
}

export interface GlsInternalCaseSubmission {
    verification_case_id?: string | null;
    primary_business_owner?: GlsEmail | null;
    business_address: GlsInternalBusinessAddress | null;
    field_workers: GlsInternalEmailDetails[];
    business_owners: GlsInternalEmailDetails[];
}

export interface GlsInternalCaseSubmissionInput {
    verification_case_id?: string | null;
    business_address: GlsInternalBusinessAddress | null;
    field_workers: GlsEmail[];
    business_owners: GlsEmail[];
}

/* eslint-enable camelcase */

// Types exposed from the EID GLS API client

export interface GlsCaseStatus {
    caseStatus: GlsCaseCaseStatus;
    submissionStatus: GlsCaseSubmissionStatus;
}

export interface GlsUserSession {
    caseId: number;
    email: string;
    interviewUrl: string | null;
}

export interface GlsBusinessAddress {
    street: string;
    city: string;
    state: string;
    postcode: string;
}

export interface GlsCheckResult {
    status: GlsCaseCaseStatus;
    checkCompletedAt: Date | null;
    businessEntitySubmittedAt: Date | null;
    businessEntityCheckCompletedAt: Date | null;
    finallyAdjudicatedAt: Date | null;
    fieldWorkerCount: number;
    ownerCount: number;
    requiredChecksCount: number;
    submittedChecksCount: number;
    completedChecksCount: number;
}

export interface GlsCaseData {
    serviceType: GlsServiceType;
    caseId: number;
    name: string;
    businessName: string;
    serviceCategories: string[];
    requiredChecks: GlsCheck[];
    businessAddress: GlsBusinessAddress | null;
    checkResult: GlsCheckResult | null;
}

export interface GlsEmailDetails {
    email: string;
    status: GlsRequestSubmissionStatus;
}

export interface GlsCaseSubmission {
    caseId: number | null;
    primaryBusinessOwner: GlsEmail | null;
    businessAddress: GlsBusinessAddress | null;
    fieldWorkers: GlsEmailDetails[];
    businessOwners: GlsEmailDetails[];
}

export interface GlsCaseSubmissionInput {
    caseId: number | null;
    businessAddress: GlsBusinessAddress | null;
    fieldWorkers: GlsEmail[];
    businessOwners: GlsEmail[];
}
