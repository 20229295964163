import { DateTime } from 'luxon';
import Question from '@evidentid/ido-lib/interfaces/Question';
import DateField from '@/fields/DateField';

export default class FutureDateField extends DateField {
    public minDate = new Date();

    public override isValid(_: Question, value: string): boolean {
        const currentDate = DateTime.fromFormat(new Date().toDateString(), 'EEE MMM dd yyyy');
        return super.isValid(_, value) && DateTime.fromFormat(value, this.format) >= currentDate;
    }
}
