import { isValidSsn, sanitizeSsn } from './ssn';
import { isValidItin, sanitizeItin } from './itin';
import { isValidEin, sanitizeEin } from './ein';

export interface TinOptions {
    ssn?: boolean;
    itin?: boolean;
    ein?: boolean;
}

const allOptionsEnabled = {
    ssn: true,
    itin: true,
    ein: true,
};

export function sanitizeTin(tin: null, options?: TinOptions): null;
export function sanitizeTin(tin: string | null, options?: TinOptions): string | null;
export function sanitizeTin(tin: string | null, options: TinOptions = allOptionsEnabled): string | null {
    return (
        (options.itin && sanitizeItin(tin)) ||
        (options.ssn && sanitizeSsn(tin)) ||
        (options.ein && sanitizeEin(tin)) ||
        null
    );
}

export function isValidTin(tin: null, options?: TinOptions): false;
export function isValidTin(tin: string | null, options?: TinOptions): boolean;
export function isValidTin(tin: string | null, options: TinOptions = allOptionsEnabled): boolean {
    return Boolean(
        (options.itin && isValidItin(tin)) ||
        (options.ssn && isValidSsn(tin)) ||
        (options.ein && isValidEin(tin))
    );
}
