<template>
    <div>
        <label id="cpr-provider" class="LineInputBlock">
            {{ question.metadata.description }}
            <Dropdown
                v-model="selectedProviderId"
                placeholder="Select"
                :options="providers"
                :build-label="buildLabel"
                @input="onChange"
            />
        </label>
        <label id="cpr-cert-id" class="LineInputBlock">
            Enter your CPR certificate ID
            <input
                v-model="certificateId"
                @input="onChange"
            >
        </label>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import Dropdown from '@/components/forms/Dropdown.vue';
    import cprProviders, { cprProvidersMap } from '@/config/cprProviders';
    import CprData from '@/fields/interfaces/CprData';

    @Component({
        components: { Dropdown },
    })
    export default class Cpr extends FieldComponent<CprData> {
        private providers: string[] = cprProviders.map((provider) => provider.id);
        private selectedProviderId = this.value?.provider || null;
        private certificateId = this.value?.cert_id || '';

        private buildLabel(value: string) {
            return cprProvidersMap[value]?.name;
        }

        @Watch('value')
        private onValueChange(value: CprData) {
            this.selectedProviderId = value?.provider || null;
            this.certificateId = value?.cert_id || '';
        }

        private onChange() {
            this.$emit('change', {
                provider: this.selectedProviderId,
                cert_id: this.certificateId,
            });
        }
    }
</script>
