<template>
    <div class="DateQuestion LineInputBlock">
        <span v-if="!bare">
            {{ question.metadata.description }}
        </span>
        <DatePicker v-model="inputValue"
            :value-format="field.format"
            :display-format="field.format"
            :min-date="field.minDate"
            @input="change"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import DatePicker from '@/components/forms/DatePicker.vue';

    @Component({
        components: { DatePicker },
    })
    export default class Date extends FieldComponent<string> {
        private inputValue = this.value || '';

        @Watch('value')
        private onValueChange(value: string) {
            this.inputValue = value;
        }

        private change() {
            this.$emit('change', this.inputValue);
        }
    }
</script>
