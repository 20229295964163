<template>
    <tr class="EntriesTableRow" :class="{ 'EntriesTableRow--disabled': disabled }">
        <td class="EntriesTableRow__cell EntriesTableRow__cell--label">
            {{ label }}
            <span v-if="isPrimaryBusinessOwner" class="EntriesTableRow__primaryEmailIndicator">
                - PRIMARY
            </span>
        </td>
        <td class="EntriesTableRow__cell EntriesTableRow__cell--status">
            <span v-if="submissionStatus === 'pending'">
                Pending Submission
            </span>
            <span v-else-if="submissionStatus === 'submitted'">
                Submission Received
            </span>
        </td>
        <td class="EntriesTableRow__cell EntriesTableRow__cell--sendLink">
            <template>
                <Spinner v-if="resendStatus === 'loading'" />
                <span v-else-if="resendStatus === 'success'">
                    Sent!
                </span>
                <button v-else :disabled="!canSendEmail" type="button" @click="onResend">
                    Send
                </button>
            </template>
        </td>
        <td v-if="shouldShowDelete" class="EntriesTableRow__cell EntriesTableRow__cell--delete">
            <button
                type="button"
                :title="deleteButtonTooltip"
                :disabled="isPrimaryBusinessOwner"
                @click="onDelete"
            >
                <Icon name="delete" touch-action="auto" />
            </button>
        </td>
    </tr>
</template>

<style lang="less">
.EntriesTableRow {
    &__cell {
        text-align: left;
        padding: 10px 12px;
        font-size: 1em;
    }

    &__cell--label {
        color: #2d3e50;
        word-break: break-word;
    }

    &__cell--status {
        color: #828e9a;
        font-style: italic;
        font-size: 0.85em;
    }

    &__cell--sendLink {
        position: relative;
        font-size: 0.9em;
        line-height: 1em;
        padding-top: 5px;
        padding-bottom: 5px;

        button {
            font: inherit;
            border: 0;
            border-radius: 1.25em;
            padding: 0.6em 0.5em;
            line-height: 1em;
            cursor: pointer;
            background-color: #2ab496;
            color: #fff;
            width: 100%;
            text-align: center;

            &:disabled {
                cursor: not-allowed;
                background: #cacfd3;
            }
        }

        .Spinner {
            zoom: 0.65;
        }
    }

    &__cell--delete,
    &__cell--sendLink {
        text-align: center;
    }

    &__cell--delete {
        color: #2ab496;
        font-size: 1.2em;
        line-height: 1em;

        button {
            border: 0;
            background: transparent;
            padding: 0;
            margin: 0;
            color: inherit;
            font: inherit;

            &:disabled {
                .fa-trash {
                    cursor: not-allowed;
                    color: #cacfd3;
                }
            }
        }
    }

    &--disabled &__cell--delete {
        color: #dadada;
    }

    &__primaryEmailIndicator {
        color: #969fa7;
    }
}
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { GlsRequestSubmissionStatus } from '@evidentid/gls-service-api-client/types';
    import Icon from '@/components/common/Icon.vue';
    import Spinner from '@/components/common/Spinner.vue';
    import { Status } from '@/store/interfaces/IdoState';

    @Component({
        components: { Spinner, Icon },
    })
    export default class EntriesTableRow extends Vue {
        @Prop({ type: String })
        private label!: string;

        @Prop({ type: String, default: GlsRequestSubmissionStatus.pending })
        private submissionStatus!: GlsRequestSubmissionStatus;

        @Prop({ type: String, default: Status.uninitialized })
        private resendStatus!: Status;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Function, default: () => false })
        private isBusinessOwner!: (email: string) => boolean;

        @Prop({ type: Boolean, default: true })
        private shouldShowDelete!: boolean;

        private get canSendEmail(): boolean {
            return this.submissionStatus === GlsRequestSubmissionStatus.pending;
        }

        private get isPrimaryBusinessOwner(): boolean {
            return this.isBusinessOwner(this.label);
        }

        private get deleteButtonTooltip(): string | null {
            return this.isBusinessOwner(this.label) ? 'The Primary Business Owner cannot be removed from the roster' : null;
        }

        private onResend(): void {
            this.$emit('resend');
        }

        private onDelete(): void {
            if (!this.disabled) {
                this.$emit('delete');
            }
        }
    }
</script>
