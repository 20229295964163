import { Component as VueComponent } from 'vue';
import Router, { Route, Location } from 'vue-router';
import withErrorHandler from '@/decorators/withErrorHandler';
import withInterview from '@/decorators/withInterview';
import ThankYou from '@/views/ThankYou.vue';
import Overview from '@/views/Overview.vue';
import AnswerQuestion from '@/views/AnswerQuestion.vue';
import Checkout from '@/views/Checkout.vue';
import Error from '@/views/Error.vue';
import LegacyGoogleBusinessVerification from '@/views/LegacyGoogleBusinessVerification.vue';
import Finishing from '@/views/Finishing.vue';
import StatusPage from '@/views/StatusPage.vue';
import withExpectedTransitionState from '@/decorators/withExpectedTransitionState';
import { TransitionState } from '@/utils/getTransitionState';
import withInterviewUpdates from '@/decorators/withInterviewUpdates';
import GoogleBusinessVerification from '@/views/GoogleBusinessVerification.vue';
import GoogleBusinessVerificationUnknown from '@/views/GoogleBusinessVerificationUnknown.vue';
import UnknownRequestErrorPage from '@/views/UnknownRequestError.vue';
import omit from 'lodash/omit';

/**
 * Decorate Vue component, to ensure that it will:
 * - handle errors
 * - load (and require) interview details
 * - be available only for selected interview state
 */
function withinInterviewFlow(transitionState: TransitionState, Component: VueComponent) {
    return withErrorHandler(withInterview(withExpectedTransitionState(transitionState, Component)));
}

const routes = [
    {
        // Send consent and wait for transition.
        path: '/finishing',
        name: 'finishing',
        component: withinInterviewFlow(TransitionState.finishing, Finishing),
    },
    {
        // Navigate here to inform the user that they are dismissed.
        path: '/done',
        name: 'thank-you',
        component: withinInterviewFlow(TransitionState.finished, ThankYou),
        meta: { title: 'Thank you' },
    },
    {
        // Navigate here to inform the user that they are dismissed.
        path: '/status',
        name: 'status',
        component: withinInterviewFlow(TransitionState.finished, withInterviewUpdates(StatusPage)),
        meta: { title: 'Status' },
    },
    {
        // This is the landing page for the typical use-case.
        path: '/overview',
        name: 'overview',
        component: withinInterviewFlow(TransitionState.request, withInterviewUpdates(Overview, true)),
        meta: { title: 'Overview' },
    },
    {
        // Endpoint used to select an interview.
        path: '/interview/:rprId',
        name: 'select-interview',
        redirect: () => ({ name: 'overview', query: {} }),
    },
    {
        // Show and answer current question.
        path: '/question/:slug',
        name: 'question',
        component: withinInterviewFlow(TransitionState.request, withInterviewUpdates(AnswerQuestion, true)),
        meta: { title: 'Question' },
    },
    {
        // Pay missing balance.
        path: '/pay',
        name: 'checkout',
        component: withinInterviewFlow(TransitionState.payment, Checkout),
        meta: { title: 'Checkout' },
    },
    {
        // TODO: Remove when we will no longer support legacy GLS Flow
        // Google Business verification page.
        path: '/google-login',
        name: 'google-business-verification',
        component: withErrorHandler(LegacyGoogleBusinessVerification),
        meta: { title: 'Welcome' },
    },
    {
        // Google Business verification page.
        path: '/v2/google-login',
        name: 'google-business-verification-v2-redirection',
        redirect: (route: Route): Location => {
            // @ts-ignore: possibly nullish value passed to parseInt
            const caseId = parseInt(route.query.case_id || route.query.caseId, 10);
            const query = omit(route.query, [ 'case_id', 'caseId' ]);
            return caseId
                ? { name: 'google-business-verification-v2', params: { caseId: `${caseId}` }, query }
                : { name: 'google-business-verification-error', params: { invalidCaseId: 'unknown' } };
        },
    },
    {
        // Google Business verification page (with case ID included in path).
        path: '/v2/google-login/:caseId(\\d+)',
        name: 'google-business-verification-v2',
        component: withErrorHandler(GoogleBusinessVerification),
        meta: { title: 'Welcome' },
    },
    {
        // Google Business verification page when case ID is invalid.
        path: '/v2/google-login/:invalidCaseId',
        name: 'google-business-verification-error',
        component: withErrorHandler(GoogleBusinessVerificationUnknown),
        meta: { title: 'Welcome' },
    },
    // Error pages
    {
        path: '/error/:reason',
        name: 'error',
        component: Error,
        meta: { title: 'Error' },
    },
    {
        path: '/error',
        name: 'generic-error',
        component: Error,
        meta: { title: 'Error' },
    },
    {
        path: '/unknown-request',
        name: 'unknown-request-error',
        component: withErrorHandler(UnknownRequestErrorPage),
        meta: { title: 'Error' },
    },
    {
        path: '/',
        name: 'main',
        redirect: () => ({ name: 'overview' }),
    },
    {
        // For now there exists no home page.
        path: '*',
        name: '404',
        redirect: () => ({ name: 'error', params: { reason: 'page-not-found' } }),
    },
];

export default function createVueRouter() {
    const router = new Router({ routes });

    router.beforeEach((to, from, next) => {
        // eslint-disable-next-line
        // @ts-ignore: using global hack
        if (!window.isNavigationBlocked) {
            next();
        }
    });
    return router;
}
