<template>
    <IdScanError back-label="Re-take photo" :submit-label="submitLabel" @submit="onSubmit" @back="$emit('retry')">
        <div class="RetryDescription">
            <h3 class="RetryDescription__heading">
                Looks like we’re having trouble getting a clear image
            </h3>
            <p class="RetryDescription__description">
                Do you want to try again to get a better image, or just use this one?
            </p>

            <IdScanFailedPreview
                :files="pages"
                :notice="notice"
            />
        </div>
        <IdScanLoadingOverlay
            v-if="submitting"
            v-bind="$props"
            :progress="uploadProgress"
        />
    </IdScanError>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import IdScanFailedPreview from '@/components/fields/IdScan/IdScanFailedPreview.vue';
    import IdScanError from '@/components/fields/IdScan/IdScanError.vue';
    import documents from '@/config/documents';
    import { DocumentType } from '@evidentid/ido-lib/interfaces/Question';
    import FieldComponent from '@/fields/FieldComponent';
    import IdScanLoadingOverlay from '@/components/fields/IdScan/IdScanLoadingOverlay.vue';

    @Component({
        inheritAttrs: false,
        components: { IdScanLoadingOverlay, IdScanError, IdScanFailedPreview },
    })
    export default class PersistentFailureScreen extends FieldComponent<any> {
        private uploadProgress = 0;

        private get submitLabel() {
            return this.pages.length > 1
                ? 'Use these images'
                : 'Use this image';
        }

        private get pages() {
            return this.value?.pages || [];
        }

        private get document() {
            return documents[this.value?.type as DocumentType] || documents[DocumentType.idCard];
        }

        private get notice() {
            return this.document.pages[0].previewNotice;
        }

        private onSubmit() {
            this.resetUploadProgress();
            this.$emit('submit', undefined, this.updateUploadProgress.bind(this));
        }

        private resetUploadProgress() {
            this.uploadProgress = 0;
        }

        private updateUploadProgress(progress: number) {
            this.uploadProgress = progress;
        }
    }
</script>
