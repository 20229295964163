import { render, staticRenderFns } from "./ChargeDetails.vue?vue&type=template&id=7877bf74&"
import script from "./ChargeDetails.vue?vue&type=script&lang=ts&"
export * from "./ChargeDetails.vue?vue&type=script&lang=ts&"
import style0 from "./ChargeDetails.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports