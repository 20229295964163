import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import UsStateComponent from '@/components/fields/UsState.vue';
import { stateIds } from '@/config/states';

export default class UsStateField extends Field<string> {
    public override component = UsStateComponent;

    public isValid(_: Question, value: string): boolean {
        return stateIds.includes(value);
    }

    public async encode(question: Question, value: string): Promise<AttributesValues> {
        return this._encode(question, value);
    }
}
