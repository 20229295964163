import Vue from 'vue';
import Application from '@/application/Application';
import createLogger from '@evidentid/vue-commons/core/createLogger';
import createTracker from '@evidentid/vue-commons/core/createTracker';
import IdoLoggerUserData from '@/tracking/IdoLoggerUserData';
import IdoTrackerUserData from '@/tracking/IdoTrackerUserData';
import * as trackingConfig from '@/config/tracking';
import Sentry from './sentry';
import './index.css';

// It should be initialized before application is started
const logger = createLogger<IdoLoggerUserData>(Sentry);

// Create tracker
const tracker = createTracker<IdoTrackerUserData>(trackingConfig.adapter, trackingConfig.options);

// Initialize tracker
tracker.initialize().catch((error) => {
    console.error('Tracker initialization error', error);
});

// Initialize application instance
const idoApplication = new Application({ Vue, location, logger, tracker });

idoApplication.initialize();

export default idoApplication;
