import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import FullNameComponent from '@/components/fields/FullName.vue';
import FullName from './interfaces/FullName';

export default class FullNameField extends Field<FullName> {
    public override component = FullNameComponent;

    public isValid(_: Question, value: FullName): boolean {
        return Boolean(value && value.first?.trim() && value.last?.trim());
    }

    public async encode(question: Question, value: FullName): Promise<AttributesValues> {
        return this._encode(question, {
            $objectType: 'Name',
            first: value.first.trim(),
            middle: value.middle.trim(),
            last: value.last.trim(),
        });
    }
}
