<template>
    <div class="ImagesBox__item" :class="{ 'ImagesBox__item--zoom': zoomed }" touch-action="auto" @click="toggle">
        <div class="ImagesBox__close">
            <Icon name="cancel" /> Click to close preview
        </div>
        <div class="ImagesBox__zoom">
            <Icon name="search" />
        </div>
        <BlobImage class="ImagesBox__image" :src="file" />
    </div>
</template>

<script lang="ts">
    import { Vue, Prop, Component } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';
    import Spinner from '@/components/common/Spinner.vue';
    import BlobImage from '@/components/common/BlobImage.vue';

    @Component({
        components: { BlobImage, Spinner, Icon },
    })
    export default class IdScanFailedPreviewItem extends Vue {
        @Prop({ type: File, default: () => null })
        private file!: File;

        @Prop({ type: Boolean, default: false })
        private zoomed!: boolean;

        private toggle() {
            this.$emit('toggle', this.file);
        }
    }
</script>
