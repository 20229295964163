<template>
    <IdScanPhotoPreview
        v-if="value"
        :notice="previewNotice"
        :file="processing ? null : value"
    />
    <PhotoInput
        v-else
        id="id-simplex-input"
        :label="uploadText"
        :value="value"
        :processing="processing"
        :process="processImage"
        :capture="capture"
        :native="!overlay"
        :overlay="overlay"
        @input="onChange"
        @invalid="onCorruptedFile"
        @processing="onProcessing"
    />
</template>

<script lang="ts">
    import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
    import { ACUANT_CARD_TYPES } from '@evidentid/acuant-sdk';
    import PhotoInput from '@/components/forms/PhotoInput.vue';
    import IdScanPhotoPreview from '@/components/fields/IdScan/IdScanPhotoPreview.vue';
    import { createIdentityScanProcessor } from '@evidentid/browser-framework/canvases';

    type ImageProcessor = (file: File) => Promise<File>;

    @Component({
        components: { IdScanPhotoPreview, PhotoInput },
    })
    export default class IdScanPhotoInput extends Vue {
        @Prop({ type: File, default: () => null })
        private value!: File | null;

        @Prop({ type: String, default: '' })
        private previewNotice!: string;

        @Prop({ type: String, default: '' })
        private uploadText!: string;

        @Prop({ type: Object, default: () => null })
        private overlay!: { width: number, height: number, text?: string };

        @Prop({ type: String, default: ACUANT_CARD_TYPES.DRIVERS_LICENSE_DUPLEX })
        private type!: ACUANT_CARD_TYPES;

        private processImage!: ImageProcessor;

        private processing = false;

        @Watch('acuantType', { immediate: true })
        private onAcuantTypeChange(acuantType: ACUANT_CARD_TYPES) {
            this.processImage = createIdentityScanProcessor({ acuantType });
        }

        private onProcessing() {
            this.processing = true;
        }

        private onChange(fileBlob: File) {
            this.processing = false;
            this.$emit('input', fileBlob);
        }

        private onCorruptedFile(file: File | null) {
            this.processing = false;
            this.$emit('invalid', file);
        }

        private get capture() {
            return this.$store.state.featureFlags.idverify_document_upload_from_mobile_enabled
                ? null
                : 'environment';
        }
    }
</script>
