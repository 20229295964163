import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import Charge from '@/interfaces/Charge';
import { IdoStore } from '@/store/interfaces/IdoStore';
import IndividualizedAssessmentComponent from '@/components/fields/IndividualizedAssessment.vue';

export default class IndividualizedAssessmentField extends Field<Charge[]> {
    public override form = IndividualizedAssessmentComponent;

    public isValid(question: Question, value: Charge[] | null): boolean {
        return Array.isArray(value) && !value.find((charge) => !this.isChargeValid(charge));
    }

    public isChargeValid(charge: Charge | null): boolean {
        return Boolean(
            charge &&
            charge.ageAtOffense &&
            charge.lastPosition &&
            typeof charge.isBonded === 'boolean' &&
            charge.additionalInformation &&
            charge.additionalEffort &&
            charge.offenseTraining &&
            charge.substanceTraining &&
            charge.paroleRequirements &&
            this.hasChargeAnyRehabilitation(charge),
        );
    }

    public hasChargeAnyRehabilitation(charge: Charge): boolean {
        return Boolean(
            charge.rehabilitationNone ||
            charge.rehabilitationEducation ||
            charge.rehabilitationAlcohol ||
            charge.rehabilitationTraining ||
            charge.rehabilitationParole ||
            charge.rehabilitationProbation ||
            charge.rehabilitationDrug ||
            typeof charge.rehabilitationOther === 'string',
        ) && (typeof charge.rehabilitationOther !== 'string' || charge.rehabilitationOther.trim().length > 0);
    }

    public async encode(question: Question, value: Charge[]): Promise<AttributesValues> {
        return this._encode(question, value);
    }

    protected override prepareData(store: Pick<IdoStore, 'state' | 'dispatch'>) {
        return store.dispatch('loadCriminalOffenses');
    }
}
