<template>
    <div class="Snackbar"
        :class="{
            'Snackbar--open': open,
            'Snackbar--success': open && success,
            'Snackbar--failure': open && !success,
        }"
    >
        <div class="Snackbar__message">
            {{ message }}
        </div>
        <button v-if="permanent" class="Snackbar__close" @click="close">
            <Icon name="x" />
        </button>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';

    @Component({
        components: { Icon },
    })
    export default class Snackbar extends Vue {
        @Prop({ type: Number, default: 4000 })
        private delay!: number;

        private timeout: any = null;
        private prevMessage: string | null = null;

        private get context() {
            return this.$store.state.snackbar;
        }

        private get open() {
            return this.message !== null;
        }

        private get success() {
            return this.context.success;
        }

        private get permanent() {
            return this.context.permanent;
        }

        private get message() {
            return this.context.message;
        }

        private mounted() {
            this.resetTimeout();
        }

        private beforeUpdate() {
            this.resetTimeout();
        }

        private beforeDestroy() {
            this.clearTimeout();
        }

        private close() {
            this.prevMessage = null;
            this.$store.dispatch('displaySnackbar', null);
        }

        private resetTimeout() {
            // When the message is changed, start calculating time again,
            // to keep new message for proper time.
            if (this.timeout && (this.permanent || this.message !== this.prevMessage)) {
                this.clearTimeout();
            }

            if (this.message !== this.prevMessage) {
                this.prevMessage = this.message;

                if (this.message && !this.permanent) {
                    this.timeout = setTimeout(this.close.bind(this), this.delay);
                }
            }
        }

        private clearTimeout() {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
        }
    }
</script>
