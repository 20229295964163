<template>
    <label class="AutoComplete" :style="style">
        <input
            :id="id"
            v-model="inputValue"
            type="text"
            class="AutoComplete__input"
            :placeholder="placeholder"
            @input="onChange"
            @keydown="onKeyDown"
        >
        <div v-if="attached" :id="id + '-entries'" ref="entries" class="AutoComplete__entries">
            <div
                v-for="(match, index) in matches"
                :key="buildKey(match, index)"
                class="AutoComplete__entries__match"
                :class="{ 'AutoComplete__entries__match--focused': focused === index }"
                touch-action="auto"
                @pointerenter="focused = index"
                @click="select(index)"
            >{{ buildLabel(match) }}</div>
        </div>
    </label>
</template>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

    @Component
    export default class AutoComplete extends Vue {
        @Prop({ type: String, default: undefined })
        private id!: string | undefined;

        @Prop({ type: String, default: undefined })
        private placeholder!: string | undefined;

        @Prop({ default: undefined })
        private value!: any;

        @Prop({ type: Array, default: () => [] })
        private matches!: any[];

        @Prop({ type: Function, default: (value: any) => value })
        private buildLabel!: (value: any) => string;

        @Prop({ type: Function, default: (_: any, index: number) => index })
        private buildKey!: (value: any, index: number) => any;

        private inputValue = '';
        private focused = 0;
        private attached = false;
        private initial = true;

        private get style() {
            return this.attached ? 'overflow:visible;position:relative' : '';
        }

        private updated() {
            const element = this.$refs.entries as Element;

            if (!element) {
                return;
            }

            // Scroll with the user when they select items via keyboard.
            const focused = element.querySelector('.AutoComplete__entries__match--focused');

            if (!focused) {
                return;
            }

            const dr = element.getBoundingClientRect() as { y: number, height: number };
            const fr = focused.getBoundingClientRect() as { y: number, height: number };

            const bottomOfFocus = fr.y + fr.height;
            const bottomOfEntries = dr.y + dr.height;

            if (bottomOfFocus > bottomOfEntries) {
                // Scroll down until bottom edges of the container and focused element match.
                element.scrollTop += bottomOfFocus - bottomOfEntries;
            } else if (fr.y < dr.y) {
                // Scroll up until top edges of the container and focused element match.
                element.scrollTop -= dr.y - fr.y;
            }
        }

        @Watch('matches')
        private resetMatchSelection() {
            this.focused = 0;
        }

        @Watch('value', { immediate: true })
        @Watch('buildLabel')
        private onValueChanged(value: any) {
            if (value !== null && value !== undefined) {
                this.inputValue = this.buildLabel(value);
                if (this.initial) {
                    this.initial = false;
                } else {
                    this.$emit('typed', this.inputValue);
                }
            }
        }

        private select(index: number) {
            const match = index in this.matches ? this.matches[index] : undefined;

            if (match !== undefined) {
                this.inputValue = this.buildLabel(match);
            }

            this.$emit('input', match);
            this.attached = false;
        }

        private onChange() {
            this.focused = 0;
            this.attached = true;
            this.$emit('typed', this.inputValue);

            if (this.value !== undefined) {
                this.$emit('input', undefined);
            }
        }

        private onKeyDown(event: KeyboardEvent) {
            const KEY_ESCAPE = 27;
            const KEY_ARROW_UP = 38;
            const KEY_ARROW_DOWN = 40;
            const KEY_ENTER = 13;
            const KEY_TAB = 9;

            switch (event.keyCode) {
                case KEY_ESCAPE:
                    if (this.inputValue !== '') {
                        this.inputValue = '';
                        this.onChange();
                    }
                    break;
                case KEY_ARROW_UP:
                    this.focused = Math.max(0, this.focused - 1);
                    break;
                case KEY_ARROW_DOWN:
                    this.focused = Math.min(this.matches.length - 1, this.focused + 1);
                    break;
                case KEY_ENTER: case KEY_TAB:
                    if (this.inputValue.length > 0 && this.matches.length > 0) {
                        event.preventDefault();
                        this.select(this.focused);
                    }
                    break;
            }
        }
    }
</script>
