<template>
    <div class="EntriesTable" :class="{ 'EntriesTable--allowAdd': allowAdd }">
        <table class="EntriesTable__table">
            <thead v-if="!allowAdd || value.length > 0">
                <tr>
                    <th class="EntriesTable__header EntriesTable__header--email">
                        Email
                    </th>
                    <th class="EntriesTable__header EntriesTable__header--status">
                        Submission status
                    </th>
                    <th class="EntriesTable__header EntriesTable__header--sendLink">
                        Email Link
                    </th>
                    <th v-if="!singleEmail" class="EntriesTable__header EntriesTable__header--delete">
                        Delete
                    </th>
                </tr>
            </thead>
            <tbody class="EntriesTable__body">
                <EntriesTableRow
                    v-for="(email, index) in value"
                    :key="index"
                    class="EntriesTable__row"
                    :label="email"
                    :disabled="isDisabled(email, index)"
                    :submission-status="getSubmissionStatusFor(email)"
                    :resend-status="getSendEmailStatus(email)"
                    :is-business-owner="isBusinessOwner"
                    :should-show-delete="!singleEmail"
                    @resend="emailLink(index)"
                    @delete="onDelete(index)"
                />
            </tbody>
            <tfoot v-if="allowAdd" class="EntriesTable__footer">
                <tr>
                    <td colspan="4">
                        <AddDataSubject :label="addItemLabel" @submit="addDataSubject" />
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<style lang="less">
    .EntriesTable {
        overflow-x: auto;

        &__table {
            table-layout: fixed;
            border-spacing: 0;
            width: 100%;
            background: #f2f2f2;
            border: 1px solid #dadada;
            border-radius: 8px;
        }

        &__row:not(:last-child) .EntriesTableRow__cell,
        &--allowAdd &__row .EntriesTableRow__cell {
            border-bottom: 1px solid #dadada;
        }

        &__header {
            padding: 0.85em 1em;
            text-align: left;
            border-bottom: 1px solid #dadada;
            color: #2d3e50;
            font-weight: bold;
            font-size: 1em;

            &--email {
                min-width: 100px;
            }

            &--status {
                width: 23%;
            }

            &--delete {
                width: 10%;
                min-width: 50px;
                text-align: center;
            }

            &--sendLink {
                text-align: center;
                width: 17%;
                min-width: 80px;
            }
        }

        &__footer td {
            padding: 5px 10px;
            font-size: 1em;

            .AddDataSubject {
                min-height: 3em;
                max-width: 400px;
            }
        }

        @media (max-width: 500px) {
            &__table {
                table-layout: auto;
            }

            &__header {
                &--sendLink,
                &--delete,
                &--status {
                    width: initial;
                }
            }
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import AddDataSubject from '@/components/google/AddDataSubject.vue';
    import AddDataSubjectInput from '@/components/google/AddDataSubjectInput.vue';
    import { GlsAttributeType } from '@/config/gls';
    import { GlsData } from '@/services/getGlsSessionInterview';
    import { GlsRequestSubmissionStatus } from '@evidentid/gls-service-api-client/types';
    import { Status } from '@/store/interfaces/IdoState';
    import EntriesTableRow from '@/components/google/EntriesTableRow.vue';

    @Component({
        components: { EntriesTableRow, AddDataSubject, AddDataSubjectInput },
    })
    export default class EntriesTable extends Vue {
        @Prop({ type: Array, default: () => [] })
        private value!: string[];

        @Prop({ type: Function, default: () => false })
        private isDisabled!: (email: string, index: number) => boolean;

        @Prop({ type: String })
        private attrType!: string;

        @Prop({ type: Boolean, default: false })
        private allowAdd!: boolean;

        @Prop({ type: Boolean, default: false })
        private singleEmail!: boolean;

        private get glsData(): GlsData | null {
            return this.$store.state.gls;
        }

        private get emailRequestStatuses(): Record<string, GlsRequestSubmissionStatus> {
            return this.glsData?.emailRequestStatuses || {};
        }

        private isBusinessOwner(email: string): boolean {
            return this.glsData
                ? this.glsData.primaryBusinessOwner === email
                : false;
        }

        private getSubmissionStatusFor(email: string): GlsRequestSubmissionStatus | null {
            return this.emailRequestStatuses[email] || GlsRequestSubmissionStatus.pending;
        }

        private getSendEmailStatus(entry: string): Status {
            return this.$store.state.glsResendLinkStatus?.[entry] || Status.uninitialized;
        }

        private onDelete(index: number) {
            this.$emit('delete', this.value[index], index);
        }

        private emailLink(index: number) {
            this.$emit('resend', this.value[index], index);
        }

        private addDataSubject(email: string) {
            this.$emit('add', email);
        }

        private get addItemLabel(): string | undefined {
            if (this.attrType === GlsAttributeType.businessOwnersAndFieldworkers) {
                return 'Add new business owner or senior partner';
            } else if (this.attrType === GlsAttributeType.businessOwners) {
                return 'Add new business owner';
            } else if (this.attrType === GlsAttributeType.fieldWorkers) {
                return 'Add new employee';
            }
            return undefined;
        }
    }
</script>
