export const ID_DOCUMENT_ATTRIBUTE_NAMESPACE = 'identity_assurance.document_verification';

export const ID_DOCUMENT_TYPE_TAXONS = [
    'permanent_residence_card',
    'drivers_license',
    'id_card',
    'passport',
];

export const ID_DOCUMENT_ATTRIBUTE_REGION_QUALIFIER_TAXONS = [
    'africa',
    'americas',
    'antarctica',
    'asia',
    'europe',
    'oceania',
];

// "my.attr.type" <-> "my-attr-type"
export function slugAttrType(attrType: string): string {
    return (attrType || '').replace(/\./g, '-');
}

export function deslugAttrType(urlSafeAttrType: string): string {
    return (urlSafeAttrType || '').replace(/-/g, '.');
}

export function doesAttrTypeRepresentIdScan(attrType: string): boolean {
    return attrType.startsWith(ID_DOCUMENT_ATTRIBUTE_NAMESPACE) && attrType.indexOf('selfie') === -1;
}

interface IdScanPrescriptions {
    region: string | null;
    country: string | null;
    document: string | null;
}

/*
Inspect an output attribute and returns the FIRST guess
of EACH possible customer restriction for an ID document submission
based on a sample dot-separated string assumed to represent
a customer-facing attribute type, all without pulling the latest
data from Attribute Master.

This function really should not exist. You should not
assume this function will return correct results in all cases UNLESS there
exists some automatic sync between this code and canonical data.

'identity_assurance.document_verification.americas.us.id_card.*' =>
    ({ country: 'us', document: 'id_card', region: 'americas' })

'identity_assurance.document_verification.americas.id_card.*' =>
    ({ country: null, document: 'id_card', region: 'americas' })

'identity_assurance.document_verification.asia.*' =>
    ({ country: null, document: null, region: 'asia' })

'identity_assurance.document_verification.passport.*' =>
    ({ country: null, document: 'passport', region: null })
*/
export function extrapolateCustomerIdScanPrescriptions(attrType: string): IdScanPrescriptions {
    return attrType
        .split('.')
        .reduce((p, t) => {
            if (!p.region && ID_DOCUMENT_ATTRIBUTE_REGION_QUALIFIER_TAXONS.includes(t)) {
                p.region = t;
            } else if (!p.country && t.length === 2) {
                p.country = t;
            } else if (!p.document && ID_DOCUMENT_TYPE_TAXONS.includes(t)) {
                p.document = t;
            }

            return p;
        }, {
            region: null,
            document: null,
            country: null,
        } as IdScanPrescriptions);
}
