import Application from '@/application/Application';
import unslugify from '@evidentid/universal-framework/unslugify';
import IdoEventHandlers from '@/tracking/IdoEventHandlers';
import { GlsAttributeType } from '@/config/gls';

function optional<T extends((...args: any[]) => any) | undefined>(handler: T): Exclude<T, undefined> {
    if (handler) {
        return ((...args: any[]) => handler(...args)) as unknown as Exclude<T, undefined>;
    } else {
        return (() => undefined) as unknown as Exclude<T, undefined>;
    }
}

function ensureEventHandlers(handlers: Partial<IdoEventHandlers>): IdoEventHandlers {
    return {
        onErrorChange: optional(handlers.onErrorChange),
        onSnackbarMessage: optional(handlers.onSnackbarMessage),
        onRouteChange: optional(handlers.onRouteChange),
        onCurrentQuestionChange: optional(handlers.onCurrentQuestionChange),
        onUserChange: optional(handlers.onUserChange),
        onIssuerChange: optional(handlers.onIssuerChange),
        onLoadingStatusChange: optional(handlers.onLoadingStatusChange),
        onInterviewRequest: optional(handlers.onInterviewRequest),
        onFeatureFlagsChange: optional(handlers.onFeatureFlagsChange),
        onQuestionsChange: optional(handlers.onQuestionsChange),
        onSubmissionRequest: optional(handlers.onSubmissionRequest),
        onSubmissionStatusChange: optional(handlers.onSubmissionStatusChange),
        onVerificationRequest: optional(handlers.onVerificationRequest),
        onVerificationResultChange: optional(handlers.onVerificationResultChange),
        onQuestionComplete: optional(handlers.onQuestionComplete),
        onValueChange: optional(handlers.onValueChange),
        onFetchAttributesDataRequest: optional(handlers.onFetchAttributesDataRequest),
        onFetchAttributesDataFinish: optional(handlers.onFetchAttributesDataFinish),
        onFetchAcademicProvidersRequest: optional(handlers.onFetchAcademicProvidersRequest),
        onFetchAcademicProvidersFinish: optional(handlers.onFetchAcademicProvidersFinish),
        onFetchUtilityProvidersRequest: optional(handlers.onFetchUtilityProvidersRequest),
        onFetchUtilityProvidersFinish: optional(handlers.onFetchUtilityProvidersFinish),
        onFetchCriminalOffensesRequest: optional(handlers.onFetchCriminalOffensesRequest),
        onFetchCriminalOffensesFinish: optional(handlers.onFetchCriminalOffensesFinish),
        onDelegationPhoneNumberChange: optional(handlers.onDelegationPhoneNumberChange),
        onDelegationRequest: optional(handlers.onDelegationRequest),
        onDelegationStart: optional(handlers.onDelegationStart),
        onDelegationFinish: optional(handlers.onDelegationFinish),
        onDelegationFailure: optional(handlers.onDelegationFailure),
        onTransitionInformationRequest: optional(handlers.onTransitionInformationRequest),
        onTransitionInformationFinish: optional(handlers.onTransitionInformationFinish),
        onSendConsentRequest: optional(handlers.onSendConsentRequest),
        onSendConsentStatusChange: optional(handlers.onSendConsentStatusChange),
        onStripeInitializationRequest: optional(handlers.onStripeInitializationRequest),
        onStripeInitializationFinish: optional(handlers.onStripeInitializationFinish),
        onPaymentRequest: optional(handlers.onPaymentRequest),
        onPaymentFinish: optional(handlers.onPaymentFinish),
        onGlsUpdate: optional(handlers.onGlsUpdate),
        onAddGlsFieldWorker: optional(handlers.onAddGlsFieldWorker),
        onAddGlsBusinessOwner: optional(handlers.onAddGlsBusinessOwner),
        onResendGlsSubmissionLinkRequest: optional(handlers.onResendGlsSubmissionLinkRequest),
        onResendGlsSubmissionLinkFinish: optional(handlers.onResendGlsSubmissionLinkFinish),
        onResendGlsSubmissionLinkFailure: optional(handlers.onResendGlsSubmissionLinkFailure),
        onDeleteGlsFieldWorker: optional(handlers.onDeleteGlsFieldWorker),
        onDeleteGlsBusinessOwner: optional(handlers.onDeleteGlsBusinessOwner),
    };
}

export default function trackIdoEvents(observer: Application['observer'], config: Partial<IdoEventHandlers>) {
    // Ensure that all handlers exists (even empty)
    const handlers = ensureEventHandlers(config);

    // Base events
    observer.onValue('error', handlers.onErrorChange);
    observer.onMutation('setSnackbarContent', (content) => {
        handlers.onSnackbarMessage(
            (content && content.message) || null,
            Boolean(content && content.success),
        );
    });

    // Routing events
    observer.onRouteChange((route) => handlers.onRouteChange((route && route.name) || null));
    observer.onRouteChange((route, _, state) => {
        const attrType = route && route.name === 'question' && route.params.slug ? unslugify(route.params.slug) : null;
        const question = attrType && state.questions && state.questions.find((x) => x.attrType === attrType);
        handlers.onCurrentQuestionChange(attrType, question ? question.type : null);
    });

    // User events
    observer.onValue('user', (userData) => {
        handlers.onUserChange(
            (userData && userData.email) || null,
            Boolean(userData && (userData.authDomain || '').startsWith('googlelocalsvcs.googleauth')),
        );
    });

    // RP events
    observer.onValue('issuer', (issuer) => {
        const rpIdMatch = issuer?.id?.match(/\/CN=([^\/]+)\//);
        const rpId = rpIdMatch ? rpIdMatch[1] : null;
        const rpDisplayName = issuer?.displayName || null;
        handlers.onIssuerChange(rpId, rpDisplayName);
    });

    // PRODUCT-9575: handle current question change, based on its data.
    // Thanks to that, we are able to update question data,
    // when user landed on question page without RPR loaded yet.
    {
        let currentAttrType: string | null = null;
        observer.onRouteChange((route, _) => {
            currentAttrType = route && route.name === 'question' && route.params.slug
                ? unslugify(route.params.slug)
                : null;
        });
        observer.onValue('questions', (questions, prevQuestions) => {
            if (currentAttrType) {
                const question = questions.find((item) => item.attrType === currentAttrType);
                const prevQuestion = prevQuestions.find((item) => item.attrType === currentAttrType);

                if (question !== prevQuestion) {
                    handlers.onCurrentQuestionChange(currentAttrType, question ? question.type : null);
                }
            }
        });
    }

    // Basic interview events
    observer.onValue('status', handlers.onLoadingStatusChange);
    observer.onAction('loadInterview', handlers.onInterviewRequest);
    observer.onMutation('setInterviewDetails', (interviewDetails) => {
        if (interviewDetails?.featureFlags) {
            handlers.onFeatureFlagsChange(interviewDetails.featureFlags);
        }
    });

    // Question manipulation events
    observer.onValue('questions', handlers.onQuestionsChange);
    observer.onAction(
        'submitAttributes',
        ({ attributes }) => handlers.onSubmissionRequest(Object.keys(attributes)),
    );
    observer.onMutation('setSubmissionStatus', (statuses) => {
        const attributeTypes = Object.keys(statuses);
        const started = attributeTypes.filter((x) => statuses[x]);
        const finished = attributeTypes.filter((x) => !statuses[x]);
        handlers.onSubmissionStatusChange(started, finished);
    });
    observer.onMutation('completeQuestion', handlers.onQuestionComplete);
    observer.onMutation('setValue', ({ name, value }) => handlers.onValueChange(name, value));
    observer.onAction(
        'verifyAttributes',
        ({ attributes }) => handlers.onVerificationRequest(Object.keys(attributes)),
    );
    observer.onMutation(
        'setVerificationStatus',
        ({ attributeTypes, failureReason, result }) =>
            handlers.onVerificationResultChange(attributeTypes, result, failureReason),
    );

    // Fetching additional data for questions
    observer.onAction('fetchAttributeData', (attributeTypes, _, state) => {
        const questions = (state.questions || [])
            .filter((question) => attributeTypes.includes(question.attrType));
        const types = questions.map((question) => question.type);
        const uniqueTypes = types.filter((x, i, arr) => arr.indexOf(x) === i);

        handlers.onFetchAttributesDataRequest(attributeTypes, uniqueTypes);
    });
    observer.onActionFinish('fetchAttributeData', (attributeTypes, _, state) => {
        const questions = state.questions
            .filter((question) => attributeTypes.includes(question.attrType));
        const types = questions.map((question) => question.type);
        const uniqueTypes = types.filter((x, i, arr) => arr.indexOf(x) === i);

        handlers.onFetchAttributesDataFinish(attributeTypes, uniqueTypes);
    });
    observer.onAction('loadAcademicProviders', handlers.onFetchAcademicProvidersRequest);
    observer.onMutation('setAcademicProviders', handlers.onFetchAcademicProvidersFinish);
    observer.onAction('loadUtilityProviders', handlers.onFetchUtilityProvidersRequest);
    observer.onMutation('setUtilityProviders', handlers.onFetchUtilityProvidersFinish);
    observer.onAction('loadCriminalOffenses', handlers.onFetchCriminalOffensesRequest);
    observer.onMutation('setCriminalOffenses', handlers.onFetchCriminalOffensesFinish);

    // Delegation events
    observer.onMutation('setDelegationPhoneNumber', (phoneNumber) => {
        handlers.onDelegationPhoneNumberChange(
            (phoneNumber && phoneNumber.number) || null,
            (phoneNumber && phoneNumber.country) || null,
            Boolean(phoneNumber && phoneNumber.valid),
        );
    });
    observer.onAction('delegate', handlers.onDelegationRequest);
    observer.onMutation('startDelegation', handlers.onDelegationStart);
    observer.onMutation('finishDelegation', handlers.onDelegationFinish);
    observer.onMutation('failDelegation', handlers.onDelegationFailure);

    // Transition & consent
    observer.onAction('requestTransition', handlers.onTransitionInformationRequest);
    observer.onActionFinish('requestTransition', handlers.onTransitionInformationFinish);
    observer.onAction('sendConsent', handlers.onSendConsentRequest);
    observer.onValue('consentGiven', handlers.onSendConsentStatusChange);

    // Payment events
    observer.onAction(
        'initializeStripe',
        (_, type, state) => handlers.onStripeInitializationRequest(state.balance),
    );
    observer.onActionFinish('initializeStripe', handlers.onStripeInitializationFinish);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    observer.onAction('pay', (_, type, state) => handlers.onPaymentRequest(state.balance!));
    observer.onMutation('finishPayment', handlers.onPaymentFinish);

    // GLS
    let glsEmails: {
        fieldWorkers: string[];
        businessOwners: string[];
        businessOwnersAndFieldWorkers: string[];
        allFieldWorkers: string[];
        all: string[];
    } = {
        fieldWorkers: [],
        businessOwners: [],
        businessOwnersAndFieldWorkers: [],
        allFieldWorkers: [],
        all: [],
    };
    function rebuildGlsEmails(state: any): typeof glsEmails {
        const fieldWorkers = state.values[GlsAttributeType.fieldWorkers]?.list || [];
        const businessOwners = state.values[GlsAttributeType.businessOwners]?.list || [];
        const businessOwnersAndFieldWorkers = state.values[GlsAttributeType.businessOwnersAndFieldworkers]?.list || [];
        glsEmails = {
            fieldWorkers,
            businessOwners,
            businessOwnersAndFieldWorkers,
            allFieldWorkers: [ ...fieldWorkers, ...businessOwnersAndFieldWorkers ],
            all: [ ...fieldWorkers, ...businessOwnersAndFieldWorkers, ...businessOwners ],
        };
        return glsEmails;
    }
    observer.onMutation('setGlsDetails', (data) => {
        handlers.onGlsUpdate(data);
    });
    observer.onAction('addGlsFieldWorker', (email, type, state) => {
        rebuildGlsEmails(state);
        handlers.onAddGlsFieldWorker(email, {
            fieldWorkers: glsEmails.allFieldWorkers,
            businessOwners: glsEmails.businessOwners,
        });
    });
    observer.onAction('addGlsBusinessOwner', (email, type, state) => {
        rebuildGlsEmails(state);
        handlers.onAddGlsBusinessOwner(email, {
            fieldWorkers: glsEmails.allFieldWorkers,
            businessOwners: glsEmails.businessOwners,
        });
    });
    observer.onAction('deleteGlsFieldWorker', (email, type, state) => {
        rebuildGlsEmails(state);
        handlers.onDeleteGlsFieldWorker(email, {
            fieldWorkers: glsEmails.allFieldWorkers,
            businessOwners: glsEmails.businessOwners,
        });
    });
    observer.onAction('deleteGlsBusinessOwner', (email, type, state) => {
        rebuildGlsEmails(state);
        handlers.onDeleteGlsBusinessOwner(email, {
            fieldWorkers: glsEmails.allFieldWorkers,
            businessOwners: glsEmails.businessOwners,
        });
    });
    observer.onAction('resendGlsSubmissionLink', (emails, type, state) => {
        rebuildGlsEmails(state);
        const fieldWorkers = emails.filter((email) => glsEmails.allFieldWorkers.includes(email));
        const businessOwners = emails.filter((email) => glsEmails.businessOwners.includes(email));
        const unknownEmails = emails.filter((email) => !glsEmails.all.includes(email));
        handlers.onResendGlsSubmissionLinkRequest(fieldWorkers, businessOwners, unknownEmails);
    });
    observer.onMutation('failGlsResendLink', (emails) => {
        const fieldWorkers = emails.filter((email) => glsEmails.allFieldWorkers.includes(email));
        const businessOwners = emails.filter((email) => glsEmails.businessOwners.includes(email));
        const unknownEmails = emails.filter((email) => !glsEmails.all.includes(email));
        handlers.onResendGlsSubmissionLinkFailure(fieldWorkers, businessOwners, unknownEmails);
    });
    observer.onMutation('finishGlsResendLink', (emails) => {
        const fieldWorkers = emails.filter((email) => glsEmails.allFieldWorkers.includes(email));
        const businessOwners = emails.filter((email) => glsEmails.businessOwners.includes(email));
        const unknownEmails = emails.filter((email) => !glsEmails.all.includes(email));
        handlers.onResendGlsSubmissionLinkFinish(fieldWorkers, businessOwners, unknownEmails);
    });
}
