import {
    GlsBusinessAddress,
    GlsCaseData,
    GlsCaseStatus,
    GlsCaseSubmission,
    GlsCaseSubmissionInput,
    GlsCheckResult,
    GlsEmailDetails,
    GlsInternalBusinessAddress,
    GlsInternalCheckResult,
    GlsInternalCaseData,
    GlsInternalCaseStatus,
    GlsInternalCaseSubmission,
    GlsInternalCaseSubmissionInput,
    GlsInternalEmailDetails,
    GlsInternalUserSession,
    GlsUserSession,
} from './types';

/* eslint-disable no-use-before-define */

function convertDate(isoDate: string | null): Date | null {
    return isoDate == null ? null : new Date(Date.parse(isoDate));
}

export function convertStatusFromInternal(status: GlsInternalCaseStatus): GlsCaseStatus {
    return {
        caseStatus: status.case_status,
        submissionStatus: status.submission_status,
    };
}

export function convertUserSessionFromInternal(session: GlsInternalUserSession): GlsUserSession {
    return {
        email: session.email,
        caseId: session.case_id,
        interviewUrl: session.interview_uri || null,
    };
}

export function convertCheckResultFromInternal(result: GlsInternalCheckResult): GlsCheckResult {
    return {
        status: result.case_status,
        checkCompletedAt: convertDate(result.check_completed_time),
        businessEntitySubmittedAt: convertDate(result.submitted_business_entity_info_time),
        businessEntityCheckCompletedAt: convertDate(result.completed_business_entity_check_time),
        finallyAdjudicatedAt: convertDate(result.final_adjudication_time),
        fieldWorkerCount: result.field_worker_count,
        ownerCount: result.owner_count,
        requiredChecksCount: result.required_checks_count,
        submittedChecksCount: result.submitted_checks_count,
        completedChecksCount: result.completed_checks_count,
    };
}

export function convertCaseDetailsFromInternal(details: GlsInternalCaseData): GlsCaseData {
    return {
        serviceType: details.service_type,
        caseId: details.case_id,
        name: details.name,
        businessName: details.business_name,
        serviceCategories: details.service_categories,
        requiredChecks: details.required_check,
        businessAddress: convertBusinessAddressFromInternal(details.business_address || null),
        checkResult: details.check_result ? convertCheckResultFromInternal(details.check_result) : null,
    };
}

function convertEmailDetailsFromInternal(details: GlsInternalEmailDetails): GlsEmailDetails {
    return {
        email: details.email,
        status: details.rpr_submission_status,
    };
}

function convertEmailDetailsToInternal(details: GlsEmailDetails): GlsInternalEmailDetails {
    return {
        email: details.email,
        rpr_submission_status: details.status,
    };
}

export function convertCaseSubmissionFromInternal(submission: GlsInternalCaseSubmission): GlsCaseSubmission {
    return {
        // @ts-ignore: possibly nullish value passed
        caseId: parseInt(submission.verification_case_id, 10) || null,
        primaryBusinessOwner: submission.primary_business_owner || null,
        fieldWorkers: submission.field_workers.map(convertEmailDetailsFromInternal),
        businessOwners: submission.business_owners.map(convertEmailDetailsFromInternal),
        businessAddress: convertBusinessAddressFromInternal(submission.business_address || null),
    };
}

export function convertCaseSubmissionToInternal(submission: GlsCaseSubmission): GlsInternalCaseSubmission {
    return {
        verification_case_id: submission.caseId ? `${submission.caseId}` : null,
        primary_business_owner: submission.primaryBusinessOwner,
        business_address: convertBusinessAddressToInternal(submission.businessAddress),
        field_workers: submission.fieldWorkers.map(convertEmailDetailsToInternal),
        business_owners: submission.businessOwners.map(convertEmailDetailsToInternal),
    };
}

export function convertCaseSubmissionInputToInternal(
    submission: GlsCaseSubmissionInput,
): GlsInternalCaseSubmissionInput {
    return {
        verification_case_id: submission.caseId ? `${submission.caseId}` : null,
        business_address: convertBusinessAddressToInternal(submission.businessAddress),
        field_workers: submission.fieldWorkers,
        business_owners: submission.businessOwners,
    };
}

function convertBusinessAddressFromInternal(address: null): null;
function convertBusinessAddressFromInternal(address: GlsInternalBusinessAddress): GlsBusinessAddress;
function convertBusinessAddressFromInternal(address: GlsInternalBusinessAddress | null): GlsBusinessAddress | null;
function convertBusinessAddressFromInternal(address: GlsInternalBusinessAddress | null): GlsBusinessAddress | null {
    if (!address) {
        return null;
    }

    const city = address.locality || '';
    const street = address.address_lines?.join(' ') || '';
    const state = address.administrative_area || '';
    const postcode = address.postal_code || '';

    if (!city && !street && !state && !postcode) {
        return null;
    }

    return {
        city,
        street,
        state,
        postcode,
    };
}
export { convertBusinessAddressFromInternal };

// TODO: Handle different countries than the USA (?)
function convertBusinessAddressToInternal(address: null): null;
function convertBusinessAddressToInternal(address: GlsBusinessAddress): GlsInternalBusinessAddress;
function convertBusinessAddressToInternal(address: GlsBusinessAddress | null): GlsInternalBusinessAddress | null;
function convertBusinessAddressToInternal(address: GlsBusinessAddress | null): GlsInternalBusinessAddress | null {
    if (!address) {
        return null;
    }
    return {
        revision: 0,
        region_code: 'US',
        language_code: 'en',
        administrative_area: address.state,
        postal_code: address.postcode,
        locality: address.city,
        address_lines: [ address.street ],
    };
}
export { convertBusinessAddressToInternal };
