<template>
    <i :touch-action="touchAction" class="Named" :class="icon" v-on="$listeners" />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import getIconClassName from '@evidentid/browser-framework/icons';

    @Component
    export default class Icon extends Vue {
        @Prop({ type: String })
        private name!: string;

        @Prop({ type: String, default: undefined })
        private touchAction!: string;

        private get icon() {
            return getIconClassName(this.name);
        }
    }
</script>
