import Tracker from '@evidentid/tracker';
import ConsoleTrackerAdapter from '@evidentid/tracker/adapters/ConsoleTrackerAdapter';
import NoopTrackerAdapter from '@evidentid/tracker/adapters/NoopTrackerAdapter';
import WoopraTrackerAdapter from '@evidentid/tracker/adapters/WoopraTrackerAdapter';
import MixpanelTrackerAdapter from '@evidentid/tracker/adapters/MixpanelTrackerAdapter';
import HeapAnalyticsTrackerAdapter from '@evidentid/tracker/adapters/HeapAnalyticsTrackerAdapter';
import TrackerAdapter from '@evidentid/tracker/TrackerAdapter';
import TrackerUserData from '@evidentid/tracker/TrackerUserData';

interface AdapterConstructor<T extends TrackerUserData = TrackerUserData> {
    new(...args: any): TrackerAdapter<T>;
}

function createAdapters<T extends { [key: string]: AdapterConstructor }>(adapters: T): T {
    return adapters;
}

const adapters = createAdapters({
    noop: NoopTrackerAdapter,
    console: ConsoleTrackerAdapter,
    woopra: WoopraTrackerAdapter,
    mixpanel: MixpanelTrackerAdapter,
    heapanalytics: HeapAnalyticsTrackerAdapter,
});

type AdaptersType = typeof adapters;
type AdapterOptions<T extends AdaptersType[keyof AdaptersType]> = ConstructorParameters<T> extends []
    ? ConstructorParameters<T> | [{}]
    : ConstructorParameters<T>;

export default function createTracker<
    T extends TrackerUserData = TrackerUserData,
    K extends keyof AdaptersType = keyof AdaptersType
>(adapter: K, ...adapterOptions: AdapterOptions<AdaptersType[K]>): Tracker<T> {
    const Adapter = adapters[adapter] as AdapterConstructor<T>;
    return new Tracker<T>(new Adapter(...adapterOptions));
}
