<template>
    <div class="ChargeNav">
        <button
            class="ChargeNav__NavLink"
            :class="isFirst ? 'ChargeNav__NavLink--inactive' : 'ChargeNav__NavLink--active'"
            @click="onPrevious"
        >
            Previous
        </button>
        <div class="ChargeNav__title">
            Charge {{ (index + 1) || 1 }} of {{ total || 1 }}
        </div>
        <button
            class="ChargeNav__NavLink"
            :class="isLast ? 'ChargeNav__NavLink--inactive' : 'ChargeNav__NavLink--active'"
            @click="onNext"
        >
            Next
        </button>
    </div>
</template>

<script lang="ts">
    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class ChargeNavigator extends Vue {
        @Prop({ type: Number })
        private index!: number;

        @Prop({ type: Number })
        private total!: number;

        private get isFirst() {
            return this.index === 0;
        }

        private get isLast() {
            return this.index >= this.total - 1;
        }

        private onPrevious() {
            if (!this.isFirst) {
                this.$emit('change', this.index - 1);
            }
        }

        private onNext() {
            if (!this.isLast) {
                this.$emit('change', this.index + 1);
            }
        }
    }
</script>
