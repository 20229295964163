import { DateTime } from 'luxon';
import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import DateComponent from '@/components/fields/Date.vue';

export default class DateField extends Field<string> {
    public format = 'MM/dd/yyyy';
    public override component: Field['component'] = DateComponent;

    public isValid(_: Question, value: string): boolean {
        const date = value ? DateTime.fromFormat(value, this.format) : null;
        return Boolean(value && date?.isValid);
    }

    public async encode(question: Question, value: string): Promise<AttributesValues> {
        const date = value ? DateTime.fromFormat(value, this.format) : null;
        return this._encode(question, {
            $objectType: 'date',
            year: date?.year,
            day: date?.day,
            month: date?.month || null,
        });
    }
}
