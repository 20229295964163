// Let the record show that I am appropriately ashamed of all that
// you are about to witness.

import logger from '@evidentid/universal-framework/logger';
import keyExclude from 'lodash/omit';
import { doesAttrTypeRepresentIdScan } from '@evidentid/evident-attributes/attrTypes';
import { isVideoSelfieAttributeType } from './videoSelfie';

const SELFIE_TYPE = 'identity_assurance.document_verification.selfie_to_document.selfie_image';

function curateSelfieStep(idScanAttrs, inputAttributes, hidden) {
    return (SELFIE_TYPE in inputAttributes)
        ? {
            [SELFIE_TYPE]: Object.assign({}, inputAttributes[SELFIE_TYPE], {
                metadata: Object.assign({}, inputAttributes[SELFIE_TYPE].metadata, {
                    hidden,
                    icon: 'camera',
                }),
            }),
        }
        : {};
}

function curateForBiometricConsent(inputAttributes, idScanAttrs) {
    const idScanQuestionAttrs = Object.keys(idScanAttrs).filter(doesAttrTypeRepresentIdScan);
    const biometricConsent = inputAttributes['consent.biometric.authorization'];
    if (biometricConsent?.complete === false) {
        // Ensure that the biometric consent authorization has next attribute types list
        biometricConsent.metadata = {
            ...biometricConsent.metadata,
            nextAttrTypes: biometricConsent.metadata?.nextAttrTypes || [],
        };

        // Expect ID scan shown after the biometric consent
        for (const attrType of idScanQuestionAttrs) {
            idScanAttrs[attrType].metadata.hidden = true;
            biometricConsent.metadata.nextAttrTypes.push(attrType);
        }
    }
}

// Transforms ID Scanning input space such that only the ID scan
// is requested to represent all attributes it implicitly populates.
export function curateIdScan(aggregatedRequest) {
    const inputAttributes = aggregatedRequest.input;
    const idScanAttrs = Object.keys(inputAttributes).filter(doesAttrTypeRepresentIdScan);

    if (idScanAttrs.length > 0) {
        // `filtered` contains NO id scanning-fulfilled input attributes.
        // We will add simplified steps on top of this.
        const filtered = keyExclude(inputAttributes, idScanAttrs.concat([
            'core.firstname',
            'core.middlename',
            'core.fullname',
            'core.lastname',
            'core.address.fulladdress',
            'core.dateofbirth',
            'idverify1.driverslicense.guid',
            'driverslicense.state',
            'driverslicense.id',
            SELFIE_TYPE,
        ]));

        // FIXME: I don't have any idea why, but the values can differ
        if (filtered['consent.biometric.authorization']) {
            filtered['consent.biometric.authorization'] = inputAttributes['consent.biometric.authorization'];
        }

        const US_RX = /us_issued/;
        const legacyAttrTypes = idScanAttrs.filter((at) => US_RX.test(at));
        const contempotaryAttrTypes = idScanAttrs.filter((at) => !US_RX.test(at));
        const idScanSteps = {};

        const selfieAttrTypes = Object.keys(inputAttributes)
            .filter((attrType) => attrType === SELFIE_TYPE || isVideoSelfieAttributeType(attrType));
        const notCompletedSelfieAttrTypes = selfieAttrTypes.filter((attrType) => !inputAttributes[attrType].complete);

        // Consume any legacy attributes
        if (legacyAttrTypes.length > 0) {
            const legacyType = 'identity_assurance.document_verification.legacy';
            const frontAttrType = legacyAttrTypes.find((a) => a.indexOf('front') > -1);
            const { title = '', description = '' } = inputAttributes[frontAttrType].metadata || {};

            idScanSteps[legacyType] = {
                attrType: legacyType,
                complete: legacyAttrTypes.every((k) => inputAttributes[k].complete),
                hasError: legacyAttrTypes.some((k) => inputAttributes[k].hasError),
                shareWith: inputAttributes[legacyAttrTypes[0]].shareWith,
                metadata: {
                    docTypeAttr: 'identity_assurance.document_verification.americas.us.document_type',
                    nextAttrTypes: notCompletedSelfieAttrTypes,
                    regionPrescribed: 'americas',
                    countryPrescribed: 'us',
                    documentPrescribed: 'drivers_license',
                    isLegacy: true,
                    icon: 'photo-id',
                    title,
                    description,
                },
            };
        }

        // Now on to the hacky bits. We scan over the input attrs ending in document_type,
        // then the ones that don't. The way the data works out allows us to procedurally
        // build input attributes. This method is only one of many you could use, but I
        // chose this one because you can at least see how we build on data that was already
        // set by previous instructions.
        const isDocTypeAttr = (at) => at.endsWith('document_type');

        const docTypeAttrs = contempotaryAttrTypes.filter((a) => isDocTypeAttr(a));
        const imgTypeAttrs = contempotaryAttrTypes.filter((a) => (
            !isDocTypeAttr(a) &&
                a !== SELFIE_TYPE
        ));

        for (const dt of docTypeAttrs) {
            const tokens = dt.split('.');
            const [ region, country ] = tokens.slice(2, -1);
            const attrType = tokens.slice(0, -1).join('.');
            const { title = '', description = '' } = inputAttributes[dt].metadata || {};

            idScanSteps[attrType] = {
                metadata: {
                    docTypeAttr: dt,
                    nextAttrTypes: notCompletedSelfieAttrTypes,
                    icon: 'photo-id',
                    documentPrescribed: false,
                    regionPrescribed: region || false,
                    countryPrescribed: country || false,
                    title,
                    description,
                },
                attrType,

                // Warning: Doing it this way makes it impossible to support multiple .document_type dependencies.
                complete: idScanAttrs.every((at) => inputAttributes[at].complete),
                hasError: idScanAttrs.some((at) => inputAttributes[at].hasError),
                shareWith: inputAttributes[dt].shareWith,
            };
        }

        for (const it of imgTypeAttrs) {
            const tokens = it.split('.');
            const prefixEnd = tokens.findIndex((t) => [ 'passport', 'drivers_license', 'id_card' ].includes(t));

            const documentPrescribed = tokens[prefixEnd];

            const key = tokens.slice(0, prefixEnd).join('.');

            const isPassport = documentPrescribed === 'passport';
            const originalInput = inputAttributes[it];
            const frontInput = inputAttributes[imgTypeAttrs.find((a) => a.indexOf('front') > -1)];
            const input = isPassport ? originalInput : frontInput || originalInput;
            const metaSource = input.metadata;

            if (!isPassport && !frontInput) {
                logger.error(`Detected ID scan without *_front attribute. Existing types: ${imgTypeAttrs.join(', ')}`);
            }

            const step = idScanSteps[key];
            const metadata = metaSource
                ? { title: metaSource.title, description: metaSource.description }
                : {};

            if (step) {
                Object.assign(step.metadata, { documentPrescribed }, metadata);
            }
        }
        // if the biometric consent attribute is available hide the id scan attribute
        curateForBiometricConsent(inputAttributes, idScanSteps);
        const selfieStep = curateSelfieStep(
            contempotaryAttrTypes,
            inputAttributes,
            idScanAttrs.some((dt) => !inputAttributes[dt].complete),
        );

        aggregatedRequest.input = Object.assign(filtered, selfieStep, idScanSteps);
    }

    return aggregatedRequest;
}
