<template>
    <div class="GlsImmediateRedirectModal">
        <div class="GlsImmediateRedirectModal__body">
            <h2 class="GlsImmediateRedirectModal__title">
                We are working on your request
            </h2>
            <Spinner />
            <span class="GlsImmediateRedirectModal__description">You will be auto-redirected to the submission page</span>
        </div>
    </div>
</template>

<style lang="less">
    .GlsImmediateRedirectModal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999999;
        width: 100%;
        height: 100%;
        overflow: auto;
        background: #dbdbdb;

        .Spinner {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            margin: 10px auto;
        }

        &__body {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            background: #fff;
            margin: 0 auto;
            width: 95%;
            max-width: 580px;
            padding: 52px 80px;
            box-shadow: 0 3px 6px 2px rgba(#000, 0.1);
            text-align: center;

            @media (max-width: 480px) {
                padding: 26px 40px;
            }
        }

        &__title {
            color: #2ab496;
            margin: 0;
        }

        &__description {
            color: #696969;
        }
    }
</style>

<script lang="ts">
    import { Vue, Component } from 'vue-property-decorator';
    import Spinner from '@/components/common/Spinner.vue';

    @Component({
        components: { Spinner },
    })

    export default class GlsImmediateRedirectModal extends Vue {
    }
</script>
