import { PolicyInfo } from '@/utils/insurance';

const consentPolicyText: Record<string, (policyInfo: PolicyInfo[]) => string | null> = {
    AL: (policyInfo: PolicyInfo[]) => (policyInfo.length > 0
        ? `This certificate or memorandum of insurance neither affirmatively nor negatively amends, extends, \
           or alters the coverage afforded by ${policyInfo.map((policy) => `policy number \
           ${policy.policyNumber} issued by ${policy.insurer}`).join(', ')}.`
        : null),

    CA: (_: PolicyInfo[]) => 'This certificate or verification of insurance is not an insurance policy and does not amend, ' +
        'extend or alter the coverage afforded by the policies listed herein. Notwithstanding any requirement, ' +
        'term, or condition of any contract or other document with respect to which this certificate or ' +
        'verification of insurance may be issued or may pertain, the insurance afforded by the policies ' +
        'described herein is subject to all the terms, exclusions and conditions of the policies. ' +
        '[CA Insurance Code, Sect. 384]',

    IA: (_: PolicyInfo[]) => 'This certificate of insurance is issued as a matter of information only and confers no rights upon the' +
        ' certificate holder. This certificate does not amend, extend, or alter the coverage afforded by the ' +
        'insurance policy referenced herein.',

    ID: (policyInfo: PolicyInfo[]) => (policyInfo.length > 0
        ? `This Certificate of Insurance neither affirmatively nor negatively amends, extends, nor alters the \
        coverage afforded by the policy or policies numbered in this certificate. \
        ${policyInfo.map((policy) => `Issued by: ${policy.insurer} (Company) Issued to: ${policy.insured} \
        (Insured) Policy Effective Date (s): ${policy.effectiveDate} Policy Expiration Date: \
        ${policy.expirationDate}`).join(', ')}.`
        : null),

    IL: (policyInfo: PolicyInfo[]) => (policyInfo.length > 0
        ? `This certificate or memorandum of insurance neither affirmatively nor negatively amends, extends, or alters \
        the coverage afforded by ${policyInfo.map((policy) => `policy number ${policy.policyNumber} issued by \
        ${policy.insurer} on ${policy.effectiveDate}`).join(', ')}.`
        : null),

    IN: (policyInfo: PolicyInfo[]) => (policyInfo.length > 0
        ? `This certificate of insurance neither affirmatively nor negatively amends, extends, or alters the coverage \
        afforded by ${policyInfo.map((policy) => `policy number ${policy.policyNumber} issued by \
        ${policy.insurer} on ${policy.effectiveDate}`).join(', ')}.`
        : null),

    KS: (_: PolicyInfo[]) => 'The certificate of insurance neither affirmatively nor negatively amends, extends or alters the ' +
        'coverage afforded by the policies listed thereon. An industry standard setting organization may be ' +
        'authorized by the commissioner of insurance to file certificate of insurance forms on behalf of ' +
        'authorized insurers.',

    KY: (policyInfo: PolicyInfo[]) => (policyInfo.length > 0
        ? `This certificate or memorandum of insurance neither affirmatively nor negatively amends, extends, or alters \
        the coverage afforded by ${policyInfo.map((policy) => `policy number ${policy.policyNumber} issued \
        by ${policy.insurer}`).join(', ')}.`
        : null),

    LA: (_: PolicyInfo[]) => 'This additional information neither affirmatively nor negatively amends, extends or alters the ' +
        'coverage afforded by the referenced insurance policy(ies).',

    MN: (_: PolicyInfo[]) => 'This certificate or memorandum of insurance does not affirmatively or negatively amend, extend, or alter the ' +
        'coverage afforded by the insurance policy.',

    MS: (policyInfo: PolicyInfo[]) => (policyInfo.length > 0
        ? `This certificate of insurance neither affirmatively nor negatively amends, extends, or alters the coverage \
        afforded by ${policyInfo.map((policy) => `policy number ${policy.policyNumber} issued by \
        ${policy.insurer}`).join(', ')}.`
        : null),

    NM: (policyInfo: PolicyInfo[]) => (policyInfo.length > 0
        ? `This certificate or memorandum of insurance neither affirmatively nor negatively amends, extends, or alters \
        the coverage afforded by ${policyInfo.map((policy) => `policy number ${policy.policyNumber} issued by \
        ${policy.insurer}`).join(', ')}.`
        : null),

    OH: (_: PolicyInfo[]) => 'This document neither affirmatively nor negatively amends, extends, or alters the terms of or the coverage ' +
        'afforded by policy referenced herein.',

    OK: (policyInfo: PolicyInfo[]) => (policyInfo.length > 0
        ? `This certificate or memorandum of insurance neither affirmatively nor negatively amends, extends, or alters \
        the coverage afforded by ${policyInfo.map((policy) => `policy number ${policy.policyNumber} issued by \
        ${policy.insurer} on ${policy.effectiveDate}`).join(', ')}.`
        : null),

    WY: (policyInfo: PolicyInfo[]) => (policyInfo.length > 0
        ? `This certificate or memorandum of insurance neither affirmatively nor negatively amends, extends, or alters \
        the coverage afforded by ${policyInfo.map((policy) => `policy number ${policy.policyNumber} issued by \
        ${policy.insurer} on ${policy.effectiveDate}`).join(', ')}.`
        : null),
};

function sanitizeSpaces(text: string): string {
    return text.replace(/\s{2,}/g, ' ').trim();
}

export const defaultConsentText: string = 'By submitting this form I attest that the information contained herein ' +
    'is accurate, and the policy is active and in good standing';

export function getConsentText(state: string, policyInfo: PolicyInfo[]): string {
    return sanitizeSpaces(consentPolicyText[state] && consentPolicyText[state](policyInfo) || defaultConsentText);
}
