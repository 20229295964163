<template>
    <label
        :for="id"
        class="Checkbox ConsentCheckbox"
        :class="[ { 'checked': value, 'disabled': disabled }, extraClass ]"
    >
        <input
            :id="id"
            type="checkbox"
            style="display: none"
            :disabled="disabled"
            :checked="value"
            @click="onChange"
        >

        <Icon :name="value ? 'checked' : 'unchecked'" />
        <slot />
    </label>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';

    @Component({
        components: { Icon },
    })
    export default class Checkbox extends Vue {
        @Prop({ type: String, default: undefined })
        private id!: string;

        @Prop({ type: String, default: undefined })
        private extraClass!: string;

        @Prop({ type: Boolean, default: false })
        private value!: boolean;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        private onChange(event: Event) {
            if (!this.disabled) {
                this.$emit('input', (event.target as HTMLInputElement).checked);
            }
        }
    }
</script>
