<template>
    <label class="LineInputBlock">
        <PhoneNumberInput
            id="phone"
            v-model="inputValue"
            @input="onChange"
        />
    </label>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';
    import FieldComponent from '@/fields/FieldComponent';
    import PhoneNumberInput from '@/components/forms/PhoneNumberInput.vue';
    import PhoneNumberValue from '@/fields/interfaces/PhoneNumber';

    @Component({
        components: { PhoneNumberInput, Icon },
    })
    export default class PhoneNumber extends FieldComponent<PhoneNumberValue> {
        private inputValue: PhoneNumberValue = this.value || '';

        @Watch('value')
        private onValueChange(value: PhoneNumberValue) {
            this.inputValue = value;
        }

        private onChange() {
            this.$emit('change', this.inputValue);
        }
    }
</script>
