import TrackerAdapter from '../TrackerAdapter';

export default class NoopTrackerAdapter implements TrackerAdapter {
    public initialize() {
        return Promise.resolve();
    }

    public isReady() {
        return true;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public track() {}
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public setUserData() {}
}
