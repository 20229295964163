import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import Question from '@evidentid/ido-lib/interfaces/Question';
import Field from './Field';
import { Status, VerificationFailureReason, VerificationResult } from '@/store/interfaces/IdoState';
import PhoneNumber from '@/fields/interfaces/PhoneNumber';

class FieldComponentConstructor<Value = any> extends Vue {
    @Prop(Object)
    protected question!: Question;

    @Prop(Object)
    protected field!: Field<Value>;

    @Prop(Boolean)
    protected valid!: boolean;

    @Prop(Boolean)
    protected submitting!: boolean;

    @Prop(Number)
    protected questionsLeft!: number;

    @Prop()
    protected value!: Value;

    @Prop({ type: String, default: Status.uninitialized })
    protected delegationStatus!: Status;

    @Prop(Number)
    protected delegationsLeft!: number;

    @Prop()
    protected delegationPhoneNumber!: PhoneNumber | null;

    @Prop(String)
    protected verificationResult!: VerificationResult;

    @Prop({ type: String, default: null })
    protected verificationFailureReason!: VerificationFailureReason | null;

    @Prop({ type: Number, default: 0 })
    protected verificationsFailed!: number;

    @Prop({ type: Number, default: 0 })
    protected verificationResolutionAttempts!: number;

    @Prop({ type: Boolean, default: false })
    protected bare!: boolean;
}

// Trick TypeScript, so it will not detect that the class is decorated
// It is required for proper IDE completion
let FieldComponent = FieldComponentConstructor;
// @ts-ignore: the index signature breaks somewhere there
FieldComponent = Mixins(Component(FieldComponent));

export default FieldComponent;
