<template>
    <div class="ProgressBar" :class="{ 'ProgressBar--indeterminate': value == null }">
        <div class="ProgressBar__bar">
            <div class="ProgressBar__inner" :style="'width: ' + width + '%'" />
        </div>
        <div v-if="label != null" class="ProgressBar__label">
            {{ label }}
        </div>
    </div>
</template>

<style lang="less">
    @bar-height: 10px;
    @label-font-size: @bar-height;
    @stripe-size: @bar-height;
    @stripe-color: rgba(255, 255, 255, 0.2);
    @progress-background-empty: #fff;
    @progress-background-full: #00bf97;
    @progress-border: @progress-background-full;

    .ProgressBar {
        position: relative;
        display: flex;
        flex-direction: column;

        &__bar {
            box-sizing: border-box;
            border: 1px solid @progress-border;
            background: @progress-background-empty;
            height: @bar-height;
            border-radius: @bar-height / 2;
            width: 100%;
        }

        &__inner {
            background: @progress-background-full;
            height: 100%;
        }

        &__label {
            margin-top: @bar-height / 2;
            font-size: @label-font-size;
            line-height: 1em;
        }

        &--indeterminate &__inner {
            width: 100%;
            transform: none;
            animation: none;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                user-select: none;
                pointer-events: none;
                background-size: @stripe-size @stripe-size;
                background-repeat: repeat;
                // stylelint-disable-next-line max-line-length
                background-image: linear-gradient(45deg, @stripe-color 25%, transparent 0, transparent 50%, @stripe-color 0, @stripe-color 75%, transparent 0, transparent);
                animation: 300ms stripesAnimation infinite linear;
            }
        }
    }

    @keyframes stripesAnimation {
        0% {
            background-position: 0 0;
        }

        100% {
            background-position: @stripe-size 0;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class ProgressBar extends Vue {
        @Prop({ type: String, default: null })
        private label!: string | null;

        @Prop({ type: Number, default: null })
        private value!: number | null;

        private get width() {
            return this.value == null ? 100 : Math.min(1, Math.max(0, this.value)) * 100;
        }
    }
</script>
