import { isEmail } from '@evidentid/universal-framework/validation';
import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import SingleLineText from '@/components/fields/SingleLineText.vue';

export default class EmailField extends Field<string> {
    public override component = SingleLineText;

    public isValid(_: Question, value: string): boolean {
        return isEmail(value);
    }

    public async encode(question: Question, value: string): Promise<AttributesValues> {
        return this._encode(question, value);
    }
}
