<template>
    <label class="CheckoutFormField">
        <div class="CheckoutFormField__input">
            <span class="CheckoutFormField__port">
                <span class="CheckoutFormField__icon"><Icon :name="icon" /></span>
                {{ label }}
            </span>
            <span class="CheckoutFormField__starboard">
                <slot />
            </span>
        </div>
        <div class="CheckoutFormField__feedback">
            <span class="CheckoutFormField__port" />
            <span class="CheckoutFormField__starboard">
                <div :id="id ? id + '-error' : undefined">{{ message || nbsp }}</div>
            </span>
        </div>
    </label>
</template>

<script lang="ts">
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';

    @Component({
        components: { Icon },
    })
    export default class CheckoutFormField extends Vue {
        private nbsp = String.fromCharCode(160);

        @Prop({ type: String })
        private label!: string;

        @Prop({ type: String })
        private icon!: string;

        @Prop({ type: [ String, Object ], default: '' })
        private error!: string | { message: string } | null;

        @Prop({ type: String })
        private id!: string;

        private get message() {
            return (this.error && typeof this.error === 'object' && this.error.message) || this.error;
        }
    }
</script>
