/*
Used to select a validation and verification pipeline.
Each type has different requirements.
*/
export enum ACUANT_CARD_TYPES {
    DRIVERS_LICENSE = 'DriversLicense',
    DRIVERS_LICENSE_DUPLEX = 'DriversLicenseDuplex',
    PASSPORT = 'Passport',
    MEDICAL_CARD = 'MedicalCard',
    BARCODE = 'Barcode',
}

interface AcuantProcessingOptions {
    isiOS: boolean;
    dpi: number;
    cardType: ACUANT_CARD_TYPES;
}

/*
Compare system and image characteristics to decide if it's a good idea to
preprocess an image on the client. This function does NOT tell you the
desired specifications of an image if this function returns true. Refer
to Acuant docs for this.
*/
export function shouldPreprocessOnTheClient({ isiOS, dpi, cardType }: AcuantProcessingOptions): boolean {
    const { DRIVERS_LICENSE, DRIVERS_LICENSE_DUPLEX, PASSPORT, MEDICAL_CARD, BARCODE } = ACUANT_CARD_TYPES;

    // Certain versions of iOS suffer with large images.
    // We'd rather let Acuants's card processor reject the image than prevent the user
    // from doing anything at all because they happen to own an iOS device.
    if (isiOS) {
        return true;
    }

    // Acuant prefers identifying documents to have a certain quality.
    // If we already know an image meets their specifications, we should
    // not mess with it before submission.
    const IdCardOrPassportIs300dpi = (
        dpi === 300 && [ DRIVERS_LICENSE, DRIVERS_LICENSE_DUPLEX, PASSPORT ].includes(cardType)
    );

    const MedCardOrBarcodeIs600dpi = (
        dpi === 600 &&
        [ MEDICAL_CARD, BARCODE ].includes(cardType)
    );

    const imageIsAtMinimumNeededQuality = IdCardOrPassportIs300dpi || MedCardOrBarcodeIs600dpi;

    return !imageIsAtMinimumNeededQuality;
}
