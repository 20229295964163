<template>
    <div class="WeddingCake ErrorScreen">
        <div class="WeddingCake__body">
            <img class="WeddingCake__ornament" :src="iconUrl">
            <div class="WeddingCake__layers">
                <slot />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import errorIconUrl from '@evidentid/ido-lib/images/ErrorIcon.svg';
    import warningIconUrl from '@evidentid/ido-lib/images/WarningIcon.svg';

    @Component
    export default class ErrorScreen extends Vue {
        @Prop({ type: Boolean, default: false })
        private warning!: boolean;

        private get iconUrl() {
            return this.warning ? warningIconUrl : errorIconUrl;
        }
    }
</script>
