<template>
    <div class="TerminatedRequest">
        <div class="TerminatedRequest__block">
            <div class="flex flex-col justify-center items-center">
                <EidIcon :icon-src="documentExclamationIcon" />
                <p class="text-2.5xl font-medium text-eidSecureBlue mt-7">
                    This link is no longer available.
                </p>
            </div>
            <p class="TerminatedRequest__colorRpColor text-lg font-semibold mt-7" :style="{ color: brandingColor }">
                You may be seeing this message because:
            </p>
            <ul class="list-disc mt-3 -ml-3 pl-8">
                <li
                    v-for="message in seeingMessageReasons"
                    :key="message"
                    class="TerminatedRequest__reason text-eidQualityBlack-75"
                >
                    {{ message }}
                </li>
            </ul>
        </div>
        <div class="TerminatedRequest__block bg-eidTechGray-10 flex">
            <div class="w-2/3">
                <p class="TerminatedRequest__colorRpColor text-lg font-semibold mt-7" :style="{ color: brandingColor }">
                    What can I do next?
                </p>
                <ul class="list-disc mt-3 -ml-3 pl-8">
                    <li
                        v-for="nextStep in nextSteps"
                        :key="nextStep"
                        class="TerminatedRequest__step text-eidQualityBlack-75"
                        v-html="nextStep"
                    />
                </ul>
            </div>
            <div class="w-1/2 flex items-center justify-center">
                <div
                    class="TerminatedRequest__backgroundRpColor rounded-full p-7"
                    :style="{ background: brandingColor }"
                >
                    <EidIcon :icon-src="chatIcon" />
                </div>
            </div>
        </div>
        <div
            v-if="rpContacts.length > 0"
            class="
                TerminatedRequest__contact-block
                TerminatedRequest__block
                TerminatedRequest__block--small
                TerminatedRequest__backgroundRpColor
            "
            :style="{ background: brandingColor }"
        >
            <a
                v-for="({text, link, icon }, index) in rpContacts"
                :key="index"
                target="_blank"
                :href="link"
                class="TerminatedRequest__contact-link TerminatedRequest--border-right"
            >
                <EidIcon :icon-src="icon" class="mr-1" />
                {{ text }}
            </a>
        </div>
        <div v-if="privacyPolicy" class="TerminatedRequest__block flex items-center justify-center flex-col">
            <p class="text-eidSecureBlue-75 text-sm">
                {{ privacyPolicy.headline }}
            </p>
            <p class="text-xxs text-center max-w-xl mt-1">
                {{ privacyPolicy.message }}
                <a target="_blank" :href="privacyPolicy.url" class="text-eidTrustGreen hover:underline">
                    {{ privacyPolicy.title }}
                </a>
            </p>
        </div>
    </div>
</template>

<style lang="less">
    @import "./TerminatedRequest.less";
</style>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import documentExclamationIcon from '@/assets/document-exclamation.svg';
    import chatIcon from '@/assets/chat.svg';
    import emailIcon from '@/assets/email.svg';
    import phoneIcon from '@/assets/phone.svg';
    import websiteIcon from '@/assets/website.svg';
    import Identity, { PrivacyPolicy } from '@evidentid/ido-lib/interfaces/Identity';
    import { IdoRequestStatus } from '@evidentid/ido-lib/getIdoRequestStatus';
    import { getBrandingColor } from '@/config/brandingColor';
    import { SUPPORT_CONTACT_INFO_DEFAULT_URL_TITLE } from '@evidentid/ido-lib/getIdoRequestStatusData';

    type MetadataProp = IdoRequestStatus & { rpName: string };

    export default Vue.extend({
        name: 'TerminatedRequest',
        components: {
            EidIcon,
        },
        props: {
            supportEmail: {
                type: String as PropType<string>,
                default: '',
            },
            supportPhone: {
                type: String as PropType<string>,
                default: '',
            },
            supportLink: {
                type: String as PropType<string>,
                default: '',
            },
            supportTitle: {
                type: String as PropType<string>,
                default: '',
            },
            privacyPolicy: {
                type: Object as PropType<PrivacyPolicy>,
                default: () => ({}) as PrivacyPolicy,
            },
            metadata: {
                type: Object as PropType<MetadataProp>,
                default: () => ({}) as MetadataProp,
            },
            issuer: {
                type: Object as PropType<Identity>,
                default: () => ({}) as Identity,
            },
        },
        data() {
            return {
                documentExclamationIcon,
                chatIcon,
            };
        },
        computed: {
            supportTitleText(): string {
                return this.supportTitle === SUPPORT_CONTACT_INFO_DEFAULT_URL_TITLE ? '' : this.supportTitle;
            },
            seeingMessageReasons(): string[] {
                return [
                    'Your coverage has been evaluated and no further action is needed from you.',
                    `You have been sent a more recent email from ${this.metadata.rpName} with updated insurance requirements.`,
                ];
            },
            nextSteps(): string[] {
                const link = `<a class="underline font-semibold text-eidQualityBlack-75" target="_blank" href="https://www.evidentid.com/support/insurancestatus/">Customer Support Form</a>`;
                return [
                    `Use our ${link} to get the status of your insurance verification.`,
                    `Check your inbox to see if you have a more recent email from ${this.metadata.rpName}.`,
                    'Contact your broker or provider.',
                    `Contact ${this.metadata.rpName} directly using the information shown below.`,
                ];
            },
            emailLink(): string {
                return `mailto:${this.supportEmail}`;
            },
            phoneLink(): string {
                return `tel:${this.supportPhone}`;
            },
            rpContacts(): { text: string, link: string, icon: string }[] {
                return [
                    { text: this.supportEmail, link: this.emailLink, icon: emailIcon },
                    { text: this.supportPhone, link: this.phoneLink, icon: phoneIcon },
                    { text: this.supportTitleText, link: this.supportLink, icon: websiteIcon },
                ].filter(({ text }) => Boolean(text));
            },
            brandingColor(): string {
                return getBrandingColor(this.issuer?.primaryColor);
            },
        },
    });
</script>

