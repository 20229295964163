<template>
    <li class="CaseTimelineItem" :class="additionalClassName">
        <div class="CaseTimelineItem__icon">
            <Icon v-if="type === 'error'" name="x" />
            <Icon v-else-if="type === 'success'" name="checkmark" />
            <div v-else>
                {{ counter }}
            </div>
        </div>
        <div class="CaseTimelineItem__content">
            <div class="CaseTimelineItem__description">
                <slot name="description" />
            </div>
            <div class="CaseTimelineItem__footer">
                <slot name="footer" />
            </div>
        </div>
    </li>
</template>

<style lang="less">
    .CaseTimelineItem {
        position: relative;
        display: inline-flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        font-weight: 600;
        font-size: 1em;

        &__icon {
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.6em;
            color: #b6b6b6;
            background: #fff;
            border: 1px solid #b6b6b6;
            border-radius: 50%;
            width: 2.3em;
            height: 2.3em;
            font-weight: 400;
            user-select: none;
            margin-bottom: 15px;
        }

        &__content {
            z-index: 2;
        }

        &__description {
            display: block;
            line-height: 1.1em;
            text-transform: uppercase;
            color: #6b6b6b;
        }

        &__footer {
            display: block;
            line-height: 1.1em;
            margin-top: 15px;
            color: #2d3e50;
            font-size: 0.85em;
            cursor: pointer;

            a {
                color: inherit;
                text-decoration: underline;
            }
        }

        &--ongoing &__icon {
            border-color: #2ab496;
            color: #2ab496;
        }

        &--success &__icon {
            border-color: transparent;
            background-color: #2ab496;
            color: #fff;
        }

        &--error &__icon {
            border-color: transparent;
            background-color: #ef5b45;
            color: #fff;
        }

        &--ongoing &__description {
            color: #2ab496;
        }

        &--success &__description {
            color: #2ab496;
        }

        &--error &__description {
            color: #ef5b45;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { IdoRequestStatusTimelineEntryStatus } from '@evidentid/ido-lib/interfaces/IdoRequestStatus';
    import Icon from '@/components/common/Icon.vue';

    @Component({
        components: { Icon },
    })
    export default class CaseTimelineItem extends Vue {
        @Prop({ type: String, default: null })
        private type!: IdoRequestStatusTimelineEntryStatus | null;

        @Prop({ type: String })
        private counter!: string;

        private get additionalClassName() {
            return this.type ? `CaseTimelineItem--${this.type}` : undefined;
        }
    }
</script>
