<template>
    <div class="LandingPage">
        <div class="LandingPage__hero">
            <div class="Hero">
                <div class="Hero__copy">
                    <div class="Hero__heading">
                        Take control of<div class="Hero__highlight">
                            your
                        </div>online identity.
                    </div>
                    <div class="Hero__subheading">
                        The power to decide who gets access to your personal information and when.
                    </div>
                </div>
            </div>
        </div>
        <div class="LandingPage__brand" />
        <div class="LandingPage__interface">
            <div class="LoginControls">
                If you've been directed here as part of a request to
                verify personal information with a business, please
                sign in with your Google account to get started.

                <GoogleLoginButton />
            </div>
        </div>
        <div class="LandingPage__links">
            <nav class="glsLinks">
                <a href="https://www.evidentid.com/own-your-identity/">Learn more</a>
                <span>|</span>
                <a href="https://www.evidentid.com/privacy-policy/">Privacy Policy</a>
            </nav>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import GoogleLoginButton from '@/components/google/GoogleLoginButton.vue';
    import { isGlsSession } from '@/interfaces/SessionData';

    @Component({
        components: { GoogleLoginButton },
    })
    export default class GoogleBusinessVerification extends Vue {
        private getExistingCaseId(): number | null {
            const existingSession = this.$store?.state?.session;
            return existingSession && isGlsSession(existingSession)
                ? existingSession.caseId
                : null;
        }

        private get authCode(): string | null {
            const authCode = this.$route.query?.auth_code || this.$route.query?.authCode;
            // Not casting the blank array results in error from Typescript around ConcatArray<never> not having any
            // overloading methods
            // Ref: https://github.com/microsoft/TypeScript/issues/36769
            return ([] as (string | null)[]).concat(authCode)[0] || null;
        }

        private get caseId(): number {
            return parseInt(this.$route.params.caseId, 10);
        }

        @Watch('caseId', { immediate: true })
        private onCaseIdChange(caseId: string) {
            // Get the auth_code query param if present
            this.handleUrlComponentChange(parseInt(caseId, 10), this.authCode);
        }

        @Watch('authCode', { immediate: true })
        private onAuthCodeChanged(authCode: string) {
            // Get the caseId param from the route
            this.handleUrlComponentChange(this.caseId, authCode || null);
        }

        private handleUrlComponentChange(newCaseId: number, newAuthCode: string | null) {
            this.logoutFromCurrentSession();
            // Update existing information about session
            this.$store?.dispatch('setSessionData', { caseId: newCaseId, authCode: newAuthCode });
        }

        private logoutFromCurrentSession() {
            // Try to log out user if there was another session existing
            const existingCaseId = this.getExistingCaseId();
            if (existingCaseId) {
                this.$store?.dispatch('logOut', { localOnly: false });
            }
        }
    }
</script>
