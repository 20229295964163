<template>
    <PendingScreen v-if="submitting" />
    <InterviewCard v-else>
        <SelfieForm v-if="isMobile" v-bind="$props" v-on="$listeners" />
        <SelfieDelegationForm v-else v-bind="$props" v-on="$listeners" />
    </InterviewCard>
</template>

<script lang="ts">
    import { Component, Prop } from 'vue-property-decorator';
    import support from '@/config/support';
    import FieldComponent from '@/fields/FieldComponent';
    import SelfieDelegationForm from '@/components/fields/Selfie/SelfieDelegationForm.vue';
    import SelfieForm from '@/components/fields/Selfie/SelfieForm.vue';
    import PendingScreen from '@/components/screens/PendingScreen.vue';
    import InterviewCard from '@/layouts/InterviewCard.vue';

    @Component({
        inheritAttrs: false,
        components: { SelfieDelegationForm, SelfieForm, InterviewCard, PendingScreen },
    })
    export default class Selfie extends FieldComponent<any> {
        @Prop({ type: Boolean, default: support.isMobileBrowser })
        private isMobile!: boolean;
    }
</script>
