<template>
    <div class="RetryDescription">
        <h3 v-if="heading" class="RetryDescription__heading">
            We’re having trouble detecting your ID
        </h3>
        <table>
            <tbody>
                <tr class="RetryDescription__row">
                    <td class="RetryDescription__cell">
                        <img
                            class="RetryDescription__image"
                            :src="documentSuccessSvgUrl"
                            width="141"
                            height="92"
                        >
                    </td>
                    <td class="RetryDescription__cell">
                        Use a dark background, with all edges and corners of the document visible.
                    </td>
                </tr>
                <tr class="RetryDescription__row">
                    <td class="RetryDescription__cell">
                        <img
                            class="RetryDescription__image"
                            :src="documentFailureLightingSvgUrl"
                            width="141"
                            height="93"
                        >
                    </td>
                    <td class="RetryDescription__cell">
                        Photo should be well-lit, but with no glare. Avoid using camera flash.
                    </td>
                </tr>
                <tr class="RetryDescription__row">
                    <td class="RetryDescription__cell">
                        <img
                            class="RetryDescription__image"
                            :src="documentFailureObfuscationSvgUrl"
                            width="141"
                            height="93"
                        >
                    </td>
                    <td class="RetryDescription__cell">
                        Do not cut off or cover up any part of the document.
                    </td>
                </tr>
                <tr class="RetryDescription__row">
                    <td class="RetryDescription__cell">
                        <img
                            class="RetryDescription__image"
                            :src="documentFailureTypeSvgUrl"
                            width="165"
                            height="108"
                        >
                    </td>
                    <td class="RetryDescription__cell">
                        Please be sure you upload the correct document type only.
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
    import { Vue, Component, Prop } from 'vue-property-decorator';

    import documentSuccessSvgUrl from '@evidentid/ido-lib/images/DocumentSuccess.svg';
    import documentFailureLightingSvgUrl from '@evidentid/ido-lib/images/DocumentFailureLighting.svg';
    import documentFailureObfuscationSvgUrl from '@evidentid/ido-lib/images/DocumentFailureObfuscation.svg';
    import documentFailureTypeSvgUrl from '@evidentid/ido-lib/images/DocumentFailureType.svg';

    @Component
    export default class RetryDescription extends Vue {
        private documentSuccessSvgUrl = documentSuccessSvgUrl;
        private documentFailureLightingSvgUrl = documentFailureLightingSvgUrl;
        private documentFailureObfuscationSvgUrl = documentFailureObfuscationSvgUrl;
        private documentFailureTypeSvgUrl = documentFailureTypeSvgUrl;

        @Prop({ type: Boolean, default: false })
        private heading!: boolean;
    }
</script>
