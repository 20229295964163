import { AnyLoggerEventConsumersMap, EventHandlersMapByLoggerEventConsumersMap } from './LoggerEventConsumer';
import LoggerUserData from './LoggerUserData';
import Logger from './index';

export default function buildLoggerEventConsumers<
    // eslint-disable-next-line no-use-before-define
    T extends AnyLoggerEventConsumersMap<U>,
    U extends LoggerUserData = LoggerUserData
>(logger: Logger<U>, consumers: Partial<T>): EventHandlersMapByLoggerEventConsumersMap<T> {
    const result: any = {};
    const eventNames = Object.keys(consumers) as (keyof T)[];

    for (const name of eventNames) {
        result[name] = (...args: Parameters<T[typeof name]>) => {
            const breadcrumb = consumers[name]!(logger, ...args);
            if (breadcrumb) {
                logger.addBreadcrumb(breadcrumb);
            }
        };
    }

    return result;
}
