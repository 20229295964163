import uniq from 'lodash/uniq';
import { doesAttrTypeRepresentIdScan } from '@evidentid/evident-attributes/attrTypes';

export function isVideoSelfieAttributeType(attrType) {
    return /\.biometric_verification\.video_selfie\./.test(attrType);
}

export function isSelfieImageAttributeType(attrType) {
    return Boolean(attrType) && /\.selfie_image$/.test(attrType);
}

function getVideoSelfieAttributeGroups(attrTypes) {
    return uniq(attrTypes.map((type) => type.replace(/\.(?:selfie_image|facemap)$/, '.')))
        .map((namespace) => attrTypes.filter((type) => type.startsWith(namespace)))
        .map(([ item1, item2 = null ]) => (isSelfieImageAttributeType(item1) ? [ item1, item2 ] : [ item2, item1 ]));
}

export const curateVideoSelfie = function(aggregatedRequest) {
    const inputAttributes = aggregatedRequest.input;

    // Check whether is there any ID scan required before Video Selfie
    const idScanAttrs = Object.keys(inputAttributes).filter(doesAttrTypeRepresentIdScan);
    const isIdScanFinished = idScanAttrs.every((attrType) => inputAttributes[attrType].complete);

    // When there are multiple video selfie required, match them together
    const videoSelfieAttrs = Object.keys(inputAttributes).filter(isVideoSelfieAttributeType);
    const videoSelfieGroups = getVideoSelfieAttributeGroups(videoSelfieAttrs);

    // There should always be a consent step for video selfies
    const consentAttr = inputAttributes['consent.biometric.authorization'] || { complete: true };

    // Ensure that biometric consent is not hidden, and next attribute types are prepared
    consentAttr.metadata = {
        ...consentAttr.metadata,
        hidden: false,
    };

    // Build list of preceding attributes
    const precedingAttributes = isIdScanFinished
        ? [ consentAttr ]
        : idScanAttrs.map((attrType) => inputAttributes[attrType]);

    // Ensure that all preceding attributes has next attribute types list prepared
    for (const precedingAttribute of precedingAttributes) {
        precedingAttribute.metadata = {
            ...precedingAttribute.metadata,
            nextAttrTypes: precedingAttribute.metadata?.nextAttrTypes || [],
        };
    }

    // Adjust video selfie attributes metadata
    for (const [ imageAttrType, facemapAttrType ] of videoSelfieGroups) {
        const imageAttr = inputAttributes[imageAttrType] || { complete: true };
        const facemapAttr = inputAttributes[facemapAttrType] || {};
        const hasFacemap = Boolean(inputAttributes[facemapAttrType]);

        // Find the next visible attribute type
        const nextAttrType = hasFacemap ? facemapAttrType : imageAttrType;

        // Try to show it after ID scan or a consent
        for (const precedingAttribute of precedingAttributes) {
            precedingAttribute.metadata.nextAttrTypes.push(nextAttrType);
        }

        // Log information about broken data
        if (!hasFacemap) {
            console.error('Video selfie image attribute requested without face-map');
            // eslint-disable-next-line no-continue
            continue;
        }

        // Remove selfie image, as it doesn't make sense without the corresponding face-map
        delete inputAttributes[imageAttrType];

        // Do not mark face-map as complete, when selfie is missing
        facemapAttr.complete = Boolean(facemapAttr.complete && imageAttr.complete);

        // Hide the video selfie until consent is given and any available id scan is complete
        facemapAttr.metadata = {
            ...facemapAttr.metadata,
            hidden: !isIdScanFinished || !consentAttr.complete,
            selfieAttrType: imageAttrType || null,
        };
    }

    return aggregatedRequest;
};
