import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import PhoneNumberComponent from '@/components/fields/PhoneNumber.vue';
import PhoneNumberValue from './interfaces/PhoneNumber';

export default class PhoneNumberField extends Field<PhoneNumberValue> {
    public override component = PhoneNumberComponent;

    public isValid(question: Question, value: PhoneNumberValue): boolean {
        return Boolean(value && value.qualifiedNumber && value.valid);
    }

    public async encode(question: Question, value: PhoneNumberValue): Promise<AttributesValues> {
        const phone = value && value.qualifiedNumber;
        return this._encode(question, phone ? `+${phone.replace(/\D/g, '')}` : '');
    }
}
