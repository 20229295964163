<template>
    <div class="PhotoCapture">
        <video ref="video" />
        <div class="PhotoCapture__overlay">
            <div v-if="!isNaN(overlayMin) && !isNaN(overlayMax)" class="PhotoCapture__boundaries">
                <img
                    class="PhotoCapture__aspectRatio PhotoCapture__aspectRatio--landscape"
                    crossorigin="anonymous"
                    :src="landscapeImage"
                >
                <img
                    class="PhotoCapture__aspectRatio PhotoCapture__aspectRatio--portrait"
                    crossorigin="anonymous"
                    :src="portraitImage"
                >
                <span class="PhotoCapture__corners-1" />
                <span class="PhotoCapture__corners-2" />
                <div v-if="!hasCameraPrivileges" class="PhotoCapture__notice">
                    Waiting for access to camera...
                </div>
            </div>
            <div v-else-if="!hasCameraPrivileges" class="PhotoCapture__boundaries">
                <div class="PhotoCapture__notice">
                    Waiting for access to camera...
                </div>
            </div>
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="PhotoCapture__label" v-html="label" />
        <div class="PhotoCapture__controls">
            <div class="PhotoCapture__cancel" touch-action="auto" @click="onCancel">
                Cancel
            </div>
            <div class="PhotoCapture__button" touch-action="auto" @click="onConfirm" />
        </div>
    </div>
</template>

<script lang="ts">
    import { Prop, Watch, Component, Vue } from 'vue-property-decorator';
    import { createAspectRatioSvgDataUrl } from '@evidentid/universal-framework/documents';
    import { buildFileFromBlob } from '@evidentid/file-utils/blobs';

    type ImageProcessor = (file: File) => Promise<File>;

    const photoConstraints: MediaStreamConstraints = {
        audio: false,
        video: {
            width: { ideal: 4096, min: 1280 },
            height: { ideal: 2160, min: 720 },
            facingMode: 'environment',
        },
    };

    @Component
    export default class PhotoCapture extends Vue {
        @Prop({ type: String })
        private label!: string | undefined;

        @Prop({ default: null })
        private overlay?: any;

        @Prop({ type: String, default: 'environment' })
        private facingMode!: string;

        @Prop({ default: () => navigator.mediaDevices })
        private mediaDevicesApi!: MediaDevices | undefined;

        // For now it's available only in Chrome browser
        @Prop({ type: Function, default: window.ImageCapture })
        private imageCaptureApi!: (typeof ImageCapture) | undefined;

        @Prop({ type: Function, default: (file: File) => Promise.resolve(file) })
        private process!: ImageProcessor;

        private landscapeImage: string | null = null;
        private portraitImage: string | null = null;
        private hasCameraPrivileges = false;
        private isMounted = false;
        private stream: MediaStream | null = null;

        private get overlayMin() {
            return Math.min(this.overlay?.width, this.overlay?.height);
        }

        private get overlayMax() {
            return Math.max(this.overlay?.width, this.overlay?.height);
        }

        private get photoConstraints(): MediaStreamConstraints {
            return {
                ...photoConstraints,
                video: { ...(photoConstraints.video as object), facingMode: this.facingMode },
            };
        }

        private mounted() {
            this.isMounted = true;
            this.initializeStream();
        }

        private async initializeStream() {
            if (!this.mediaDevicesApi || !this.mediaDevicesApi.getUserMedia) {
                this.$emit('unavailable');
                return;
            }

            try {
                this.stream = await this.mediaDevicesApi.getUserMedia(this.photoConstraints);

                if (!this.isMounted) {
                    this.stopVideo();
                    return;
                }

                this.hasCameraPrivileges = true;
                const video = this.$refs.video as HTMLVideoElement;
                video.srcObject = this.stream;
                await video.play();
            } catch (error) {
                this.$emit('unavailable', error);
            }
        }

        private destroyed() {
            this.isMounted = false;
            this.hasCameraPrivileges = false;
            this.stopVideo();
        }

        private stopVideo() {
            if (this.stream) {
                this.stream.getTracks().forEach((track) => track.stop());
            }
        }

        private async onConfirm() {
            if (!this.stream) {
                return;
            }

            const ImageCapture = this.imageCaptureApi;
            try {
                if (ImageCapture) {
                    const imageCapture = new ImageCapture(this.stream.getVideoTracks()[0]);
                    const file = buildFileFromBlob(await imageCapture.takePhoto(), 'captured-photo.jpg');
                    this.$emit('input', file);
                    return;
                }
            } catch (error) {
                console.log(error, 'PhotoCapture/onConfirm/ImageCapture');
            }

            try {
                const video = (this.$refs.video as HTMLVideoElement);
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                (canvas.getContext('2d') as CanvasRenderingContext2D).drawImage(video, 0, 0);
                canvas.toBlob((image) => this.emitFile(image as Blob), 'image/jpeg', 1.0);
            } catch (error) {
                console.log(error, 'PhotoCapture/onConfirm/video');
            }
        }

        private async emitFile(blob: Blob) {
            const file = buildFileFromBlob(blob, 'captured-photo.jpg');

            this.$emit('processing');

            try {
                this.$emit('input', await this.process(file));
            } catch (error) {
                console.log(error, 'PhotoCapture/emitFile catch');
                const passedError = error?.name === 'ImageProcessingError' ? error : null;
                this.$emit('invalid', file.name, passedError);
            }
        }

        private onCancel() {
            this.$emit('cancel');
        }

        @Watch('overlay', { immediate: true })
        private buildLandscapeImage() {
            this.landscapeImage = this.overlay
                ? createAspectRatioSvgDataUrl(this.overlayMax, this.overlayMin)
                : null;
        }

        @Watch('overlay', { immediate: true })
        private buildPortraitImage() {
            this.portraitImage = this.overlay
                ? createAspectRatioSvgDataUrl(this.overlayMin, this.overlayMax)
                : null;
        }
    }
</script>
