<template>
    <div>
        <div class="IdScanPreviewImage">
            <BlobImage :src="file" />
        </div>
        <div v-if="notice" class="IdScanPreviewNotice">
            <Icon name="exclamation-circle" /> {{ notice }}
        </div>
    </div>
</template>

<script lang="ts">
    import { Vue, Prop, Component } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';
    import Spinner from '@/components/common/Spinner.vue';
    import BlobImage from '@/components/common/BlobImage.vue';

    @Component({
        components: { BlobImage, Spinner, Icon },
    })
    export default class IdScanPhotoPreview extends Vue {
        @Prop({ type: File, default: () => null })
        private file!: File;

        @Prop({ type: String, default: '' })
        private notice!: string;
    }
</script>
