import { ACUANT_CARD_TYPES } from '@evidentid/acuant-sdk';
import IdScanImageCaptureFrontSvg from '@evidentid/ido-lib/images/IdScanImageCaptureFront.svg';
import IdScanImageCaptureBackSvg from '@evidentid/ido-lib/images/IdScanImageCaptureBack.svg';
import { DocumentType } from '@evidentid/ido-lib/interfaces/Question';

export interface DocumentDetailsPageOverlay {
    text: string;
    width: number;
    height: number;
    overrides?: Record<string, Partial<DocumentDetailsPageOverlay>>;
}

export interface DocumentDetailsPage {
    id: string;
    name: string;
    heading: string;
    uploadText: string;
    visualAidImageUrl: string;
    instructions: string;
    previewNotice: string;
    overlay: DocumentDetailsPageOverlay | null;
}

export interface DocumentDetails<T extends DocumentType = DocumentType> {
    id: T;
    acuantType: ACUANT_CARD_TYPES;
    name: string;
    shortName: string;
    dropdownName: string;
    unavailableDocumentText: string;
    useAsAlternativeDocumentText: string;
    delegationText: string;
    universalInstructionsText: string;
    pages: DocumentDetailsPage[];
}

type DocumentDetailsMap = {
    [K in DocumentType]: DocumentDetails<K>;
};

// Document sizes, based on ISO/IEC 7810 standard
// @see https://en.wikipedia.org/wiki/ISO/IEC_7810
const ID_1 = { width: 85.60, height: 53.98 };
const ID_2 = { width: 105, height: 74 };
const ID_3 = { width: 125, height: 88 };

const documents: DocumentDetailsMap = {
    [DocumentType.driversLicense]: {
        id: DocumentType.driversLicense,
        acuantType: ACUANT_CARD_TYPES.DRIVERS_LICENSE_DUPLEX,
        name: 'driver\'s license',
        shortName: 'driver\'s license',
        dropdownName: 'Driver\'s license',
        unavailableDocumentText: 'No driver\'s license?',
        useAsAlternativeDocumentText: 'Use a driver\'s license instead.',
        delegationText: 'Use your mobile phone to capture photos of the front and back of your driver\'s license',
        universalInstructionsText: 'Upload photos of the front and back of your driver\'s license.',
        pages: [
            {
                id: 'image.front',
                name: 'Front side of driver\'s license',
                heading: 'Front of driver license',
                uploadText: 'Upload front side of driver license',
                visualAidImageUrl: IdScanImageCaptureFrontSvg,
                instructions: `Take a photo of the front side of your driver license on a dark surface.
                               The details on your card should be clearly visible in the photo.`,
                previewNotice: 'Please ensure all text is readable in the image',
                overlay: {
                    text: '<strong>FRONT</strong> side of driver\'s license',
                    ...ID_1,
                },
            },
            {
                id: 'image.back',
                name: 'Back side of driver\'s license',
                heading: 'Back of driver license',
                uploadText: 'Upload back side of driver license',
                visualAidImageUrl: IdScanImageCaptureBackSvg,
                instructions: `Take a photo of the back side of your driver license on a dark surface.
                               The details on your card should be clearly visible in the photo.`,
                previewNotice: 'Please ensure all text is readable in the image',
                overlay: {
                    text: '<strong>BACK</strong> side of driver\'s license',
                    ...ID_1,
                },
            },
        ],
    },
    [DocumentType.idCard]: {
        id: DocumentType.idCard,
        acuantType: ACUANT_CARD_TYPES.DRIVERS_LICENSE_DUPLEX,
        name: 'ID card',
        shortName: 'card',
        dropdownName: 'ID card',
        unavailableDocumentText: 'No card?',
        useAsAlternativeDocumentText: 'Use an ID card instead.',
        delegationText: 'Use your mobile phone to capture photos of the front and back of your ID card',
        universalInstructionsText: 'Upload photos of the front and back of your identity card.',
        pages: [
            {
                id: 'image.front',
                name: 'Front side of ID card',
                heading: 'Front of ID card',
                uploadText: 'Upload front side of ID card',
                visualAidImageUrl: IdScanImageCaptureFrontSvg,
                instructions: `Take a photo of the front side of your ID card on a dark surface.
                               The details on your card should be clearly visible in the photo.`,
                previewNotice: 'Please ensure all text is readable in the image',
                overlay: {
                    text: '<strong>FRONT</strong> side of identity document',
                    ...ID_1,
                    overrides: {
                        ro: ID_2,
                        fr: ID_2,
                    },
                },
            },
            {
                id: 'image.back',
                name: 'Back side of ID card',
                heading: 'Back of ID card',
                uploadText: 'Upload back side of ID card',
                visualAidImageUrl: IdScanImageCaptureBackSvg,
                instructions: `Take a photo of the back side of your ID card on a dark surface.
                               The details on your card should be clearly visible in the photo.`,
                previewNotice: 'Please ensure all text is readable in the image',
                overlay: {
                    text: '<strong>BACK</strong> side of identity document',
                    ...ID_1,
                    overrides: {
                        ro: ID_2,
                        fr: ID_2,
                    },
                },
            },
        ],
    },
    [DocumentType.passport]: {
        id: DocumentType.passport,
        acuantType: ACUANT_CARD_TYPES.PASSPORT,
        name: 'passport',
        shortName: 'passport',
        dropdownName: 'Passport',
        unavailableDocumentText: 'No passport?',
        useAsAlternativeDocumentText: 'Use a passport instead.',
        delegationText: 'Use your mobile phone to capture photos of the identification page of your passport',
        universalInstructionsText: 'Upload a photo of the identity page of your passport.',
        pages: [
            {
                id: 'image',
                name: 'ID page of passport',
                heading: 'Upload the identification page of your passport',
                uploadText: 'Upload ID page of passport',
                visualAidImageUrl: IdScanImageCaptureFrontSvg,
                instructions: `Please take a photo of the identification page
                               of your passport against a dark surface`,
                previewNotice: 'Please ensure all text is readable in the image',
                overlay: {
                    text: 'Identification page of your passport',
                    ...ID_3,
                },
            },
        ],
    },
};

// Documents which may be used, when we are not able to determine a required type
export const universalDocuments: DocumentType[] = [
    DocumentType.idCard,
    DocumentType.passport,
];

export default documents;
