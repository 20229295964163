import { DateTime } from 'luxon';
import Question from '@evidentid/ido-lib/interfaces/Question';
import Birthday from '@/components/fields/Birthday.vue';
import DateField from '@/fields/DateField';

export default class BirthdayField extends DateField {
    public override component = Birthday;

    public override isValid(_: Question, value: string): boolean {
        return super.isValid(_, value) && DateTime.fromFormat(value, this.format) < DateTime.now();
    }
}
