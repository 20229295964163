// Let the record show that I am appropriately ashamed of all that
// you are about to witness.

import keyExclude from 'lodash/omit';

const SELFIE_WITH_PAYCARD_TYPE = 'identity_assurance.proofing.selfie_with_payment_card';

// Transforms ID Scanning input space such that only the ID scan
// is requested to represent all attributes it implicitly populates.
export function curateSelfieWithPayCard(aggregatedRequest) {
    const inputAttributes = aggregatedRequest.input;

    if (SELFIE_WITH_PAYCARD_TYPE in inputAttributes) {
        const filtered = keyExclude(inputAttributes, [ SELFIE_WITH_PAYCARD_TYPE ]);

        const original = inputAttributes[SELFIE_WITH_PAYCARD_TYPE];

        aggregatedRequest.input = Object.assign(filtered, {
            [SELFIE_WITH_PAYCARD_TYPE]: {
                attrType: SELFIE_WITH_PAYCARD_TYPE,
                complete: original.complete,
                hasError: original.hasError,
                shareWith: original.shareWith,
                metadata: Object.assign({}, original.metadata, {
                    copySource: 'paymentCardSelfieContent',
                    icon: 'camera',
                }),
            },
        });
    }

    return aggregatedRequest;
}
