<template>
    <div class="StringView">
        <label class="LabeledControl" :for="id">
            <span class="LabeledControl__label">{{ form.schema.title }}</span>

            <input
                :id="id"
                type="number"
                class="StringView__input"
                :value="viewValue"
                :min="minimum"
                :max="maximum"
                :step="multipleOf"
                :placeholder="form.schema.description"
                @input="onChange"
            >
        </label>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaInteger, JsonSchemaNumber, JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    type JsonFormNumeric = JsonFormBase<JsonSchemaNumber | JsonSchemaInteger>;

    @Component({
        inheritAttrs: false,
    })
    export default class JsonSchemaNumberForm extends AbstractJsonSchemaForm<JsonFormNumeric, string | number> {
        private get minimum() {
            const { minimum, exclusiveMinimum, type } = this.form.schema;

            if (minimum !== undefined) {
                return minimum;
            }

            return exclusiveMinimum === undefined
                ? undefined
                : type === JsonSchemaType.integer ? exclusiveMinimum + 1 : exclusiveMinimum;
        }

        private get maximum() {
            const { maximum, exclusiveMaximum, type } = this.form.schema;

            if (maximum !== undefined) {
                return maximum;
            }

            return exclusiveMaximum === undefined
                ? undefined
                : type === JsonSchemaType.integer ? exclusiveMaximum - 1 : exclusiveMaximum;
        }

        private get multipleOf() {
            if (this.form.schema.multipleOf) {
                return this.form.schema.multipleOf;
            }

            return this.form.schema.type === JsonSchemaType.integer ? 1 : undefined;
        }

        private get viewValue() {
            return this.value == null ? '' : typeof this.value === 'number' ? `${this.value}` : this.value;
        }

        private onChange(event: InputEvent) {
            const inputValue = (event.target as HTMLInputElement).value;
            this.$emit('input', this.form.getValue(inputValue));
        }
    }
</script>
