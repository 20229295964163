import Question from '@evidentid/ido-lib/interfaces/Question';
import { isValidTin, sanitizeTin, TinOptions } from '@evidentid/tin-tools';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Ssn from '@/components/fields/Ssn.vue';
import Field from './Field';

const acceptedTinTypes: TinOptions = {
    ssn: true,
    itin: true,
};

export default class SsnField extends Field<string> {
    public override component = Ssn;

    public isValid(question: Question, value: string): boolean {
        return isValidTin(value, acceptedTinTypes);
    }

    public async encode(question: Question, value: string): Promise<AttributesValues> {
        return this._encode(question, sanitizeTin(value, acceptedTinTypes));
    }
}
