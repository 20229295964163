<template>
    <div class="MobileSelfieForm">
        <h2>{{ contents.heading }}</h2>
        <p>{{ contents.reason }}</p>

        <PhotoPreview v-if="inputValue" :file="inputValue" @delete="onRemove" />
        <PhotoInput v-else
            id="selfie-dropbox"
            v-model="inputValue"
            :process="processImage"
            :processing="processing"
            label="Take a selfie"
            capture="user"
            @input="onChange"
            @invalid="onCorruptedFile"
            @processing="onProcessing"
        />

        <QuestionFormControls v-bind="$props" v-on="$listeners" />
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import contents from '@/config/selfieContents';
    import QuestionFormControls from '@/components/forms/QuestionFormControls.vue';
    import PhotoInput from '@/components/forms/PhotoInput.vue';
    import PhotoPreview from '@/components/forms/PhotoPreview.vue';
    import { createImageProcessor } from '@evidentid/browser-framework/canvases';

    @Component({
        inheritAttrs: false,
        components: { PhotoPreview, PhotoInput, QuestionFormControls },
    })
    export default class SelfieForm extends FieldComponent<File> {
        private inputValue!: File;
        private processImage = createImageProcessor({
            preferredResolution: 4000 * 3000, // 12 Mpx
        });
        private processing = false;

        private get copySource(): string {
            return this.question.metadata.copySource || 'vanillaSelfieContent';
        }

        private get contents() {
            return contents[this.copySource] || contents.vanillaSelfieContent;
        }

        private onProcessing() {
            this.processing = true;
        }

        @Watch('value', { immediate: true })
        private onValueChange(value: File) {
            this.inputValue = value || null;
        }

        private onRemove() {
            this.$emit('change', null);
        }

        private onChange() {
            this.processing = false;
            this.$emit('change', this.inputValue);
        }

        private onCorruptedFile(file: File) {
            this.processing = false;
            this.$store.dispatch('displaySnackbar', {
                message: `Selected file is empty, corrupted or unsupported: ${file.name}.`,
            });
        }
    }
</script>
