import { VueRouter } from 'vue-router/types/router';

export default function getQueryValue(
    router: VueRouter,
    location: { hash: string },
    ...names: string[]
): (string | undefined)[] {
    const route = router.resolve(location.hash.substr(1)).location;
    const { params = {}, query = {} } = route;

    return names.map((name) => (name in params ? params[name] : query[name]) as string | undefined);
}
