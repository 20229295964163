import zipObject from 'lodash/zipObject';
import US_SUBDIVISIONS from '@evidentid/common-datasets/states.json';

const states = (US_SUBDIVISIONS as string[][])
    .sort(([ a ], [ b ]) => a.localeCompare(b));

export const stateIds = states.map((state) => state[1]);
export const statesMap = zipObject(stateIds, states.map((state) => state[0])) as unknown as { [key: string]: string };

export default states;
