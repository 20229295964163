import every from 'lodash/every';
import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question, { DocumentType } from '@evidentid/ido-lib/interfaces/Question';
import IdScanComponent from '@/components/fields/IdScan.vue';
import documents, { universalDocuments } from '@/config/documents';
import { buildBinaryDataObjectFromFile, isFileValid } from '@evidentid/file-utils/prepareFile';
import { mimeTypes } from '@evidentid/file-utils/mimeTypes';

export default class IdScanField extends Field<any> {
    public override form = IdScanComponent;

    public getPossibleDocumentTypes(question: Question): DocumentType[] {
        const documentPrescribed = question && question.metadata.documentPrescribed;
        return documentPrescribed ? [ documentPrescribed ] : universalDocuments;
    }

    public override getDefaultValue(question: Question): any {
        return {
            type: this.getPossibleDocumentTypes(question)[0],
            useSinglePageUpload: false,
            country: (question && question.metadata.countryPrescribed) || null,
            currentPage: 0,
            pages: [],
        };
    }

    public isValid(question: Question, value: any): boolean {
        if (!value || !value.pages || !this.getPossibleDocumentTypes(question).includes(value.type)) {
            return false;
        }

        const details = documents[value.type as DocumentType];
        return details.pages.length === value.pages.length &&
            every(value.pages, (page) => isFileValid(page, mimeTypes.image));
    }

    public async encode(question: Question, value: any): Promise<AttributesValues> {
        const { country, type, pages } = value || {};
        const { docTypeAttr, countryPrescribed, regionPrescribed, isLegacy } = question?.metadata || {};
        const document = documents[type as DocumentType] || { pages: [] };
        const encodedData: AttributesValues = {};
        const readyPages = await Promise.all((pages || [])
            .map((page: File) => buildBinaryDataObjectFromFile(page, { cropped: false })));

        if (readyPages.length < document.pages.length) {
            throw new Error('You need to pass all required document pages for encoding');
        }

        if (isLegacy) {
            const frontAttrType = 'identity_assurance.document_verification.us_issued.front_image';
            const backAttrType = 'identity_assurance.document_verification.us_issued.back_image';

            return {
                [frontAttrType]: {
                    shareWith: question.shareWith,
                    value: readyPages[0],
                },
                [backAttrType]: {
                    shareWith: question.shareWith,
                    value: readyPages[1],
                },
            };
        }

        if (docTypeAttr) {
            encodedData[docTypeAttr] = {
                shareWith: question.shareWith,
                value: {
                    country,
                    id_type: type,
                },
            };
        }

        const tokens = [
            'identity_assurance.document_verification',
            regionPrescribed,
            countryPrescribed && countryPrescribed.toLowerCase(),
            type,
        ].filter(Boolean).join('.');

        for (let i = 0; i < document.pages.length; i++) {
            encodedData[`${tokens}.${document.pages[i].id}`] = {
                shareWith: question.shareWith,
                value: readyPages[i],
            };
        }

        return encodedData;
    }
}
