/* eslint-disable no-use-before-define */

export enum JsonSchemaType {
    array = 'array',
    object = 'object',
    string = 'string',
    boolean = 'boolean',
    number = 'number',
    integer = 'integer',
}

export interface JsonSchemaMetadata<T = any> {
    $id?: string;
    $schema?: string;
    title?: string;
    description?: string | null;
    strings?: any; // Used for customization in built forms
    examples?: T[];

    // Descriptive additions
    hint?: string | null;
    warning?: string | null;

    // Control for kind-of-constant fields
    readOnly?: boolean;
}

export interface JsonSchemaEnum<T = any> extends JsonSchemaMetadata<T> {
    enum: T[];
    enumLabels?: string[] | null;
}

export interface JsonSchemaConst<T = any> extends JsonSchemaMetadata<T> {
    const: T;
}

export interface JsonSchemaBase<T = any> extends JsonSchemaMetadata<T> {
    type: JsonSchemaType;
}

export interface JsonSchemaString extends JsonSchemaBase<string> {
    type: JsonSchemaType.string;
    format?: string;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    multiline?: boolean;
    variables?: string[];
    htmlExtensions?: {
        links?: boolean;
    };
}

export interface JsonSchemaBoolean extends JsonSchemaBase<boolean> {
    type: JsonSchemaType.boolean;
}

interface JsonSchemaNumeric extends JsonSchemaBase<number> {
    multipleOf?: number;
    maximum?: number;
    exclusiveMaximum?: number;
    minimum?: number;
    exclusiveMinimum?: number;
}

export interface JsonSchemaNumber extends JsonSchemaNumeric {
    type: JsonSchemaType.number;
}

export interface JsonSchemaInteger extends JsonSchemaNumeric {
    type: JsonSchemaType.integer;
}

export interface JsonSchemaArray<T extends JsonSchemaMetadata<any> = JsonSchemaMetadata<any>>
    extends JsonSchemaBase<NonNullable<T['examples']>> {
    type: JsonSchemaType.array;
    addItemTitle?: string;
    items: T;
    minItems?: number;
    maxItems?: number;
}

export interface JsonSchemaAbstractObject extends JsonSchemaBase<object> {
    type: JsonSchemaType.object;
    properties: { [key: string]: JsonSchema };
    propertiesOrder?: string[];
    required?: string[];
}

export interface JsonSchemaBasicObject extends JsonSchemaAbstractObject {
    if?: undefined;
    then?: undefined;
    else?: undefined;
}

export interface JsonSchemaComplexObject extends JsonSchemaAbstractObject {
    if: Partial<JsonSchemaObject>;
    then: Partial<JsonSchemaObject>;
    else: Partial<JsonSchemaObject>;
}

export type JsonSchemaObject = JsonSchemaBasicObject | JsonSchemaComplexObject;

type JsonSchema =
    JsonSchemaEnum | JsonSchemaConst | JsonSchemaString | JsonSchemaInteger |
    JsonSchemaBoolean | JsonSchemaNumber | JsonSchemaArray | JsonSchemaObject;

export type RegularJsonSchema = Exclude<JsonSchema, JsonSchemaEnum | JsonSchemaObject | JsonSchemaConst>;

export default JsonSchema;
