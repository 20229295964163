<template>
    <img :key="objectUrl" crossorigin="anonymous" :src="objectUrl">
</template>

<script lang="ts">
    import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';
    import Spinner from '@/components/common/Spinner.vue';

    @Component({
        components: { Spinner, Icon },
    })
    export default class BlobImage extends Vue {
        @Prop({ type: File, default: () => null })
        private src!: File;

        private objectUrl: string | null = null;

        private destroyed() {
            this.revokeObjectUrl();
        }

        @Watch('src', { immediate: true })
        private onFileChange() {
            this.revokeObjectUrl();
            if (this.src) {
                this.objectUrl = URL.createObjectURL(this.src);
            }
        }

        private revokeObjectUrl() {
            if (this.objectUrl) {
                URL.revokeObjectURL(this.objectUrl);
                this.objectUrl = null;
            }
        }
    }
</script>
