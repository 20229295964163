import { VerificationFailureReason } from '@/store/interfaces/IdoState';

const availableFailureReasons: string[] = [
    VerificationFailureReason.glare,
    VerificationFailureReason.sharpness,
    VerificationFailureReason.provider,
    VerificationFailureReason.glareAndSharpness,
];

export default function getVerificationFailureReason(reasons: string[] | undefined): VerificationFailureReason {
    // When 'reasons' have invalid format, return unknown reason
    if (!Array.isArray(reasons) || reasons.length === 0) {
        return VerificationFailureReason.unknown;
    }

    // Otherwise, check if a reason has a known type or return unknown one.
    const combinedReason = reasons.sort().join('_');
    return availableFailureReasons.includes(combinedReason)
        ? combinedReason as VerificationFailureReason
        : VerificationFailureReason.unknown;
}
