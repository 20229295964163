import { Route } from 'vue-router/types';
import Logger from '@evidentid/logger';
import Tracker from '@evidentid/tracker';
import { Application as GenericApplication, ApplicationOptions } from '@evidentid/vue-commons/core/Application';
import handleLegacyMithrilLocation from '@evidentid/vue-commons/compatibility/handleLegacyMithrilLocation';
import buildLoggerEventConsumers from '@evidentid/logger/buildLoggerEventConsumers';
import buildTrackerEventConsumers from '@evidentid/tracker/buildTrackerEventConsumers';
import getQueryValue from '@evidentid/vue-commons/router/getQueryValue';
import slugify from '@evidentid/universal-framework/slugify';
import getSessionData from '@/utils/getSessionData';
import createVueRouter from '@/router';
import createVuexStore from '@/store';
import trackIdoEvents from '@/tracking/trackIdoEvents';
import loggerEventConsumers from '@/tracking/loggerEventConsumers';
import trackerEventConsumers from '@/tracking/trackerEventConsumers';
import IdoLoggerUserData from '@/tracking/IdoLoggerUserData';
import IdoTrackerUserData from '@/tracking/IdoTrackerUserData';
import ErrorObject from '@/store/interfaces/ErrorObject';
import AppView from '@/components/App.vue';
import support from '@/config/support';
import { createModalPlugin } from '@/components/common/Modal';

type IdoAppStore = ReturnType<typeof createVuexStore>;
type IdoAppRouter = ReturnType<typeof createVueRouter>;
type IdoAppLogger = Logger<IdoLoggerUserData>;
type IdoAppTracker = Tracker<IdoTrackerUserData>;

class IdoFacingApplication extends GenericApplication<IdoAppLogger, IdoAppTracker, IdoAppStore, IdoAppRouter> {
    protected Component = AppView;

    public constructor(
        options: Omit<
            ApplicationOptions<IdoAppLogger, IdoAppTracker, IdoAppStore, IdoAppRouter>,
            'createStore' | 'createRouter'
        >
    ) {
        super({
            ...options,
            createRouter: createVueRouter,
            createStore: createVuexStore,
        });
    }

    protected override beforeInitialize(): void {
        // Redirect legacy paths to new syntax
        handleLegacyMithrilLocation(this.options.location);
    }

    protected override afterInitialize(): void {
        // Consume events in logger
        trackIdoEvents(this.observer, buildLoggerEventConsumers(this.logger, loggerEventConsumers));

        // Consume events in tracker
        trackIdoEvents(this.observer, buildTrackerEventConsumers(this.tracker, trackerEventConsumers));

        this.options.Vue.use(createModalPlugin());
    }

    protected override onVueError(error: Error): void {
        if (this.store) {
            this.store.dispatch('showError', error as unknown as ErrorObject);
        }
    }

    protected override onRouteChange(route: Route): void {
        // Clean up full-screen errors after routing change.
        // It should be dispatched anyway, but it doesn't look clean in Vuex log.
        if (route.name !== 'error' && this.store && this.store.state.error) {
            this.store.dispatch('showError', null);
        }
    }

    protected override beforeMount(): void {
        if (!this.router || !this.store) {
            throw new Error('Router and store are required to resolve application.');
        }

        const keys = [ 'q', 'rprId', 'canary', 'continue_url', 'enable_return', 'case_id', 'auth_code' ];
        const [ question, rprId, canary, continueUrl, enableReturn, caseId, authCode ]
            = getQueryValue(this.router, this.options.location, ...keys);

        // Redirect to question, when it was passed in query parameters
        if (question) {
            this.router.push({ name: 'question', params: { slug: slugify(question) } });
        }

        // Inform logger about support
        const storage = support.sessionStorage ? window.sessionStorage : null;
        this.logger?.setTag('session-storage', storage ? 'yes' : 'no');

        // Inform user about possible problems with session storage
        if (!storage) {
            this.store.actions.displaySnackbar({
                permanent: true,
                message: 'Since you’re using your browser’s incognito mode, please be aware that refreshing the page will result in losing your progress and will require starting over.',
            });
        }

        // Initialize current session
        const parsedEnableReturn = enableReturn === 'true';
        const parsedCaseId = caseId ? parseInt(caseId, 10) : null;
        const sessionData = getSessionData(
            storage, rprId, canary, continueUrl, parsedEnableReturn, parsedCaseId, authCode
        );
        this.store.dispatch('setSessionData', sessionData);
    }
}

export default IdoFacingApplication;
