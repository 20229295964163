interface RegexWithExample {
    regex: RegExp;
    example: string;
}

type StateToRegexWithExample = Record<string, RegexWithExample>;

const fallbackRegex = /.+/;

const massageTherapistRegexMap: StateToRegexWithExample = {
    FL: { regex: /^(MA|AP)[1-9][0-9]{3,5}$/i, example: 'MA14567' },
    CA: { regex: /^[1-9][0-9]{1,4}$/i, example: '60160' },
    TX: { regex: /^MT[0-9]{6}$/i, example: 'MT131025' },
    CO: { regex: /^MT\.[0]{2}[0-9]{5}$/i, example: 'MT.0013606' },
    NY: { regex: /^[0-9]{3,6}(-01)?$/i, example: '29639' },
    GA: { regex: /^MT[0-9]{6}$/i, example: 'MT001377' },
    AZ: { regex: /^MT-[0-9]{5}P?$/i, example: 'MT-26297' },
    WA: { regex: /^(MA)?[0-9]{8}$/i, example: 'MA60826304' },
    IL: { regex: /^(227)\.?[0-9]{6}$/i, example: '227022161' },
    NC: { regex: /^(1|2)?[0-9]{3,4}$/i, example: '18767' },
    NV: { regex: /^(NVMT\.)?[0-9]{3,5}$/i, example: 'NVMT.9159' },
    PA: { regex: /^MSG[0-9]{6}$/i, example: 'MSG009251' },
    CT: { regex: /^[0-9]{4,6}$/i, example: '10357' },
    TN: { regex: /^[0-9]{4,5}$/i, example: '10357' },
    OH: { regex: /^33\.[0-9]{5,6}$/i, example: '33.015305' },
    VA: { regex: /^(00)?190[0-9]{5}$/i, example: '19018405' },
    MI: { regex: /^75010[0-9]{5}$/i, example: '7501013751' },
    MD: { regex: /^(R|M)[0-9]{5,6}$/i, example: 'R02137 or M01239' },
    DC: { regex: /^MT[0-9]{4,7}$/i, example: 'MT1271' },
    UT: { regex: /^[0-9]{6,8}-4701$/i, example: '4131762-4701' },
    MO: { regex: /^20[0-9]{8}$/i, example: '2002081010' },
    OR: { regex: /^LMT-[0-9]{5,6}$/i, example: 'LMT-12345' },
    MA: { regex: /^[0-9]{3,5}$/i, example: '13125' },
    LA: { regex: /^(LA)?[0-9]{4}$/i, example: 'LA1234 or 1234' },
    IN: { regex: /^MT[0-9]{8}$/i, example: 'MT12345678' },
    OK: { regex: /^[0-9]{6}$/i, example: '151234' },
    NJ: { regex: /^18KT[0-9]{8}$/i, example: '18KT01234567' },
    HI: { regex: /^MAT-[0-9]{4,5}$/i, example: 'MAT-12345' },
    SC: { regex: /^[0-9]{4,5}$/i, example: '12345' },
};
const nurseLicenseRegexMap: StateToRegexWithExample = {
    IL: { regex: /^(41|43)(.)?[0-9]{6}$/i, example: '431123456' },
    TX: { regex: /^[0-9]{6}$/i, example: '123456' },
    FL: { regex: /^(PN|RN)[0-9]{6,7}$/i, example: 'PN1234567' },
    OH: { regex: /^(RN|LPN)\.[0-9]{6}(\.MEDS)?(-IV)?$/i, example: 'RN.123456, LPN.123456.MEDS-IV' },
    CA: { regex: /^[1-9][0-9]{5,7}$/i, example: '123456' },
    NM: { regex: /^(R|RN-|L|LPN-)?[0-9]{5}$/i, example: 'R12345 or RN-12345 or 12345 or L12345 or LPN-12345' },
    CT: { regex: /^(R|E|NA)?[1-9][0-9]{4,7}$/i, example: '12345' },
    PA: { regex: /^(RN|PN)[0-9]{6}(L)?$/i, example: 'RN123456 or RN123456L' },
    OK: { regex: /^(R)?[0-9]{5,8}$/i, example: '12345, R01234567' },
    NJ: { regex: /^26(NR|NP)[0-9]{8}$/, example: '26NR12345678 or 26NP12345678' },
    IN: { regex: /^(28|27)[0-9]{6}(A|C)$/i, example: '28123456A' },
    WA: { regex: /^(RN)?6[0-9]{7}$/i, example: 'RN61234567 or 61234567' },
    NY: { regex: /^[1-9][0-9]{5}$/i, example: '543211' },
    WI: { regex: /^[1-9][0-9]{4,5}(-30|-31)?$/i, example: '12345 or 12345-30' },
    KS: { regex: /^[1-9][0-9]{4,5}$/i, example: '12345 or 123456' },
    VA: { regex: /^(12|11|20|13|10)[0-9]{5,6}$/i, example: '1212345' },
    MI: { regex: /^470[0-9]{7}$/i, example: '4701234567' },
    GA: { regex: /^(RN|LPN)[0-9]{6}$/i, example: 'RN123456 or LPN123456' },
    DE: { regex: /^(L1|L2)-[0-9]{7}$/i, example: 'L1-1234567 or L2-1234567' },
    AZ: { regex: /^(RN)?[0-9]{6}$/i, example: 'RN123456' },
    CO: { regex: /(RN|PN)\.[0-9]{7}$/i, example: 'RN.1234567 or PN.1234567' },
    MO: { regex: /^(201|200|202|199)[0-9]{7}$/i, example: '2011234567' },
    OR: { regex: /^(201|200|202|085|097)[0-9]{6}(RN)$/i, example: '201123456RN' },
    NC: { regex: /^[1-9][0-9]{5}$/i, example: '12345' },
    NV: { regex: /^(RN)?[1-9][0-9]{4,5}$/i, example: '812345' },
    MT: { regex: /^NUR(-)?(RN|LPN)(-)?LIC(-)?[0-9]{5,6}$/i, example: 'NUR-RN-LIC-12345' },
    VT: { regex: /^26\.[0-9]{7}$/i, example: '26.1234567' },
    MA: { regex: /^RN(23|22|15)[0-9]{4,5}$/i, example: 'RN2312345' },
    MN: { regex: /^(24|19|18)[0-9]{5}$/i, example: '2412345' },
    LA: { regex: /^(RN)?[1-9][0-p]{5}$/i, example: '201234' },
    RI: { regex: /^RN[1-9][0-9]{4}$/i, example: 'RN61234' },
};

const physicianAssistantRegexMap: StateToRegexWithExample = {
    GA: { regex: /^[1-9][0-9]{3}$/i, example: '4123' },
    AZ: { regex: /^[1-9][0-9]{3}$/i, example: '4123' },
};

const physicalTherapistRegexMap: StateToRegexWithExample = {
    AZ: { regex: /^(LPT-)?[0-9]{5,6}$/i, example: 'LPT-001234' },
    OH: { regex: /^PT[0-9]{6}$/i, example: 'PT012345' },
};

const professionToRegexMap = (profession: string): StateToRegexWithExample | null => {
    switch (profession) {
        case '#massageTherapistlicenseIdAndState':
        case 'usMassageTherapistLicenseList':
            return massageTherapistRegexMap;
        case '#registeredNurseLicenseIdAndState':
        case '#advancedPracticeRegisteredNurseLicenseIdAndState':
        case '#licensedPracticalLicenseIdAndState':
        case '#nursePractitionerLicenseIdAndState':
        case 'usNursePractitionerLicenseList':
        case 'usAdvancedPracticeRegisteredNurseLicenseList':
        case 'usRegisteredNurseLicenseList':
        case 'usLicensedPracticalNurseLicenseList':
            return nurseLicenseRegexMap;
        case '#physicalTherapistLicenseIdAndState':
        case 'usPhysicalTherapistLicenseList':
            return physicalTherapistRegexMap;

        case '#physicianAssistantLicenseIdAndState':
        case 'usPhysicianAssistantLicenseList':
            return physicianAssistantRegexMap;
        default:
            return null;
    }
};

/**
 * Check whether the given value is a contrived data that should be always accepted - for automated tests purposes
 */
const isAllowedContrivedValue = (state: string, license: string): boolean => {
    const contrivedValuePairs = [
        { state: 'MI', professionId: '0123456789' },
        { state: 'CO', professionId: '9876543210' },
        { state: 'MO', professionId: 'NA0000001' },
    ];
    return contrivedValuePairs.some((value) => value.state === state && license === value.professionId);
};

export const getExampleForGivenStateAndProfession = (state: string, professionId: string): string => {
    const map = professionToRegexMap(professionId);
    return map?.[state]?.example ?? '';
};

export const isLicenseValidForProfessionInState = (license: string, state: string, professionId: string): boolean => {
    if (isAllowedContrivedValue(state, license)) {
        return true;
    }
    const stateRegexMap = professionToRegexMap(professionId) ?? {};
    return (stateRegexMap[state] ? stateRegexMap[state].regex.test(license) : fallbackRegex.test(license));
};
