<template>
    <div class="MobileWizardStep">
        <slot />

        <QuestionFormControls
            :valid="true"
            :submit-label="submitLabel"
            :back-label="backLabel"
            @back="$emit('back')"
            @submit="$emit('submit')"
        />
    </div>
</template>

<script lang="ts">
    import { Vue, Prop, Component } from 'vue-property-decorator';
    import QuestionFormControls from '@/components/forms/QuestionFormControls.vue';

    @Component({
        components: { QuestionFormControls },
    })
    export default class IdScanError extends Vue {
        @Prop({ type: String, default: 'Retry' })
        private submitLabel!: string | undefined;

        @Prop({ type: String })
        private backLabel!: string | undefined;
    }
</script>
