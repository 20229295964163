<template>
    <div class="BulkEmailQuestion">
        <div class="BulkEmailQuestion__form">
            <header class="QuestionHeader">
                <h3>{{ question.metadata.title }}</h3>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-if="question.metadata.instructions" v-html="question.metadata.instructions" />
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-html="question.metadata.description" />
            </header>

            <div class="BulkEmailQuestion__toolbar">
                <Button type="secondary" @click="clearList">
                    Clear Emails
                </Button>
            </div>

            <EmailList v-model="emails" @input="onChange" />

            <Checkbox
                v-if="!question.metadata.autoConfirmation"
                v-model="confirmed"
                extra-class="ConsentQuestion__acceptCheck"
                @input="onChange"
            >
                I certify that the information above is complete and accurate.
            </Checkbox>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import BulkEmailValue from '@/fields/interfaces/BulkEmail';
    import Button from '@/components/common/Button.vue';
    import ChipList from '@/components/forms/ChipList.vue';
    import Checkbox from '@/components/forms/Checkbox.vue';
    import EmailList from '@/components/forms/EmailList.vue';

    @Component({
        components: { EmailList, Checkbox, ChipList, Button },
    })
    export default class BulkEmail extends FieldComponent<BulkEmailValue> {
        private emails: string[] = [];
        private confirmed: boolean = false;

        @Watch('value', { immediate: true })
        private onValueChange(value: BulkEmailValue) {
            this.emails = value?.list || [];
            this.confirmed = Boolean(this.question.metadata?.autoConfirmation || value?.confirmed);
        }

        private onChange() {
            this.$emit('change', {
                confirmed: this.confirmed,
                list: this.emails,
            });
        }

        private clearList() {
            this.emails = [];
            this.onChange();
        }
    }
</script>
