import { IdoStore } from '@/store/interfaces/IdoStore';

type OnBeforeUnloadHandler = (event: BeforeUnloadEvent) => string | void;

export default function createEventHandlerForBlockingRefreshDuringSubmission(store: IdoStore): OnBeforeUnloadHandler {
    return (event: BeforeUnloadEvent) => {
        const submission = store.state.submission || {};
        const isSubmissionInProgress = Object.values(submission).includes(true);
        if (isSubmissionInProgress) {
            event.preventDefault();
            // This message will be ignored in modern browsers, although it's good to show it anyway
            return 'Your data are still not sent. Do you want to abort the submission?';
        }
    };
}
