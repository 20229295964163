<template>
    <div class="JsonSchemaImageUrlForm"
        :class="{
            'JsonSchemaImageUrlForm--focus': focused,
            'JsonSchemaImageUrlForm--disabled': disabled || processing,
        }"
    >
        <FormElement
            :label-for="id"
            :label="form.schema.title"
            :required="requiredElement"
        >
            <template v-slot:requiredIndicator>
                {{ translate('requiredLabel') }}
            </template>
            <template v-slot:labelHint>
                <div v-if="form.schema.warning" class="JsonSchemaForm__warning">
                    {{ form.schema.warning }}
                </div>
                <div v-else-if="form.schema.hint" class="JsonSchemaForm__hint">
                    {{ form.schema.hint }}
                </div>
            </template>
            <FormInput :invalid="accessed && hasError" force-error-slot force-action-slot>
                <div class="JsonSchemaImageUrlForm__input">
                    <label :for="id" class="JsonSchemaImageUrlForm__inputContent">
                        <div v-if="processing" class="JsonSchemaImageUrlForm__inputContentUploading">
                            <FontAwesomeIcon :icon="faSpinner" spin />
                            Processing...
                        </div>
                        <div v-else-if="fileName" class="JsonSchemaImageUrlForm__inputContentFile">
                            {{ fileName }}
                            <button
                                v-if="!requiredElement"
                                type="button"
                                class="JsonSchemaImageUrlForm__removeButton"
                                @click="onRemove"
                            >
                                <FontAwesomeIcon :icon="faTrashAlt" />
                            </button>
                        </div>
                        <div v-else-if="value" class="JsonSchemaImageUrlForm__inputContentFile">
                            <button type="button" class="JsonSchemaImageUrlForm__uploadButton">
                                <FontAwesomeIcon :icon="faUpload" />
                                Replace
                            </button>
                            image.jpg

                            <button
                                v-if="!requiredElement"
                                type="button"
                                class="JsonSchemaImageUrlForm__removeButton"
                                @click="onRemove"
                            >
                                <FontAwesomeIcon :icon="faTrashAlt" />
                            </button>
                        </div>
                        <div v-else class="JsonSchemaImageUrlForm__inputContentEmpty">
                            <button type="button" class="JsonSchemaImageUrlForm__uploadButton">
                                <FontAwesomeIcon :icon="faUpload" />
                                Upload
                            </button>
                            No file uploaded
                        </div>
                    </label>
                    <input
                        :id="id"
                        type="file"
                        :disabled="disabled || processing"
                        :placeholder="form.schema.description || description"
                        :maxlength="form.schema.maxLength"
                        accept=".jpeg,.jpg,.png,.svg"
                        @blur="touch"
                        @focus="onFocus"
                        @change="onChange"
                    >
                </div>
                <div class="JsonSchemaImageUrlForm__image" :style="`background-image: url(${value})`" />

                <template v-if="accessed && error" v-slot:errorMessage>
                    {{ error }}
                </template>
                <template v-if="$slots.icon" v-slot:icon>
                    <slot name="icon" />
                </template>
                <template v-if="deletable" v-slot:actionButtons>
                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                        <FontAwesomeIcon :icon="faTimes" />
                    </div>
                </template>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import { faTimes, faUpload, faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { isFileValid } from '@evidentid/file-utils/prepareFile';
    import { readFile } from '@evidentid/file-utils/blobs';
    import { FormElement, FormInput } from '../../Form';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    type JsonFormString = JsonFormBase<JsonSchemaString>;

    @Component({
        components: { FormInput, FormElement, FontAwesomeIcon },
        inheritAttrs: false,
    })
    export default class JsonSchemaImageUrlForm extends AbstractJsonSchemaForm<JsonFormString, string> {
        private faTimes = faTimes;
        private faUpload = faUpload;
        private faSpinner = faSpinner;
        private faTrashAlt = faTrashAlt;

        private processing = false;
        private fileName: string | null = null;
        private fileContents: string | null = null;

        @Watch('value')
        private onValueChange(): void {
            if (this.value !== this.fileContents) {
                this.fileName = null;
                this.fileContents = null;
            }
        }

        protected override getCustomError(): string | null {
            const value = this.value;
            if ((this.form.schema.minLength || 0) > value.length) {
                return this.translate('error.text.tooShort');
            } else if ((this.form.schema.maxLength || Infinity) < value.length) {
                return this.translate('error.image.tooBig');
            }
            return null;
        }

        private get requiredElement(): boolean {
            return Boolean(this.required || this.form.schema.minLength || this.form.schema.pattern);
        }

        private onRemove(event: Event): void {
            event.preventDefault();
            this.fileName = null;
            this.processing = false;
            this.$emit('input', null);
            this.$emit('change', null);
        }

        private async onChange(event: Event): Promise<void> {
            event.preventDefault();
            const file = (event.target as HTMLInputElement).files?.[0];
            if (!file || !isFileValid(file, [ 'image/png', 'image/jpeg', 'image/svg+xml' ])) {
                return;
            }
            this.processing = true;
            const name = file.name;
            const dataUrl = await readFile.asDataURL(file);
            if (!this.processing) {
                return;
            }
            const value = this.form.getValue(dataUrl);
            this.fileName = name;
            this.fileContents = dataUrl;
            this.processing = false;
            this.$emit('input', value);
            this.$emit('change', value);
        }
    }
</script>
