import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import SingleLineText from '@/components/fields/SingleLineText.vue';

export default class IntegerField extends Field<string> {
    public override component = SingleLineText;

    public isValid(_: Question, value: string): boolean {
        return typeof value === 'string' && /^(0|[1-9][0-9]*)$/.test(value);
    }

    public async encode(question: Question, value: string): Promise<AttributesValues> {
        return this._encode(question, parseInt(value, 10));
    }
}
