<template>
    <div class="AllQuestions">
        <p class="faded">
            {{ introText }}
        </p>
        <section class="InterviewCard__steps">
            <QuestionsListTile
                v-for="question in sortedQuestions"
                :key="question.attrType"
                :question="question"
            />
        </section>
        <em v-if="numberOfRequiredQuestions > 0">
            <span class="fg-danger">*</span> Required information
        </em>

        <div v-if="returnUrl" class="InterviewCard__returnSection">
            <a
                :href="returnUrl"
                class="Button Button--glass-grey"
                data-title="You can come back and complete this request when you are ready."
            >
                Return
            </a>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import semanticQuestionSort from '@evidentid/ido-lib/views/semanticQuestionsSort';
    import QuestionsListTile from './QuestionsListTile.vue';
    import PrivacyNotice from './PrivacyNotice.vue';
    import { isGlsSession } from '@/interfaces/SessionData';

    @Component({
        components: { PrivacyNotice, QuestionsListTile },
    })
    export default class QuestionsList extends Vue {
        private get numberOfRequiredQuestions() {
            return this.questions
                .reduce((acc, question) => (question.metadata.required ? acc + 1 : acc), 0);
        }

        private get returnUrl() {
            const session = this.$store.state.session;

            if (!session || isGlsSession(session) || !session.enableReturn) {
                return null;
            }

            return session.continueUrl || null;
        }

        private get questions() {
            return this.$store.state.questions;
        }

        private get visibleQuestions() {
            return this.questions.filter((question) => !question.metadata.hidden);
        }

        private get sortedQuestions() {
            return semanticQuestionSort(this.visibleQuestions);
        }

        private get introText() {
            return `Action required to complete your ${this.$store.state.summary || 'profile'}`;
        }
    }
</script>
