// Format currency with awe-inspiring cultural sensitivity.
export default function formatBalanceDue(currency: string, amount: string | number): string {
    const normalizedCurrency = currency.toUpperCase();

    let balance = `${amount}`;

    if (/^\d+$/.test(balance)) {
        balance += '.00';
    } else if (/^\d+\.\d$/.test(balance)) {
        balance += '0';
    }

    return (normalizedCurrency === 'USD')
        ? `$${balance}`
        : `${balance} ${normalizedCurrency}`;
}
