<template>
    <select
        :id="id"
        v-model="inputValue"
        class="Dropdown Dropdown--with-arrow"
        :class="extraClass"
        :name="id"
        :disabled="disabled"
        @change="onChange"
    >
        <option v-if="placeholder !== null" :value="-1">
            {{ placeholder }}
        </option>
        <option
            v-for="(option, index) in options"
            :key="buildKey(option, index)"
            :class="{ selected: index === inputValue }"
            :value="index"
        >
            {{ buildLabel(option) }}
        </option>
    </select>
</template>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

    @Component
    export default class Dropdown extends Vue {
        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: String, default: undefined })
        private extraClass!: string | undefined;

        @Prop({ type: String, default: undefined })
        private id!: string | undefined;

        @Prop({ default: 'Select one' })
        private placeholder!: string | null;

        @Prop({ default: null })
        private value!: any;

        @Prop({ type: Array, default: () => [] })
        private options!: any[];

        @Prop({ type: Function, default: (value: any) => value })
        private buildLabel!: (value: any) => string;

        @Prop({ type: Function, default: (_: any, index: number) => index })
        private buildKey!: (value: any, index: number) => any;

        private inputValue = -1;

        @Watch('value', { immediate: true })
        @Watch('options')
        private changeValue(value: any) {
            this.inputValue = this.options.indexOf(value);
        }

        private onChange() {
            const value = this.options[this.inputValue];

            if (this.value !== value) {
                this.$emit('input', value);
            }
        }
    }
</script>
