import pick from 'lodash/pick';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { setUp } from '@evidentid/ido-lib/idoWebClient';
import RawIdoMutations from './interfaces/RawIdoMutations';
import { createMutationsFactories } from './buildInitialState';
import createMapWithKeys from '@/utils/createMapWithKeys';
import { Status, VerificationResult, VerificationStatus } from '@/store/interfaces/IdoState';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';

const factories = createMutationsFactories<RawIdoMutations>(() => ({
    setError(error) {
        this.error = error || null;
    },
    setSnackbarContent(configuration) {
        const permanent = Boolean(configuration?.permanent);
        this.snackbar = configuration
            ? { success: Boolean(configuration.success), message: configuration.message || null, permanent }
            : { success: false, message: null, permanent };
    },
    setStatus(status) {
        this.status = status;
    },
    setUserSession(session) {
        this.session = session;

        if (session) {
            setUp(session);
        }
    },
    setInterviewDetails(payload) {
        // eslint-disable-next-line no-param-reassign
        payload = payload || {};

        this.user = payload.user || null;
        this.issuer = payload.issuer || null;
        this.questions = payload.questions || [];
        this.summary = payload.summary || null;
        this.requestStatus = payload.status || null;
        this.balance = payload.balance || null;
        this.featureFlags = payload.featureFlags || this.featureFlags;
        this.attributeSharingAgreements = payload.attributeSharingAgreements;

        // Initialize values
        const attributeTypes = this.questions.map((question) => question.attrType);
        this.values = { ...createMapWithKeys(attributeTypes, null), ...this.values };
        this.submission = { ...createMapWithKeys(attributeTypes, false), ...this.submission };
    },
    setGlsDetails(payload) {
        this.gls = payload || null;
    },
    setSubmissionStatus(submissionMap) {
        this.submission = {
            ...this.submission,
            ...submissionMap,
        };
    },
    setValue(payload) {
        this.values = {
            ...this.values,
            [payload.name]: payload.value,
        };

        // Reset verification status after value change
        const verification = this.verificationsRequested[payload.name];
        if (verification?.result && verification.result !== VerificationResult.uninitialized) {
            this.verificationsRequested = {
                ...this.verificationsRequested,
                [payload.name]: {
                    ...verification,
                    result: VerificationResult.uninitialized,
                } as VerificationStatus,
            };
        }
    },
    completeQuestion(attrType) {
        // Mark selected question as completed
        this.questions = this.questions.map((question) => (
            question.attrType === attrType ? { ...question, complete: true } : question
        ));

        // When ID scan with Selfie required is finished, unlock the Selfie and/or Video Selfie question
        const currentQuestion = this.questions.find((question) => question.attrType === attrType);

        // Enable dependent attributes (i.e. for ID scan)
        const nextAttrTypes = currentQuestion?.metadata?.nextAttrTypes;
        if (nextAttrTypes) {
            this.questions = this.questions.map((question) => {
                if (nextAttrTypes.includes(question.attrType) && question.metadata?.hidden) {
                    return {
                        ...question,
                        metadata: { ...question.metadata, hidden: false },
                    };
                }
                return question;
            });
        }
    },
    setAcademicProviders(providers) {
        this.academicProviders = providers;
    },
    setUtilityProviders(providers) {
        this.utilityProviders = providers;
    },
    setCriminalOffenses(criminalOffenses) {
        this.criminalOffenses = criminalOffenses;
    },
    setDelegationPhoneNumber(phoneNumber) {
        this.delegationPhoneNumber = phoneNumber;
    },
    startDelegation(attrType) {
        const delegationStatus = this.delegationsRequested[attrType];
        const requests = delegationStatus ? delegationStatus.requests : 0;

        this.delegationsRequested = {
            ...this.delegationsRequested,
            [attrType]: { status: Status.loading, requests },
        };
    },
    finishDelegation(attrType) {
        const delegationStatus = this.delegationsRequested[attrType];
        const requests = delegationStatus ? delegationStatus.requests : 0;

        this.delegationsRequested = {
            ...this.delegationsRequested,
            [attrType]: { status: Status.success, requests: requests + 1 },
        };
    },
    failDelegation(attrType) {
        const delegationStatus = this.delegationsRequested[attrType];
        const requests = delegationStatus ? delegationStatus.requests : 0;

        this.delegationsRequested = {
            ...this.delegationsRequested,
            [attrType]: { status: Status.error, requests },
        };
    },
    startVerification(payload) {
        const { attributeTypes } = payload;
        const defaults = {
            failures: 0,
        };

        for (const attrType of attributeTypes) {
            this.verificationsRequested = {
                ...this.verificationsRequested,
                [attrType]: {
                    ...defaults,
                    ...this.verificationsRequested[attrType],
                    result: VerificationResult.uploading,
                    resolutionAttempts: 0,
                    datumIds: null,
                    failureReason: null,
                },
            };
        }
    },
    attemptVerificationResolution(payload) {
        const { attributeTypes } = payload;
        const defaults = {
            failures: 0,
            result: VerificationResult.uninitialized,
        };

        for (const attrType of attributeTypes) {
            const previousStatus: any = this.verificationsRequested[attrType] || {};
            this.verificationsRequested = {
                ...this.verificationsRequested,
                [attrType]: {
                    ...defaults,
                    datumIds: null,
                    failureReason: null,
                    ...previousStatus,
                    resolutionAttempts: (previousStatus.resolutionAttempts || 0) + 1,
                },
            };
        }
    },
    setVerificationStatus(payload) {
        const { attributeTypes, datumIds, failureReason, result } = payload;
        const defaults = {
            resolutionAttempts: 0,
            failures: 0,
            result: VerificationResult.uninitialized,
            datumIds: null,
            failureReason: null,
        };

        for (const attrType of attributeTypes) {
            const previousStatus = this.verificationsRequested[attrType] || {};
            const nextStatus: VerificationStatus = {
                ...defaults,
                ...previousStatus,
            };

            if (datumIds) {
                nextStatus.datumIds = datumIds;
            }

            if (result === VerificationResult.failed) {
                nextStatus.result = VerificationResult.failed;
                nextStatus.failureReason = failureReason || null;
                nextStatus.failures += 1;
            } else if (result === VerificationResult.verified) {
                nextStatus.result = VerificationResult.verified;
            } else if (result) {
                nextStatus.result = result;
            }

            this.verificationsRequested = {
                ...this.verificationsRequested,
                [attrType]: nextStatus,
            };
        }
    },
    setStripeInstance(stripe) {
        this.stripe = stripe;
    },
    finishPayment() {
        this.balance = null;
    },
    setConsentStatus(status) {
        this.consentGiven = status;
    },
    setValuesOptionally(values) {
        const attributeTypes = Object.keys(values);
        const allowedAttributeTypes = attributeTypes.filter((attributeType) => {
            const question = this.questions.find((x) => x.attrType === attributeType);
            return (
                this.values[attributeType] == null || // Empty attributes
                !question || // Static values, beside regular flow
                question.complete // Already submitted
            );
        });

        if (allowedAttributeTypes.length > 0) {
            this.values = {
                ...this.values,
                ...pick(values, allowedAttributeTypes),
            };
        }
    },

    startGlsResendLink(emails) {
        this.glsResendLinkStatus = {
            ...this.glsResendLinkStatus,
            ...mapValues(keyBy(emails, (email) => email), () => Status.loading),
        };
    },

    finishGlsResendLink(emails) {
        this.glsResendLinkStatus = {
            ...this.glsResendLinkStatus,
            ...mapValues(keyBy(emails, (email) => email), () => Status.success),
        };
    },

    failGlsResendLink(emails) {
        this.glsResendLinkStatus = {
            ...this.glsResendLinkStatus,
            ...mapValues(keyBy(emails, (email) => email), () => Status.error),
        };
    },
    setLoadingAgentResponse(status: OperationStatus) {
        this.onlineChat = {
            ...this.onlineChat,
            loadAgentResponse: status,
        };
    },
}));

const mutations = factories.instantiateMutations();

export const { instantiateMutations, createActionFactories } = factories;

export default mutations;
