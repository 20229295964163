import sortBy from 'lodash/sortBy';

const SEMANTIC_ORDER = {
    'core.fullname': 0,
    'core.firstname': 1,
    'core.middlename': 2,
    'core.lastname': 3,
    'core.ssn': 4,
    'core.dateofbirth': 5,
    'consent.fcra': 6,
};

/*
Sort questions by semantic English and UX rules for types, so
that "First Name" appears before "Last Name", for example.

Input:
                       Last Name (core.lastname)
                      First Name (core.firstname)
          Social Security Number (core.ssn)
                         ID Scan (<varies>)
    Authorization and Disclosure (consent.fcra)

Output (Note that the indices above declare type ordering, and other attrs appear at the bottom):

    Authorization and Disclosure
                      First Name
                       Last Name
          Social Security Number
                         ID Scan

*/
function semanticCuratedTypeSort(questions) {
    if (!questions.length) {
        return [];
    }

    // Note that we do a group sort such that the attributes in
    // SEMANTIC_ORDER are sorted separately from those NOT in SEMANTIC_ORDER.
    // Sorting all of them would yield incorrect results.
    const prefix = sortBy(
        questions.filter(({ attrType }) => attrType in SEMANTIC_ORDER),
        ({ attrType }) => SEMANTIC_ORDER[attrType],
    );

    const suffix = sortBy(
        questions.filter(({ attrType }) => !(attrType in SEMANTIC_ORDER)),
        ({ metadata, attrType }) => metadata?.title || attrType
    );

    return prefix.concat(suffix);
}

/*
Group questions into complete and incomplete, then sort each group
using semanticCuratedTypeSort. Complete questions come after incomplete
questions.

Input:
                       Last Name ☑
                      First Name ☑
          Social Security Number ☐
    Authorization and Disclosure ☐
                         ID Scan ☐

Output:

    Authorization and Disclosure ☐
          Social Security Number ☐
                         ID Scan ☐
                      First Name ☑
                       Last Name ☑
*/
export default function semanticQuestionsSort(questions) {
    const breakout = questions.slice(0);

    const checked = breakout.filter(({ complete }) => complete);
    const unchecked = breakout.filter(({ complete }) => !complete);

    const sortedComplete = semanticCuratedTypeSort(checked);
    const sortedPending = semanticCuratedTypeSort(unchecked);

    return sortedPending.concat(sortedComplete);
}
