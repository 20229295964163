<template>
    <div>
        <label class="FullWidthInputBlock">
            <span>How old were you at the time the offense was committed or that the suit was filed?</span>
            <input v-model="value.ageAtOffense"
                type="number"
                min="1"
                max="150"
                @input="onAgeChange"
            >
        </label>

        <label class="FullWidthInputBlock">
            <span>
                What is your current or most recent position?
                How long have you been in your current or most recent Position?
            </span>
            <textarea v-model="value.lastPosition" @input="onChange" />
        </label>

        <label class="FullWidthInputBlock">
            <span>
                In your own words, can you describe what led to your being charged with the offense?
                Please include detailed information around who was involved in the offense,
                what was your role, and any other relevant factors.
            </span>
            <textarea v-model="value.cause" @input="onChange" />
        </label>

        <span class="ChargeInfo__label">
            Please select all rehabilitation efforts that apply:
        </span>

        <div class="RehabQs">
            <div class="standardEfforts">
                <ChargeCheckbox
                    v-model="value.rehabilitationParole"
                    :disabled="value.rehabilitationNone"
                    @input="onRehabilitationChange"
                >
                    Parole <span class="ChargeDetails__note">(If civil suit, leave the box unchecked)</span>
                </ChargeCheckbox>

                <ChargeCheckbox
                    v-model="value.rehabilitationProbation"
                    :disabled="value.rehabilitationNone"
                    @input="onRehabilitationChange"
                >
                    Probation <span class="ChargeDetails__note">(If civil suit, leave the box unchecked)</span>
                </ChargeCheckbox>

                <ChargeCheckbox
                    v-model="value.rehabilitationEducation"
                    :disabled="value.rehabilitationNone"
                    @input="onRehabilitationChange"
                >
                    Education <span class="ChargeDetails__note">(If civil suit, leave the box unchecked)</span>
                </ChargeCheckbox>

                <ChargeCheckbox
                    v-model="value.rehabilitationTraining"
                    :disabled="value.rehabilitationNone"
                    @input="onRehabilitationChange"
                >
                    Training
                </ChargeCheckbox>

                <ChargeCheckbox
                    v-model="value.rehabilitationAlcohol"
                    :disabled="value.rehabilitationNone"
                    @input="onRehabilitationChange"
                >
                    Alcohol Treatment
                </ChargeCheckbox>

                <ChargeCheckbox
                    v-model="value.rehabilitationDrug"
                    :disabled="value.rehabilitationNone"
                    @input="onRehabilitationChange"
                >
                    Drug Treatment
                </ChargeCheckbox>
            </div>
            <div class="otherEfforts">
                <ChargeCheckbox
                    v-model="value.rehabilitationNone"
                    @input="onNoRehabilitationChange"
                >
                    None
                </ChargeCheckbox>

                <ChargeCheckbox
                    :value="value.rehabilitationOther != null"
                    :disabled="value.rehabilitationNone"
                    @input="onOtherRehabilitationChange"
                >
                    Other Treatment/Program, Describe below <span class="ChargeDetails__note">(select none if civil)</span>
                </ChargeCheckbox>

                <textarea
                    v-model="value.rehabilitationOther"
                    :class="value.rehabilitationOther === null ? 'disabledRehabQ' : ''"
                    :disabled="value.rehabilitationOther === null"
                    @input="onChange"
                />
            </div>
        </div>

        <label class="FullWidthInputBlock">
            <span>
                Have you satisfied parole and/or probation requirements,
                or any requirements necessary to complete for civil offenses? Please detail below.
            </span>
            <textarea v-model="value.paroleRequirements" @input="onChange" />
        </label>

        <label class="FullWidthInputBlock">
            <span>
                Have you received education and/or training related to your offense?
                Please detail below, including what the program was, how long the program is,
                and time left to complete the program, if any.
            </span>
            <textarea v-model="value.offenseTraining" @input="onChange" />
        </label>

        <label class="FullWidthInputBlock">
            <span>
                Have you participated in alcohol and/or drug treatment programs?
                Please detail below, including what the program was,
                how long the program is, and time left to complete the program, if any.
            </span>
            <textarea v-model="value.substanceTraining" @input="onChange" />
        </label>

        <label class="FullWidthInputBlock">
            <span>
                What additional efforts have you made to change behavior to avoid future offenses?
                Please provide details below.
            </span>
            <textarea v-model="value.additionalEffort" @input="onChange" />
        </label>

        <label class="FullWidthInputBlock">
            <span>Are you out on bond?</span>
            <Dropdown
                v-model="value.isBonded"
                placeholder="Select one"
                :options="isBondedOptions"
                :build-label="isBondedGetLabel"
                @input="onChange"
            />
        </label>

        <label class="FullWidthInputBlock">
            <span>
                Is there any additional information surrounding the offense you would like to add?
            </span>
            <textarea v-model="value.additionalInformation" @input="onChange" />
        </label>
    </div>
</template>

<style lang="scss">
    .RehabQs {
        label {
            font-size: 0.95em;
        }

        i {
            width: 20px;
        }
    }

    .ChargeDetails__note {
        font-size: 0.8em;
    }
</style>

<script lang="ts">
    import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
    import Charge from '@/interfaces/Charge';
    import Icon from '@/components/common/Icon.vue';
    import Dropdown from '@/components/forms/Dropdown.vue';
    import ChargeCheckbox from './ChargeCheckbox.vue';

    const isBondedOptions = [ true, false ];
    const isBondedGetLabel = (value: string) => (value ? 'Yes' : 'No');

    @Component({
        components: { ChargeCheckbox, Dropdown, Icon },
    })
    export default class ChargeDetails extends Vue {
        @Prop({ type: Object })
        private charge!: Charge;

        private value: Charge | null = null;

        private isBondedOptions = isBondedOptions;
        private isBondedGetLabel = isBondedGetLabel;

        @Watch('charge', { immediate: true })
        private onChargeDetailsChange(charge: Charge) {
            // Copy value, to make sure that we will not modify external value
            this.value = { ...charge };
        }

        private onChange() {
            this.$emit('input', { ...this.value });
        }

        private onAgeChange() {
            // @ts-ignore: ensuring proper type
            this.value.ageAtOffense = parseInt(this.value?.ageAtOffense, 10) || null;
            this.onChange();
        }

        private onRehabilitationChange(value: boolean) {
            // When any rehabilitation field is set to true, we should reset "none" as well
            this.value = {
                ...this.value!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                rehabilitationNone: Boolean(this.value?.rehabilitationNone && !value),
            };
            this.onChange();
        }

        private onNoRehabilitationChange() {
            // Clear other rehabilitation fields when "none" is selected
            this.value = {
                ...this.value!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                rehabilitationParole: false,
                rehabilitationProbation: false,
                rehabilitationEducation: false,
                rehabilitationTraining: false,
                rehabilitationAlcohol: false,
                rehabilitationDrug: false,
                rehabilitationOther: null,
            };
            this.onChange();
        }

        private onOtherRehabilitationChange(hasOther: boolean) {
            this.value = {
                ...this.value!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                rehabilitationOther: hasOther ? '' : null,
            };
            this.onRehabilitationChange(hasOther);
        }
    }
</script>
