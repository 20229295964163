<template>
    <div
        class="InterviewStepTile"
        :touch-action="question.complete ? false : 'auto'"
        :class="{ error: question.hasError, complete: question.complete }"
        @click="onClick"
    >
        <div class="InterviewStepTile__icon">
            <Icon :name="question.metadata.icon" />
        </div>
        <div class="InterviewStepTile__text">
            <div class="InterviewStepTile__title">
                {{ question.metadata.title }}<span v-if="question.metadata.required" class="fg-danger">&nbsp;*</span>
            </div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span class="InterviewStepTile__description" v-html="question.metadata.description" />
        </div>
        <div class="InterviewStepTile__status">
            <img v-if="question.complete" :src="checkIconUrl">
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import checkIconUrl from '@evidentid/ido-lib/images/Check.svg';
    import Question from '@evidentid/ido-lib/interfaces/Question';
    import slugify from '@evidentid/universal-framework/slugify';
    import Icon from '@/components/common/Icon.vue';

    @Component({
        components: { Icon },
    })
    export default class QuestionsListTile extends Vue {
        @Prop({ type: Object, default: false })
        private question!: Question;

        private checkIconUrl = checkIconUrl;

        private onClick() {
            if (!this.question.complete) {
                this.$router.push({
                    name: 'question',
                    params: { slug: slugify(this.question.attrType) },
                });
            }
        }
    }
</script>
