<template>
    <v-component :is="component" v-bind="$props" v-on="$listeners" />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import support from '@/config/support';
    import MobileFileUploadInput from './FileUploadInput/MobileFileUploadInput.vue';
    import DesktopFileUploadInput from './FileUploadInput/DesktopFileUploadInput.vue';

    @Component
    export default class FileUploadInput extends Vue {
        @Prop({ type: String, default: undefined })
        private id!: string | undefined;

        @Prop({ type: Array, default: () => [] })
        private value!: File[];

        @Prop({ type: Boolean, default: false })
        private submitting!: boolean;

        @Prop({ type: Boolean, default: false })
        private imagesOnly!: boolean;

        @Prop({ type: String, default: '' })
        private description!: string;

        @Prop({ type: Boolean, default: support.isMobileBrowser })
        private isMobile!: boolean;

        private get component() {
            return this.isMobile ? MobileFileUploadInput : DesktopFileUploadInput;
        }
    }
</script>
