<template>
    <div class="AcademicProviderQuestion">
        <header class="QuestionHeader">
            <h3>{{ question.metadata.title }}</h3>
            <p>{{ question.metadata.description }}</p>
        </header>

        <div class="AcademicProviderQuestion__form">
            <AutoComplete
                placeholder="Type and select a known institution"
                :matches="matches"
                :value="value"
                :build-label="buildLabel"
                @typed="onInputValueChange"
                @input="onChange"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import AcademicProviderValue from '@/interfaces/AcademicProvider';
    import AutoComplete from '@/components/forms/AutoComplete.vue';

    @Component({
        components: { AutoComplete },
    })
    export default class AcademicProvider extends FieldComponent<AcademicProviderValue> {
        private matches: AcademicProviderValue[] = [];
        private inputValue: string = this.buildLabel(this.value);

        private get providers() {
            return this.$store.state.academicProviders || [];
        }

        private buildLabel(provider: AcademicProviderValue | null) {
            return provider ? provider.name : '';
        }

        private updateMatches() {
            const name = (this.inputValue || '').toLowerCase();

            this.matches = this.providers
                .filter((provider) => provider.name.toLowerCase().indexOf(name) !== -1)
                .slice(0, 10);
        }

        private onChange(value: AcademicProviderValue | null) {
            this.$emit('change', value || null);
        }

        private onInputValueChange(value: string) {
            this.inputValue = value;
            this.updateMatches();
        }

        @Watch('providers')
        private updateMatchesOnProvidersChange() {
            this.updateMatches();
        }

        @Watch('value')
        private onValueChange(value: AcademicProviderValue | null) {
            this.inputValue = this.buildLabel(value);
            this.updateMatches();
        }
    }
</script>
