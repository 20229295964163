<template>
    <!--deprecated by footer links for now-->
    <div class="PrivacyNotice">
        <h3 v-if="headline" class="PrivacyNotice__header">
            {{ headline }}
        </h3>
        <p v-if="message" class="PrivacyNotice__body">
            {{ message }}
        </p>
        <a
            v-if="title && url"
            class="PrivacyNotice__link"
            target="_blank"
            rel="noopener noreferrer"
            :href="url"
        >
            {{ title }}
        </a>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component
    export default class PrivacyNotice extends Vue {
        @Prop({ type: String, default: null })
        private headline !: string | null;

        @Prop({ type: String, default: null })
        private message !: string | null;

        @Prop({ type: String, default: null })
        private title !: string | null;

        @Prop({ type: String, default: null })
        private url !: string | null;
    }
</script>
