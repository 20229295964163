import noop from 'lodash/noop';
import littleLoader from 'little-loader';

type SetupFn = (script: HTMLScriptElement) => void;

export const cache: Record<string, Promise<any>> = {};

function loadOne(script: string, setup: SetupFn = noop): Promise<void> {
    // We disable eslint rule below because the vendor calls for that identifier.
    if (script in cache) {
        return cache[script];
    }

    cache[script] = new Promise((resolve, reject) => {
        littleLoader(script, {
            setup,
            callback(err) { // eslint-disable-line id-blacklist
                if (err) {
                    delete cache[script];
                    reject(err);
                } else {
                    resolve(undefined);
                }
            },
        });
    });

    return cache[script];
}

export default function load(...args: (string | { script: string, setup: SetupFn })[]): Promise<void[]> {
    const queries = args.map((arg) => {
        if (typeof arg === 'string') {
            return loadOne(arg);
        } else if (typeof arg === 'object') {
            return loadOne(arg.script, arg.setup);
        } else {
            throw new Error(`Cannot load script using ${arg}`);
        }
    });

    return Promise.all(queries);
}

export function loadAndForceExecute(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.onload = () => {
            if (script.parentNode) {
                script.parentNode.removeChild(script);
            }
            resolve();
        };
        script.onerror = (event) => {
            if (script.parentNode) {
                script.parentNode.removeChild(script);
            }
            if (typeof event === 'object' && event && 'loaded' in event && !((event as ProgressEvent).loaded)) {
                reject(new Error('The request has been aborted'));
            } else {
                reject(event);
            }
        };
        script.src = filePath;
        document.body.appendChild(script);
    });
}

export async function loadUmdAsyncBundle<T>(
    filePath: string,
    globalKey: string,
    globalScope: any = window,
): Promise<T> {
    // Cache value from before
    const hadPrevValue = globalKey in globalScope;
    const prevValue = globalScope[globalKey];

    // Remove value from before to avoid conflicts
    if (hadPrevValue) {
        delete globalScope[globalKey];
    }

    // Load UMD package into global scope
    await loadAndForceExecute(filePath);

    // Get copy of loaded bundle
    const bundle = globalScope[globalKey];

    // Rebuild previous state of global scope
    if (hadPrevValue) {
        globalScope[globalKey] = prevValue;
    } else {
        delete globalScope[globalKey];
    }

    // Get back with loaded bundle
    return bundle;
}
