<template>
    <IdScanError @submit="$emit('retry')" @back="$emit('back')">
        <RetryDescription heading />
    </IdScanError>
</template>

<script lang="ts">
    import { Vue, Component } from 'vue-property-decorator';
    import IdScanError from '@/components/fields/IdScan/IdScanError.vue';
    import RetryDescription from '@/components/fields/IdScan/RetryDescription.vue';

    @Component({
        components: { RetryDescription, IdScanError },
    })
    export default class UnknownErrorScreen extends Vue {
    }
</script>
