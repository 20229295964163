import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import { IdoRequestStatus, RelyingPartyRequestStatusType } from '@evidentid/ido-lib/getIdoRequestStatus';
import {
    GlsBusinessAddress,
    GlsCaseCaseStatus,
    GlsCaseData,
    GlsCaseSubmission,
    GlsCaseSubmissionStatus,
    GlsCheck,
    GlsRequestSubmissionStatus,
    GlsServiceType,
} from '@evidentid/gls-service-api-client/types';
import { ValuesMap } from '@/store/interfaces/IdoState';
import { GlsSessionData } from '@/interfaces/SessionData';
import UserData from '@/interfaces/UserData';
import Identity from '@evidentid/ido-lib/interfaces/Identity';
import Question from '@evidentid/ido-lib/interfaces/Question';
import { RelyingPartyRequestFeatureFlags } from '@evidentid/ido-lib/interfaces/AttributeRelyingPartyRequest';
import { SessionInterviewDetails } from '@/services/getSessionInterview';
import glsApiClient from '@/glsApiClient';
import {
    buildBusinessAddressQuestion,
    buildBusinessAddressValues,
    buildBusinessOwnerOnlyQuestion,
    buildBusinessOwnersQuestion,
    buildBusinessOwnersValues,
    buildFieldworkerOnlyValues,
    buildFieldworkersQuestion,
    buildFieldworkersValues,
    buildProfessionalsQuestion,
    buildProfessionalsValues,
    buildSeniorPartnersQuestion,
    buildSeniorPartnersValues,
    createGlsIdentity,
    GlsQuestion,
    glsUserAuthDomain,
} from '@/config/gls';

interface GlsCaseInfo {
    caseStatus: GlsCaseCaseStatus;
    submissionStatus: GlsCaseSubmissionStatus;
    user: UserData;
    submittedData: GlsCaseSubmission | null;
}

export type GlsBasicCaseData = Pick<
    GlsCaseData,
    'serviceType' | 'businessName' | 'serviceCategories' | 'requiredChecks' | 'checkResult'
>;

export interface GlsData {
    caseStatus: GlsCaseCaseStatus;
    caseData: GlsBasicCaseData;
    submissionStatus: GlsCaseSubmissionStatus;
    primaryBusinessOwner: string | null;
    emailRequestStatuses: Record<string, GlsRequestSubmissionStatus>;
    businessAddress: GlsBusinessAddress | null;
}

export const glsFeatureFlags: RelyingPartyRequestFeatureFlags = {
    idverify_document_capture_retry_enabled: true,
    idverify_document_upload_from_mobile_enabled: false,
    idverify_document_upload_from_desktop_enabled: false,
    idverify_user_media_overlay_enabled: false,
    rpr_constant_polling_enabled: false,
};

const IMMEDIATE_FINISH_STATUSES = [
    RelyingPartyRequestStatusType.complete,
    RelyingPartyRequestStatusType.processing,
    RelyingPartyRequestStatusType.timeout,
];

export function buildBasicGlsCaseData(caseData: GlsCaseData): GlsBasicCaseData {
    return pick(caseData, [ 'serviceType', 'businessName', 'serviceCategories', 'requiredChecks', 'checkResult' ]);
}

function getGlsRequestStatusType(completed: boolean, questions: Question[], blockImmediateFinish?: boolean) {
    const statusType: RelyingPartyRequestStatusType = completed
        ? RelyingPartyRequestStatusType.complete
        : questions.every((question) => question.complete)
            ? RelyingPartyRequestStatusType.processing
            : RelyingPartyRequestStatusType.new;
    return blockImmediateFinish && IMMEDIATE_FINISH_STATUSES.includes(statusType)
        ? RelyingPartyRequestStatusType.new
        : statusType;
}

function buildGlsRequestStatus(
    completed: boolean,
    questions: Question[],
    blockImmediateFinish?: boolean
): IdoRequestStatus {
    return {
        type: getGlsRequestStatusType(completed, questions, blockImmediateFinish),
        requestedAt: null,
        submittedAt: null,
        completedAt: null,
        daysProcessing: 0,
    };
}

async function getGlsUserData(): Promise<UserData> {
    const userSession = await glsApiClient.getSession();
    return {
        email: userSession.email,
        authDomain: glsUserAuthDomain,
        transitionUrl: userSession.interviewUrl,
    };
}

function finishGlsQuestion(question: GlsQuestion, completed: boolean): Question {
    return {
        ...question,
        shareWith: [],
        hasError: false,
        complete: completed,
    };
}

async function getGlsCaseInfo(caseId: number): Promise<GlsCaseInfo> {
    const result = await Promise.all([
        getGlsUserData(),
        glsApiClient.getStatus(caseId),
        glsApiClient.getSubmittedData(caseId),
    ]);
    return {
        user: result[0],
        caseStatus: result[1].caseStatus,
        submissionStatus: result[1].submissionStatus,
        submittedData: result[2],
    };
}

interface GlsRequestData {
    values: ValuesMap;
    questions: Question[];
}

type GlsRequestDataFactory = (
    caseData: GlsCaseData,
    submittedData: GlsCaseSubmission | null,
    user: UserData,
) => GlsRequestData;
type GlsRequestDataServiceFactories = { [K in GlsServiceType]: GlsRequestDataFactory };

const requestDataFactories: GlsRequestDataServiceFactories = {
    [GlsServiceType.professionalServices](caseData, submittedData, userData) {
        const submitted = Boolean(submittedData);
        const needsBusinessEntity = caseData.requiredChecks.includes(GlsCheck.businessEntity);
        const needsFieldWorkers = caseData.requiredChecks.includes(GlsCheck.fieldWorkerCriminal);
        const needsBusinessOwners = caseData.requiredChecks.includes(GlsCheck.businessOwner);
        const values: ValuesMap = {};
        const questions: Question[] = [];

        // We don't need business address or COI if its Field Worker only case
        if (caseData.requiredChecks.length === 1 && needsFieldWorkers) {
            questions.push(finishGlsQuestion(buildFieldworkersQuestion(), submitted));
            Object.assign(values, buildFieldworkerOnlyValues(caseData, submittedData, userData));
            return { questions, values };
        }
        if (caseData.requiredChecks.length === 1 && needsBusinessEntity) {
            questions.push(finishGlsQuestion(buildBusinessAddressQuestion(), needsBusinessEntity ? submitted : true));
            Object.assign(values, buildBusinessAddressValues(caseData, submittedData, userData));
            return { questions, values };
        }

        if (caseData.requiredChecks.length === 1 && needsBusinessOwners) {
            questions.push(finishGlsQuestion(buildBusinessOwnerOnlyQuestion(), submitted));
            Object.assign(values, buildBusinessOwnersValues(caseData, submittedData, userData));
            return { questions, values };
        }
        if (needsBusinessOwners && needsBusinessEntity && caseData.requiredChecks.length === 2) {
            questions.push(finishGlsQuestion(buildSeniorPartnersQuestion(), submitted));
            Object.assign(values, buildSeniorPartnersValues(caseData, submittedData, userData));
            questions.push(finishGlsQuestion(buildBusinessAddressQuestion(), needsBusinessEntity ? submitted : true));
            Object.assign(values, buildBusinessAddressValues(caseData, submittedData, userData));

            return { questions, values };
        }
        if (needsBusinessOwners && needsFieldWorkers) {
            questions.push(finishGlsQuestion(buildSeniorPartnersQuestion(), submitted));
            Object.assign(values, buildSeniorPartnersValues(caseData, submittedData, userData));
        } else if (needsFieldWorkers) {
            questions.push(finishGlsQuestion(buildProfessionalsQuestion(), submitted));
            Object.assign(values, buildProfessionalsValues(caseData, submittedData, userData));
        } else if (needsBusinessOwners) {
            questions.push(finishGlsQuestion(buildSeniorPartnersQuestion(), submitted));
            Object.assign(values, buildSeniorPartnersValues(caseData, submittedData, userData));
        }

        if (needsBusinessEntity) {
            questions.push(finishGlsQuestion(buildBusinessAddressQuestion(), needsBusinessEntity ? submitted : true));
            Object.assign(values, buildBusinessAddressValues(caseData, submittedData, userData));
        }

        return { questions, values };
    },
    [GlsServiceType.homeServices](caseData, submittedData, userData) {
        const submitted = Boolean(submittedData);
        const needsBusinessEntity = caseData.requiredChecks.includes(GlsCheck.businessEntity);
        const needsFieldWorkers = caseData.requiredChecks.includes(GlsCheck.fieldWorkerCriminal);
        const needsBusinessOwners = caseData.requiredChecks.includes(GlsCheck.businessOwner);
        const values: ValuesMap = {};
        const questions: Question[] = [];

        if (caseData.requiredChecks.length === 1 && needsFieldWorkers) {
            questions.push(finishGlsQuestion(buildFieldworkersQuestion(), submitted));
            Object.assign(values, buildFieldworkerOnlyValues(caseData, submittedData, userData));
            return { values, questions };
        }

        if (caseData.requiredChecks.length === 1 && needsBusinessOwners) {
            questions.push(finishGlsQuestion(buildBusinessOwnerOnlyQuestion(), submitted));
            Object.assign(values, buildBusinessOwnersValues(caseData, submittedData, userData));
            return { questions, values };
        }

        if (needsBusinessOwners) {
            questions.push(finishGlsQuestion(buildBusinessOwnersQuestion(), submitted));
            Object.assign(values, buildBusinessOwnersValues(caseData, submittedData, userData));
        }

        if (needsFieldWorkers) {
            questions.push(finishGlsQuestion(buildFieldworkersQuestion(), submitted));
            Object.assign(values, buildFieldworkersValues(caseData, submittedData, userData));
        }

        if (needsBusinessEntity) {
            questions.push(finishGlsQuestion(buildBusinessAddressQuestion(), needsBusinessEntity ? submitted : true));
            Object.assign(values, buildBusinessAddressValues(caseData, submittedData, userData));
        }
        return { values, questions };
    },
};

export default async function getGlsSessionInterview(
    sessionData: GlsSessionData,
    blockImmediateFinish?: boolean
): Promise<SessionInterviewDetails> {
    // Retrieve caseId from session
    const caseId = sessionData.caseId;
    const authCode = sessionData.authCode || null;

    // Authorize user for selected case
    const caseData = await glsApiClient.authorize(caseId, authCode);

    // Retrieve all information about current GLS case
    const { user, caseStatus, submissionStatus, submittedData } = await getGlsCaseInfo(caseId);
    const completed = caseStatus !== GlsCaseCaseStatus.unspecified;

    // Retrieve static information about GLS issuer
    const issuer: Identity = createGlsIdentity();

    // Retrieve request data builder for specific request type
    const createRequestData = requestDataFactories[caseData.serviceType];
    if (!createRequestData) {
        throw new Error(`Unknown GLS service type: "${caseData.serviceType}"`);
    }

    // Build compatible information about required questions
    const { questions, values } = createRequestData(caseData, submittedData, user);
    const status = buildGlsRequestStatus(completed, questions, blockImmediateFinish);

    // Build information about e-mail statuses
    const emails = (submittedData?.fieldWorkers || []).concat(submittedData?.businessOwners || []);
    const emailRequestStatuses = mapValues(keyBy(emails, 'email'), 'status');

    return {
        user,
        balance: null,
        issuer,
        questions,
        gls: {
            caseStatus,
            submissionStatus,
            caseData: buildBasicGlsCaseData(caseData),
            primaryBusinessOwner: submittedData?.primaryBusinessOwner || null,
            emailRequestStatuses,
            businessAddress: submittedData?.businessAddress || null,
        },
        status,
        featureFlags: { ...glsFeatureFlags },
        summary: null,
        values,
    };
}
