<template>
    <div class="LicenseIdInputForm">
        <label class="LicenseIdInputForm__labeledControl" :for="id">
            <span>
                {{ form.schema.title }}
                <span v-if="required">
                    <slot name="requiredIndicator"><i class="fg-danger">*</i></slot>
                </span>
            </span>
            <input
                :id="id"
                type="text"
                class="LicenseIdInputForm__input"
                :class="{'LicenseIdInputForm__input--error': !isValid}"
                :value="viewValue"
                :placeholder="hintText"
                @input="onChange"
            >
            <span v-if="!isValid" class="LicenseIdInputForm__error-text">{{ errorMsg }}</span>
            <span v-if="!!viewValue" class="LicenseIdInputForm__hint">{{ hintText }}</span>
        </label>
    </div>
</template>

<script lang="ts">
    import { Component, Prop } from 'vue-property-decorator';
    import {
        getExampleForGivenStateAndProfession,
        isLicenseValidForProfessionInState,
    } from '@/utils/licenseValidation/licenseValidation';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    @Component({
        inheritAttrs: false,
    })
    export default class LicenseIdInputForm extends AbstractJsonSchemaForm<JsonFormBase<JsonSchemaString>, string> {
        @Prop(String)
        private state!: string;

        @Prop(String)
        private parentSchemaId!: string;

        private get hintText(): string {
            const example = getExampleForGivenStateAndProfession(this.state, this.parentSchemaId);
            return example ? `Eg. ${example}` : '';
        }

        private get errorMsg(): string {
            return this.hintText ? `Incorrect format. ${this.hintText}` : '';
        }

        private get isValid(): boolean {
            if (!this.state) {
                return true;
            }
            return isLicenseValidForProfessionInState(this.viewValue, this.state, this.parentSchemaId);
        }

        private get viewValue(): string {
            return `${this.value ?? ''}`;
        }

        private onChange(event: InputEvent): void {
            const inputValue = (event.target as HTMLInputElement).value;
            this.$emit('input', this.form.getValue(inputValue));
        }
    }
</script>
