<template>
    <div>
        <div class="LargeFormatSelfieDelegation">
            <div class="LargeFormatSelfieDelegation__visualAid">
                <img class="SelfieCaptureVisualAid" :src="imageUrl">
            </div>
            <div class="LargeFormatSelfieDelegation__instructions">
                <h2>{{ contents.heading }}</h2>
                <p>{{ contents.reason }}</p>
                <p>{{ contents.delegationInstructions }}</p>

                <PhoneDelegationInput
                    id="selfie-delegation-control"
                    :value="delegationPhoneNumber"
                    :loading="delegationStatus === 'loading'"
                    :disabled="delegationsLeft <= 0"
                    start-button-label="Send 1-Time Text"
                    @input="changeDelegationPhoneNumber"
                    @start="sendMessage"
                />
            </div>
        </div>
        <QuestionFormControls v-bind="$props" :has-submit="false" v-on="$listeners" />
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import selfieCaptureVisualAidUrl from '@evidentid/ido-lib/images/SelfieCaptureVisualAid.svg';
    import PhoneNumber from '@/fields/interfaces/PhoneNumber';
    import FieldComponent from '@/fields/FieldComponent';
    import contents from '@/config/selfieContents';
    import PhoneDelegationInput from '@/components/forms/PhoneDelegationInput.vue';
    import QuestionFormControls from '@/components/forms/QuestionFormControls.vue';

    @Component({
        inheritAttrs: false,
        components: { QuestionFormControls, PhoneDelegationInput },
    })
    export default class SelfieDelegationForm extends FieldComponent<any> {
        private imageUrl = selfieCaptureVisualAidUrl;

        private get copySource(): string {
            return this.question.metadata.copySource || 'vanillaSelfieContent';
        }

        private get contents() {
            return contents[this.copySource] || contents.vanillaSelfieContent;
        }

        private sendMessage() {
            this.$emit('delegate');
        }

        private changeDelegationPhoneNumber(phoneNumber: PhoneNumber | null) {
            this.$emit('delegationChange', phoneNumber);
        }
    }
</script>
