import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import JsonForm from '@evidentid/json-schema/interfaces/JsonForm';
import { PropType } from 'vue';
import { JsonSchemaFormCustomComponentInput } from '@evidentid/dashboard-commons/components/JsonSchemaForm/types';

@Component
class AbstractJsonSchemaForm<T = JsonForm, V = any> extends Vue {
    @Prop(Object)
    protected form!: T;

    @Prop()
    protected value!: V;

    @Prop({ type: Boolean, default: false })
    protected required!: boolean;

    @Prop(String)
    protected id!: string;

    @Prop({ type: Number, default: 0 })
    protected depth!: number;

    @Prop(Function)
    protected FormElementComponent!: Vue.Component;

    @Prop({ type: [ Object, Function ] as PropType<JsonSchemaFormCustomComponentInput | null> })
    protected customComponentInput?: JsonSchemaFormCustomComponentInput | null;
}

const AbstractJsonSchemaFormMixin = Mixins(AbstractJsonSchemaForm) as typeof AbstractJsonSchemaForm;
export default AbstractJsonSchemaFormMixin;
