import { EventHandlersMapByTrackerEventConsumersMap, AnyTrackerEventConsumersMap } from './TrackerEventConsumer';
import TrackerUserData from './TrackerUserData';
import Tracker from './index';

export default function buildTrackerEventConsumers<
    // eslint-disable-next-line no-use-before-define
    T extends AnyTrackerEventConsumersMap<U>,
    U extends TrackerUserData = TrackerUserData,
>(logger: Tracker<U>, consumers: Partial<T>): EventHandlersMapByTrackerEventConsumersMap<T> {
    const result: any = {};
    const eventNames = Object.keys(consumers) as (keyof T)[];

    for (const name of eventNames) {
        result[name] = (...args: Parameters<T[typeof name]>) => {
            const event = consumers[name]!(logger, ...args);
            if (event) {
                logger.track(event);
            }
        };
    }

    return result;
}
