import { RawLocation } from 'vue-router/types/router';
import { TransitionState } from '@/utils/getTransitionState';

const transitionRoutes: Record<TransitionState, RawLocation> = {
    request: { name: 'overview' },
    payment: { name: 'checkout' },
    finishing: { name: 'finishing' },
    finished: { name: 'thank-you' },
    lookup: { name: 'status' },
};

export default function getTransitionRoute(transitionState: TransitionState): RawLocation {
    return transitionRoutes[transitionState];
}
