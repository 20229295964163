<template>
    <ChipList
        v-bind="$props"
        :delimiter-regex="delimiterRegex"
        :validate="isEmail"
        v-on="$listeners"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { isEmail } from '@evidentid/universal-framework/validation';
    import Icon from '@/components/common/Icon.vue';
    import ChipList from '@/components/forms/ChipList.vue';

    @Component({
        components: { ChipList, Icon },
    })
    export default class EmailList extends Vue {
        @Prop({ type: Array, default: () => [] })
        private value!: string[];

        @Prop({ type: String, default: 'Add an email' })
        private placeholder!: string;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        private isEmail = isEmail;
        private delimiterRegex = /[^a-zA-Z0-9@^.|?*+!{$#%&'\-\/=_`}~]+/g;
    }
</script>
