<template>
    <form
        class="AddDataSubjectInput"
        :class="{ 'AddDataSubjectInput--invalid': !valid }"
        @submit.prevent="onSubmit"
    >
        <input
            ref="input"
            type="email"
            :value="value"
            class="AddDataSubjectInput__input"
            @input="onInput"
        >
        <button
            type="submit"
            class="AddDataSubjectInput__button AddDataSubjectInput__button--accept"
            :disabled="!valid"
        >
            <Icon name="checkmark" />
        </button>
        <button
            type="button"
            class="AddDataSubjectInput__button AddDataSubjectInput__button--cancel"
            @click="onReset"
        >
            <Icon name="x" />
        </button>
    </form>
</template>

<style lang="less">
    .AddDataSubjectInput {
        display: flex;

        &__input {
            font: inherit;
            flex: 1 auto;
        }

        &__button {
            align-self: center;
            background: transparent;
            padding: 5px 15px;
            margin: 0;
            border: 0;
            border-right: 1px solid #dadada;

            &:not(:disabled) {
                cursor: pointer;
            }

            &--accept {
                color: #2ab496;
            }

            &--cancel {
                color: #ff4646;
            }

            &:last-child {
                border-right: 0;
            }
        }

        &--invalid &__button--accept {
            color: #dadada;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
    import { isEmail } from '@evidentid/universal-framework/validation';
    import Icon from '@/components/common/Icon.vue';

    @Component({
        components: { Icon },
    })
    export default class AddDataSubjectInput extends Vue {
        @Prop({ type: String, default: '' })
        private value!: string;

        @Ref()
        private input!: HTMLInputElement;

        private focusTimeout: any = null;

        private stopAutoFocus(): void {
            clearTimeout(this.focusTimeout);
        }

        private destroyed(): void {
            this.stopAutoFocus();
        }

        public focus(): void {
            if (this.input) {
                this.stopAutoFocus();
                this.focusTimeout = setTimeout(() => this.input?.focus());
            }
        }

        private get valid(): boolean {
            return isEmail(this.value);
        }

        private onInput(event: InputEvent): void {
            this.$emit('input', (event.target as HTMLInputElement).value);
        }

        private onSubmit(): void {
            if (this.valid) {
                this.$emit('submit');
            }
        }

        private onReset(): void {
            this.$emit('reset');
        }
    }
</script>

