export enum IdoRequestStatus {
    loading = 'loading',
    error = 'error',
    success = 'success',
}

export enum IdoRequestStatusTimelineEntryStatus {
    ongoing = 'ongoing',
    success = 'success',
    error = 'error',
    disabled = 'disabled',
}

export interface IdoRequestStatusTimelineEntry {
    status?: IdoRequestStatusTimelineEntryStatus | null;
    title: string;
    description?: string | null;
    metadata?: Record<string, any>;
}
