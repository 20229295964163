<template>
    <div class="ExternalProvider">
        <div class="ExternalProvider__instructions">
            Verify one of your utility providers by logging into your service account.

            <div class="ExternalProvider__workflow">
                <div class="ExternalProvider__row">
                    <span>Search for a utility provider:</span>
                    <AutoComplete
                        id="ext-providers"
                        placeholder="Utility providers"
                        :matches="matches"
                        :value="provider"
                        :build-label="buildLabel"
                        @typed="onAutoCompleteTyped"
                        @input="onProviderChange"
                    />
                </div>

                <div class="ExternalProvider__row">
                    <template v-if="provider">
                        <span>Log in to your {{ provider.displayName }} account:</span>
                        <div class="CredentialsForm">
                            <div class="CredentialsForm__field">
                                {{ provider.displayName }} username or email
                                <input v-model="username" type="text" @input="onChange">
                            </div>
                            <div class="CredentialsForm__field">
                                {{ provider.displayName }} password
                                <input v-model="password" type="password" @input="onChange">
                            </div>
                        </div>
                    </template>

                    <div v-else-if="matches.length === 0 && inputValue !== ''" class="ProviderNotFound">
                        <h2 class="ProviderNotFound__heading">
                            <Icon name="alert" /> Sorry, this utility provider is not available
                        </h2>
                        <p class="ProviderNotFound__body">
                            Please try searching for another of your utility providers.
                            If none of your providers are available, please contact us
                            at <a href="mailto:support@evidentid.com">support@evidentid.com</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';
    import AutoComplete from '@/components/forms/AutoComplete.vue';
    import UtilityProvider from '@/interfaces/UtilityProvider';
    import UtilityCredentials from '@/fields/interfaces/UtilityCredentials';
    import FieldComponent from '@/fields/FieldComponent';

    @Component({
        components: { Icon, AutoComplete },
    })
    export default class ExternalProvider extends FieldComponent<UtilityCredentials> {
        private matches: UtilityProvider[] = [];

        private provider: UtilityProvider | null = (this.value && this.value.provider) || null;
        private inputValue: string = this.buildLabel(this.provider);
        private username: string = (this.value && this.value.username) || '';
        private password: string = (this.value && this.value.password) || '';

        private get providers() {
            return this.$store.state.utilityProviders || [];
        }

        private buildLabel(provider: UtilityProvider | null) {
            return (provider && provider.displayName) || '';
        }

        private onAutoCompleteTyped(value: string) {
            this.inputValue = value;
        }

        private onProviderChange(provider: UtilityProvider | undefined) {
            this.provider = provider || null;
            this.username = '';
            this.password = '';
            this.onChange();
        }

        private onChange() {
            this.$emit('change', {
                provider: this.provider,
                username: this.username,
                password: this.password,
            });
        }

        @Watch('providers')
        @Watch('inputValue')
        private updateMatches() {
            const name = (this.inputValue || '').toLowerCase();

            this.matches = (this.providers || [])
                .filter((provider) => provider.displayName.toLowerCase().indexOf(name) !== -1)
                .slice(0, 10);
        }

        @Watch('value')
        private onExternalValueChange(value: UtilityCredentials | null) {
            const nextProvider = (value && value.provider) || null;
            if (nextProvider !== this.provider) {
                this.provider = nextProvider;
                this.inputValue = this.buildLabel(nextProvider);
            }

            this.username = (value && value.username) || '';
            this.password = (value && value.password) || '';
        }
    }
</script>
