<template>
    <div class="LargeFormatIdScanForm">
        <div class="LargeFormatIdScanForm__methods">
            <div class="LargeFormatIdScanForm__form">
                <RetryDescription />
            </div>
            <div class="LargeFormatIdScanForm__delegation">
                <h2 class="LargeFormatIdScanForm__title">
                    I already have photos that I can upload
                </h2>
                <p class="LargeFormatIdScanForm__subtitle">
                    Please be sure the photos you're uploading
                    meet the requirements described on this page.
                </p>

                <DocumentInput
                    :available-document-types="availableDocumentTypes"
                    :value="value"
                    @input="onChange"
                    @invalid="onCorruptedFile"
                />

                <a href="#" class="LargeFormatIdScanForm__uploadLink" @click="toggleDesktopUpload">
                    I'd rather take new photos with my mobile device
                </a>
            </div>
        </div>
        <QuestionFormControls v-bind="$props" @back="onBack" @submit="onSubmit" />
        <IdScanLoadingOverlay
            v-if="submitting"
            v-bind="$props"
            :progress="uploadProgress"
        />
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import QuestionFormControls from '@/components/forms/QuestionFormControls.vue';
    import RetryDescription from './RetryDescription.vue';
    import IdScanLoadingOverlay from '@/components/fields/IdScan/IdScanLoadingOverlay.vue';
    import { VerificationResult } from '@/store/interfaces/IdoState';
    import DocumentInput from '@/components/forms/DocumentInput.vue';
    import IdScanField from '@/fields/IdScanField';
    import { DocumentType } from '@evidentid/ido-lib/interfaces/Question';

    @Component({
        inheritAttrs: false,
        components: {
            DocumentInput,
            IdScanLoadingOverlay,
            RetryDescription,
            QuestionFormControls,
        },
    })
    export default class IdScanSinglePageUploadForm extends FieldComponent<any> {
        private uploadProgress = 0;

        private get isIdScanVerificationEnabled() {
            return Boolean(this.$store.state.featureFlags.idverify_document_capture_retry_enabled);
        }

        private get availableDocumentTypes(): DocumentType[] {
            return (this.field as IdScanField).getPossibleDocumentTypes(this.question);
        }

        private toggleDesktopUpload(event: Event) {
            event.preventDefault();
            this.$emit('change', {
                ...this.value,
                currentPage: 0,
                pages: [],
                useSinglePageUpload: false,
            });
        }

        private onCorruptedFile() {
            this.$store.dispatch('displaySnackbar', {
                message: 'An error occurred processing this file. Please try again or use a different file',
            });
        }

        private onBack() {
            this.$emit('back', false);
        }

        private onChange(value: any) {
            this.$emit('change', {
                ...value,
                useSinglePageUpload: true,
            });
        }

        private onSubmit() {
            if (!this.isIdScanVerificationEnabled) {
                return this.legacySubmit();
            }

            const onFinish = () => {
                // Fall back to legacy submission, when there is something wrong with the back-end
                const result = this.verificationResult;
                if (result === VerificationResult.timeout || result === VerificationResult.error) {
                    console.error(`Falling back to legacy submission, result: ${result}`);
                    this.legacySubmit();
                }
            };

            this.resetUploadProgress();
            this.$emit('verify', onFinish, this.updateUploadProgress.bind(this));
        }

        private legacySubmit() {
            this.resetUploadProgress();
            this.$emit('submit', undefined, this.updateUploadProgress.bind(this));
        }

        private resetUploadProgress() {
            this.uploadProgress = 0;
        }

        private updateUploadProgress(progress: number) {
            this.uploadProgress = progress;
        }
    }
</script>
