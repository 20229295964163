<template>
    <label class="LineInputBlock">
        {{ question.metadata.description }}
        <Dropdown
            id="dl-state"
            v-model="inputValue"
            :options="states"
            :build-label="buildStateLabel"
            @input="onChange"
        />
    </label>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import Dropdown from '@/components/forms/Dropdown.vue';
    import { stateIds, statesMap } from '@/config/states';

    @Component({
        components: { Dropdown },
    })
    export default class UsState extends FieldComponent<string> {
        private states = stateIds;
        private inputValue = this.value || null;

        private buildStateLabel(value: string) {
            return statesMap[value];
        }

        @Watch('value')
        private onValueChange(value: string) {
            this.inputValue = value || null;
        }

        private onChange() {
            this.$emit('change', this.inputValue);
        }
    }
</script>
