import Vue, { Component as VueComponent } from 'vue';
import noop from 'lodash/noop';
import { VueRouter } from 'vue-router/types/router';
import getTransitionState, { TransitionState } from '@/utils/getTransitionState';
import getTransitionRoute from '@/utils/getTransitionRoute';

export default function withExpectedTransitionState(transitionState: TransitionState, Component: VueComponent) {
    function redirect(actualTransitionState: TransitionState, router: VueRouter) {
        if (actualTransitionState !== transitionState) {
            router.push(getTransitionRoute(actualTransitionState)).catch(noop);
        }
    }

    return Vue.component('withExpectedTransitionState', {
        data(): { unsubscribe: (() => void) | null } {
            return {
                unsubscribe: null,
            };
        },

        computed: {
            transitionState() {
                return getTransitionState(this.$store.state);
            },
        },

        beforeUpdate() {
            redirect(this.transitionState, this.$router);
        },

        created() {
            redirect(this.transitionState, this.$router);
        },

        mounted() {
            this.unsubscribe = this.$store.subscribe(() => this.$forceUpdate());
        },

        destroyed() {
            if (this.unsubscribe) {
                this.unsubscribe();
            }
        },

        render(createElement) {
            return createElement(Component);
        },
    });
}
