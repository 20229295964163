export interface RegularSessionData {
    rprId: string;
    canary: string;
    continueUrl: string | null;
    enableReturn: boolean;
}

export interface GlsSessionData {
    caseId: number;
    authCode: string | null;
}

type SessionData = RegularSessionData | GlsSessionData;

export function isGlsSession(sessionData: SessionData): sessionData is GlsSessionData {
    return 'caseId' in sessionData && Boolean(sessionData.caseId);
}

export function isRegularSession(sessionData: SessionData): sessionData is RegularSessionData {
    return !isGlsSession(sessionData);
}

export default SessionData;
