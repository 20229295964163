<template>
    <PersistentFailureScreen v-if="error === 'PERSISTENT_FAILURE'" v-bind="$props" v-on="$listeners" @retry="onRetry" />
    <GlareErrorScreen v-else-if="error === 'GLARE'" :value="value" v-on="$listeners" @retry="onRetry" />
    <BlurryErrorScreen v-else-if="error === 'SHARPNESS'" :value="value" v-on="$listeners" @retry="onRetry" />
    <BlurryGlareErrorScreen v-else-if="error === 'GLARE_SHARPNESS'" :value="value" v-on="$listeners" @retry="onRetry" />
    <UnknownErrorScreen v-else-if="error" :value="value" v-on="$listeners" @retry="onRetry" />
    <IdScanSinglePageUploadForm v-else-if="value && value.useSinglePageUpload" v-bind="$props" v-on="$listeners" />
    <IdScanDelegationForm v-else-if="!isMobile" v-bind="$props" v-on="$listeners" />
    <IdScanForm v-else v-bind="$props" v-on="$listeners" />
</template>

<script lang="ts">
    import { Component, Prop, Watch } from 'vue-property-decorator';
    import support from '@/config/support';
    import FieldComponent from '@/fields/FieldComponent';
    import IdScanDelegationForm from '@/components/fields/IdScan/IdScanDelegationForm.vue';
    import IdScanForm from '@/components/fields/IdScan/IdScanForm.vue';
    import UnknownErrorScreen from '@/components/fields/IdScan/UnknownErrorScreen.vue';
    import { VerificationFailureReason, VerificationResult } from '@/store/interfaces/IdoState';
    import * as verificationConfig from '@/config/attributesVerification';
    import GlareErrorScreen from '@/components/fields/IdScan/GlareErrorScreen.vue';
    import BlurryErrorScreen from '@/components/fields/IdScan/BlurryErrorScreen.vue';
    import BlurryGlareErrorScreen from '@/components/fields/IdScan/BlurryGlareErrorScreen.vue';
    import PersistentFailureScreen from '@/components/fields/IdScan/PersistentFailureScreen.vue';
    import IdScanSinglePageUploadForm from '@/components/fields/IdScan/IdScanSinglePageUploadForm.vue';

    @Component({
        inheritAttrs: false,
        components: {
            IdScanSinglePageUploadForm,
            PersistentFailureScreen,
            BlurryGlareErrorScreen,
            BlurryErrorScreen,
            GlareErrorScreen,
            UnknownErrorScreen,
            IdScanForm,
            IdScanDelegationForm,
        },
    })
    export default class IdScan extends FieldComponent<any> {
        @Prop({ type: Boolean, default: support.isMobileBrowser })
        private isMobile!: boolean;

        @Prop({ type: Number, default: verificationConfig.persistentFailureTimes })
        private persistentFailureTimes!: number;

        private get error(): VerificationFailureReason | 'PERSISTENT_FAILURE' | null {
            if (this.verificationResult !== VerificationResult.failed) {
                return null;
            }

            return this.verificationsFailed >= this.persistentFailureTimes
                ? 'PERSISTENT_FAILURE'
                : this.verificationFailureReason || VerificationFailureReason.unknown;
        }

        private destroyed() {
            if (this.verificationResult === VerificationResult.failed) {
                this.onRetry();
            }
        }

        private onRetry() {
            this.$emit('change', {
                ...this.value,
                currentPage: 0,
                pages: [],
            });
        }

        @Watch('value.currentPage')
        @Watch('verificationResult')
        private scrollToTop() {
            window.scrollTo(window.scrollX, 0);
        }
    }
</script>
