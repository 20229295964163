// Initialize sentry before everything
import './sentry';

// Load polyfills
import 'blueimp-canvas-to-blob';
import './polyfills/ie11-compatibility-fixes.ts';

// Load other libraries
import Vue from 'vue';
import createMetaNavigationGuard from '@evidentid/vue-commons/router/createMetaNavigationGuard';
import idoApplication from '@/idoApplication';
import createEventHandlerForBlockingRefreshDuringSubmission
    from '@/utils/createEventHandlerForBlockingRefreshDuringSubmission';
import PortalVue from 'portal-vue';

Vue.config.productionTip = false;

if (process.env.NODE_ENV !== 'production') {
    /* eslint-disable */
    // @ts-ignore
    const VueAxe = require('vue-axe');
    /* eslint-enable */
    Vue.use(VueAxe, {
        clearConsoleOnUpdate: false,
        config: {
            runOnly: {
                type: 'tag',
                values: [ 'wcag21aa' ],
            },
        },
    });
}

Vue.use(PortalVue);

if (idoApplication.router) {
    idoApplication.router.beforeEach(createMetaNavigationGuard('Evident', document));
}

// Find 'root' element
const rootElement = document.querySelector('#root');

// Mount application
if (rootElement) {
    idoApplication.mount(rootElement);
} else {
    throw new Error('Unable to find #root element.');
}

if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line
    // @ts-ignore: attaching application instance to window
    window.idoApp = idoApplication;

    require('@evidentid/vue-commons/development/hackDevelopmentHmr');
}

// Side effect: ensure that user will not leave during submission.
// Unfortunately, Safari on iOS is not supporting it.
window.onbeforeunload = createEventHandlerForBlockingRefreshDuringSubmission(idoApplication.store!);
