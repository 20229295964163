import { buildDigitRegex } from './buildDigitRegex';

// FIXME: It could have more rules
const einNumberRegex = buildDigitRegex(`
    ^
        [0-9]{9}
    $
`);

const einPatterns = [
    /^\d{9}$/,
    /^\d{2}-\d{7}$/,
    /^\d{2}\s+\d{7}$/,
];

function isSanitizedEinValid(sanitizedEin: null): false;
function isSanitizedEinValid(sanitizedEin: string | null): boolean;
function isSanitizedEinValid(sanitizedEin: string | null): boolean {
    return sanitizedEin !== null && einNumberRegex.test(sanitizedEin);
}

export function sanitizeEin(ein: null): null;
export function sanitizeEin(ein: string | null): string | null;
export function sanitizeEin(ein: string | null): string | null {
    if (typeof ein !== 'string') {
        return null;
    } else if (!einPatterns.find((pattern) => pattern.test(ein))) {
        return null;
    }
    const sanitizedEin = ein.replace(/\D+/g, '');
    return isSanitizedEinValid(sanitizedEin) ? sanitizedEin : null;
}

export function isValidEin(ein: null): false;
export function isValidEin(ein: string | null): boolean;
export function isValidEin(ein: string | null): boolean {
    return sanitizeEin(ein) !== null;
}
