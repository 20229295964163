<template>
    <div class="WeddingCake">
        <div class="WeddingCake__body SuccessScreen">
            <img class="WeddingCake__ornament" :src="iconUrl">
            <div class="WeddingCake__layers">
                <slot />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import successIconUrl from '@evidentid/ido-lib/images/SuccessIcon.svg';

    @Component
    export default class SuccessScreen extends Vue {
        private iconUrl = successIconUrl;
    }
</script>
