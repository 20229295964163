import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import StatementOfTruthWithConsent from '@/interfaces/insurance/StatementOfTruthWithConsent';
import StatementOfTruth from '@/components/fields/StatementOfTruth.vue';

export default class StatementOfTruthField extends Field<StatementOfTruthWithConsent> {
    public override component = StatementOfTruth;

    public isValid(_: Question, value: StatementOfTruthWithConsent): boolean {
        return value !== null && value !== undefined && typeof value.value === 'boolean'
            && typeof value.consentText === 'string' && value.consentText.length > 0;
    }

    public override getDefaultValue(question: Question): StatementOfTruthWithConsent {
        return {
            value: false,
            consentText: '',
        };
    }

    public async encode(question: Question, value: StatementOfTruthWithConsent): Promise<AttributesValues> {
        return this._encode(question, { ...value, $objectType: 'BrokerStatementOfTruth' });
    }
}
