/**
 * Create SVG data URL image, which will have scaleX:scaleY proportions.
 * It is multiplied, to simplify using max-width/max-height CSS properties.
 *
 * @param {number} scaleX
 * @param {number} scaleY
 * @returns {string}
 */
export function createAspectRatioSvgDataUrl(scaleX: number, scaleY: number): string {
    const multiplier = 1000;
    const width = scaleX * multiplier;
    const height = scaleY * multiplier;
    const attributes = `width='${width}' height='${height}' viewBox='0 0 ${width} ${height}'`;
    return `data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' ${attributes}></svg>`;
}
