import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import SelectOptionComponent from '@/components/fields/SelectOption.vue';

export default class SelectOptionField extends Field<string> {
    public override component = SelectOptionComponent;

    public isValid(question: Question, value: string): boolean {
        const options = JSON.parse(question.metadata?.constraints || '{}')?.options || [];
        return Boolean(value && options.includes(value));
    }

    public async encode(question: Question, value: string): Promise<AttributesValues> {
        return this._encode(question, value);
    }
}
