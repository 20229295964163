import { support } from '@evidentid/browser-framework/supportSingleton';
import xhr from '@evidentid/browser-framework/xhr';

const _noSession = () => Promise.reject(new Error('Session not established.'));

// Will change to a client if this module is properly set up.
let _makeRequest = _noSession;
let _$window = window;
const _makeGuestRequest = xhr.extend(xhr.json, (o) => ({
    url: `${deploy.WEB_PUBLIC_IDOWEB_SERVICE_URL_PREFIX}${o.url}`,
}));

export function setSessionSource($window) {
    _$window = $window;
}

// Used to access session data in local storage
export const SESSION_STORAGE_KEY = '__idoweb-session';

export function getSessionData() {
    try {
        return {
            ...JSON.parse(_$window.sessionStorage.getItem(SESSION_STORAGE_KEY)),
            continueUrl: _$window.sessionStorage.continue_url,
        };
    } catch (e) {
        return null;
    }
}

export function setSessionData(session) {
    const { kwargs } = window.spa ? window.spa.router.procure() : {};

    // Capture canary override.
    if (kwargs && kwargs.canary) {
        session.canary = kwargs.canary;
    }

    if (support.sessionStorage) {
        _$window.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(session));
    }
}

export function exchangeSingleUseToken(singleUseToken) {
    return xhr.json({
        method: 'POST',
        url: `${deploy.WEB_PUBLIC_IDOWEB_SERVICE_URL_PREFIX}/auth`,
        config(xo) {
            xo.withCredentials = true;
            xo.setRequestHeader('Authorization', `Bearer ${singleUseToken}`);
        },
    }).then(({ canaryToken }) => canaryToken);
}

// This tells us what forms to generate.
export function getVerificationRequestDetails(requestId) {
    return _makeRequest({
        url: `/requests/${requestId}`,
    });
}

// This tells us about the customer interested in the submission, such as their branding info.
export function getIdentity(rpId) {
    return _makeRequest({
        url: `/identity/distinguishedNames?dn=${encodeURIComponent(rpId)}&includeConsentText=true`,
    });
}

// Returns a list of utility providers against which the IDO may authenticate.
export function getUtilityProviders() {
    return _makeRequest({
        method: 'GET',
        url: '/providers/utility',
    }).then(({ providers }) => providers);
}

export function getAttributeData() {
    return _makeRequest({
        method: 'GET',
        url: '/attributeData',
    }).then((result) => result);
}

export function getAcademicProviders() {
    return _makeRequest({
        method: 'GET',
        url: '/providers/academic',
    }).then(({ providers }) => providers);
}

export function verifyDocumentImages(datumIds) {
    const queryString = datumIds
        .map((datumId) => `datumId=${encodeURIComponent(datumId)}`)
        .join('&');

    return _makeRequest({
        method: 'GET',
        url: `/IDReadabilityCheck?${queryString}`,
    });
}

// Send another authenticated session to a delegate device that we assume
// belongs to a valid custodian of the same info as the current user.
export function notifyDelegate({
    continueUrl = null,
    enableReturn = false,
    recipient,
    method,
    authToken,
}) {
    return _makeRequest({
        method: 'POST',
        url: '/notifications',
        data: {
            notificationMethod: method,
            continueUrl,
            enableReturn,
            recipient,
            authToken,
        },
    });
}

// Sends a Stripe token upstream to carry out a transaction.
export function submitPayment(token) {
    return _makeRequest({
        method: 'POST',
        url: '/payments',
        data: {
            paymentToken: token.id,
        },
    });
}

// Used to mark an IDO's acceptance in attribute sharing agreements
export function sendConsent(rprId) {
    return _makeRequest({
        method: 'PUT',
        url: `/requests/${rprId}/consent`,
        data: {
            reply: 'accepted',
        },
    });
}

export function requestTransition(rprId) {
    return _makeRequest({
        method: 'GET',
        url: `/requests/${rprId}/requestTransition`,
    });
}

// Sends attribute values for verification
export function submitWebAttributes(data) {
    return _makeRequest({
        method: 'POST',
        url: '/attributeData',
        data,
    });
}

// Grabs countries for a dropdown. A falsey region will yield all countries.
export function getCountriesInRegion(region) {
    return _makeRequest({
        method: 'GET',
        url: (region)
            ? `/countryMappings/${region}`
            : '/countryMappings',
    }).then(({ countries }) =>
        // [{ '<CODE>': '<NAME>' }, ...] --becomes--> [['<CODE>', '<NAME>'], ...],
        // with blank names removed.
        countries
            .map((o) => Object.entries(o).pop())
            .filter(([ , name ]) => Boolean(name))
            .sort((a, b) => a[1].localeCompare(b[1])),
    );
}

// Binds a session to a client for AJAX.
export function setUp(session) {
    setSessionData(session);

    _makeRequest = xhr.extend(xhr.json, (o) => ({
        url: `${deploy.WEB_PUBLIC_IDOWEB_SERVICE_URL_PREFIX}${o.url}`,
        config(xo) {
            xo.withCredentials = true;
            xo.setRequestHeader('X-CSRFToken', session.canary);
            if (o.headers) {
                Object.entries(o.headers).forEach(([ key, val ]) => xo.setRequestHeader(key, val));
            }
        },
    }));
}

export function undoSetUp() {
    if (_$window && support.sessionStorage) {
        _$window.sessionStorage.removeItem(SESSION_STORAGE_KEY);
    }

    _makeRequest = _noSession;
}

// Destroy service binding.
export function tearDown() {
    undoSetUp();

    // This request unsets the session cookie.
    // Set withCredentials to true so the browser accepts the change.
    return _makeGuestRequest({
        method: 'DELETE',
        url: '/auth',
        config(xo) {
            xo.withCredentials = true;
        },
    });
}

export function getIdoProfile() {
    return _makeRequest({
        method: 'GET',
        url: '/userInfo',
    }).then(({ auth_domain: ad, email }) => ({
        email,
        authDomain: ad.domain,
    }));
}

export function queryChatBot(question, sessionId) {
    return _makeRequest({
        method: 'POST',
        url: '/chatbots/10c0e358-0820-4462-8c05-24922320163d/completions',
        data: {
            question: {
                content: question,
            },
        },
        headers: {
            'Session-Id': sessionId,
        },
    });
}
