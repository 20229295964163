import Vue from 'vue';
import Vuex from 'vuex';
import buildInitialState from './buildInitialState';
import mutations from './mutations';
import actions from './actions';
import { createStore } from '@evidentid/vue-commons/store';

Vue.use(Vuex);

export default function createVuexStore() {
    return createStore({
        state: buildInitialState(),
        mutations,
        actions,
        strict: process.env.NODE_ENV !== 'production',
    });
}
