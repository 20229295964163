<template>
    <div>
        <label class="LineInputBlock">
            <span>Jurisdiction</span>
            <Dropdown
                id="dl-state"
                v-model="jurisdiction"
                placeholder="Select a Jurisdiction"
                :options="states"
                :build-label="buildStateLabel"
                @input="onChange"
            />
        </label>
        <label class="LineInputBlock">
            <span>Year admitted to practice law</span>
            <input
                v-model="yearAdmitted"
                type="text"
                @input="onChange"
            >
        </label>
        <label class="LineInputBlock">
            <span>Attorney License Number (if applicable)</span>
            <input
                v-model="licenseNumber"
                type="text"
                @input="onChange"
            >
        </label>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import Dropdown from '@/components/forms/Dropdown.vue';
    import AttorneyLicenseDetails from '@/fields/interfaces/AttorneyLicenseDetails';
    import { stateIds, statesMap } from '@/config/states';

    @Component({
        components: { Dropdown },
    })
    export default class BarLicenseDetails extends FieldComponent<AttorneyLicenseDetails> {
        private states = stateIds;
        private jurisdiction = (this.value && this.value.jurisdiction) || null;
        private yearAdmitted = (this.value && this.value.yearAdmitted) || '';
        private licenseNumber = (this.value && this.value.licenseNumber) || '';

        private buildStateLabel(value: string) {
            return statesMap[value];
        }

        @Watch('value')
        private onValueChange(value: AttorneyLicenseDetails) {
            this.jurisdiction = (value && value.jurisdiction) || null;
            this.yearAdmitted = (value && value.yearAdmitted) || '';
            this.licenseNumber = (value && value.licenseNumber) || '';
        }

        private onChange() {
            this.$emit('change', {
                jurisdiction: this.jurisdiction,
                yearAdmitted: this.yearAdmitted,
                licenseNumber: this.licenseNumber,
            });
        }
    }
</script>
