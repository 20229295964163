<template>
    <div class="LogoFooter" :class="{'LogoFooter--withLinks': footerLinks && footerLinks.length>0}">
        <div class="LogoFooter__logo">
            <slot>
                Powered by
                <img class="LogoFooter__branding" :src="logoUrl" alt="Evident ID">
            </slot>
        </div>
        <div v-if="footerLinks" class="LogoFooter__links" :class="{'LogoFooter__links--vertical': verticalMenu}">
            <div v-for="(link,index) of footerLinks" :key="index" class="LogoFooter__link">
                <a :href="link.href" target="_blank">
                    {{ link.label }}
                </a>
            </div>
        </div>
    </div>
</template>
<style lang="less">
    .LogoFooter {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        &__logo {
            display: flex;
            align-items: center;
            min-width: 200px;
            justify-content: center;
            margin-top: 5px;
        }

        &__branding {
            height: 1.7em;
            margin-left: 10px;
        }

        &__links {
            margin-top: 5px;
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;
        }

        &__link {
            &:not(:first-child) {
                border-left: 1px solid;
            }

            &:last-child a {
                margin-right: 0;
            }

            a {
                margin: 0 20px;
                color: #2d3e50;
                font-size: 0.9em;
                font-weight: bold;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
            }
        }

        &__links--vertical {
            width: 100%;
            flex-direction: column;
            justify-content: center;
            margin-top: 10px;
        }

        &__links--vertical &__link {
            border: none;
            margin-top: 5px;

            &:last-child a {
                margin-right: 20px;
            }
        }

        @media (min-width: 562px) and (max-width: 774px) {
            &:after {
                content: "";
                flex: auto;
            }
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import evidentWordmarkDarkSvgUrl from '@evidentid/assets-bridge/evident-wordmark.svg';

    @Component
    export default class LogoFooter extends Vue {
        @Prop({ type: String, default: evidentWordmarkDarkSvgUrl })
        private logoUrl!: string;

        @Prop({ type: Array, default: () => [] })
        private links!: { label: string, href: string }[];

        @Prop({ type: Number, default: 562 })
        private verticalMenuAt!: number | null;
        private verticalMenu: boolean = false;

        private get footerLinks(): { label: string, href: string }[] {
            if (this.links.length > 0) {
                return this.links;
            }
            const learnMoreLink = this.$store.state?.issuer?.privacyPolicy?.url ||
                'https://www.evidentid.com/secure-online-identity-verification/';
            return [ {
                label: 'LEARN MORE ABOUT EVIDENT',
                href: learnMoreLink,
            }, {
                label: 'PRIVACY POLICY',
                href: 'https://www.evidentid.com/privacy-policy/',
            }, {
                label: 'TERMS AND CONDITIONS',
                href: 'https://www.evidentid.com/terms-of-use/',
            } ];
        }

        private created() {
            window.addEventListener('resize', this.onResize);
        }

        private mounted() {
            this.onResize();
        }

        private destroyed() {
            window.removeEventListener('resize', this.onResize);
        }

        private onResize() {
            if (this.verticalMenuAt) {
                this.verticalMenu = window.screen.width <= this.verticalMenuAt;
            }
        }
    }
</script>
