import Vue, { Component as VueComponent } from 'vue';
import Error from '@/views/Error.vue';

export default function withErrorHandler(Component: VueComponent) {
    return Vue.component('withErrorHandler', {
        render(createElement) {
            return this.$store.state.error
                ? createElement(Error)
                : createElement(Component);
        },
    });
}
