<template>
    <label class="LineInputBlock">
        7-digit MMC reference number
        <input v-model="inputValue" type="text" @input="onChange">
    </label>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';

    @Component
    export default class MmcReferenceNumber extends FieldComponent<string> {
        private inputValue = this.value || '';

        @Watch('value')
        private onValueChange(value: string) {
            this.inputValue = value;
        }

        private onChange() {
            this.$emit('change', this.inputValue);
        }
    }
</script>
