<template>
    <div v-if="submitting" class="LivenessLoader">
        <AlternativeSpinner>
            Submitting verification
        </AlternativeSpinner>
    </div>
    <div v-else class="LivenessQuestion">
        <h3 class="LivenessQuestion__title">
            Get ready for your video selfie
        </h3>
        <p class="LivenessQuestion__description">
            In order to verify your identity we need to compare a live selfie to the photograph on your identity document.
        </p>

        <div class="LivenessQuestion__tips">
            <div class="LivenessQuestion__tip">
                <img class="LivenessQuestion__tipImage" aria-hidden="true" :src="instructionValidUrl" alt="">
                <div class="LivenessQuestion__tipContent">
                    Use the on-screen guide to frame your face in the selfie photograph.
                </div>
            </div>
            <div class="LivenessQuestion__tip">
                <img class="LivenessQuestion__tipImage" aria-hidden="true" :src="instructionGlareUrl" alt="">
                <div class="LivenessQuestion__tipContent">
                    Must be well-lit, but not washed out. Avoid strong light or backlight.
                </div>
            </div>
            <div class="LivenessQuestion__tip">
                <img class="LivenessQuestion__tipImage" aria-hidden="true" :src="instructionCoverUrl" alt="">
                <div class="LivenessQuestion__tipContent">
                    Please remove any eyewear and make sure your face is not covered.
                </div>
            </div>
            <div class="LivenessQuestion__tip">
                <img class="LivenessQuestion__tipImage" aria-hidden="true" :src="instructionSmileUrl" alt="">
                <div class="LivenessQuestion__tipContent">
                    Please don't smile. Use a neutral facial expression only.
                </div>
            </div>
        </div>

        <Button v-if="loading" class="LivenessQuestion__button" type="primary" disabled>
            Please read the instructions...
        </Button>
        <Button v-else class="LivenessQuestion__button" type="primary" @click="verify">
            Continue
        </Button>
    </div>
</template>

<script lang="ts">
    import { Component, Prop } from 'vue-property-decorator';
    import { FaceTecSDKStatus, FaceTecSessionStatus, LivenessCheckResult } from '@evidentid/zoom-sdk/types';
    import type FaceTecWrapper from '@evidentid/zoom-sdk/internals/FaceTecWrapper';
    import { AbortError, initialize } from '@evidentid/zoom-sdk';
    import * as facetecConfig from '@/config/facetec';
    import Button from '@/components/common/Button.vue';
    import FieldComponent from '@/fields/FieldComponent';
    import Liveness from '@/interfaces/Liveness';
    import { RegularSessionData } from '@/interfaces/SessionData';
    import AlternativeSpinner from '@/components/common/AlternativeSpinner.vue';
    import instructionCoverUrl from '@/assets/liveness-instruction-cover.svg';
    import instructionGlareUrl from '@/assets/liveness-instruction-glare.svg';
    import instructionSmileUrl from '@/assets/liveness-instruction-smile.svg';
    import instructionValidUrl from '@/assets/liveness-instruction-valid.svg';
    import support from '@/config/support';

    const MAX_TRIES = 5;
    const ZOOM_IGNORED_ERRORS: FaceTecSessionStatus[] = [
        FaceTecSessionStatus.ContextSwitch,
        FaceTecSessionStatus.ProgrammaticallyCancelled,
        FaceTecSessionStatus.UserCancelled,
        FaceTecSessionStatus.UserCancelledFromNewUserGuidance,
        FaceTecSessionStatus.UserCancelledFromRetryGuidance,
        FaceTecSessionStatus.UserCancelledWhenAttemptingToGetCameraPermissions,
        FaceTecSessionStatus.UserCancelledViaClickableReadyScreenSubtext,
        FaceTecSessionStatus.UserCancelledFullScreenMode,
    ];

    const ZOOM_FATAL_ERRORS: FaceTecSessionStatus[] = [
        FaceTecSessionStatus.MissingGuidanceImages,
        FaceTecSessionStatus.InitializationNotCompleted,
        FaceTecSessionStatus.StillLoadingResources,
        FaceTecSessionStatus.DocumentNotReady,
        FaceTecSessionStatus.UnknownInternalError,
        FaceTecSessionStatus.NonProductionModeDeviceKeyIdentifierInvalid,
        FaceTecSessionStatus.NotAllowedUseIframeConstructor,
        FaceTecSessionStatus.NotAllowedUseNonIframeConstructor,
        FaceTecSessionStatus.IFrameNotAllowedWithoutPermission,
        FaceTecSessionStatus.ResourcesCouldNotBeLoadedOnLastInit,
    ];

    const ZOOM_ERROR_MESSAGES: Partial<Record<FaceTecSessionStatus, string>> = {
        [FaceTecSessionStatus.Timeout]:
            'The request has expired, please try again.',
        [FaceTecSessionStatus.OrientationChangeDuringSession]:
            'The screen orientation has been changed during session, please try again.',
        [FaceTecSessionStatus.LandscapeModeNotAllowed]:
            'Please hold your device in portrait mode and try again.',
        [FaceTecSessionStatus.LockedOut]:
            'Too many requests encountered, please try again in few minutes.',
        [FaceTecSessionStatus.CameraNotEnabled]:
            'Please enable your camera and try again.',
        [FaceTecSessionStatus.CameraNotRunning]:
            'Please enable your camera and try again.',
        [FaceTecSessionStatus.SessionInProgress]:
            'There was already a video selfie session running, please finish the previous one first.',
    };

    const ZOOM_INIT_ERROR_PAGE_CODES: Partial<Record<FaceTecSDKStatus, string>> = {
        [FaceTecSDKStatus.NetworkIssues]: 'network-outage',
        [FaceTecSDKStatus.DeviceInLandscapeMode]: 'facetec-device-in-landscape-mode',
        [FaceTecSDKStatus.DeviceLockedOut]: 'facetec-device-locked-out',
    };

    @Component({
        components: { Button, AlternativeSpinner },
    })
    export default class LivenessCheck extends FieldComponent<Liveness> {
        private instructionCoverUrl = instructionCoverUrl;
        private instructionGlareUrl = instructionGlareUrl;
        private instructionSmileUrl = instructionSmileUrl;
        private instructionValidUrl = instructionValidUrl;
        private isMounted = false;
        private started = false;

        @Prop({ type: Function, default: initialize })
        private initialize!: typeof initialize;

        @Prop({ type: Boolean, default: support.isIOS })
        private isIOS!: boolean;

        @Prop({ type: Boolean, default: support.isFirefox })
        private isFirefox!: boolean;

        @Prop({ type: Boolean, default: support.isChrome })
        private isChrome!: boolean;

        @Prop({ type: Boolean, default: support.isSafari })
        private isSafari!: boolean;

        private facetec: FaceTecWrapper | null = null;
        private verificationAbortController: AbortController | null = null;

        private get verifying(): boolean {
            return this.verificationAbortController !== null;
        }

        private get ready(): boolean {
            return Boolean(this.facetec?.ready);
        }

        private get loading(): boolean {
            return this.started || !this.ready;
        }

        private cancel(): void {
            if (this.verificationAbortController) {
                this.verificationAbortController.abort();
                this.verificationAbortController = null;
            }
        }

        private async verify() {
            // Ignore edge-case, when it should cause conflicts
            if (!this.ready || !this.facetec || this.verifying || !this.isMounted) {
                return;
            }

            // Initialize procedure
            this.verificationAbortController = new AbortController();
            this.started = true;
            const result = await this.facetec.checkLiveness({
                signal: this.verificationAbortController.signal,
                headers: { 'X-CSRFToken': (this.$store.state.session as RegularSessionData).canary },
                onRetry: this.onRetry.bind(this),
                maxTries: MAX_TRIES,
            });
            this.started = false;
            this.verificationAbortController = null;

            // Handle the response
            if (result.status === FaceTecSessionStatus.SessionCompletedSuccessfully) {
                this.onSuccess(result);
            } else {
                this.onError(result);
            }
        }

        private onRetry(triesCount: number) {
            const triesLeft = MAX_TRIES - triesCount;
            const message = triesLeft === 1
                ? 'You have 1 try remaining'
                : `You have ${triesLeft} tries remaining`;
            this.$store.dispatch('displaySnackbar', { success: false, message });
        }

        private onError(result: LivenessCheckResult) {
            const isFatal = ZOOM_FATAL_ERRORS.includes(result.status);
            const isIgnored = ZOOM_IGNORED_ERRORS.includes(result.status);
            const message = ZOOM_ERROR_MESSAGES[result.status];

            // Show error information to user
            if (isFatal) {
                this.$store.dispatch('showError', 'unexpected');
            } else if (message) {
                this.$store.dispatch('displaySnackbar', { success: false, message });
            }

            // Report errors to console/Sentry
            if (isFatal || (!message && !isIgnored)) {
                console.error(`FaceTec session failed with status code: ${result.status}`);
            }
        }

        private onSuccess(result: LivenessCheckResult) {
            // Liveness check was successful save the faceMap to account for the updates FaceTec made
            // for backend SDK 9.6.31. After this update using the same FaceScan more than once results in failed
            // liveness verification. Since we have already used the current FaceScan for pre-flight check,
            // passing the same FaceScan will not work and based on recommendation from FaceTec team we need to use
            // the FaceMap that was generated on backend as part of pre-flight check to get the expected result.
            // So we need to submit FaceMap as attribute value instead of FaceScan if the pre-flight check was
            // successful. If the FaceMap is not available then submit the FaceScan as a fallback because the
            // pre-flight check most likely failed and we should unblock the user.
            // See https://evidentid.atlassian.net/browse/PRODUCT-19247 for more details.
            this.$emit(
                'change',
                { selfie: result.auditTrailImage, faceMap: result.faceMap ? result.faceMap : result.faceScan },
            );
            this.$emit('submit');
        }

        private async mounted() {
            this.isMounted = true;
            let loadError: Error | null = null;
            this.facetec = await this.initialize(facetecConfig).catch((error) => {
                loadError = error;
                return null;
            });

            // Do nothing, if it's no longer mounted
            if (!this.isMounted) {
                return;
            }

            // Handle situation when there was problem loading FaceTec SDK
            if (!this.facetec) {
                if (typeof loadError === 'object' && loadError && (loadError as any) instanceof AbortError) {
                    console.warn('The FaceTecSDK.js could not be loaded, due to page refresh');
                    this.$store.dispatch('showError', 'cancelled-network-request');
                } else {
                    console.error('There was a problem while loading FaceTecSDK.js', loadError);
                    this.$store.dispatch('showError', 'unexpected');
                }
                return;
            }

            const { status, statusDescription } = this.facetec;

            // Handle nicely situation when the device is not supported
            if (status === FaceTecSDKStatus.DeviceNotSupported) {
                if (this.isIOS && !this.isSafari) {
                    this.$store.dispatch('showError', 'facetec-unsupported-browser-ios');
                } else if (this.isChrome || this.isFirefox) {
                    this.$store.dispatch('showError', 'facetec-unsupported-browser-old');
                } else {
                    this.$store.dispatch('showError', 'facetec-unsupported-browser');
                }
                return;
            }

            const errorCode = ZOOM_INIT_ERROR_PAGE_CODES[status];

            if (status === FaceTecSDKStatus.Initialized) {
                // Do nothing, it's fine
            } else if (errorCode) {
                this.$store.dispatch('showError', errorCode);
            } else {
                console.error(`FaceTec SDK initialization failed: ${status} (${statusDescription})`);
                this.$store.dispatch('showError', 'unexpected');
            }
        }

        private destroyed() {
            this.isMounted = false;
            this.facetec = null;
            this.cancel();
        }
    }
</script>

<style lang="less">
@brandprimary: #2eb495;
@snackbar-z-index: 99999;

.LivenessQuestion {
    &__selfieImg {
        width: 110px;
        display: block;
        margin: auto;
    }

    &__description {
        size: 13px;
        margin-bottom: 30px;
    }

    &__title {
        font-family: "Roboto Condensed", sans-serif;
        size: 20px;
        font-weight: 500;
        margin-bottom: 0;
    }

    & &__button {
        width: 100%;
    }

    &__tips {
        display: flex;
        margin: 22px -8px;
    }

    &__tip {
        flex: 1 0;
        margin: 8px;
    }

    &__tipImage {
        display: block;
        width: 100%;
        height: auto;
    }

    &__tipContent {
        margin-top: 10px;
        font-size: 0.95em;
    }

    @media only screen and (max-width: 799px) {
        &__tips {
            flex-wrap: wrap;
        }

        &__tip {
            flex-basis: 35%;
        }
    }
}

// Hack: ensure that the snackbar will be visible above ZoOm
body {
    #zoom-sdk-body-element {
        z-index: @snackbar-z-index - 3;
    }

    #zoom-screen-container {
        z-index: @snackbar-z-index - 2;
    }

    #zoom-wrapper-container {
        z-index: @snackbar-z-index - 1;
    }

    .Snackbar {
        z-index: @snackbar-z-index;
    }
}

.LivenessWarning {
    color: #de0000;
    border: 1px solid #de0000;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff0f0;

    .Named {
        margin-right: 5px;
    }
}
</style>
