<template>
    <div class="FilePreview">
        <span class="FilePreview__display">
            <BlobImage
                v-if="isImage"
                class="FilePreview__image"
                :src="file"
            />
            <Icon v-else name="file" />
        </span>
        <span class="FilePreview__fileName">
            {{ fileName }}
            <div v-if="uploading" class="IndefiniteProgressBar" />
        </span>
        <span v-if="!uploading" class="FilePreview__removeIcon" touch-action="auto" @click="removeFile">
            <Icon name="delete" />
        </span>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';
    import BlobImage from '@/components/common/BlobImage.vue';

    @Component({
        components: { BlobImage, Icon },
    })
    export default class FilePreview extends Vue {
        @Prop(File)
        private file!: File;

        @Prop({ type: Boolean, default: false })
        private uploading!: boolean;

        private get fileName() {
            return this.file?.name || '<unknown>';
        }

        private get isImage() {
            return Boolean(this.file?.type) && this.file.type.indexOf('image') === 0;
        }

        private removeFile() {
            this.$emit('remove', this.file);
        }
    }
</script>
