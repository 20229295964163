<template>
    <SingleCol stretch>
        <Spinner />
    </SingleCol>
</template>

<style lang="less">
    .GlsErrorLink {
        margin-top: 10px;
        display: inline-block;
        color: #5f97f5;
        font-weight: bold;
    }
</style>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import redirect from '@evidentid/vue-commons/router/redirect';
    import SingleCol from '@/layouts/SingleCol.vue';
    import ErrorScreen from '@/components/screens/ErrorScreen.vue';
    import Spinner from '@/components/common/Spinner.vue';
    import { glsErrorLinkUrl } from '@/config/gls';

    @Component({
        components: { Spinner, ErrorScreen, SingleCol },
    })
    export default class GoogleBusinessVerificationUnknownPage extends Vue {
        private mounted(): void {
            redirect(glsErrorLinkUrl);
        }
    }
</script>
