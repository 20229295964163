<template>
    <div class="ObjectView">
        <template v-if="title">
            <h1 v-if="depth === 0">
                {{ title }}
            </h1>
            <h2 v-else-if="depth === 1">
                {{ title }}
            </h2>
            <h3 v-else-if="depth === 2">
                {{ title }}
            </h3>
            <h4 v-else>
                {{ title }}
            </h4>
        </template>
        <p v-if="form.schema.description">
            {{ form.schema.description }}
        </p>

        <v-component
            :is="FormElementComponent"
            v-for="property in properties"
            :id="`${id}_${property.name}`"
            :key="property.form.key"
            :depth="depth + 1"
            :form="property.form"
            :required="property.required"
            :value="value[property.name]"
            :custom-component-input="customComponentInput"
            @input="onChange(property, $event)"
        />
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { JsonFormObject, JsonFormProperty } from '@evidentid/json-schema/interfaces/JsonForm';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    @Component({
        inheritAttrs: false,
    })
    export default class JsonSchemaObjectForm extends AbstractJsonSchemaForm<JsonFormObject, object> {
        private get title() {
            return this.form.schema.title;
        }

        private get properties() {
            return this.form.getProperties(this.value);
        }

        private onChange(property: JsonFormProperty, value: any) {
            this.$emit('input', this.form.getValue({
                ...this.value,
                [property.name]: value,
            }));
        }
    }
</script>
