<template>
    <form id="pay" class="CheckoutForm">
        <div v-if="error" class="alertNotice">
            {{ error }}
        </div>
        <div class="CheckoutForm__heading">
            <div class="CheckoutForm__summary">
                {{ summary }}
            </div>
            <div class="CheckoutForm__amount">
                {{ balanceDue }}
            </div>
        </div>

        <div class="CheckoutForm__fields">
            <CheckoutFormField icon="user" label="Name">
                <input
                    id="cardHolderName"
                    v-model="cardHolderName"
                    placeholder="Ann E. Body"
                >
            </CheckoutFormField>

            <CheckoutFormField id="cardNumber" icon="credit-card" label="Card" :error="stripeData.cardNumber.error">
                <StripeElement
                    :stripe-elements="stripeElements"
                    name="cardNumber"
                    @change="onChange('cardNumber', $event)"
                />
            </CheckoutFormField>

            <CheckoutFormField id="cardExpiry" icon="calendar" label="Expiry" :error="stripeData.cardExpiry.error">
                <StripeElement
                    :stripe-elements="stripeElements"
                    name="cardExpiry"
                    @change="onChange('cardExpiry', $event)"
                />
            </CheckoutFormField>

            <CheckoutFormField id="cardCvc" icon="calendar" label="CVC" :error="stripeData.cardCvc.error">
                <StripeElement
                    :stripe-elements="stripeElements"
                    name="cardCvc"
                    @change="onChange('cardCvc', $event)"
                />
            </CheckoutFormField>

            <CheckoutFormField id="postalCode" icon="home" label="Zip" :error="stripeData.postalCode.error">
                <StripeElement
                    :stripe-elements="stripeElements"
                    name="postalCode"
                    @change="onChange('postalCode', $event)"
                />
            </CheckoutFormField>

            <div class="InterviewCard__controls">
                <Button type="primary" :disabled="!awaitingPaymentRequest" @click="onSubmit">
                    Pay {{ balanceDue }}
                </Button>
            </div>
        </div>
    </form>
</template>

<script lang="ts">
    import { Vue, Watch, Prop, Component } from 'vue-property-decorator';
    import formatBalanceDue from '@evidentid/stripe-pos/formatBalanceDue';
    import Button from '@/components/common/Button.vue';
    import CheckoutFormField from './CheckoutFormField.vue';
    import StripeElement from './StripeElement.vue';

    function isCompleted(field: any): boolean {
        return Boolean(field && field.complete);
    }

    function getFieldNameByErrorParam(param: string) {
        if (param === 'exp_month' || param === 'exp_year') {
            return 'cardExpiry';
        } else if (param === 'cvc' || param === 'incorrect_cvc') {
            return 'cardCvc';
        } else if (param === 'address_zip') {
            return 'postalCode';
        }
        return null;
    }

    @Component({
        components: { Button, StripeElement, CheckoutFormField },
    })
    export default class CheckoutForm extends Vue {
        @Prop({ type: String })
        private currency!: string;

        @Prop({ type: [ String, Number ] })
        private amount!: string | number;

        @Prop({ type: Object })
        private stripe!: any;

        private stripeElements: any = null;
        private pending: boolean = false;
        private error: string | null = null;

        private cardHolderName: string = '';
        private stripeData: any = {
            cardExpiry: {},
            cardNumber: {},
            cardCvc: {},
            postalCode: {},
        };

        private get awaitingPaymentRequest() {
            return this.stripe &&
                !this.pending &&
                isCompleted(this.stripeData.cardNumber) &&
                isCompleted(this.stripeData.cardExpiry) &&
                isCompleted(this.stripeData.cardCvc) &&
                isCompleted(this.stripeData.postalCode) &&
                this.cardHolderName.length > 0;
        }

        private get balanceDue() {
            return formatBalanceDue(this.currency, this.amount);
        }

        private get summary() {
            return this.$store.state.summary;
        }

        @Watch('stripe', { immediate: true })
        private onStripeChange() {
            this.error = null;
            this.stripeElements = this.stripe ? this.stripe.elements() : null;
        }

        private async onSubmit() {
            if (!this.awaitingPaymentRequest) {
                return;
            }

            this.pending = true;

            const { error, token } = await this.stripe.createToken(this.stripeData.cardNumber.element, {
                name: this.cardHolderName,
            });

            if (error) {
                this.pending = false;
                this.error = null;
                const fieldName = getFieldNameByErrorParam(error.param);
                if (fieldName) {
                    this.stripeData[fieldName] = { error: 'Please review this field.' };
                } else {
                    this.error = error && error.message;
                }
                return;
            }

            try {
                await this.$store.dispatch('pay', token.id);
            } finally {
                this.pending = false;
            }
        }

        private onChange(name: string, data: any) {
            this.stripeData[name] = data;
        }
    }
</script>
