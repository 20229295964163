import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';

export default function diffObject(modifiedObject: any, originalObject: any): any {
    const result: any = {};
    const keys = uniq(Object.keys(modifiedObject).concat(Object.keys(originalObject)));

    for (const key of keys) {
        const prevValue = originalObject[key];
        const nextValue = modifiedObject[key];

        if (!isEqual(prevValue, nextValue)) {
            result[key] = nextValue;
        }
    }

    return result;
}
