<template>
    <div class="Consent">
        <header class="QuestionHeader">
            <h3>
                <template v-if="question.metadata && question.metadata.title">
                    {{ question.metadata.title }}
                </template>
                <template v-else>
                    Background Check Disclosure &amp; Authorization
                </template>
            </h3>
        </header>
        <div class="ConsentQuestion__language">
            <template v-if="consent">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="consent" />
            </template>
            <template v-else>
                <h3>APPLICANT DISCLOSURE STATEMENT</h3>

                <p>
                    With your application to {{ relyingPartyLegalName }} (COMPANY) to
                    provide services as an independent contractor, employee, or
                    volunteer you may have information requested about you from a
                    Consumer Reporting Agency (CRA). This information may be
                    obtained in the form of a consumer report.
                </p>

                <p>
                    These reports may contain information about your character,
                    general reputation, personal characteristics and/or mode of
                    living. The types of information that may be obtained
                    include, but are not limited to: social security number
                    verifications; address history; criminal records checks;
                    public court records checks; driving records checks; and
                    professional licensing/certification checks. This information
                    may be obtained from private and/or public records sources,
                    including, as appropriate; governmental agencies, courthouses,
                    educational institutions, former employers, or other information
                    sources.
                </p>

                <h3>APPLICANT AUTHORIZATION OF BACKGROUND INVESTIGATION</h3>

                <p>
                    I have carefully read, and understand, this Authorization form
                    and further acknowledge receipt of the separate document
                    entitled “<a href="https://privacy.evidentid.com/FCRA/pdf-0096-fair-credit-reporting-act.pdf">
                        A Summary of Your Rights under the Fair Credit Reporting Act
                    </a>” and the “Applicant Disclosure
                    Statement” and certify that I have read and understand
                    both documents. By my signature below, I consent to the release
                    of consumer reports and/or investigative consumer reports
                    (“Background Reports”) prepared by Evident ID Inc.
                    located at 75 5th Street NW Suite 2060 Atlanta, GA 30308 --
                    877-832-5298 - <a href="https://www.evidentid.com/privacy-policy">Privacy Policy</a>,
                    to COMPANY and its designated representatives and agents for
                    the purpose of determining and maintaining my relationship as an
                    independent contractor, employee, or volunteer with COMPANY.
                </p>

                <p>
                    I understand that if COMPANY engages in a relationship with me,
                    my consent will apply, and COMPANY may obtain Background
                    Reports throughout my relationship with them, if such
                    obtainment is permissible under applicable State law and
                    COMPANY policy. I also understand that information contained
                    in my application, or otherwise disclosed by me may be used
                    when ordering the Background Reports and that nothing herein
                    shall be construed as an offer of employment or a guarantee
                    of a relationship with COMPANY.
                </p>
            </template>
            <div v-if="hasOptionalNotice" class="ConsentQuestion__optionalNotice">
                <p style="margin-bottom: 1rem">
                    California, Minnesota or Oklahoma applicants only: You
                    may receive a free copy of any consumer report or
                    investigative consumer report obtained on you if you
                    check the box below.
                </p>

                <Checkbox id="free-report" v-model="requestedFreeCopy" @input="onChange">
                    I wish to receive a free copy of the report
                </Checkbox>
            </div>
        </div>

        <Checkbox
            id="a-and-a"
            v-model="authorized"
            extra-class="ConsentQuestion__acceptCheck"
            @input="onChange"
        >
            I have read and accept this disclosure
        </Checkbox>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import ConsentValue from '@/fields/interfaces/Consent';
    import Checkbox from '@/components/forms/Checkbox.vue';

    const reviewedDisclosureRegEx = /^consent\.fcra(?:\..+|)\.reviewed_disclosure$/;
    const stateResidentNoticeRegEx = /^consent\..+\.state_resident_notice$/;

    @Component({
        components: { Checkbox },
    })
    export default class Consent extends FieldComponent<ConsentValue> {
        private authorized = false;
        private requestedFreeCopy = false;

        private get hasOptionalNotice() {
            const type = this.question.attrType;
            return reviewedDisclosureRegEx.test(type) || stateResidentNoticeRegEx.test(type);
        }

        private get relyingPartyLegalName() {
            const issuer = this.issuer;
            return issuer?.displayName || '{{COMPANY}}';
        }

        private get consent() {
            const issuer = this.issuer;
            const consentHtml = issuer?.consentHtml || {};
            const attrConsent = consentHtml[this.question.attrType];
            const fcraConsent = consentHtml['consent.fcra'];
            return attrConsent?.data || fcraConsent?.data;
        }

        private get issuer() {
            return this.$store.state.issuer;
        }

        @Watch('value', { immediate: true })
        private onValueChange(value: ConsentValue) {
            this.authorized = Boolean(value?.authorized);
            this.requestedFreeCopy = Boolean(value?.requestedFreeCopy);
        }

        private onChange() {
            this.$emit('change', {
                requestedFreeCopy: this.requestedFreeCopy,
                authorized: this.authorized,
            });
        }
    }
</script>
