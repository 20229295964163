<template>
    <input
        :value="value"
        class="w-full"
        type="email"
        @input="onInput"
    >
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';

    export default Vue.extend({
        name: 'GlsSingleEmailQuestion',
        props: {
            value: {
                type: String as PropType<string>,
                default: '',
            },
        },
        methods: {
            onInput(event: InputEvent): void {
                const inputValue = (event.target as HTMLInputElement).value;
                this.$emit('input', [ inputValue ]);
            },
        },
    });
</script>
