import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import CprComponent from '@/components/fields/Cpr.vue';
import CprData from './interfaces/CprData';
import { cprProvidersMap } from '@/config/cprProviders';

export default class CprField extends Field<CprData> {
    public override component = CprComponent;

    public isValid(_: Question, value: CprData): boolean {
        if (!value || !value.provider || !value.cert_id) {
            return false;
        }
        const provider = cprProvidersMap[value.provider];
        return Boolean(provider && provider.isCertificateIdValid(value.cert_id));
    }

    public async encode(question: Question, value: CprData): Promise<AttributesValues> {
        const provider = value && cprProvidersMap[value.provider];
        return this._encode(question, {
            $objectType: 'CPRData',
            cert_id: value.cert_id,
            provider: provider && provider.id,
        });
    }
}
