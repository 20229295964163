<template>
    <ul class="Timeline">
        <slot />
    </ul>
</template>

<style lang="less">
    @import "~@evidentid/browser-framework/comps/Timeline.less";
</style>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class Timeline extends Vue {
    }
</script>
