<template>
    <div class="ConfirmBusinessOwnerModal">
        <div class="ConfirmBusinessOwnerModal__body">
            <div class="ConfirmBusinessOwnerModal__icon">
                <Icon name="briefcase" />
            </div>
            <header class="ConfirmBusinessOwnerModal__header">
                Please confirm that <strong>{{ email }}</strong> is a Business Owner
                and not an Employee
            </header>

            <div class="ConfirmBusinessOwnerModal__info">
                <Icon name="info-circle" />
                <a
                    href="https://support.google.com/adspolicy/answer/6245891?hl=en"
                    class="ConfirmBusinessOwnerModal__infoContent"
                    target="_blank"
                    rel="noopener"
                >
                    Employees provide services in customers' homes or workplaces
                </a>
            </div>

            <div class="ConfirmBusinessOwnerModal__footer">
                <button
                    type="button"
                    class="ConfirmBusinessOwnerModal__button"
                    @click="$emit('confirm', true)"
                >
                    <Icon name="briefcase" />
                    Business Owner
                </button>
                <button
                    ref="confirmFieldWorker"
                    type="button"
                    class="ConfirmBusinessOwnerModal__button ConfirmBusinessOwnerModal__button--primary"
                    @click="$emit('confirm', false)"
                >
                    <Icon name="wrench" />
                    Employee
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .ConfirmBusinessOwnerModal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999999;
        width: 100%;
        height: 100%;
        overflow: auto;
        background: rgba(#2d3e50, 0.5);

        &__icon {
            text-align: center;
            font-size: 51px;
            width: 1.75em;
            height: 1.75em;
            line-height: 1.85em;
            margin: 0 auto;
            border-radius: 50%;
            border: 1px solid #a9b3bb;
            color: #698296;
        }

        &__header {
            font: 26px/33px "Roboto Condensed", "Roboto", "Helvetica Sans", "Arial", "Tahoma", sans-serif;
            color: #2ab496;
            margin: 28px 0 20px;
        }

        &__body {
            box-sizing: border-box;
            background: #fff;
            margin: 0 auto;
            width: 95%;
            max-width: 770px;
            padding: 52px 80px;
            box-shadow: 0 3px 6px 2px rgba(#000, 0.1);
            text-align: center;
        }

        &__info {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0;
            font-size: 1.0625em;

            .Named {
                color: #2ab496;
                margin-right: 5px;
            }

            a {
                color: inherit;
            }
        }

        &__infoContent {
            display: inline;
            text-decoration: underline;
            font-weight: bold;
        }

        &__footer {
            display: flex;
            justify-content: center;
            margin-top: 44px;
        }

        &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font: inherit;
            font-weight: bold;
            line-height: 1em;
            height: 48px;
            width: 203px;
            padding: 0 20px;
            color: #698296;
            border: 1px solid #778ea1;
            box-shadow: 0 0 4px rgba(#d3d3d3, 0.5);
            border-radius: 24px;
            background: transparent;
            outline: none;
            cursor: pointer;

            .Named {
                margin-right: 10px;
            }

            &:hover {
                background: rgba(#000, 0.05);
            }

            &:focus {
                box-shadow: 0 0 4px rgba(#d3d3d3, 0.5), 0 0 0 1px #778ea1;
            }

            &--primary {
                color: #fff;
                background: #2ab496;
                border-color: #2ab496;

                &:hover {
                    background: darken(#2ab496, 3%);
                }
            }

            & + & {
                margin-left: 28px;
            }
        }
    }

    @media (max-width: 600px) {
        .ConfirmBusinessOwnerModal__info {
            display: inline-block;

            .Named {
                display: none;
            }
        }

        .ConfirmBusinessOwnerModal__body {
            padding: 20px;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';

    @Component({
        components: { Icon },
    })
    export default class ConfirmBusinessOwnerModal extends Vue {
        @Prop({ type: String })
        private email!: string;

        @Ref()
        private confirmFieldWorker!: HTMLButtonElement;

        private mounted(): void {
            // Focus Field Worker by default
            this.confirmFieldWorker.focus();
        }
    }
</script>
