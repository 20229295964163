<template>
    <div class="LicenseStateObjectForm">
        <template v-if="title">
            <h1 v-if="depth === 0">
                {{ title }}
            </h1>
            <h2 v-else-if="depth === 1">
                {{ title }}
            </h2>
            <h3 v-else-if="depth === 2">
                {{ title }}
            </h3>
            <h4 v-else>
                {{ title }}
            </h4>
        </template>
        <p>{{ form.schema.description }}</p>
        <div class="LicenseStateObjectForm__ComponentWrapper">
            <v-component
                :is="FormElementComponent"
                v-for="property in properties"
                :id="`${id}_${property.name}`"
                :key="property.form.key"
                :depth="depth + 1"
                :form="property.form"
                :required="property.required"
                :value="value[property.name]"
                :state="value.licenseState"
                :custom-component-input="customComponentInput"
                :parent-schema-id="form.schema.$id"
                @input="onChange(property, $event)"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import {
        JsonFormObject,
        JsonFormProperty,
    } from '@evidentid/json-schema/interfaces/JsonForm';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    @Component({
        inheritAttrs: false,
    })
    export default class LicenseStateObjectForm extends AbstractJsonSchemaForm<JsonFormObject, object> {
        private get title(): string | undefined {
            return this.form.schema.title;
        }

        private get properties(): JsonFormProperty[] {
            return this.form.getProperties(this.value);
        }

        private onChange(property: JsonFormProperty, value: any): void {
            this.$emit('input', this.form.getValue({
                ...this.value,
                [property.name]: value,
            }));
        }
    }
</script>
