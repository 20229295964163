import License from '@/interfaces/licenses/License';

const getStateInfo = (state: string): string => (state ? ` for ${state}` : '');

const getLicenseId = (license: License): string => license.licenseId || '[License ID not provided]';

export const generateContactEmailBody = (title: string, licenses: License[]): string => {
    const plural = licenses.length > 1 ? 's' : '';
    const firstSentence = `The form is not accepting my ${title} license number${plural}: `;
    const licensesText = licenses
        .filter((license) => license.licenseId || license.licenseState)
        .reduce((acc, license) => `${acc} ${(getLicenseId(license))}${getStateInfo(license.licenseState)},`, '')
        .trim()
        .replace(/,\s*$/, '');
    return firstSentence + licensesText;
};
