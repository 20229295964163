import { FaceTecCustomization, FaceTecVocalGuidanceMode } from '../types';

type FaceTecCustomizationPartial = Partial<{
    [K in keyof FaceTecCustomization]: Partial<FaceTecCustomization[K]>;
}>;

export interface FaceTecCustomizationSettings {
    brandLogoFilePath: string | null;
    brandColorPrimary: string;
    brandColorDark: string;
    colorPrimary: string;
    colorBackground: string;
    colorOverlayBackground: string;
    vocalGuidance: boolean;
}

export function createFaceTecCustomizationOptions(
    customizationSettings: Partial<FaceTecCustomizationSettings>,
): FaceTecCustomizationPartial {
    return {
        guidanceCustomization: {
            backgroundColors: customizationSettings.colorBackground,
            foregroundColor: customizationSettings.brandColorDark,
            buttonTextNormalColor: customizationSettings.colorBackground,
            buttonBackgroundNormalColor: customizationSettings.colorPrimary,
            buttonTextHighlightColor: customizationSettings.colorBackground,
            buttonBackgroundHighlightColor: customizationSettings.colorPrimary,
            buttonBorderColor: 'transparent',
            buttonBorderWidth: '0px',
            buttonCornerRadius: '4px',
            readyScreenOvalFillColor: 'rgba(9, 181, 163, 0.2)',
            readyScreenTextBackgroundColor: customizationSettings.colorBackground,
            readyScreenTextBackgroundCornerRadius: '5px',
        },
        initialLoadingAnimationCustomization: {
            foregroundColor: customizationSettings.brandColorPrimary,
        },
        overlayCustomization: {
            backgroundColor: customizationSettings.colorOverlayBackground,
            showBrandingImage: Boolean(customizationSettings.brandLogoFilePath),
            brandingImage: customizationSettings.brandLogoFilePath || '',
        },
        resultScreenCustomization: {
            foregroundColor: customizationSettings.brandColorDark,
            activityIndicatorColor: customizationSettings.colorPrimary,
            resultAnimationBackgroundColor: customizationSettings.colorPrimary,
            resultAnimationForegroundColor: customizationSettings.colorBackground,
            uploadProgressTrackColor: 'rgba(0, 0, 0, 0.2)',
            uploadProgressFillColor: customizationSettings.colorPrimary,
        },
        feedbackCustomization: {
            backgroundColor: customizationSettings.colorPrimary,
            textColor: customizationSettings.colorBackground,
            cornerRadius: '5px',
        },
        frameCustomization: {
            backgroundColor: customizationSettings.colorBackground,
            borderColor: customizationSettings.brandColorDark,
            borderWidth: '2px',
            borderCornerRadius: '4px',
        },
        ovalCustomization: {
            strokeColor: customizationSettings.brandColorDark,
            progressColor1: customizationSettings.brandColorPrimary,
            progressColor2: customizationSettings.brandColorPrimary,
        },
        vocalGuidanceCustomization: {
            mode: customizationSettings.vocalGuidance
                ? FaceTecVocalGuidanceMode.MINIMAL_VOCAL_GUIDANCE
                : FaceTecVocalGuidanceMode.NO_VOCAL_GUIDANCE,
        },
    };
}
