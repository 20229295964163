<template>
    <div class="PhoneDelegationInput">
        <PhoneNumberInput :id="id" :value="value" @input="onChange" />
        <Button v-if="loading" type="primary" :disabled="true">
            Sending...
        </Button>
        <Button v-else-if="disabled" type="primary" :disabled="true">
            Sent!
        </Button>
        <Button v-else type="primary" :disabled="!maySendText" @click="onStart">
            {{ startButtonLabel }}
        </Button>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import PhoneNumberInput from '@/components/forms/PhoneNumberInput.vue';
    import Button from '@/components/common/Button.vue';
    import PhoneNumber from '@/fields/interfaces/PhoneNumber';

    @Component({
        components: { Button, PhoneNumberInput },
    })
    export default class PhoneDelegationInput extends Vue {
        @Prop({ type: String, default: undefined })
        private id!: string;

        @Prop({ type: Boolean, default: false })
        private loading!: boolean;

        @Prop({ type: String, default: 'Start' })
        private startButtonLabel!: string;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ default: null })
        private value!: PhoneNumber | null;

        private get maySendText() {
            return this.value?.valid && !this.disabled;
        }

        private onStart() {
            this.$emit('start');
        }

        private onChange(value: PhoneNumber | null) {
            this.$emit('input', value);
        }
    }
</script>
