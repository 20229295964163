import Question from '@evidentid/ido-lib/interfaces/Question';
import { isValidEin, sanitizeEin } from '@evidentid/tin-tools';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Ein from '@/components/fields/Ein.vue';
import Field from './Field';

export default class EinField extends Field<string> {
    public override component = Ein;

    public isValid(question: Question, value: string): boolean {
        return isValidEin(value);
    }

    public async encode(question: Question, value: string): Promise<AttributesValues> {
        return this._encode(question, sanitizeEin(value));
    }
}
