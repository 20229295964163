<template>
    <div class="BooleanView">
        <Checkbox :value="value" @input="onChange">
            {{ form.schema.description }}
        </Checkbox>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaBoolean } from '@evidentid/json-schema/interfaces/JsonSchema';
    import Checkbox from '@/components/forms/Checkbox.vue';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    type JsonFormBoolean = JsonFormBase<JsonSchemaBoolean>;

    @Component({
        components: { Checkbox },
        inheritAttrs: false,
    })
    export default class JsonSchemaBooleanForm extends AbstractJsonSchemaForm<JsonFormBoolean, boolean> {
        private onChange(value: boolean) {
            this.$emit('input', this.form.getValue(value));
        }
    }
</script>
