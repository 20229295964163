<template>
    <div class="WeddingCake" :class="{ 'WeddingCake--fullscreen': fullscreen }">
        <div class="WeddingCake__body PendingScreen">
            <div class="PendingScreen__indicator">
                <Spinner />
            </div>
            <div><slot>Please wait...</slot></div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import Spinner from '@/components/common/Spinner.vue';

    @Component({
        components: { Spinner },
    })
    export default class PendingScreen extends Vue {
        @Prop({ type: Boolean, default: false })
        private fullscreen!: boolean;
    }
</script>
