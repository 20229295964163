<template>
    <div class="ImagesBox" :class="{ 'ImagesBox--solid': solid }">
        <h3 v-if="title" class="ImagesBox__title">
            {{ title }}
        </h3>

        <div class="ImagesBox__container">
            <IdScanFailedPreviewItem
                v-for="(file, index) in files"
                :key="index"
                :file="file"
                :zoomed="zoomedFile === file"
                @toggle="onToggle"
            />
        </div>

        <div v-if="notice" class="IdScanPreviewNotice">
            <Icon name="exclamation-circle" /> {{ notice }}
        </div>
    </div>
</template>

<script lang="ts">
    import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';
    import Spinner from '@/components/common/Spinner.vue';
    import IdScanFailedPreviewItem from '@/components/fields/IdScan/IdScanFailedPreviewItem.vue';

    @Component({
        components: { IdScanFailedPreviewItem, Spinner, Icon },
    })
    export default class IdScanFailedPreview extends Vue {
        @Prop({ type: Array, default: () => [] })
        private files!: File[];

        @Prop({ type: String, default: '' })
        private notice!: string;

        @Prop({ type: Boolean, default: false })
        private solid!: boolean;

        @Prop({ type: String, default: '' })
        private title!: string;

        private zoomedFile: File | null = null;
        private handleRedirectionEvent!: (event: Event) => void;

        private mounted() {
            this.handleRedirectionEvent = (event: Event) => {
                event.preventDefault();
                this.zoomedFile = null;
                history.forward();
                this.$forceUpdate();
            };
        }

        private destroyed() {
            setTimeout(() => this.unregisterRedirectHook());
        }

        @Watch('zoomedFile')
        private onZoomedFileChange() {
            if (this.zoomedFile) {
                this.registerRedirectHook();
            } else {
                this.unregisterRedirectHook();
            }
        }

        private registerRedirectHook() {
            // Make sure that we are not duplicating events
            this.unregisterRedirectHook();

            // eslint-disable-next-line
            // @ts-ignore: disable router changes based on "back" button
            window.isNavigationBlocked = true;

            // Ensure that user will not leave the page
            window.onbeforeunload = () => 'Are you sure you want to leave?';

            // Register events
            window.addEventListener('hashchange', this.handleRedirectionEvent);
            window.addEventListener('popstate', this.handleRedirectionEvent);
        }

        private unregisterRedirectHook() {
            // eslint-disable-next-line
            // @ts-ignore: enabled router changes based on "back" button
            window.isNavigationBlocked = false;

            // eslint-disable-next-line
            // @ts-ignore: stop ensuring if user will accidentally not leave
            window.onbeforeunload = undefined;

            // Unregister window events
            window.removeEventListener('hashchange', this.handleRedirectionEvent);
            window.removeEventListener('popstate', this.handleRedirectionEvent);
        }

        private onToggle(file: File) {
            this.zoomedFile = this.zoomedFile === file ? null : file;
        }
    }
</script>
