<template>
    <div class="JsonSchemaEnumForm" :class="{ 'JsonSchemaEnumForm--focused': focused }">
        <FormElement :label-for="id" :label="form.schema.title" :required="required">
            <template #requiredIndicator>
                {{ translate('requiredLabel') }}
            </template>
            <template #labelHint>
                <div v-if="form.schema.warning" class="JsonSchemaForm__warning">
                    {{ form.schema.warning }}
                </div>
                <div v-else-if="form.schema.hint" class="JsonSchemaForm__hint">
                    {{ form.schema.hint }}
                </div>
            </template>

            <FormInput :invalid="accessed && hasError" force-error-slot force-action-slot>
                <Select
                    :id="id"
                    type="text"
                    :disabled="disabled"
                    :value="viewValue"
                    :placeholder="form.schema.description || description"
                    :maxlength="form.schema.maxLength || 1024"
                    @input="onChange"
                    @blur="touch"
                    @focus="onFocus"
                >
                    <option v-for="(option, index) in form.schema.enum" :key="option" :value="index">
                        {{ buildLabel(option, index) }}
                    </option>
                </Select>

                <template v-if="accessed && error" #errorMessage>
                    {{ error }}
                </template>
                <template #icon>
                    <slot name="icon">
                        <FontAwesomeIcon class="JsonSchemaEnumForm__arrow" :icon="faChevronDown" />
                    </slot>
                </template>

                <button v-if="!required && viewValue !== null" class="JsonSchemaEnumForm__clear" @click="clearValue">
                    <FontAwesomeIcon :icon="faTimes" /> <span class="JsonSchemaEnumForm__clearText">Clear</span>
                </button>

                <template v-if="deletable" #actionButtons>
                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                        <FontAwesomeIcon :icon="faTimes" />
                    </div>
                </template>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaEnum } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { FormElement, FormInput } from '../../Form';
    import { Select } from '../../Select';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    @Component({
        components: { FormInput, FormElement, Select, FontAwesomeIcon },
        inheritAttrs: false,
    })
    export default class JsonSchemaEnumForm extends AbstractJsonSchemaForm<JsonFormBase<JsonSchemaEnum>, string> {
        private faTimes = faTimes;
        private faChevronDown = faChevronDown;

        private get viewValue(): number | null {
            const index = this.form.schema.enum.indexOf(this.value);
            return index === -1 ? null : index;
        }

        private buildLabel(option: any, index: number): string {
            return this.form.schema.enumLabels?.[index] || `${option}`;
        }

        private onChange(inputIndex: number): void {
            const inputValue = this.form.schema.enum?.[inputIndex];
            this.$emit('input', this.form.getValue(inputValue));
        }

        private clearValue() {
            this.$emit('input', this.form.getValue(undefined));
        }
    }
</script>
