<template>
    <div role="main" class="SingleCol" :class="{ 'SingleCol--stretch': stretch, 'SingleCol--center': center }">
        <div class="SingleCol__body">
            <slot />
        </div>
        <div class="SingleCol__footer" :class="{'SingleCol__footer--stretchX': footerStretchX}">
            <slot name="footer">
                <LogoFooter />
            </slot>
        </div>
        <OnlineChat v-if="shouldRenderChat" custom-actions-path="actions" class="hidden md:block" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import LogoFooter from '@/components/common/LogoFooter.vue';
    import OnlineChat from '@evidentid/dashboard-commons/modules/online-chat/components/OnlineChat/OnlineChat.vue';
    import support from '@/config/support';

    @Component({
        inheritAttrs: false,
        components: { LogoFooter, OnlineChat },
    })
    export default class SingleCol extends Vue {
        @Prop({ type: Boolean, default: false })
        private stretch!: boolean;

        @Prop({ type: Boolean, default: false })
        private footerStretchX!: boolean;

        @Prop({ type: Boolean, default: false })
        private center!: boolean;

        @Prop({ type: Boolean, default: false })
        private isChatAvailable!: boolean;

        private get shouldRenderChat(): boolean {
            return this.isChatAvailable && !this.isMobileBrowser();
        }

        // wrapper so that we can control this variable in unit test in order to test shouldRenderChat correctly
        private isMobileBrowser(): boolean {
            return support.isMobileBrowser;
        }
    }
</script>
