export function curateCOIScan(aggregatedRequest) {
    const coiInputAttributeRegex =
        /risk_decisioning\.insurance_policy_verification\.self_attestation\..+\.input.document_scan/;

    const coiInputAttributes = Object.keys(aggregatedRequest.input)
        .filter((attributeName) => coiInputAttributeRegex.test(attributeName));

    coiInputAttributes.forEach((attributeName, index) => {
        if (index > 0) {
            aggregatedRequest.input[attributeName].metadata.hidden = true;
        }
    });

    return aggregatedRequest;
}
