<template>
    <div class="EnumView">
        <label class="LabeledControl" :for="id">
            <span class="LabeledControl__label">
                {{ form.schema.title }}
                <span v-if="required">
                    <slot name="requiredIndicator"><i class="fg-danger">*</i></slot>
                </span>
            </span>

            <Dropdown
                :id="id"
                :value="value"
                :placeholder="form.schema.description"
                :options="form.schema.enum"
                @input="onChange"
            />
        </label>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaEnum } from '@evidentid/json-schema/interfaces/JsonSchema';
    import Dropdown from '@/components/forms/Dropdown.vue';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    type JsonFormEnum = JsonFormBase<JsonSchemaEnum>;

    @Component({
        components: { Dropdown },
        inheritAttrs: false,
    })
    export default class JsonSchemaEnumForm extends AbstractJsonSchemaForm<JsonFormEnum, any> {
        private onChange(value: any) {
            this.$emit('input', this.form.getValue(value));
        }
    }
</script>
