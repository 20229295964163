<template>
    <QuestionForm :submit-label="submitLabel" :valid="isValid" @back="$emit('back')" @submit="onSubmit">
        <ChargeNavigator
            v-if="charges.length > 1"
            :index="currentIndex"
            :total="charges.length"
            @change="onChargeChange"
        />
        <ChargeInfo :charge="currentCharge" />
        <ChargeDetails :charge="currentCharge" @input="onCurrentChargeChange" />
        <ChargeNavigator
            v-if="charges.length > 1"
            :index="currentIndex"
            :total="charges.length"
            @change="onChargeChange"
        />
    </QuestionForm>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import QuestionForm from '@/components/forms/QuestionForm.vue';
    import Charge from '@/interfaces/Charge';
    import ChargeNavigator from './IndividualizedAssessment/ChargeNavigator.vue';
    import ChargeDetails from './IndividualizedAssessment/ChargeDetails.vue';
    import ChargeInfo from './IndividualizedAssessment/ChargeInfo.vue';
    import IndividualizedAssessmentField from '@/fields/IndividualizedAssessmentField';

    const emptyCharge: Charge = {
        chargeName: 'Offense Not found',
        chargeDate: 'Date Not Found',
        chargeID: '',
        isBonded: null,
        ageAtOffense: null,
        cause: '',
        lastPosition: '',
        additionalInformation: '',
        additionalEffort: '',
        offenseTraining: '',
        substanceTraining: '',
        paroleRequirements: '',
        rehabilitationParole: false,
        rehabilitationProbation: false,
        rehabilitationEducation: false,
        rehabilitationTraining: false,
        rehabilitationAlcohol: false,
        rehabilitationDrug: false,
        rehabilitationNone: false,
        rehabilitationOther: null,
    };

    @Component({
        components: { ChargeInfo, ChargeDetails, ChargeNavigator, QuestionForm },
    })
    export default class IndividualizedAssessment extends FieldComponent<Charge[]> {
        private currentIndex = 0;

        private get defaultCharges(): Charge[] {
            const offenses = this.$store.state.criminalOffenses || [];
            return offenses.map((offense) => ({
                ...emptyCharge,
                chargeID: offense.id,
                chargeName: offense.specificOffense,
                chargeDate: offense.effectiveDate,
            }));
        }

        private get charges(): Charge[] {
            return this.value ? this.value : this.defaultCharges;
        }

        private get currentCharge() {
            return this.charges[this.currentIndex];
        }

        private get isLast() {
            return this.charges.length - 1 <= this.currentIndex;
        }

        private get isValid() {
            return this.isLast
                ? this.valid
                : (this.field as IndividualizedAssessmentField).isChargeValid(this.currentCharge);
        }

        private get submitLabel() {
            return this.isLast ? 'Save' : 'Continue to the next charge';
        }

        private onCurrentChargeChange(charge: Charge) {
            const currentCharges = this.charges;
            this.$emit('change', [
                ...currentCharges.slice(0, this.currentIndex),
                charge,
                ...currentCharges.slice(this.currentIndex + 1),
            ]);
        }

        private onSubmit() {
            if (this.isLast) {
                return this.$emit('submit');
            } else {
                this.currentIndex++;
            }
        }

        private onChargeChange(index: number) {
            this.currentIndex = index;
        }
    }
</script>
