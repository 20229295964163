<template>
    <div ref="element" />
</template>

<script lang="ts">
    import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';

    @Component({
        components: { Icon },
    })
    export default class StripeElement extends Vue {
        @Prop({ type: Object })
        private stripeElements!: any;

        @Prop({ type: String })
        private name!: string;

        private element: any = null;

        private mounted() {
            this.initializeElement();
        }

        private destroyed() {
            this.destroyElement();
        }

        @Watch('stripeElements')
        private onStripeChange() {
            this.initializeElement();
        }

        private initializeElement() {
            this.destroyElement();

            if (this.stripeElements) {
                this.element = this.stripeElements.create(this.name);
                this.element.on('change', (data: any) => this.$emit('change', { ...data, element: this.element }));
                this.element.mount(this.$refs.element);
            }
        }

        private destroyElement() {
            if (this.element) {
                this.element.destroy();
                this.element = null;
            }
        }
    }
</script>
