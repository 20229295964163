// By default, use ConsoleAdapter for development, or NoopAdapter for production
const defaultAdapter = process.env.NODE_ENV === 'development' ? 'console' : 'noop';

// Choose one of available Tracker adapter
export const adapter = deploy.WEB_PUBLIC_IDOWEB_TRACKING_ADAPTER || defaultAdapter;

function getTrackingOptions(): any {
    try {
        return JSON.parse(deploy.WEB_PUBLIC_IDOWEB_TRACKING_OPTIONS);
    } catch (error) {
        return {};
    }
}

export const options = getTrackingOptions();
