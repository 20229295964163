import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import SingleLineTextField from '@/fields/SingleLineTextField';

export default class InsurancePolicyNumberField extends SingleLineTextField {
    public override allowedCharRegex = /[a-zA-Z0-9\- ]/;

    public override isValid(_: Question, value: string): boolean {
        return super.isValid(_, value) && /^[a-zA-Z0-9\- ]+$/g.test(value);
    }

    public override async encode(question: Question, value: string): Promise<AttributesValues> {
        return super.encode(question, value);
    }
}
