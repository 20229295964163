import flatten from 'lodash/flatten';
import { getAttributeData } from '@evidentid/ido-lib/idoWebClient';
import CriminalOffenseCategory, { CriminalOffense, RawCriminalOffense } from '@/interfaces/OffenseCategory';
import formatCriminalOffenseDate from '@/utils/formatCriminalOffenseDate';

export default async function getCriminalOffensesList(): Promise<CriminalOffense[]> {
    // Retrieve attributes data from back-end
    const { attributeData } = await getAttributeData();

    // Get all offenses from attributes
    const offenses = flatten(
        attributeData.map((category: CriminalOffenseCategory) => category[1] && category[1].offenses),
    ).filter(Boolean) as RawCriminalOffense[];

    // Format date and set up default offense name
    return offenses.map((offense) => ({
        ...offense,
        effectiveDate: formatCriminalOffenseDate(offense.effectiveDate),
        specificOffense: offense.specificOffense || 'Offense Not found',
    }));
}

