<template>
    <p class="IdScanInstructions">
        {{ instructions }}
    </p>
</template>

<script lang="ts">
    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class IdScanInstructions extends Vue {
        @Prop({ type: String })
        private instructions!: string;
    }
</script>
