<template>
    <SingleCol stretch center>
        <div class="UnknownRequestError">
            <img aria-hidden="true" alt="Error" :src="imageUrl">

            <h3>Uh oh, this link isn’t working!</h3>

            <p>
                As a security measure, verification links generated by the Evident platform
                will expire after a certain amount of time or uses. This link may be beyond the expiration date,
                or it was already accessed.
                More rarely, the link could contain a formatting error, or the associated page may no longer exist.
                If you still want to continue with this verification,
                please contact the business who sent you the link and they can generate new link for you.
            </p>
        </div>
    </SingleCol>
</template>

<style lang="scss">
    .UnknownRequestError {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 900px;
        max-width: 70%;
        margin: 20px auto;
        padding: 50px 30px;
        border-radius: 5px;
        border: 2px solid rgba(#000, 0.1);
        background: #fff;
        text-align: center;

        img {
            display: block;
            margin: 0 0 20px;
            max-width: 50%;
            width: 400px;
        }

        h3 {
            font-size: 1.75em;
            font-weight: 400;
            margin: 30px auto;
        }

        p {
            font-size: 1.15em;
        }

        h3, p {
            max-width: 730px;
        }
    }
</style>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import SingleCol from '@/layouts/SingleCol.vue';
    import ErrorScreen from '@/components/screens/ErrorScreen.vue';
    import imageUrl from '@/assets/illustration-linkexpired.svg';

    @Component({
        components: { ErrorScreen, SingleCol },
    })
    export default class UnknownRequestErrorPage extends Vue {
        private imageUrl = imageUrl;
    }
</script>
