import Vue, { Component } from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import Router, { Route } from 'vue-router/types';
import { sync } from 'vuex-router-sync';

import Logger from '@evidentid/logger';
import Tracker from '@evidentid/tracker';

import { ExtractFullActions, ExtractFullMutations, ExtractFullState, TypedStore } from '../store';
import ApplicationObserver from './ApplicationObserver';

export interface ApplicationOptions<
    L extends Logger<any> = Logger<any>,
    T extends Tracker<any> = Tracker<any>,
    S extends TypedStore<any> = TypedStore<any>,
    R extends Router = Router,
> {
    Vue?: typeof Vue;
    Vuex?: typeof Vuex;
    VueRouter?: typeof Router;
    location: Location;
    logger: L;
    tracker: T;
    createRouter: (app: any) => R;
    createStore: (app: any) => S;
}

type RequiredObject<T> = { [K in keyof T]-?: Exclude<T[K], undefined>; };

export abstract class Application<
    L extends Logger<any> = Logger<any>,
    T extends Tracker<any> = Tracker<any>,
    S extends TypedStore<any> = TypedStore<any>,
    R extends Router = Router,
    O extends ApplicationOptions<L, T, S, R> = ApplicationOptions<L, T, S, R>,
> {
    protected abstract readonly Component: Component;
    protected readonly options: RequiredObject<O>;
    protected vue: Vue | null = null;
    protected root: Element | null = null;
    public readonly logger: L;
    public readonly tracker: T;
    public readonly observer: ApplicationObserver<ExtractFullState<S>, ExtractFullActions<S>, ExtractFullMutations<S>>;
    public router: R | null = null;
    public store: S | null = null;

    public constructor(options: O) {
        this.options = Object.assign({}, options, {
            Vue: options.Vue || Vue,
            Vuex: options.Vuex || Vuex,
            VueRouter: options.VueRouter || VueRouter,
        }) as RequiredObject<O>;
        this.logger = options.logger;
        this.tracker = options.tracker;
        this.observer = new ApplicationObserver<
            ExtractFullState<S>,
            ExtractFullActions<S>,
            ExtractFullMutations<S>
        >(this);
    }

    public get initialized(): boolean {
        return this.router !== null && this.store !== null;
    }

    public get mounted(): boolean {
        return this.initialized && this.root !== null;
    }

    public readonly initialize = (): void => {
        if (!this.initialized) {
            // Register sub-components in Vue instance
            this.options.Vue.use(this.options.VueRouter);
            this.options.Vue.use(this.options.Vuex);

            // Start initialization
            this.beforeInitialize();

            // Initialize store and router
            this.router = this.options.createRouter(this);
            this.store = this.options.createStore(this);

            // Synchronize router with store
            sync(this.store!, this.router!);

            // Handle basic logic after router change
            this.router!.afterEach(this.onRouteChange.bind(this));

            // Set-up Vue error handling
            // FIXME: Clean up error handler after destroy?
            // eslint-disable-next-line @typescript-eslint/unbound-method
            const originalVueErrorHandler = this.options.Vue.config.errorHandler;
            // eslint-disable-next-line @typescript-eslint/unbound-method
            this.options.Vue.config.errorHandler = (error, ...args) => {
                this.logger?.warning(error);
                this.onVueError(error);

                if (originalVueErrorHandler) {
                    originalVueErrorHandler(error, ...args);
                }
            };

            // Finish initialization
            this.afterInitialize();
        }
    };

    public readonly destroy = (): void => {
        if (this.initialized) {
            // Start destroy
            this.beforeDestroy();

            // Destroy Vue
            this.destroyMountedComponent();

            // Remove references
            this.vue = null;
            this.store = null;
            this.router = null;

            // Finish destroy
            this.afterDestroy();
        }
    };

    public readonly mount = (root: Element): void => {
        if (!this.initialized) {
            throw new Error('You can\'t render already destroyed application instance.');
        } else if (this.mounted) {
            this.destroyMountedComponent();
        }

        this.root = root;
        this.beforeMount();
        this.vue = new this.options.Vue({
            ...this.getVueOptions(),
            render: (h: any) => h(this.Component),
        } as any).$mount(root);
        this.afterMount();
    };

    protected getVueOptions() {
        return {
            router: this.router!,
            store: this.store!,
        };
    }

    /* eslint-disable @typescript-eslint/no-empty-function */
    protected beforeMount(): void {}
    protected afterMount(): void {}
    protected beforeInitialize(): void {}
    protected afterInitialize(): void {}
    protected beforeDestroy(): void {}
    protected afterDestroy(): void {}
    protected onVueError(error: Error): void {}
    protected onRouteChange(to: Route, from: Route): void {}
    /* eslint-enable */

    private destroyMountedComponent(): void {
        // Get back previous element
        const element = this.vue?.$el;
        if (element?.parentNode) {
            element.parentNode.replaceChild(this.root!, element);
        }

        // Destroy Vue instance
        this.vue?.$destroy();
    }
}
