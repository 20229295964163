<template>
    <div class="FileQuestion">
        <div class="FileQuestion__instructions">
            {{ description }} Supported file formats include {{ humanReadableExtensions }}.
        </div>
        <label
            class="FileDropbox"
            :for="id"
            @click="openFileBrowser"
            @touchend="openFileBrowser"
        >
            <slot>
                <span class="FileDropbox__icon"><Icon name="upload" /></span>
            </slot>
        </label>
        <input
            :id="id"
            ref="input"
            type="file"
            multiple
            :name="id"
            :accept="extensions.map(x => `.${x}`).join(',')"
            style="display: none"
            @change="onChange"
        >
        <FilesListPreview
            v-if="value && value.length > 0"
            :files="value"
            :uploading="submitting"
            @remove="removeFile"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { extensions, regularExtensions, mimeTypes, regularMimeTypes } from '@evidentid/file-utils/mimeTypes';
    import { validateFiles } from '@evidentid/file-utils/prepareFile';
    import Icon from '@/components/common/Icon.vue';
    import FilesListPreview from './FilesListPreview.vue';
    import { cloneFile } from '@evidentid/file-utils/blobs';

    @Component({
        inheritAttrs: false,
        components: { FilesListPreview, Icon },
    })
    export default class MobileFileUploadInput extends Vue {
        @Prop({ type: String, default: undefined })
        private id!: string | undefined;

        @Prop({ type: Array, default: () => [] })
        private value!: File[];

        @Prop({ type: Boolean, default: false })
        private submitting!: boolean;

        @Prop({ type: Boolean, default: false })
        private imagesOnly!: boolean;

        @Prop({ type: String, default: '' })
        private description!: string;

        private get extensions() {
            return this.imagesOnly ? extensions.image : regularExtensions.filter((extension) => extension !== 'txt');
        }

        private get mimeTypes() {
            return this.imagesOnly ? mimeTypes.image : regularMimeTypes.filter((type) => type !== 'text/plain');
        }

        private get humanReadableExtensions() {
            const upperCase = this.extensions.map((extension: string) => extension.toUpperCase());
            return `${upperCase.slice(0, -1).join(', ')}, and ${upperCase[upperCase.length - 1]}`;
        }

        private openFileBrowser(event: Event) {
            event.preventDefault();

            const input = this.$refs.input as HTMLInputElement;

            if (input) {
                input.click();
            }
        }

        private removeFile(file: File) {
            this.$emit('input', (this.value || []).filter((x) => x !== file));
        }

        private async addFiles(files: FileList | File[]) {
            const { success, failed } = validateFiles(files, this.mimeTypes);
            // PRODUCT-11363: Chrome can discard previous `File` instances, when it will encounter new exactly same one
            const successCopy = await Promise.all(success.map(cloneFile));
            this.$emit('input', (this.value || []).concat(successCopy));
            if (failed.length > 0) {
                this.$emit('invalid', failed);
            }
        }

        private onChange(event: Event) {
            event.preventDefault();

            const { files } = ((event as DragEvent).dataTransfer as DataTransfer) || (event.target as HTMLInputElement);
            this.addFiles(files);

            if (event.target) {
                (event.target as HTMLInputElement).value = '';
            }
        }
    }
</script>
