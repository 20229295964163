import url from '@evidentid/universal-framework/url';
import parseJsonConditionally from '@evidentid/universal-framework/parseJsonConditionally';
import createCustomXhrErrorFactory from '@evidentid/universal-framework/createCustomXhrErrorFactory';
import {
    GlsCaseSubmission,
    GlsCaseStatus,
    GlsEmail,
    GlsUserSession,
    GlsCaseData,
    GlsInternalCaseSubmission,
    GlsInternalCaseData,
    GlsInternalUserSession,
    GlsCaseSubmissionInput,
    GlsInternalCaseStatus,
} from './types';
import {
    convertCaseDetailsFromInternal,
    convertCaseSubmissionFromInternal,
    convertCaseSubmissionInputToInternal,
    convertStatusFromInternal,
    convertUserSessionFromInternal,
} from './converters';

const statusErrorFactories: Record<number, (xhr?: XMLHttpRequest) => Error> = {
    401: createCustomXhrErrorFactory('gls/unauthorized', 'You are not authorized for selected operation'),
    403: createCustomXhrErrorFactory('gls/forbidden', 'You are not authorized for selected operation'),
    404: createCustomXhrErrorFactory('gls/not-found', 'The requested resource is not found'),
    440: createCustomXhrErrorFactory('gls/session-expired', 'Your session has expired'),
};

class GlsServiceApiClient {
    protected baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl.replace(/\/+$/, '');
    }

    /**
     * Create session in EID GLS service.
     *
     * Possible error codes:
     * - 400 Bad Request - invalid case ID
     * - 401 Unauthorized - invalid session
     * - 403 Forbidden - invalid session
     * - 500 Internal Server Error - couldn't parse data from GLS
     */
    public async authorize(caseId: number, authCode: string | null): Promise<GlsCaseData> {
        const data = authCode ? { auth_code: authCode } : undefined;
        return convertCaseDetailsFromInternal(
            await this.request<GlsInternalCaseData>('POST', url`/v1/case/${caseId}/authorizations`, data)
        );
    }

    /**
     * Obtain session data of EID GLS service.
     *
     * Possible error codes:
     * - 401 Unauthorized - invalid session
     * - 403 Forbidden - invalid session
     */
    public async getSession(): Promise<GlsUserSession> {
        return convertUserSessionFromInternal(
            await this.request<GlsInternalUserSession>('GET', url`/v1/session`)
        );
    }

    /**
     * Clear user session data.
     */
    public async clearSession(): Promise<any> {
        return await this.request('DELETE', url`/v1/session`);
    }

    /**
     * Get current case status.
     *
     * Possible error codes:
     * - 400 Bad Request - invalid case ID
     * - 401 Unauthorized - invalid session
     * - 403 Forbidden - no permission to selected case ID
     */
    public async getStatus(caseId: number): Promise<GlsCaseStatus> {
        return convertStatusFromInternal(
            await this.request<GlsInternalCaseStatus>('GET', url`/v2/case/${caseId}/status`),
        );
    }

    /**
     * Get already submitted data.
     *
     * Possible error codes:
     * - 400 Bad Request - invalid case ID
     * - 401 Unauthorized - invalid session
     * - 403 Forbidden - no permission to selected case ID
     * - 500 Internal Server Error - couldn't parse data from GLS
     */
    public async getSubmittedData(caseId: number): Promise<GlsCaseSubmission | null> {
        try {
            const response = await this.request<GlsInternalCaseSubmission>('GET', url`/v2/case/${caseId}/submission`);
            return response === null ? null : convertCaseSubmissionFromInternal(response);
        } catch (error) {
            if (error?.reason === 'gls/not-found') {
                return null;
            }
            throw error;
        }
    }

    /**
     * Submit new data for current case.
     *
     * Possible error codes:
     * - 400 Bad Request - invalid case ID
     * - 400 Bad Request - invalid data passed for submission
     * - 401 Unauthorized - invalid session
     * - 403 Forbidden - no permission to selected case ID
     * - 409 Conflict - problem while saving data in DB (probably data has been already sent)
     * - 500 Internal Server Error - problem with RPC worker connection
     */
    public async submit(caseId: number, submission: GlsCaseSubmissionInput): Promise<any> {
        // Since v2 submission requires a COI input, explicitly pass a blank array for the COI input until
        // we decide to add COI support again
        const payload = {
            ...convertCaseSubmissionInputToInternal(submission),
            insurance_coi: [],
        };
        return this.request('POST', url`/v2/case/${caseId}/submission`, payload);
    }

    /**
     * Delete field worker e-mail from current case.
     *
     * Possible error codes:
     * - 400 Bad Request - invalid case ID
     * - 401 Unauthorized - invalid session
     * - 403 Forbidden - no permission to selected case ID
     * - 500 Internal Server Error - DB problem
     */
    public async deleteFieldWorker(caseId: number, email: GlsEmail): Promise<any> {
        // Build and perform a request
        return this.request('DELETE', url`/v1/case/${caseId}/submission/field_workers/${email}`);
    }

    /**
     * Add field worker e-mail from current case.
     *
     * Possible error codes:
     * - 400 Bad Request - invalid case ID, case closed, already exists, RPR problem or Google problem
     * - 401 Unauthorized - invalid session
     * - 403 Forbidden - no permission to selected case ID
     * - 500 Internal Server Error - DB problem
     */
    public async addFieldWorker(caseId: number, email: GlsEmail): Promise<any> {
        // Build and perform a request
        return this.request('POST', url`/v1/case/${caseId}/submission/field_workers/${email}`);
    }

    /**
     * Add business owner e-mail from current case.
     *
     * Possible error codes:
     * - 400 Bad Request - invalid case ID, case closed, already exists, RPR problem or Google problem
     * - 401 Unauthorized - invalid session
     * - 403 Forbidden - no permission to selected case ID
     * - 500 Internal Server Error - DB problem
     */
    public async addBusinessOwner(caseId: number, email: GlsEmail): Promise<any> {
        // Build and perform a request
        return this.request('POST', url`/v1/case/${caseId}/submission/business_owners/${email}`);
    }

    /**
     * Resend e-mail with submission link to selected business owners.
     *
     * Possible error codes:
     * - 400 Bad Request - all e-mails failed
     * - 401 Unauthorized - invalid session
     * - 403 Forbidden - no permission to selected case ID
     * - 200 OK - partial or full success
     */
    public async resendBusinessOwnersLink(caseId: number, emails: GlsEmail[]): Promise<{
        success: boolean | null;
        failed: GlsEmail[];
    }> {
        const requestUrl = url`/v1/case/${caseId}/submission/business_owners/notifications`;
        const { failed_emails: failed } = await this.request('POST', requestUrl, { emails }, [ 400 ]);
        return {
            success: failed?.length === 0 ? true : failed?.length < emails.length ? null : false,
            failed,
        };
    }

    /**
     * Resend e-mail with submission link to selected field workers.
     *
     * Possible error codes:
     * - 400 Bad Request - all e-mails failed
     * - 401 Unauthorized - invalid session
     * - 403 Forbidden - no permission to selected case ID
     * - 200 OK - partial or full success
     */
    public async resendFieldWorkersLink(caseId: number, emails: GlsEmail[]): Promise<{
        success: boolean | null;
        failed: GlsEmail[];
    }> {
        const requestUrl = url`/v1/case/${caseId}/submission/field_workers/notifications`;
        const { failed_emails: failed } = await this.request('POST', requestUrl, { emails }, [ 400 ]);
        return {
            success: failed?.length === 0 ? true : failed?.length < emails.length ? null : false,
            failed,
        };
    }

    /**
     * Delete business owner e-mail from current case.
     * It can't be primary business owner.
     *
     * Possible error codes:
     * - 400 Bad Request - invalid case ID
     * - 401 Unauthorized - invalid session or primary business owner requested
     * - 403 Forbidden - no permission to selected case ID
     * - 500 Internal Server Error - DB problem
     */
    public async deleteBusinessOwner(caseId: number, email: GlsEmail): Promise<any> {
        // Build and perform a request
        return this.request('DELETE', url`/v1/case/${caseId}/submission/business_owners/${email}`);
    }

    /**
     * Make a request to EID GLS service.
     */
    private request<T = any>(
        method: string,
        requestUrl: string,
        data?: any,
        allowedAdditionalHttpStatuses: number[] = [],
    ): Promise<T> {
        return new Promise((resolve, reject) => {
            // Initialize XHR object
            const xhr = new XMLHttpRequest();
            xhr.withCredentials = true;
            xhr.open(method, `${this.baseUrl}${requestUrl}`);
            xhr.setRequestHeader('cache-control', 'no-cache');

            // Handle valid response (read as JSON, but fallback to auth error or responseText)
            xhr.addEventListener('load', () => {
                const statusErrorFactory = statusErrorFactories[xhr.status];
                if (statusErrorFactory) {
                    return reject(statusErrorFactory(xhr));
                }
                const result = parseJsonConditionally(xhr.responseText);
                const success = (
                    (xhr.status >= 200 && xhr.status < 300) ||
                    allowedAdditionalHttpStatuses.includes(xhr.status)
                );
                const finish = success ? resolve : reject;
                return finish(result);
            });

            // Handle connection problem
            xhr.addEventListener('error', (error) => {
                console.error(`GLS Api Client request error: ${requestUrl} [${xhr.status}]`, error);
                reject(error);
            });

            // Initialize request
            if (data === undefined) {
                xhr.send();
            } else {
                xhr.send(JSON.stringify(data));
            }
        });
    }
}

export default GlsServiceApiClient;
