<template>
    <div>
        <Checkbox v-model="hasPastName" extra-class="PastNameQuestion__acceptCheck" @input="onChange">
            I have a maiden name or previous name to report.
        </Checkbox>
        <label class="LineInputBlock">
            <span>Enter your past first name<i v-if="hasPastName" class="fg-danger">*</i></span>
            <input
                v-model="firstName"
                type="text"
                :disabled="!hasPastName"
                @input="onChange"
            >
        </label>
        <label class="LineInputBlock">
            <span>Enter your past middle name</span>
            <input
                v-model="middleName"
                type="text"
                :disabled="!hasPastName"
                @input="onChange"
            >
        </label>
        <label class="LineInputBlock">
            <span>Enter your past last name<i v-if="hasPastName" class="fg-danger">*</i></span>
            <input
                v-model="lastName"
                type="text"
                :disabled="!hasPastName"
                @input="onChange"
            >
        </label>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import PastNameValue from '@/fields/interfaces/PastName';
    import Checkbox from '@/components/forms/Checkbox.vue';

    @Component({
        components: { Checkbox },
    })
    export default class PastName extends FieldComponent<PastNameValue> {
        private hasPastName: boolean = Boolean(this.value && this.value.has);
        private firstName: string = (this.value && this.value.first) || '';
        private middleName: string = (this.value && this.value.middle) || '';
        private lastName: string = (this.value && this.value.last) || '';

        @Watch('value')
        private onValueChange(value: PastNameValue) {
            this.hasPastName = Boolean(value && value.has);
            this.firstName = value && value.first;
            this.middleName = value && value.middle;
            this.lastName = value && value.last;
        }

        private onChange() {
            if (!this.hasPastName) {
                this.firstName = '';
                this.middleName = '';
                this.lastName = '';
            }

            this.$emit('change', {
                has: this.hasPastName,
                first: this.firstName,
                middle: this.middleName,
                last: this.lastName,
            });
        }
    }
</script>
