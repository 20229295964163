import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Liveness from '@/interfaces/Liveness';
import Question from '@evidentid/ido-lib/interfaces/Question';
import LivenessCheckComponent from '@/components/fields/LivenessCheck.vue';
import createBinaryDataAttributeValue from '@/utils/createBinaryDataAttributeValue';

const DEFAULT_SELFIE_IMAGE_ATTR_TYPE = 'identity_assurance.biometric_verification.video_selfie.selfie_image';

export default class LivenessCheckField extends Field<Liveness> {
    public override form = LivenessCheckComponent;

    public isValid(_: Question, value: Liveness | any): boolean {
        return Boolean(value?.faceMap) && Boolean(value?.selfie);
    }

    public async encode(question: Question, value: any): Promise<AttributesValues> {
        const selfieAttrType = question.metadata?.selfieAttrType || DEFAULT_SELFIE_IMAGE_ATTR_TYPE;

        const binarySelfie = createBinaryDataAttributeValue({
            filename: 'video-selfie.jpg',
            usable: Boolean(value?.selfie),
            dataUrl: value?.selfie || null,
            mimeType: 'image/jpeg',
        });

        return {
            ...this._encode(question, value?.faceMap),
            [selfieAttrType]: {
                shareWith: question.shareWith,
                value: binarySelfie,
            },
        };
    }
}
