<template>
    <div v-if="!question.complete && !disabled" class="GlsFormQuestion">
        <header class="QuestionHeader">
            <h3>{{ question.metadata.title }}</h3>
            <div class="QuestionHeader__subtitle">
                {{ question.metadata.emptyInformation }}
            </div>
        </header>
        <AddressInput :value="value" @input="$emit('input', $event)" />
    </div>
    <div v-else-if="value || (!question.complete && disabled)" class="GlsFormQuestion">
        <header class="QuestionHeader">
            <h3>{{ question.metadata.title }}</h3>
            <div class="QuestionHeader__subtitle">
                {{ disabled ? question.metadata.completedInformation : question.metadata.instructions }}
            </div>
        </header>
        <AddressInput :value="value" disabled />
    </div>
    <div v-else class="GlsFormQuestion">
        <header class="QuestionHeader">
            <h3>{{ question.metadata.title }}</h3>
            <div class="QuestionHeader__subtitle">
                {{ question.metadata.emptyInformation }}
            </div>
        </header>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import AddressInput from '@/components/forms/AddressInput.vue';
    import Address from '@/fields/interfaces/Address';
    import Question from '@evidentid/ido-lib/interfaces/Question';

    @Component({
        components: { AddressInput },
    })
    export default class GlsAddressQuestion extends Vue {
        @Prop({ type: Object })
        private question!: Question;

        @Prop({ type: Object, default: () => null })
        private value!: Address;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;
    }
</script>
