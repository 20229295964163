<template>
    <div class="FileQuestion__previews">
        <FilePreview
            v-for="(file, index) in files"
            :key="index"
            :file="file"
            :uploading="uploading"
            @remove="removeFile"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import FilePreview from './FilePreview.vue';

    @Component({
        components: { FilePreview },
    })
    export default class FilesListPreview extends Vue {
        @Prop({ type: Array, default: () => [] })
        private files!: File[];

        @Prop({ type: Boolean, default: false })
        private uploading!: boolean;

        private removeFile(file: File) {
            this.$emit('remove', file);
        }
    }
</script>
