<template>
    <div class="LineInputBlock SelectMultipleOptions">
        <span v-if="!bare" class="SelectMultipleOptions__title">
            {{ question.metadata.description }}
        </span>
        <Checkbox
            v-for="option in options"
            :key="option"
            :value="inputValue.includes(option)"
            @input="onChange(option)"
        >
            <div class="SelectMultipleOptions__label">
                {{ buildLabel(option) }}
            </div>
        </Checkbox>
    </div>
</template>

<style lang="scss">
    .SelectMultipleOptions {
        &__title {
            margin-bottom: 10px;
        }

        &__label {
            display: inline;
        }

        .Checkbox {
            cursor: pointer;

            i {
                color: #2eb495;
            }
        }
    }
</style>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import Dropdown from '@/components/forms/Dropdown.vue';
    import Checkbox from '@/components/forms/Checkbox.vue';
    import FieldComponent from '@/fields/FieldComponent';
    import upperFirst from 'lodash/upperFirst';

    @Component({
        components: { Dropdown, Checkbox },
    })
    export default class SelectMultipleOptions extends FieldComponent<string[]> {
        private inputValue = this.value || [];

        private get constraints(): { options?: [], customLabels?: Record<string, string> } {
            return JSON.parse(this.question.metadata?.constraints || '{}');
        }

        private get options(): string[] {
            return this.constraints?.options || [];
        }

        private get customLabels(): Record<string, string> {
            return this.constraints?.customLabels || {};
        }

        private buildLabel(value: string): string {
            return value in this.customLabels ?
                this.customLabels[value] :
                value.split('_').map((s) => upperFirst(s)).join(' ');
        }

        @Watch('value')
        private onValueChange(value: string[]) {
            this.inputValue = value || [];
        }

        private onChange(option: string) {
            this.inputValue = this.inputValue.includes(option) ?
                this.inputValue.filter((x) => x !== option) :
                [ ...this.inputValue, option ];

            this.$emit('change', this.inputValue);
        }
    }
</script>
