<template>
    <div v-if="!file" class="MobileSimplexPreview">
        <Spinner />
    </div>
    <div
        v-else
        class="MobileSimplexPreview"
        :class="{ 'MobileSimplexPreview--flexible': flexible }"
    >
        <BlobImage :src="file" />
        <div class="MobileSimplexPreview__remove" touch-action="auto" @click="$emit('delete')">
            <Icon name="delete" />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';
    import Spinner from '@/components/common/Spinner.vue';
    import BlobImage from '@/components/common/BlobImage.vue';

    @Component({
        components: { BlobImage, Spinner, Icon },
    })
    export default class PhotoPreview extends Vue {
        @Prop({ type: File, default: () => null })
        private file!: File | null;

        @Prop({ type: Boolean, default: false })
        private flexible!: boolean;
    }
</script>
