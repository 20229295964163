<template>
    <PendingScreen fullscreen />
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import { Status } from '@/store/interfaces/IdoState';
    import PendingScreen from '@/components/screens/PendingScreen.vue';

    @Component({
        components: { PendingScreen },
    })
    export default class WaitingForResults extends Vue {
        private isMounted = false;
        private transitionTimeout: number | null = null;

        private get consentGiven() {
            return this.$store.state.consentGiven;
        }

        private get duringTransition() {
            return this.$store.state.status === Status.transition;
        }

        private async mounted() {
            // Mark component as mounted, for polling safe-check
            this.isMounted = true;

            // Ensure that user has given consent for sending data
            if (!this.consentGiven) {
                await this.$store.dispatch('sendConsent');
            }

            // Start polling for transition request
            this.tickTransition();
        }

        private destroyed() {
            this.isMounted = false;
            if (this.transitionTimeout) {
                clearTimeout(this.transitionTimeout);
            }
        }

        private async tickTransition() {
            // Clear timeout information
            this.transitionTimeout = null;

            // Stop polling when this component is no longer mounted
            if (!this.isMounted) {
                return;
            }

            // When it's in "finishing" step, request transition change
            if (!this.duringTransition) {
                await this.$store.dispatch('requestTransition');
            }

            // Try again in 1 second
            this.transitionTimeout = setTimeout(() => this.tickTransition(), 1000);
        }
    }
</script>
