import { render, staticRenderFns } from "./PhoneNumberInput.vue?vue&type=template&id=13aec086&"
import script from "./PhoneNumberInput.vue?vue&type=script&lang=ts&"
export * from "./PhoneNumberInput.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports