<template>
    <div
        class="OnlineChat"
        :class="{ 'OnlineChat--left': botPosition === LEFT_POSITION }"
    >
        <OnlineChatBox
            v-if="chatOpened"
            disallow-view-more
            :chat-messages="chatMessages"
            :is-loading-agent-response="loadingResponse"
            @message-input="sendMessage"
            @close="toggleChatBox(false)"
        />
        <div v-else class="OnlineChat__buttonContainer">
            <OnlineChatButton
                id="evident_evie"
                :chat-opened="chatOpened"
                :draggable="!disableReposition"
                @toggle="toggleChatBox"
                @dragstart="dragging = true"
                @dragend="dragging = false"
            />
        </div>
        <div
            v-if="dragging"
            class="OnlineChat__dragArea"
        >
            <div
                class="OnlineChat__preview"
                :class="[`OnlineChat__preview--${botPositionPreview}`, { 'OnlineChat__preview--dropping': dropping }]"
                @drop.prevent="repositionEvie"
                @dragover.prevent="dropping = true"
                @dragenter="dropping = true"
                @dragleave="dropping = false"
            >
                <img
                    class="OnlineChat__evieIcon--preview"
                    :src="evieImg"
                    alt="eviePreview"
                >
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "./index.scss";
</style>

<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import { PropType } from 'vue';
    import { ChatMessage, ChatParty } from '../../models';
    import OnlineChatBox from '../OnlineChatBox/OnlineChatBox.vue';
    import OnlineChatButton from '../OnlineChatButton/OnlineChatButton.vue';
    import { getChatSessionId, setChatSessionId } from '../../utils/session-states/getSetSessionId';
    import { getChatOpenStatus, setChatOpenStatus } from '../../utils/session-states/getSetChatOpenStatus';
    import { addMessageToSessionHistory, getSessionChatHistory } from '../../utils/session-states/sessionHistory';
    import { createOnlineChatMessage } from '../../utils/createOnlineChatMessage';
    import {
        CHAT_BOT_WELCOME_MSG,
        CHAT_ERROR_RESPONSE_MSG,
        EVIE_POSITION,
        SESSION_KEY_CHAT_HISTORY,
    } from '../../utils/session-states/constants';
    import { get } from 'lodash';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { getSessionData, setSessionData } from '../../utils/session-states/getSetSessionData';
    import evieImg from '../../assets/online-chat-evie-image.svg';

    const DEFAULT_STATE_PATH = 'state.onlineChat';
    const DEFAULT_ACTIONS_PATH = 'actions.onlineChat';
    const LEFT_POSITION = 'left';
    const RIGHT_POSITION = 'right';
    /*
    * 3 ways to use this component
    * 1. for apps like insurance-facing where we can reuse the whole module (same api client)
    *  - simply demand the module from application index.
    * 2. for apps that uses store but actions/state are not matching this module
    *  - implement own function to communicate to chat-bot api and handle insert question to history
    *  - provide action path for askAgent function, provide path for loadAgentResponse state
    * 3. for apps not using store
    *  - implement own function to communicate to chat-bot api and handle insert question to history
    *  - pass in async askAgent function (sendMessageFunc)
    * */
    export default Vue.extend({
        name: 'OnlineChat',
        components: {
            OnlineChatBox,
            OnlineChatButton,
        },
        props: {
            disallowViewMore: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            sendMessageFunc: {
                type: Function as PropType<(message: string) => Promise<any>>,
                default: null,
            },
            customStatePath: {
                type: String as PropType<string>,
                default: null,
            },
            customActionsPath: {
                type: String as PropType<string>,
                default: null,
            },
            disableReposition: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        data(): {
            viewingMessage: ChatMessage | null;
            loadingMessageViaPropFunc: boolean;
            chatOpened: boolean;
            chatMessages: ChatMessage[];
            sessionId: string | null;
            botPosition: string;
            dragging: boolean;
            dropping: boolean;
            evieImg: any;
            LEFT_POSITION: string;
        } {
            return {
                viewingMessage: null,
                loadingMessageViaPropFunc: false,
                chatOpened: getChatOpenStatus(),
                chatMessages: getSessionChatHistory(),
                sessionId: getChatSessionId(),
                botPosition: RIGHT_POSITION,
                dragging: false,
                dropping: false,
                evieImg,
                LEFT_POSITION,
            };
        },
        computed: {
            botPositionPreview(): string {
                return this.botPosition === LEFT_POSITION ? RIGHT_POSITION : LEFT_POSITION;
            },
            store(): any {
                // not using store when Func passed
                if (this.sendMessageFunc !== null) {
                    return null;
                }
                return (this as any).$store;
            },
            useStore(): boolean {
                const hasChatState =
                    Boolean(get(this.store, DEFAULT_STATE_PATH) || get(this.store, this.customStatePath));
                const hasChatActions =
                    Boolean(get(this.store, DEFAULT_ACTIONS_PATH) || get(this.store, this.customActionsPath));
                return hasChatState && hasChatActions;
            },
            // Rp name can be null if module/component is being used in apps such as ido-facing
            rpName(): string | null {
                return (this as any).$rp?.current || null;
            },
            getLoadingStateFromStore(): boolean {
                const statePath = this.customStatePath || DEFAULT_STATE_PATH;
                return get(this.store, statePath)?.loadAgentResponse === OperationStatus.loading || false;
            },
            loadingResponse(): boolean {
                return this.useStore
                    ? this.getLoadingStateFromStore
                    : this.loadingMessageViaPropFunc;
            },
        },
        mounted() {
            setChatSessionId();
            this.sessionId = getChatSessionId();
            this.botPosition = getSessionData(EVIE_POSITION) || RIGHT_POSITION;
        },
        methods: {
            toggleChatBox(isOpen?: boolean): void {
                const opened = isOpen !== undefined ? isOpen : !this.chatOpened;
                setChatOpenStatus(opened);
                this.getChatOpenStatusFromSession();
                if (opened) {
                    this.addWelcomeMessageIfEmpty();
                }
            },
            getChatOpenStatusFromSession(): void {
                this.chatOpened = getChatOpenStatus();
            },
            getChatMessagesFromSession(): void {
                this.chatMessages = getSessionChatHistory();
            },
            addWelcomeMessageIfEmpty(): void {
                if (this.chatMessages.length === 0) {
                    addMessageToSessionHistory(createOnlineChatMessage(CHAT_BOT_WELCOME_MSG, ChatParty.agent));
                    this.getChatMessagesFromSession();
                }
            },
            repositionEvie(): void {
                this.dropping = false;
                this.botPosition = this.botPositionPreview;
                setSessionData(EVIE_POSITION, this.botPosition);
            },
            async askAgentQuestion(question: string): Promise<void> {
                const actionPath = this.customActionsPath || DEFAULT_ACTIONS_PATH;
                const actionFunc = get(this.store, actionPath).askAgent as Function;
                if (actionFunc) {
                    const userEmail = this.store?.state?.user?.data?.email || null;
                    await actionFunc({
                        rpName: this.rpName,
                        question,
                        sessionId: this.sessionId,
                        userEmail,
                    });
                }
            },
            async sendMessage(message: string): Promise<void> {
                addMessageToSessionHistory(createOnlineChatMessage(message, ChatParty.client));
                this.getChatMessagesFromSession();
                if (this.sendMessageFunc) {
                    this.loadingMessageViaPropFunc = true;
                    try {
                        const result = await this.sendMessageFunc(message);
                        addMessageToSessionHistory(createOnlineChatMessage(result, ChatParty.agent));
                    } catch (error) {
                        addMessageToSessionHistory(createOnlineChatMessage(CHAT_ERROR_RESPONSE_MSG, ChatParty.agent));
                    }
                    this.loadingMessageViaPropFunc = false;
                    this.getChatMessagesFromSession();
                } else if (this.useStore) {
                    await this.askAgentQuestion(message);
                    this.getChatMessagesFromSession();
                }
            },
        },
    });
</script>
