<template>
    <div class="DocumentInput">
        <div v-if="availableDocumentTypes.length > 1" class="DocumentInput__type">
            <label for="doc-type-dropdown">Identity document type</label>
            <Dropdown
                id="doc-type-dropdown"
                :placeholder="value && value.type ? null : undefined"
                :value="value ? value.type : null"
                :options="availableDocumentTypes"
                :build-label="getDocumentTypeLabel"
                @input="onDocumentTypeChange"
            />
        </div>

        <template v-if="details">
            <p class="DocumentInput__instructions">
                {{ details.universalInstructionsText }}
            </p>

            <div class="DocumentInput__pages">
                <DocumentPageInput
                    v-for="(page, index) in details.pages"
                    :key="page.id"
                    class="DocumentInput__page"
                    :acuant-type="details.acuantType"
                    :flexible-preview="details.pages.length === 1"
                    :value="value && value.pages && value.pages[index]"
                    :label="details.pages.length > 1 ? page.name : undefined"
                    @input="onPageChange(index, $event)"
                    @invalid="$emit('invalid', $event)"
                />
            </div>
        </template>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { DocumentType } from '@evidentid/ido-lib/interfaces/Question';
    import documents, { DocumentDetails, universalDocuments } from '@/config/documents';
    import Dropdown from '@/components/forms/Dropdown.vue';
    import { FileData } from '@evidentid/file-utils/FileData';
    import DocumentPageInput from '@/components/forms/DocumentPageInput.vue';

    @Component({
        components: { DocumentPageInput, Dropdown },
    })
    export default class DocumentInput extends Vue {
        @Prop({ type: Object, default: () => ({}) })
        private value!: any;

        @Prop({ type: Array, default: () => universalDocuments })
        private availableDocumentTypes!: DocumentType[];

        private get details() {
            return this.value?.type ? documents[this.value.type as DocumentType] : null;
        }

        private getDocumentTypeLabel(type: DocumentType) {
            const document = documents[type];
            return document?.dropdownName || type;
        }

        private onDocumentTypeChange(type: DocumentType) {
            this.$emit('input', {
                ...this.value,
                currentPage: 0,
                pages: [],
                type,
            });
        }

        private onPageChange(index: number, fileData: FileData) {
            const pages = [ ...this.value?.pages || [] ];

            // Ensure that we've got enough pages filled and replace it on selected index
            pages.length = (this.details as DocumentDetails).pages.length;
            pages[index] = fileData;

            this.$emit('input', { ...this.value, pages });
        }
    }
</script>

<style lang="less">
    .DocumentInput {
        &__type {
            label {
                display: block;
                margin-bottom: 5px;
                font-size: 14px;
            }

            .Dropdown {
                width: 100%;
                padding: 15px;
                color: #000;
                background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 400 167' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m24.065683,0l238,0c21.4,0 32.1,25.9 17,41l-119,119c-9.4,9.4 -24.6,9.4 -33.9,0l-119.1,-119c-15.1,-15.1 -4.4,-41 17,-41z' fill='%234c585e'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: 0.85em;
                background-position: 97% center;
            }
        }

        &__instructions {
            font-size: 14px;
            margin: 30px 0 20px;
        }

        &__pages {
            display: flex;
            margin: 10px -10px 30px;
        }

        &__page {
            text-align: center;
            flex: 1 0;
            margin: 0 10px;
            font-size: 12px;
        }
    }
</style>
