import EXIF from 'exif-js';

// All rotations are going clock-opposite
export enum Orientation {
    regular = 1,
    mirror = 2,
    rotate180 = 3,
    mirrorRotate180 = 4,
    mirrorRotate90 = 5,
    rotate90 = 6,
    mirrorRotate270 = 7,
    rotate270 = 8,
}

export function isJpegFile(file: ArrayBuffer): boolean {
    const view = new DataView(file);
    return view.getUint16(0, false) === 0xFFD8;
}

/**
 * Get simple information about JPEG orientation.
 *
 * @see https://github.com/exif-js/exif-js/issues/63#issuecomment-359807684
 * @param {ArrayBuffer} file
 */
export function getJpegOrientation(file: ArrayBuffer): Orientation {
    const view = new DataView(file);
    const length = view.byteLength;

    let offset = 2;
    while (offset + 1 < length) {
        const marker = view.getUint16(offset, false);
        offset += 2;

        if (marker === 0xFFE1) {
            if (view.getUint32(offset += 2, false) !== 0x45786966) {
                return Orientation.regular;
            }

            const little = view.getUint16(offset += 6, false) === 0x4949;
            offset += view.getUint32(offset + 4, little);
            const tags = view.getUint16(offset, little);
            offset += 2;
            for (let i = 0; i < tags; i++) {
                if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                    return view.getUint16(offset + (i * 12) + 8, little);
                }
            }
        } else if ((marker & 0xFF00) === 0xFF00) { // eslint-disable-line no-bitwise
            offset += view.getUint16(offset, false);
        } else {
            break;
        }
    }

    return Orientation.regular;
}

export function getExifData(file: ArrayBuffer) {
    if (!isJpegFile(file)) {
        return {};
    }

    try {
        return {
            Orientation: Orientation.regular, // ensure that we will always have Orientation passed (even default one)
            ...EXIF.readFromBinaryFile(file) || {},
        };
    } catch (error) {
        return {
            Orientation: getJpegOrientation(file),
        };
    }
}
