<template>
    <li class="TimelineItem" :class="additionalClassName">
        <div class="TimelineItem__icon">
            <Icon v-if="icon" :name="icon" />
            <Icon v-else-if="type === 'ongoing'" name="circle" />
            <Icon v-else-if="type === 'success'" name="checkmark" />
            <Icon v-else-if="type === 'error'" name="x" />
        </div>
        <div class="TimelineItem__description">
            <strong>{{ title }}</strong>
            <small v-if="description">{{ description }}</small>
        </div>
    </li>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { IdoRequestStatusTimelineEntryStatus } from '@evidentid/ido-lib/interfaces/IdoRequestStatus';
    import Icon from '@/components/common/Icon.vue';

    @Component({
        components: { Icon },
    })
    export default class TimelineItem extends Vue {
        @Prop({ type: String, default: null })
        private type!: IdoRequestStatusTimelineEntryStatus | null;

        @Prop({ type: String, default: null })
        private icon!: string | null;

        @Prop({ type: String, default: '' })
        private title!: string;

        @Prop({ type: String, default: '' })
        private description!: string;

        private get additionalClassName() {
            return this.type ? `TimelineItem--${this.type}` : undefined;
        }
    }
</script>
