import TrackerAdapter from '../TrackerAdapter';
import TrackerUserData from '../TrackerUserData';
import TrackerEvent from '../TrackerEvent';

export default class ConsoleTrackerAdapter implements TrackerAdapter {
    public initialize() {
        return Promise.resolve();
    }

    public isReady() {
        return true;
    }

    public track(event: TrackerEvent, timestamp: number) {
        const { name, ...eventData } = event;
        console.log(
            `%c[track: event] %c${new Date(timestamp).toISOString()} %c${name}`,
            'font-weight: bold; color: magenta',
            'font-weight: bold; color: blue',
            'font-weight: bold',
            eventData,
        );
    }

    public setUserData(userData: TrackerUserData | null) {
        console.log('%c[track: user]', 'font-weight: bold; color: magenta', userData);
    }
}
