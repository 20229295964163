<template>
    <p class="ConstView">
        {{ form.schema.description }}
    </p>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaConst } from '@evidentid/json-schema/interfaces/JsonSchema';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    type JsonFormConst = JsonFormBase<JsonSchemaConst>;

    @Component({
        inheritAttrs: false,
    })
    export default class JsonSchemaConstForm extends AbstractJsonSchemaForm<JsonFormConst, any> {
    }
</script>
