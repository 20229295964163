<template>
    <div class="LicenseQuestionContactFooter">
        <h4 class="LicenseQuestionContactFooter__header">
            Trouble submitting your License Number?
            <a
                :href="href"
                target="_blank"
                rel="noopener noreferrer"
            >Contact us here.</a>
        </h4>
        <span class="LicenseQuestionContactFooter__text">
            Please contact us if you have a valid license that doesn’t match the required format
            or need assistance with another question.
        </span>
    </div>
</template>
<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { generateContactEmailBody } from '@/utils/licenseValidation/generateContactEmailBody';
    import License from '@/interfaces/licenses/License';

    @Component
    export default class LicenseQuestionContactFooter extends Vue {
        @Prop({ type: String, default: '' })
        private title!: string;

        @Prop({ type: Array as PropType<License[]>, default: () => [] })
        private licenses!: License[];

        private get href(): string {
            const subject = `${this.title} Support`;
            return `mailto:support@evidentid.com?body=${generateContactEmailBody(this.title, this.licenses)}&subject=${subject}`;
        }
    }
</script>
