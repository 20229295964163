/**
 * This file is used for redirection,
 * so we are able to test location changes.
 * Unfortunately, neither `window.location` nor `location.assign` can be spied.
 */
export const windowObject = {
    location: window.location,
};

export default function redirect(url: string) {
    windowObject.location.assign(url);
}
