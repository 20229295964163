<template>
    <div class="GoogleLoginButton">
        <img :src="imageUrl" touch-action="auto" @click="redirect">
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import loginButtonFilePath from '@evidentid/ido-lib/images/google-login-button.svg';
    import redirect from '@evidentid/vue-commons/router/redirect';

    @Component
    export default class GoogleLoginButton extends Vue {
        private imageUrl = loginButtonFilePath;

        private redirect() {
            const prefix: string = deploy.WEB_PUBLIC_IDOWEB_SERVICE_URL_PREFIX;
            redirect(`${prefix}/authgooglev2`);
        }
    }
</script>
