<template>
    <section class="RequestStatus">
        <Alert v-if="status === 'loading'" icon="spinner">
            {{ message }}
        </Alert>
        <Alert v-else-if="status === 'success'" icon="list-finished" type="success">
            {{ message }}
        </Alert>
        <Alert v-else-if="status === 'error'" type="error" icon="error">
            {{ message }}
        </Alert>

        <div class="RequestStatus__columns">
            <Timeline>
                <TimelineItem
                    v-for="(entry, index) in timeline"
                    :key="index"
                    :type="entry.status"
                    :title="format(entry.title, entry.metadata)"
                    :description="format(entry.description, entry.metadata)"
                />
            </Timeline>

            <div class="RequestStatus__description">
                <p>{{ format(description) }}</p>

                <h3>What happens next?</h3>
                <p>{{ format(nextStepsDescription) }}</p>

                <a v-if="nextStepsLink" target="_blank" class="RequestStatus__nextLink" :href="nextStepsLink">
                    <Icon name="raquo" /> <span>{{ format(nextStepsLinkTitle) }}</span>
                </a>
            </div>
        </div>

        <footer v-if="hasFooter" class="RequestStatusFooter">
            <div class="RequestStatusFooter__content">
                <a v-if="supportLink" target="_blank" class="RequestStatusFooter__link" :href="supportLink">
                    <Icon name="file-text" /> <span>{{ format(supportTitle) }}</span>
                </a>
                <a v-if="supportEmail" target="_blank" class="RequestStatusFooter__link" :href="emailLink">
                    <Icon name="envelope-solid" /> <span>{{ supportEmail }}</span>
                </a>
                <a v-if="supportPhone" target="_blank" class="RequestStatusFooter__link" :href="phoneLink">
                    <Icon name="phone" /> <span>{{ supportPhone }}</span>
                </a>
            </div>
        </footer>
    </section>
</template>

<style lang="less">
    @import "~@evidentid/ido-lib/views/RequestStatus.less";
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import Alert from '@/components/common/Alert.vue';
    import Timeline from '@/components/common/Timeline.vue';
    import TimelineItem from '@/components/common/TimelineItem.vue';
    import Icon from '@/components/common/Icon.vue';
    import formatMessage from '@evidentid/universal-framework/formatMessage';
    import { IdoRequestStatus, IdoRequestStatusTimelineEntry } from '@evidentid/ido-lib/interfaces/IdoRequestStatus';

    @Component({
        components: { Icon, TimelineItem, Timeline, Alert },
    })
    export default class RequestStatus extends Vue {
        @Prop({ type: String, default: null })
        private status!: IdoRequestStatus | null;

        @Prop({ type: String, default: null })
        private message!: string | null;

        @Prop({ type: String, default: null })
        private supportEmail!: string | null;

        @Prop({ type: String, default: null })
        private supportLink!: string | null;

        @Prop({ type: String, default: null })
        private supportTitle!: string | null;

        @Prop({ type: String, default: null })
        private supportPhone!: string | null;

        @Prop({ type: String, default: null })
        private nextStepsLinkTitle!: string | null;

        @Prop({ type: String, default: null })
        private nextStepsLink!: string | null;

        @Prop({ type: String, default: null })
        private description!: string | null;

        @Prop({ type: String, default: null })
        private nextStepsDescription!: string | null;

        @Prop({ type: Object, default: () => ({}) })
        private metadata!: Record<string, any>;

        @Prop({ type: Array, default: () => [] })
        private timeline!: IdoRequestStatusTimelineEntry[];

        private get hasFooter() {
            return Boolean(this.supportEmail || this.supportLink || this.supportPhone);
        }

        private get emailLink() {
            return `mailto:${this.supportEmail}`;
        }

        private get phoneLink() {
            return `tel:${this.supportPhone}`;
        }

        private format(text: string | null | undefined, additionalMetadata?: Record<string, any>) {
            return text
                ? formatMessage(text, { ...this.metadata, ...additionalMetadata })
                : text;
        }
    }
</script>
