<template>
    <Gls v-if="gls" />
    <InterviewCard
        v-else
        :class="{ 'StatusPage StatusPage--terminated-request': isInsuranceTerminatedRequest }"
    >
        <TerminatedRequest
            v-if="isInsuranceTerminatedRequest"
            v-bind="requestStatusProps"
            :privacy-policy="privacyPolicy"
            :issuer="issuer"
        />
        <RequestStatus v-else v-bind="requestStatusProps" />
    </InterviewCard>
</template>

<style lang="less">
    .StatusPage--terminated-request {
        .InterviewCard {
            background: #f8f8f8;

            &__body {
                padding: 0;
            }
        }
    }
</style>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import getIdoRequestStatusData, { RequestStatusData } from '@evidentid/ido-lib/getIdoRequestStatusData';
    import SuccessScreen from '@/components/screens/SuccessScreen.vue';
    import InterviewCard from '@/layouts/InterviewCard.vue';
    import Spinner from '@/components/common/Spinner.vue';
    import Icon from '@/components/common/Icon.vue';
    import Alert from '@/components/common/Alert.vue';
    import Timeline from '@/components/common/Timeline.vue';
    import TimelineItem from '@/components/common/TimelineItem.vue';
    import RequestStatus from '@/components/interview/RequestStatus.vue';
    import { IdoRequestStatus, RelyingPartyRequestStatusType } from '@evidentid/ido-lib/getIdoRequestStatus';
    import GlsForm from '@/components/google/GlsForm.vue';
    import { isGlsSession } from '@/interfaces/SessionData';
    import Gls from '@/views/Gls.vue';
    import TerminatedRequest from '@/components/interview/TerminatedRequest.vue';
    import Identity, { PrivacyPolicy } from '@evidentid/ido-lib/interfaces/Identity';

    @Component({
        components: {
            Gls,
            GlsForm,
            RequestStatus,
            TimelineItem,
            Timeline,
            Alert,
            Icon,
            Spinner,
            InterviewCard,
            SuccessScreen,
            TerminatedRequest,
        },
    })
    export default class StatusPage extends Vue {
        private get gls() {
            const session = this.$store?.state?.session;
            return Boolean(session && isGlsSession(session));
        }

        private get issuer(): Identity | null {
            return this.$store.state.issuer;
        }

        private get requestStatusProps(): RequestStatusData {
            const requestStatus: IdoRequestStatus = this.$store.state.requestStatus || {
                type: RelyingPartyRequestStatusType.new,
                requestedAt: null,
                submittedAt: null,
                completedAt: null,
                daysProcessing: 0,
            };

            return getIdoRequestStatusData(requestStatus, this.issuer);
        }

        private get privacyPolicy(): PrivacyPolicy | null {
            if (!this.issuer) {
                return null;
            }
            return this.issuer.privacyPolicy;
        }

        private get isInsuranceTerminatedRequest(): boolean {
            const isCanceled = this.$store.state.requestStatus?.type === RelyingPartyRequestStatusType.canceled;
            const questions = this.$store?.state?.questions;
            // It doesn't consider the BROKER FLOW. Think about condition for the BROKER FLOW
            const isSelfAttestation = Boolean(
                questions?.length > 0 && questions?.every((question) =>
                    /^risk_decisioning\.insurance_policy_verification\.self_attestation\./.test(question.attrType),
                ),
            );
            return isCanceled && isSelfAttestation;
        }
    }
</script>
