import { buildDigitRegex } from './buildDigitRegex';

// FIXME: It could have more rules, i.e. middle groups 70-87 are expired
const itinNumberRegex = buildDigitRegex(`
    ^
        9[0-9]{8}
    $
`);

const itinPatterns = [
    /^\d{9}$/,
    /^\d{3}-\d{2}-\d{4}$/,
    /^\d{3}\s+\d{2}\s+\d{4}$/,
];

function isSanitizedItinValid(sanitizedItin: null): false;
function isSanitizedItinValid(sanitizedItin: string | null): boolean;
function isSanitizedItinValid(sanitizedItin: string | null): boolean {
    return sanitizedItin !== null && itinNumberRegex.test(sanitizedItin);
}

export function sanitizeItin(itin: null): null;
export function sanitizeItin(itin: string | null): string | null;
export function sanitizeItin(itin: string | null): string | null {
    if (typeof itin !== 'string') {
        return null;
    } else if (!itinPatterns.find((pattern) => pattern.test(itin))) {
        return null;
    }
    const sanitizedItin = itin.replace(/\D+/g, '');
    return isSanitizedItinValid(sanitizedItin) ? sanitizedItin : null;
}

export function isValidItin(itin: null): false;
export function isValidItin(itin: string | null): boolean;
export function isValidItin(itin: string | null): boolean {
    return sanitizeItin(itin) !== null;
}
