/**
 * How many times it should ask back-end for verification results?
 *
 * @type {number}
 */
export const maxPollingTimes = 15;

/**
 * How long it should wait between consecutive back-end requests for verification results?
 *
 * @type {number} milliseconds
 */
export const pollingDelayTimeMs = 2250;

/**
 * How many verification failures should be treat as persistent failure error?
 *
 * @type {number}
 */
export const persistentFailureTimes = 3;

/**
 *
 */
export const stateMessages = {
    uploading: 'Uploading your document',
    fallback: 'Confirming results',
};

/**
 * Messages to show during the preflight check progressing.
 *
 * @type {string[]}
 */
export const waitingMessages = [
    'Your document is being verified',
    'Still verifying your document',
    'Almost done',
];
