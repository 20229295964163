import Vue, { Component as VueComponent } from 'vue';
import { Status } from '@/store/interfaces/IdoState';
import PendingScreen from '@/components/screens/PendingScreen.vue';
import getSessionData from '@/utils/getSessionData';
import support from '@/config/support';

const FINAL_STATUSES = [
    Status.loading,
    Status.success,
    Status.finished,
    Status.transition,
];

export default function withInterview(Component: VueComponent) {
    return Vue.component('withInterview', {
        mounted() {
            const status = this.$store.state.status;
            const storage = support.sessionStorage ? window.sessionStorage : null;
            const session = this.$store.state.session || getSessionData(storage);
            if (!FINAL_STATUSES.includes(status)) {
                this.$store.dispatch('loadInterview', session);
            }
        },

        render(createElement) {
            const { status } = this.$store.state;
            return [ Status.success, Status.transition, Status.finished ].includes(status)
                ? createElement(Component)
                : createElement(PendingScreen, { props: { fullscreen: true } });
        },
    });
}
