<template>
    <div id="root">
        <router-view />
        <Snackbar />
        <ModalTarget parent-class-name-opened="body--modalOpened" />
    </div>
</template>

<script lang="ts">
    import { Vue, Component } from 'vue-property-decorator';
    import Snackbar from '@/components/common/Snackbar.vue';
    import Error from '@/views/Error.vue';
    import { ModalTarget } from '@/components/common/Modal';

    @Component({
        components: { Error, Snackbar, ModalTarget },
    })
    export default class App extends Vue {
    }
</script>

<style lang="less">
    @import "./screen";
    @import "~@evidentid/browser-framework/comps/LabeledControl.less";
    @import "~@evidentid/ido-lib/views/PhotoCapture.less";
    @import "~@evidentid/ido-lib/styles/SmartAttribute.less";
    @import "~@evidentid/ido-lib/styles/ArrayView.less";
    @import "~@evidentid/ido-lib/styles/LicenseStateObjectForm.less";
    @import "~@evidentid/ido-lib/styles/LicenseIdInput.less";
    @import "~@evidentid/ido-lib/styles/LicenseQuestionContactFooter.less";
</style>

