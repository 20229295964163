import { isEmail } from '@evidentid/universal-framework/validation';
import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import BulkEmailComponent from '@/components/fields/BulkEmail.vue';
import BulkEmail from './interfaces/BulkEmail';

export default class BulkEmailField extends Field<BulkEmail> {
    public override component = BulkEmailComponent;

    public override getDefaultValue(question: Question): BulkEmail | null {
        return {
            confirmed: Boolean(question.metadata?.autoConfirmation),
            list: [],
        };
    }

    public isValid(question: Question, value: BulkEmail): boolean {
        const { confirmed, list } = value || {};
        return Boolean(confirmed) &&
            Array.isArray(list) &&
            (list.length > 0 || !question.metadata?.required) &&
            list.reduce((acc: boolean, email) => acc && isEmail(email), true);
    }

    public async encode(question: Question, value: BulkEmail): Promise<AttributesValues> {
        const list = (value && value.list || []);
        return this._encode(question, list.join('\n'));
    }
}
