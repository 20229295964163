<template>
    <div class="MobileSimplexDropbox">
        <PhotoCapture
            v-if="photoCaptureOpened"
            :label="photoCaptureText"
            :overlay="overlay"
            :facing-mode="capture || 'environment'"
            @input="onFileChange"
            @unavailable="onCameraUnavailable"
            @cancel="onCameraCancel"
        />
        <label class="MobileSimplexDropbox__body" :for="id" :class="{ ready }" @click="onOpen">
            <input
                :id="id"
                ref="input"
                type="file"
                style="display: none"
                accept="image/*"
                :disabled="processing"
                :name="id"
                :capture="captureValue"
                @change="onInputChange"
            >
            <span class="MobileSimplexDropbox__label">
                <template v-if="ready"><Icon name="checkmark" /> {{ readyLabel }}</template>
                <template v-else>{{ label }}</template>
            </span>
            <span class="MobileSimplexDropbox__icon">
                <Spinner v-if="processing" />
                <Icon v-else name="camera" />
            </span>
        </label>
        <span v-if="fileWasTooLarge" class="MobileSimplexDropbox__warning">
            Too large image. Only image smaller than {{ maxFileSizeMb }} MB can be uploaded.
        </span>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { mimeTypes } from '@evidentid/file-utils/mimeTypes';
    import { isFileValid } from '@evidentid/file-utils/prepareFile';
    import Icon from '@/components/common/Icon.vue';
    import Spinner from '@/components/common/Spinner.vue';
    import PhotoCapture from '@/components/forms/PhotoCapture.vue';
    import { cloneFile } from '@evidentid/file-utils/blobs';

    type ImageProcessor = (file: File) => Promise<File>;

    export enum PhotoInputCapturingMethod {
        user = 'user',
        environment = 'environment',
    }

    @Component({
        components: { PhotoCapture, Spinner, Icon },
    })
    export default class PhotoInput extends Vue {
        @Prop({ type: String, default: undefined })
        private id!: string | undefined;

        @Prop({ type: File, default: () => null })
        private value!: File;

        @Prop({ type: String, default: 'Take a photo' })
        private label!: string;

        @Prop({ type: String, default: 'Image ready' })
        private readyLabel!: string;

        @Prop({ type: String, default: null })
        private capture!: PhotoInputCapturingMethod | null;

        @Prop({ type: Number, default: 10 })
        private maxFileSizeMb!: number;

        @Prop({ type: Boolean, default: false })
        private processing!: boolean;

        @Prop({ type: Boolean, default: true })
        private native!: boolean;

        @Prop({ default: null })
        private overlay!: { width: number, height: number, text?: string } | true | null;

        @Prop({ type: Function, default: (file: File) => Promise.resolve(file) })
        private process!: ImageProcessor;

        private fileWasTooLarge = false;
        private userMediaDisabled: boolean = false;
        private photoCaptureOpened: boolean = false;

        private get photoCaptureText(): string | undefined {
            return this.overlay && typeof this.overlay === 'object' && 'text' in this.overlay ? this.overlay.text : '';
        }

        private get hasPhotoCapture() {
            return !this.userMediaDisabled && !this.native && this.overlay;
        }

        private get ready() {
            return this.value && !this.processing;
        }

        private get captureValue() {
            const validOptions = [
                PhotoInputCapturingMethod.user,
                PhotoInputCapturingMethod.environment,
            ];
            return this.capture && validOptions.includes(this.capture) ? this.capture : false;
        }

        private onOpen(event: Event) {
            if (this.processing) {
                return;
            }

            console.error({ userMediaDisabled: this.userMediaDisabled, native: this.native, overlay: this.overlay }, 'PhotoInput/onOpen');

            if (this.hasPhotoCapture) {
                event.preventDefault();
                this.photoCaptureOpened = true;
            }
        }

        private onCameraUnavailable() {
            // Mark camera as unavailable
            this.userMediaDisabled = true;
            this.photoCaptureOpened = false;

            // Fallback to input[type=file]
            const input = this.$refs.input as HTMLInputElement;
            if (input) {
                input.click();
            }
        }

        private onCameraCancel() {
            this.photoCaptureOpened = false;
        }

        @Watch('processing')
        private onProcessingStateChange(processing: boolean) {
            if (processing) {
                this.photoCaptureOpened = false;
            }
        }

        @Watch('value')
        private onValueChange() {
            this.fileWasTooLarge = false;
        }

        private async onFileChange(file: File) {
            this.photoCaptureOpened = false;

            if (!isFileValid(file, mimeTypes.image)) {
                this.$emit('invalid', file);
                return;
            }

            this.$emit('processing', file);

            try {
                const finalFile = await this.process(file);
                if (finalFile.size / 1000 ** 2 > this.maxFileSizeMb) {
                    this.fileWasTooLarge = true;
                    this.$emit('invalid', file, null);
                } else {
                    // PRODUCT-11363: Chrome can discard previous `File` instances, when encounter exactly same new one
                    this.$emit('input', finalFile === file ? await cloneFile(file) : file);
                }
            } catch (error) {
                const passedError = error?.name === 'ImageProcessingError' ? error : null;
                this.$emit('invalid', file, passedError);
            }
        }

        private onInputChange(event: Event) {
            event.preventDefault();

            // Extract sent file
            const file = ((event.target as HTMLInputElement).files as FileList)[0];

            // Clean up information about file size
            this.fileWasTooLarge = false;

            // Ensure initially that file is valid
            if (!file) {
                return;
            }

            // Accept file
            this.onFileChange(file);

            // Clear input value
            if (event.target) {
                (event.target as HTMLInputElement).value = '';
            }
        }
    }
</script>
