import Vue, { Component as VueComponent } from 'vue';
import noop from 'lodash/noop';
import { Status } from '@/store/interfaces/IdoState';
import IdleTimeTracker from '@evidentid/idle-time-tracker';
import { RelyingPartyRequestStatusType } from '@evidentid/ido-lib/getIdoRequestStatus';
import delay from '@evidentid/universal-framework/delay';
import * as config from '@/config/interviewUpdates';

const IGNORED_STATUSES = [ Status.error, Status.uninitialized, Status.loading, Status.loggedOut ];
const FINAL_REQUEST_STATUSES = [
    RelyingPartyRequestStatusType.complete,
    RelyingPartyRequestStatusType.canceled,
    RelyingPartyRequestStatusType.timeout,
    RelyingPartyRequestStatusType.maxAttemptsExceeded,
];

const easeOutQuad = (t: number) => t * (2 - t);

export default function withInterviewUpdates(
    Component: VueComponent,
    blockImmediateFinish = false,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    now = Date.now
) {
    return Vue.component('withInterviewUpdates', {
        data: () => ({
            isMounted: false,
            tracker: new IdleTimeTracker({ now }),
        }),

        methods: {
            async tick() {
                // Calculate user idleness time
                const idleTime = this.tracker.get();

                // Stop when it is not mounted
                if (!this.isMounted) {
                    return;
                }

                // When user was inactive too long, reschedule update.
                if (idleTime > config.maxInactivityTime) {
                    await delay(500);
                    this.tick();
                    return;
                }

                // Wait selected time before next update
                const maxDelayDiff = config.maxRefreshDelayTime - config.minRefreshDelayTime;
                const t = easeOutQuad(idleTime / config.maxInactivityTime);
                const delayTime = config.minRefreshDelayTime + maxDelayDiff * t;
                await delay(delayTime);

                // Schedule next update
                if (this.isMounted) {
                    await this.updateInterview();
                    this.tick();
                }
            },

            async updateInterview() {
                // Load required data
                const { status, requestStatus } = this.$store.state;

                // Do not update when interview is not started, loading or failed.
                if (IGNORED_STATUSES.includes(status)) {
                    return;
                }

                // Do not update when the interview can't change anymore.
                if (requestStatus && FINAL_REQUEST_STATUSES.includes(requestStatus.type)) {
                    return;
                }

                // Ignore reloading when
                if (!this.$store?.state?.featureFlags?.rpr_constant_polling_enabled) {
                    return;
                }

                // Initialize interview refresh
                return this.$store.dispatch('reloadInterview', { blockImmediateFinish }).catch(noop);
            },
        },

        mounted() {
            this.isMounted = true;
            this.tracker = new IdleTimeTracker({ now });
            this.tracker.start();
            this.tick();
        },

        destroyed() {
            this.isMounted = false;
            this.tracker?.stop();
        },

        render(createElement) {
            return createElement(Component);
        },
    });
}
