<template>
    <div class="ArrayView">
        <p>{{ form.schema.title }}</p>

        <div v-for="(item, index) in (value || [])" :key="index" class="ArrayView__item">
            <v-component
                :is="FormElementComponent"
                :id="`${id}_${index}`"
                :key="index"
                :depth="depth + 1"
                :form="form.item"
                :value="item"
                :custom-component-input="customComponentInput"
                @input="onChange(index, $event)"
            />
            <Icon
                v-if="minItems < itemsLength"
                name="delete"
                touch-action="auto"
                @click="onRemove(index)"
            />
        </div>

        <Button type="primary" :disabled="itemsLength >= maxItems" @click="onAdd">
            {{ form.schema.addItemTitle || 'Add' }}
        </Button>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { JsonFormArray } from '@evidentid/json-schema/interfaces/JsonForm';
    import Icon from '@/components/common/Icon.vue';
    import Button from '@/components/common/Button.vue';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    @Component({
        components: { Button, Icon },
        inheritAttrs: false,
    })
    export default class JsonSchemaArrayForm extends AbstractJsonSchemaForm<JsonFormArray, any[]> {
        private get itemsLength() {
            return Array.isArray(this.value) ? this.value.length : 0;
        }

        private get minItems() {
            return this.form.schema.minItems || 0;
        }

        private get maxItems() {
            return this.form.schema.maxItems == null ? Infinity : this.form.schema.maxItems;
        }

        private onAdd() {
            this.$emit('input', this.form.getValue([
                ...this.value,
                this.form.item.getValue(),
            ]));
        }

        private onRemove(index: number) {
            this.$emit('input', this.form.getValue([
                ...this.value.slice(0, index),
                ...this.value.slice(index + 1),
            ]));
        }

        private onChange(index: number, value: any) {
            this.$emit('input', this.form.getValue([
                ...this.value.slice(0, index),
                value,
                ...this.value.slice(index + 1),
            ]));
        }
    }
</script>
