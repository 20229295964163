<template>
    <div>
        <h2 class="text-2.5xl font-bold">
            {{ question.metadata.title }}
        </h2>
        <JsonSchemaCustomForm
            v-if="controller"
            v-slot="{ fields }"
            v-model="controller.value"
            :controller="controller"
        >
            <JsonSchemaCustomFormElement
                v-for="(field, key) in fields"
                :key="key"
                :field="field"
                :custom-component-input="getCustomElement"
                @input="onChange"
            />
        </JsonSchemaCustomForm>
    </div>
</template>

<script lang="ts">
    import { licenseStateIdsWithCustomValidation } from '@/utils/licenseValidation/constants';
    import { Component } from 'vue-property-decorator';
    import cloneDeep from 'lodash/cloneDeep';
    import FieldComponent from '@/fields/FieldComponent';
    import {
        JsonSchemaCustomForm,
        JsonSchemaCustomFormElement,
    } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import JsonForm, { JsonFormType } from '@evidentid/json-schema/interfaces/JsonForm';
    import JsonSchemaStringForm from '@/components/forms/JsonSchemaForm/JsonSchemaStringForm.vue';
    import JsonSchemaNumberForm from '@/components/forms/JsonSchemaForm/JsonSchemaNumberForm.vue';
    import JsonSchemaBooleanForm from '@/components/forms/JsonSchemaForm/JsonSchemaBooleanForm.vue';
    import JsonSchemaConstForm from '@/components/forms/JsonSchemaForm/JsonSchemaConstForm.vue';
    import JsonSchemaEnumForm from '@/components/forms/JsonSchemaForm/JsonSchemaEnumForm.vue';
    import JsonSchemaObjectForm from '@/components/forms/JsonSchemaForm/JsonSchemaObjectForm.vue';
    import JsonSchemaArrayForm from '@/components/forms/JsonSchemaForm/JsonSchemaArrayForm.vue';
    import SmartAttributeField from '@/fields/SmartAttributeField';
    import LicenseStateObjectForm from '@/components/forms/JsonSchemaForm/LicenseStateObjectForm.vue';
    import { isObject } from '@evidentid/json-schema/schemaChecks';
    import LicenseIdInputForm from '@/components/forms/JsonSchemaForm/LicenseIdInputForm.vue';

    const elementByType: Record<JsonFormType, Vue.Component> = {
        string: JsonSchemaStringForm,
        number: JsonSchemaNumberForm,
        boolean: JsonSchemaBooleanForm,
        const: JsonSchemaConstForm,
        enum: JsonSchemaEnumForm,
        object: JsonSchemaObjectForm,
        array: JsonSchemaArrayForm,
    };

    @Component({
        components: { JsonSchemaCustomForm, JsonSchemaCustomFormElement },
    })
    export default class SmartAttribute extends FieldComponent<any> {
        private controller = (this.field as SmartAttributeField).smartAttributeFormController;
        private getCustomElement(form: JsonForm): Vue.Component {
            if (this.isLicenseStateForm(form)) {
                return LicenseStateObjectForm;
            }
            if (this.isCustomValidatedLicenseIdInput(form)) {
                return LicenseIdInputForm;
            }
            return elementByType[form.type];
        }

        private isLicenseStateForm(form: JsonForm): boolean {
            return Boolean(
                isObject(form.schema) &&
                    form.schema.properties.licenseState &&
                    this.isLicenseState(),
            );
        }

        private isCustomValidatedLicenseIdInput(form: JsonForm): boolean {
            return Boolean(
                form.schema.$id === '#licenseString' && this.isLicenseState(),
            );
        }

        private isLicenseState(): boolean {
            return licenseStateIdsWithCustomValidation
                .some((item: string) => this.controller?.form.schema.$id?.includes(item));
        }

        private onChange() {
            this.$emit('change', cloneDeep(this.controller?.value || null));
        }
    }
</script>
