<template>
    <div
        class="SubmissionRedirectModal"
        :class="{ 'SubmissionRedirectModal--submitting': submitting }"
    >
        <Spinner v-if="submitting" />
        <div class="SubmissionRedirectModal__body">
            <div class="SubmissionRedirectModal__icon">
                <img alt="" :src="resumeSubmissionIcon">
            </div>
            <h2 class="SubmissionRedirectModal__header">
                Click "Continue" to resume your submission
            </h2>

            <div class="SubmissionRedirectModal__content">
                Additional information is needed for your application before we can fully
                process it. Please click to complete your submission
            </div>

            <div class="SubmissionRedirectModal__footer">
                <button
                    type="button"
                    class="SubmissionRedirectModal__button SubmissionRedirectModal__button--decline"
                    :disabled="submitting"
                    @click="decline"
                >
                    Continue later
                </button>
                <button
                    type="button"
                    class="SubmissionRedirectModal__button SubmissionRedirectModal__button--confirm"
                    :disabled="submitting"
                    @click="confirm"
                >
                    Continue
                    <img
                        alt=""
                        :src="longArrowRight"
                        class="SubmissionRedirectModal__button--icon"
                    >
                </button>
            </div>
            <div
                v-if="!submitting"
                class="SubmissionRedirectModal__dismiss"
                @click="decline"
            >
                <Icon name="x" />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .SubmissionRedirectModal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999999;
        width: 100%;
        height: 100%;
        overflow: auto;
        background: rgba(#2d3e50, 0.5);

        &__icon {
            margin: 0 auto;
            align-items: center;
        }

        &__header {
            font-weight: normal;
            font-size: 26px;
            line-height: 33px;
            color: #2ab496;
            margin: 28px 0 20px;
        }

        &__body {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            background: #fff;
            margin: 0 auto;
            width: 95%;
            max-width: 770px;
            padding: 52px 80px;
            box-shadow: 0 3px 6px 2px rgba(#000, 0.1);
            text-align: center;
        }

        &__info {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0;
            font-size: 1.0625em;

            .Named {
                color: #2ab496;
                margin-right: 5px;
            }

            a {
                color: inherit;
            }
        }

        &__infoContent {
            display: inline;
            text-decoration: underline;
            font-weight: bold;
        }

        &__footer {
            display: flex;
            justify-content: center;
            margin-top: 44px;
        }

        &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font: inherit;
            font-weight: bold;
            line-height: 1em;
            height: 48px;
            width: 203px;
            padding: 0 20px;
            color: #698296;
            border: 1px solid #fff;
            border-radius: 24px;
            background: transparent;
            outline: none;
            cursor: pointer;

            .Named {
                margin-right: 10px;
            }

            &:hover {
                background: rgba(#000, 0.05);
            }

            &--confirm {
                color: #fff;
                background: #2ab496;
                box-shadow: 0 0 4px rgba(#d3d3d3, 0.5);

                &:hover {
                    background: darken(#2ab496, 3%);
                }

                &:focus {
                    box-shadow: 0 0 4px rgba(#d3d3d3, 0.5), 0 0 0 1px #778ea1;
                }
            }

            &--decline {
                color: #698296;
                background: transparent;
                outline: none;

                &:hover {
                    background: transparent;
                }
            }

            &--icon {
                width: 16px;
                height: 16px;
                margin-left: 16px;
            }

            & + & {
                margin-left: 28px;
            }
        }

        &__dismiss {
            display: flex;
            align-self: flex-end;
            order: -1;
            transform: translateY(-150%);
            margin-right: -6%;

            &:hover {
                cursor: pointer;
            }
        }

        &--submitting &__body > * {
            opacity: 0.3;
        }

        &--submitting &__button--decline {
            color: #ccc;
            cursor: initial;
        }

        &--submitting &__button--confirm {
            cursor: initial;
            background: #ccc;
        }
    }

    @media (max-width: 600px) {
        .SubmissionRedirectModal__info {
            display: inline-block;

            .Named {
                display: none;
            }
        }

        .SubmissionRedirectModal__body {
            padding: 20px;
        }

        .SubmissionRedirectModal__dismiss {
            margin-right: -3%;
            transform: translateY(-100%);
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import resumeSubmissionIcon from '@/assets/gls-resume-submission.svg';
    import longArrowRight from '@/assets/long-arrow-right.svg';
    import Icon from '@/components/common/Icon.vue';
    import Spinner from '@/components/common/Spinner.vue';

    @Component({
        components: { Spinner, Icon },
    })

    export default class SubmissionRedirectModal extends Vue {
        private resumeSubmissionIcon = resumeSubmissionIcon;
        private longArrowRight = longArrowRight;

        @Prop({ type: Boolean, default: false })
        private submitting!: boolean;

        private confirm(): void {
            if (!this.submitting) {
                this.$emit('confirm', true);
            }
        }

        private decline(): void {
            if (!this.submitting) {
                this.$emit('confirm', false);
            }
        }
    }
</script>
