<template>
    <PendingScreen v-if="!question || !ready" fullscreen />
    <v-component
        :is="viewController"
        v-else-if="question"
        :key="key"
        :field="field"
        :question="question"
    />
</template>

<script lang="ts">
    import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
    import noop from 'lodash/noop';
    import Question from '@evidentid/ido-lib/interfaces/Question';
    import slugify from '@evidentid/universal-framework/slugify';
    import unslugify from '@evidentid/universal-framework/unslugify';
    import getField from '@/fields/getField';
    import Field from '@/fields/Field';
    import PendingScreen from '@/components/screens/PendingScreen.vue';
    import getTransitionRoute from '@/utils/getTransitionRoute';
    import getTransitionState from '@/utils/getTransitionState';
    import { isGlsSession } from '@/interfaces/SessionData';

    @Component({
        components: { PendingScreen },
    })
    export default class AnswerQuestion extends Vue {
        @Prop({ type: Function, default: getField })
        private getField!: typeof getField;

        private ready: boolean = false;

        private get id() {
            const slug = this.$route.params.slug;
            return slug ? unslugify(slug) : null;
        }

        private get key() {
            return this.question?.attrType || '<unknown>';
        }

        private get question() {
            const id = this.id;
            return this.$store.state.questions.find((question) => question.attrType === id);
        }

        private get nextQuestion() {
            const currentQuestion = this.question;
            const nextQuestions = this.$store.state.questions.filter((question) => !question.complete);
            const nextAttrTypes = nextQuestions.map(({ attrType }) => attrType);
            const expectedAttrTypes = currentQuestion?.metadata?.nextAttrTypes || [];
            const nextAttrType = expectedAttrTypes.find((attrType) => nextAttrTypes.includes(attrType));
            return nextQuestions.find(({ attrType }) => attrType === nextAttrType);
        }

        private get field() {
            const question = this.question;
            return question ? this.getField(question.type) : null;
        }

        private get viewController() {
            const field = this.field;
            return field ? field.viewController : null;
        }

        private get session() {
            return this.$store.state.session;
        }

        private initialize(field: Field | null, question: Question | null) {
            if (!field || !question) {
                return;
            }

            this.ready = field.ready;
            if (!this.ready) {
                this.ready = false;
                return this.$store.dispatch('fetchAttributeData', [ question.attrType ]).then(() => {
                    if (this.field === field) {
                        this.ready = true;
                        this.$forceUpdate();
                    }
                });
            }
        }

        private beforeUpdate() {
            this.safeCheck();
        }

        private created() {
            this.safeCheck();
        }

        @Watch('question')
        private onQuestionChange(question: Question | null) {
            this.initialize(question && this.getField(question.type), question);
        }

        private safeCheck() {
            const question = this.question;
            if (!question || question.complete || !this.session || isGlsSession(this.session)) {
                this.redirect();
            } else if (this.field) {
                this.initialize(this.field, question);
            } else {
                throw new Error(`Couldn't find a field associated with "${question.attrType}".`);
            }
        }

        private redirect() {
            const nextQuestion = this.nextQuestion;
            if (nextQuestion) {
                // @ts-ignore: FIXME something is wrong with this push (?)
                this.$router.push({
                    ...this.$router.currentRoute,
                    params: {
                        ...this.$router.currentRoute.params,
                        slug: slugify(nextQuestion.attrType),
                    },
                });
            } else {
                this.$router.push(getTransitionRoute(getTransitionState(this.$store.state))).catch(noop);
            }
        }
    }
</script>
