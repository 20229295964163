import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import BarLicenseDetailsComponent from '@/components/fields/BarLicenseDetails.vue';
import AttorneyLicenseDetails from './interfaces/AttorneyLicenseDetails';

export default class BarLicenseDetailsField extends Field<AttorneyLicenseDetails> {
    public override component = BarLicenseDetailsComponent;

    public isValid(_: Question, value: AttorneyLicenseDetails): boolean {
        const { jurisdiction, yearAdmitted } = value || {};

        return Boolean(jurisdiction) && typeof yearAdmitted === 'string' && /^(19|20)\d{2}$/.test(yearAdmitted);
    }

    public async encode(question: Question, value: AttorneyLicenseDetails): Promise<AttributesValues> {
        const { jurisdiction, yearAdmitted, licenseNumber } = value || {};
        return this._encode(question, {
            $objectType: 'AttorneyLicenseDetails',
            license_number: licenseNumber || '',
            year_admitted: parseInt(yearAdmitted, 10) || 0,
            jurisdiction,
        });
    }
}
