/**
 * FaceTec Device Key Identifier, unique for the application.
 *
 * @see {@link https://dev.facetec.com/keys-v8#device-key-identifier}
 */
export const deviceLicenseKeyIdentifier = `${deploy.WEB_PUBLIC_FACETEC_DEVICE_KEY}`;

/**
 * Public encryption key, used to encrypt obtained face map.
 *
 * @see {@link https://dev.facetec.com/identity-issuers}
 */
export const publicEncryptionKey = `${deploy.WEB_PUBLIC_FACETEC_FACEMAP_KEY}`;

/**
 * Production key, which could help with sessions.
 * Otherwise, FaceTec will obtain session details from their servers.
 *
 * @see {@link https://dev.facetec.com/keys#integrating-production-keys}
 */
export const productionKey: string | null = deploy.WEB_PUBLIC_FACETEC_PRODUCTION_KEY || null;

/**
 * Public directory, where "resources" and "FaceTec_images" are available.
 *
 * @see {@link ../../../zoom-sdk/createZoomSdkResourcesWebpackPlugins.js}
 */
export const publicDirectory = `${process.env.ZOOM_SDK_PUBLIC_DIRECTORY}`;

/**
 * Endpoint URL to retrieve session token.
 * It expects { sessionToken: string } as a JSON response.
 */
export const zoomServerSessionTokenUrl: string = deploy.WEB_PUBLIC_FACETEC_SESSION_TOKEN_URL || (
    'https://api.zoomauth.com/api/v3/biometrics/session-token'
);

/**
 * Should include credentials during session token request?
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Sending_a_request_with_credentials_included}
 */
export const zoomServerSessionTokenIncludeCredentials = deploy.WEB_PUBLIC_FACETEC_SESSION_TOKEN_INCLUDE_CREDENTIALS === 'true';

/**
 * Endpoint URL with POST method to retrieve verification status.
 * It expects ( facescan: string, selfie_image: string } as JSON payload.
 * It expects { livenessStatus: string } as a JSON response.
 */
export const zoomServerVerifyUrl: string | null = deploy.WEB_PUBLIC_FACETEC_VERIFY_URL || null;

/**
 * Should include credentials during verification request?
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Sending_a_request_with_credentials_included}
 */
export const zoomServerVerifyIncludeCredentials = deploy.WEB_PUBLIC_FACETEC_VERIFY_INCLUDE_CREDENTIALS === 'true';
