import { FileData } from '@evidentid/file-utils/FileData';

export interface BinaryDataAttributeMetadata {
    cropped: boolean;
}

export default function createBinaryDataAttributeValue(
    value: FileData,
    metadata: BinaryDataAttributeMetadata = { cropped: false }
) {
    const [ , base64 ] = value ? (value.dataUrl || '').split(',') : [];

    return {
        $objectType: 'BinaryData',
        data: base64 || null,
        mime_type: value ? value.mimeType : null,
        metadata,
    };
}
