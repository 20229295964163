<template>
    <SingleCol stretch>
        <Spinner v-if="errorAction" />
        <ErrorScreen v-else :warning="error.warning">
            <h3>{{ heading }}</h3>
            <p v-if="error.html" v-html="message" />
            <p v-else>
                {{ message }}
            </p>
        </ErrorScreen>
    </SingleCol>
</template>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import formatMessage from '@evidentid/universal-framework/formatMessage';
    import SingleCol from '@/layouts/SingleCol.vue';
    import ErrorScreen from '@/components/screens/ErrorScreen.vue';
    import PendingScreen from '@/components/screens/PendingScreen.vue';
    import Spinner from '@/components/common/Spinner.vue';
    import errors, { actionableErrors } from '@/config/errors';

    @Component({
        components: { Spinner, PendingScreen, ErrorScreen, SingleCol },
    })
    export default class ErrorPage extends Vue {
        @Prop({ type: String })
        private reason!: string | undefined;

        @Prop({ type: Object, default: () => ({}) })
        private meta!: Record<string, string>;

        private getRouteError() {
            return this.$route?.params?.reason
                ? this.$route.params as unknown as { reason: string, meta?: Record<string, string> }
                : null;
        }

        private getExternalError() {
            const rawError = this.$route?.query?.reason;
            if (!rawError || typeof rawError !== 'string') {
                return null;
            }

            try {
                const error = JSON.parse(atob(rawError));
                return typeof error?.auth_error === 'string'
                    ? { reason: error.auth_error, meta: {} }
                    : null;
            } catch (e) {
                return null;
            }
        }

        private getPropsError() {
            return this.reason
                ? { reason: this.reason, meta: this.meta }
                : null;
        }

        private get errorSource() {
            return this.getPropsError() || this.$store.state.error || this.getRouteError() || this.getExternalError();
        }

        private get error(): { heading: string, message: string, warning?: boolean, metadata: Record<string, string> } {
            const source = this.errorSource;
            const reason = source?.reason;
            const metadata = (source?.meta) || {};
            return {
                ...(errors[reason as string] || errors.unexpected),
                metadata,
            };
        }

        private get heading() {
            const error = this.error;
            return formatMessage(error.heading, error.metadata);
        }

        private get message() {
            const error = this.error;
            return formatMessage(error.message, error.metadata);
        }

        private get errorAction() {
            const reason = this.errorSource?.reason;
            return (reason && actionableErrors[reason]) || null;
        }

        @Watch('errorAction', { immediate: true })
        private onErrorActionChange(action: (() => any) | null): void {
            if (action && typeof action === 'function') {
                action();
            } else if (action) {
                this.$router.replace(action).catch(() => {
                    // Redundant navigation, clear error then, so it will stay on redirected page
                    if (this.errorSource === this.$store?.state?.error) {
                        console.warn('Redundant redirection on error page');
                        this.$store.dispatch('showError', null);
                    }
                });
            }
        }
    }
</script>
