import { FaceTecSessionStatus, LivenessCheckResultFailed } from '../types';

export function buildFailedLivenessCheckResult(
    status: Exclude<FaceTecSessionStatus, FaceTecSessionStatus.SessionCompletedSuccessfully>,
): LivenessCheckResultFailed {
    return {
        status,
        userAgent: null,
        sessionId: null,
        faceScan: null,
        faceMap: null,
        auditTrailImage: null,
        lowQualityAuditTrailImage: null,
    };
}
