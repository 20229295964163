export default function flattenObject(obj: any, pathPrefix = '') {
    const result: any = {};

    for (const key of Object.keys(obj)) {
        const value = obj[key];
        if (value && typeof value === 'object' && !Array.isArray(value)) {
            Object.assign(result, flattenObject(value, `${pathPrefix}${key}.`));
        } else {
            result[`${pathPrefix}${key}`] = value;
        }
    }

    return result;
}
