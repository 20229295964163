import { IdoRequestStatus, RelyingPartyRequestStatusType } from './getIdoRequestStatus';
import { RequestStatusType } from './interfaces/RequestStatusType';

export const instructions: Record<RelyingPartyRequestStatusType, string> = {
    [RelyingPartyRequestStatusType.new]: `
        You need to complete your interview.
    `,
    [RelyingPartyRequestStatusType.timeout]: `
        Your verification has expired,
        as it was not completed within the required 30-day time period.
    `,
    [RelyingPartyRequestStatusType.processing]: `
        The information you provided is being securely verified with
        Evident's network of authoritative sources.
        This could complete as quickly as within minutes,
        or could take several days in some circumstances.
    `,
    [RelyingPartyRequestStatusType.canceled]: `
        {{rpName}} has chosen to cancel your verification.
        Please note that Evident is unable to answer any questions on decisions made by {{rpName}}.
    `,
    [RelyingPartyRequestStatusType.maxAttemptsExceeded]: `
        Evident allows a maximum of two attempts to verify the information you provide.
        If your information fails twice, your verification is automatically canceled.
        This typically occurs, when the information you provide does not match
        what was requested, or the files you submit did not meet the specifications required
        (ex: a photo of a document may be too blurry to be readable).
    `,
    [RelyingPartyRequestStatusType.complete]: `
        The results of your verification have been sent to {{rpName}} for evaluation.
    `,
};

interface RequestStatusAlertMessage {
    type: RequestStatusType;
    message: string;
}

export const alertMessages: Record<RelyingPartyRequestStatusType, RequestStatusAlertMessage | null> = {
    [RelyingPartyRequestStatusType.new]: null,
    [RelyingPartyRequestStatusType.timeout]: {
        type: RequestStatusType.error,
        message: 'This verification has expired due to lack of response',
    },
    [RelyingPartyRequestStatusType.processing]: {
        type: RequestStatusType.loading,
        message: 'Your information is being verified with official sources',
    },
    [RelyingPartyRequestStatusType.maxAttemptsExceeded]: {
        type: RequestStatusType.error,
        message: 'This verification has reached the maximum number of attempts',
    },
    [RelyingPartyRequestStatusType.canceled]: {
        type: RequestStatusType.error,
        message: 'This verification has been canceled',
    },
    [RelyingPartyRequestStatusType.complete]: {
        type: RequestStatusType.success,
        message: 'Your information verification has completed successfully',
    },
};

export const longProcessingNextStep = {
    minDaysProcessing: 13,
    nextStepsLink: 'https://www.evidentid.com/support/individual/',
    nextStepsLinkTitle: 'Submit a support ticket',
};

export const nextStepsMessages: Record<RelyingPartyRequestStatusType, (status: IdoRequestStatus) => string> = {
    [RelyingPartyRequestStatusType.new]: () => `
        Firstly, you need to complete your interview.
    `,
    [RelyingPartyRequestStatusType.timeout]: () => `
        A new verification link will need to be provided to you.
        Please reach out to {{rpName}} for assistance.
    `,
    [RelyingPartyRequestStatusType.processing]: (status) => {
        if (status.daysProcessing >= 13) {
            return `
                So sorry for the delay. Your verification has been in progress for {{daysProcessing}} days,
                which is longer than expected.
                This typically occurs when certain local jurisdictions take a while
                to respond to Evident’s requests.
                If you’d like more detail around why this may be taking longer than normal,
                please contact Evident’s support team for assistance.
                In the meantime, we will continue verifying your information.
            `;
        }

        if (status.daysProcessing >= 7) {
            return `
                Thank you for your patience. Your verification has been in progress for {{daysProcessing}} days.
                Rest assured, we’re still working on verifying all of the information you provided,
                but it’s taking a little longer than normal.
            `;
        }

        return `
            Your verification has been in progress for {{daysProcessing}} days,
            and should be completed shortly.
            You can return to this page at any time for further updates.
        `;
    },
    [RelyingPartyRequestStatusType.maxAttemptsExceeded]: () => `
        A new verification link will need to be provided to you.
        Please reach out to {{rpName}} for assistance.
    `,
    [RelyingPartyRequestStatusType.canceled]: () => `
        At this time, {{rpName}} is unable to move forward with your application.
        If you would like further clarification, please contact {{rpName}}'s support team.
    `,
    [RelyingPartyRequestStatusType.complete]: () => `
        Please follow up with {{rpName}} to determine next steps.
        Evident is unable to answer questions on any decisions {{rpName}} may make based on the results.
    `,
};

export const timelineMessages = {
    request: {
        title: '{{rpName}} requests information from you',
        description: 'Information requested on {{requestedAt}}',
    },
    submission: {
        title: 'We receive your information',
        description: 'You provided all the information needed on {{submittedAt}}',
    },
    timeout: {
        description: 'No information was provided',
    },
    processing: {
        title: 'We verify your data with various official sources',
        description: 'Verification completed on {{completedAt}}',
        ongoing: 'Verification in progress',
    },
    canceled: {
        // TODO(PRODUCT-9194): use date when it will be provided
        // description: 'Verification canceled on {{canceledAt}}',
        description: 'Verification canceled',
    },
    maxAttemptsExceeded: {
        description: 'Maximum number of attempts',
    },
    complete: {
        title: 'We send the results to {{rpName}}',
        description: 'Results sent on {{completedAt}}',
    },
};
