import Question from '@evidentid/ido-lib/interfaces/Question';
import startCase from 'lodash/startCase';
import getFieldType from '@/fields/getFieldType';
import getField from '@/fields/getField';
import Field from '@/fields/Field';
import Insurer from '@/interfaces/insurance/Insurer';
import occupationalAccident from '@/assets/broker-flow-occupational.svg';
import umbrellaExcess from '@/assets/broker-flow-umbrella-excess.svg';
import commercialAuto from '@/assets/broker-flow-commercial-auto.svg';
import commercialCrime from '@/assets/broker-flow-commercial-crime.svg';
import commercialGeneralLiability from '@/assets/broker-flow-commercial-general-liability.svg';
import commercialProperty from '@/assets/broker-flow-commercial-property.svg';
import workersCompensation from '@/assets/broker-flow-workers-compensation.svg';
import defaultIcon from '@/assets/broker-flow-coverage-tile-default.svg';
import { CoverageQuestions } from '@/interfaces/insurance/CoverageType';

export interface PolicyInfo {
    insured: string;
    insurer: string;
    policyNumber: string;
    effectiveDate: string;
    expirationDate: string;
}

const coverageTypeIcons: Record<string, string> = {
    commercial_auto_liability: commercialAuto,
    commercial_general_liability: commercialGeneralLiability,
    commercial_property: commercialProperty,
    commercial_crime: commercialCrime,
    workers_compensation: workersCompensation,
    umbrella_excess_liability: umbrellaExcess,
    occupational_accident: occupationalAccident,
};

export function extractCoverageField(question: Question): string {
    return question.attrType.match(/[^.]+$/)![0];
}

export function formatCoverageName(coverageName: string): string {
    return startCase(coverageName.replace(/_/g, ' '));
}

export function getCoverageTypeIcon(coverageName: string): string {
    return coverageTypeIcons[coverageName] || defaultIcon;
}

export function getInsuranceField(question: Question): Field | null {
    const fieldType = getFieldType(question);
    return getField(fieldType);
}

export function isValueValid(question: Question, value: any): boolean {
    return Boolean(getInsuranceField(question)?.isValid(question, value));
}

export function createInsurer(letter: string): Insurer {
    return {
        uuid: `${Math.random()}.${Math.random()}`,
        letter,
        name: '',
        naic: '',
        amBest: '',
    } as Insurer;
}

export function extractStandardQuestions(questions: CoverageQuestions): Question[] {
    return [
        ...questions.limit,
        ...questions.typeOfInsurance,
        ...questions.fallback,
        ...Object.values(questions.acord25),
    ];
}

export const coiInputAttributeRegex =
    /risk_decisioning\.insurance_policy_verification\.self_attestation\..+\.input.document_scan/;
