<template>
    <div class="svgSpinner">
        <img class="svgSpinner__image" :src="primarySpinnerImageUrl">
        <div class="svgSpinner__text">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import primarySpinnerImageUrl from '@evidentid/assets-bridge/primarySpinner.svg';

    @Component
    export default class AlternativeSpinner extends Vue {
        private primarySpinnerImageUrl = primarySpinnerImageUrl;
    }
</script>
