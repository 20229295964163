import { IdoState } from '@/store/interfaces/IdoStore';
import { Status } from '@/store/interfaces/IdoState';
import { RelyingPartyRequestStatusType } from '@evidentid/ido-lib/getIdoRequestStatus';

export enum TransitionState {
    request = 'request',
    payment = 'payment',
    finishing = 'finishing',
    finished = 'finished',
    lookup = 'lookup',
}

export default function getTransitionState(state: IdoState): TransitionState {
    const isCompleted = state.questions.reduce((acc, question) => acc && question.complete, true);
    const isPaymentFulfilled = !state.balance || !parseFloat(`${state.balance.amount}`);

    if (state.requestStatus?.type === RelyingPartyRequestStatusType.processing && !isCompleted) {
        return TransitionState.request;
    } else if (state.requestStatus?.type !== RelyingPartyRequestStatusType.new) {
        return TransitionState.lookup;
    } else if (!isCompleted) {
        return TransitionState.request;
    } else if (!isPaymentFulfilled) {
        return TransitionState.payment;
    } else if (!state.consentGiven || state.status !== Status.finished) {
        return TransitionState.finishing;
    }

    return TransitionState.finished;
}
