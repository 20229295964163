<template>
    <div class="text-center mt-10 mb-10">
        <h4 class="mb-1">
            Your privacy is important to us!
        </h4>
        <p class="text-xs">
            Often the response shared with the requesting third party will be a “Yes” or “No” regarding the
            authenticity
            of your submission.
            <br>
            This is done to protect your personal information. <a
                href="https://www.evidentid.com/secure-online-identity-verification/"
                target="_blank"
            >Learn more about Evident.</a>
        </p>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        name: 'GlsPrivacyFooter',
    });
</script>
