<template>
    <div class="ChargeInfo">
        <div class="ChargeInfo__field">
            <div class="ChargeInfo__label">
                Charge:
            </div>
            <div class="ChargeInfo__content">
                {{ charge ? charge.chargeName : '' }}
            </div>
        </div>
        <div class="ChargeInfo__field">
            <div class="ChargeInfo__label">
                Date:
            </div>
            <div class="ChargeInfo__content">
                {{ charge ? charge.chargeDate : '' }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import Charge from '@/interfaces/Charge';

    @Component
    export default class ChargeInfo extends Vue {
        @Prop({ type: Object })
        private charge!: Charge;
    }
</script>
