<template>
    <PendingScreen v-if="!stripe" fullscreen />
    <InterviewCard v-else>
        <CheckoutForm :stripe="stripe" :currency="balance.currency" :amount="balance.amount" />
    </InterviewCard>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import InterviewCard from '@/layouts/InterviewCard.vue';
    import CheckoutForm from '@/components/payment/CheckoutForm.vue';
    import PendingScreen from '@/components/screens/PendingScreen.vue';

    @Component({
        components: { PendingScreen, CheckoutForm, InterviewCard },
    })
    export default class Checkout extends Vue {
        private get balance() {
            return this.$store.state.balance || { currency: 'USD', amount: '0' };
        }

        private get stripe() {
            return this.$store.state.stripe;
        }

        private mounted() {
            if (!this.stripe) {
                this.$store.dispatch('initializeStripe');
            }
        }
    }
</script>
