<template>
    <div
        class="ChipList"
        :class="{
            'ChipList--disabled': disabled,
            'ChipList--hasInput': disabled || focused || (value && value.length) || inputValue,
        }"
        @click="focusInput"
    >
        <span v-for="chip in value" :key="chip">
            <span :class="validate(chip) ? 'ChipList__validChip' : 'ChipList__invalidChip'">
                {{ chip }}
            </span>
            <Icon v-if="!disabled" touch-action="auto" name="x" @click="deleteChip(chip)" />
        </span>
        <div class="ChipList__input">
            <input
                ref="input"
                v-model="inputValue"
                :disabled="disabled"
                :size="inputSize"
                :readonly="!focused"
                @input="onInputValueChange"
                @keydown="onKeyDown"
                @focus="onFocus"
                @blur="onBlur"
            >
        </div>
        <span class="ChipList__placeholder">{{ placeholder }}</span>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
    import Icon from '@/components/common/Icon.vue';

    @Component({
        components: { Icon },
    })
    export default class ChipList extends Vue {
        @Prop({ type: Array, default: () => [] })
        private value!: string[];

        @Prop({ type: String, default: 'Add' })
        private placeholder!: string;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: RegExp, default: () => /\s+/g })
        private delimiterRegex!: RegExp;

        @Prop({ type: Function, default: () => true })
        private validate!: (chip: string) => boolean;

        @Ref()
        private input!: HTMLInputElement;

        private inputValue: string = '';
        private focused: boolean = false;

        private get inputSize(): number {
            return Math.min(this.inputValue.length + 3, 80);
        }

        private isDelimiterFound(text: string) {
            return Boolean(text.match(this.delimiterRegex));
        }

        private focusInput() {
            const input = this.$refs.input as HTMLInputElement;

            if (input) {
                input.focus();
            }
        }

        private onInputValueChange() {
            if (this.isDelimiterFound(this.inputValue)) {
                this.addChips(this.inputValue);
            }
        }

        private onKeyDown(event: KeyboardEvent) {
            const ENTER_CODE = 13;
            if (event.keyCode === ENTER_CODE) {
                event.preventDefault();

                if (this.inputValue) {
                    this.addChips(this.inputValue);
                }
            }
        }

        private onFocus() {
            this.focused = true;
        }

        private onBlur() {
            this.focused = false;
            const trimmedInput = this.inputValue.trim();
            if (trimmedInput.length > 0) {
                this.addChips(trimmedInput);
            }
        }

        private splitText(chip: string) {
            return chip.trim().split(this.delimiterRegex).filter((chunk) => chunk.length);
        }

        private deleteChip(chip: string) {
            this.$emit('input', this.value.filter((element) => element !== chip));
        }

        private addChips(text: string) {
            const chips = this.splitText(text);
            const nextValue = Array.isArray(this.value) ? this.value.concat(chips) : chips;
            const sanitizedValue = nextValue
                .filter((value) => value.length)
                .filter((value, index, array) => array.lastIndexOf(value) === index);

            this.inputValue = '';
            this.$emit('input', sanitizedValue);
        }

        private onChange(event: Event) {
            this.$emit('input', (event.target as HTMLInputElement).checked);
        }
    }
</script>
