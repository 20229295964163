import { GlsEmail } from '@evidentid/gls-service-api-client/types';
import SessionData from '@/interfaces/SessionData';
import UserData from '@/interfaces/UserData';
import Identity from '@evidentid/ido-lib/interfaces/Identity';
import Question from '@evidentid/ido-lib/interfaces/Question';
import AcademicProvider from '@/interfaces/AcademicProvider';
import UtilityProvider from '@/interfaces/UtilityProvider';
import PhoneNumber from '@/fields/interfaces/PhoneNumber';
import Balance from '@/interfaces/Balance';
import ErrorObject from './ErrorObject';
import { CriminalOffense } from '@/interfaces/OffenseCategory';
import { IdoRequestStatus } from '@evidentid/ido-lib/getIdoRequestStatus';
import { RelyingPartyRequestFeatureFlags } from '@evidentid/ido-lib/interfaces/AttributeRelyingPartyRequest';
import { GlsData } from '@/services/getGlsSessionInterview';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import AttributeSharingAgreement from '@evidentid/ido-lib/interfaces/AttributeSharingAgreement';

export enum Status {
    uninitialized = 'uninitialized',
    loading = 'loading',
    success = 'success',
    finished = 'finished',
    transition = 'transition',
    error = 'error',
    loggedOut = 'loggedOut',
}

export interface SnackbarConfiguration {
    success: boolean;
    permanent: boolean;
    message: string | null;
}

export interface SubmissionMap {
    [key: string]: boolean;
}

export interface ValuesMap {
    [key: string]: any;
}

export interface DelegationStatus {
    status: Status;
    requests: number;
}

export enum VerificationResult {
    uninitialized = 'uninitialized',
    uploading = 'uploading',
    verifying = 'verifying',
    failed = 'failed',
    verified = 'verified',
    error = 'error',
    timeout = 'timeout',
}

export enum VerificationFailureReason {
    unknown = 'UNKNOWN',
    provider = 'PROVIDER',
    glare = 'GLARE',
    sharpness = 'SHARPNESS',
    glareAndSharpness = 'GLARE_SHARPNESS',
}

interface VerificationStatusAbstract {
    resolutionAttempts: number;
    failures: number;
    datumIds: string[] | null;
    result: VerificationResult;
}

export interface VerificationStatusFailed extends VerificationStatusAbstract {
    result: VerificationResult.failed;
    datumIds: string[] | null;
    failureReason: VerificationFailureReason;
}

export interface VerificationStatusNotReady extends VerificationStatusAbstract {
    result: Exclude<Exclude<VerificationResult, VerificationResult.failed>, VerificationResult.verified>;
    datumIds: string[] | null;
    failureReason: null;
}

export interface VerificationStatusSucceed extends VerificationStatusAbstract {
    result: VerificationResult.verified;
    datumIds: string[];
    failureReason: null;
}

export type VerificationStatus = VerificationStatusFailed | VerificationStatusNotReady | VerificationStatusSucceed;

export default interface IdoState {
    status: Status;
    consentGiven: boolean;
    error: ErrorObject | Partial<ErrorObject> | null;
    snackbar: SnackbarConfiguration;
    session: SessionData | null;
    gls: GlsData | null;
    user: UserData | null;
    issuer: Identity | null;
    requestStatus: IdoRequestStatus | null;
    balance: Balance | null;
    questions: Question[];
    summary: string | null;
    submission: SubmissionMap;
    values: ValuesMap;
    academicProviders: AcademicProvider[] | null;
    utilityProviders: UtilityProvider[] | null;
    criminalOffenses: CriminalOffense[] | null;
    delegationPhoneNumber: PhoneNumber | null;
    delegationsRequested: { [key: string]: DelegationStatus };
    verificationsRequested: { [key: string]: VerificationStatus };
    glsResendLinkStatus: Record<GlsEmail, Status>;
    featureFlags: RelyingPartyRequestFeatureFlags;
    stripe: any;
    onlineChat: { loadAgentResponse: OperationStatus };
    attributeSharingAgreements?: AttributeSharingAgreement[] | null;
}
