<template>
    <label class="LineInputBlock">
        <span v-if="!bare">
            {{ question.metadata.description }}
        </span>
        <Dropdown
            v-model="inputValue"
            :options="options"
            :build-label="buildLabel"
            @input="onChange"
        />
    </label>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import Dropdown from '@/components/forms/Dropdown.vue';
    import FieldComponent from '@/fields/FieldComponent';
    import upperFirst from 'lodash/upperFirst';

    @Component({
        components: { Dropdown },
    })
    export default class SelectOption extends FieldComponent<string> {
        private inputValue = this.value || null;

        private get constraints(): { options?: [], customLabels?: Record<string, string> } {
            return JSON.parse(this.question.metadata?.constraints || '{}');
        }

        private get options(): string[] {
            return this.constraints?.options || [];
        }

        private get customLabels(): Record<string, string> {
            return this.constraints?.customLabels || {};
        }

        private buildLabel(value: string): string {
            return value in this.customLabels ?
                this.customLabels[value] :
                value.split('_').map((s) => upperFirst(s)).join(' ');
        }

        @Watch('value')
        private onValueChange(value: string) {
            this.inputValue = value || null;
        }

        private onChange() {
            this.$emit('change', this.inputValue);
        }
    }
</script>
