<template>
    <div class="AddressInput">
        <div class="AddressInput__street">
            <label>Street address</label>
            <input
                v-model="street"
                type="text"
                :disabled="disabled"
                @input="onChange"
            >
        </div>
        <div class="AddressInput__city">
            <label>City</label>
            <input
                v-model="city"
                type="text"
                :disabled="disabled"
                @input="onChange"
            >
        </div>
        <div class="AddressInput__state">
            <label>State</label>
            <Dropdown
                v-model="state"
                :options="states"
                :build-label="buildStateLabel"
                :disabled="disabled"
                :placeholder="disabled ? '' : undefined"
                @input="onChange"
            />
        </div>
        <div class="AddressInput__postcode">
            <label>ZIP</label>
            <input
                v-model="postcode"
                type="text"
                :disabled="disabled"
                @input="onChange"
            >
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
    import Dropdown from '@/components/forms/Dropdown.vue';
    import AddressValue from '@/fields/interfaces/Address';
    import { stateIds, statesMap } from '@/config/states';

    @Component({
        components: { Dropdown },
    })
    export default class AddressInput extends Vue {
        @Prop({ type: Object, default: () => null })
        private value!: AddressValue | null;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        private street: string = '';
        private city: string = '';
        private postcode: string = '';
        private state: string | null = null;

        private states = stateIds;

        private buildStateLabel(value: string) {
            return statesMap[value];
        }

        @Watch('value', { immediate: true })
        private onValueChange(value: AddressValue) {
            this.street = value?.street || '';
            this.city = value?.city || '';
            this.postcode = value?.postcode || '';
            this.state = value?.state || null;
        }

        private onChange() {
            this.$emit('input', {
                street: this.street,
                city: this.city,
                postcode: this.postcode,
                state: this.state,
            });
        }
    }
</script>
