<template>
    <div class="AddressQuestion">
        <header class="QuestionHeader">
            <h3>{{ question.metadata.title }}</h3>
            <p>{{ question.metadata.description }}</p>
        </header>
        <AddressInput :value="value" @input="onChange" />
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import Dropdown from '@/components/forms/Dropdown.vue';
    import AddressValue from '@/fields/interfaces/Address';
    import AddressInput from '@/components/forms/AddressInput.vue';

    @Component({
        components: { AddressInput, Dropdown },
    })
    export default class Address extends FieldComponent<AddressValue> {
        private onChange(value: AddressValue) {
            this.$emit('change', value);
        }
    }
</script>
