import Field from './Field';
import SsnField from './SsnField';
import FullNameField from './FullNameField';
import PastNameField from './PastNameField';
import CprField from './CprField';
import MmcReferenceNumberField from './MmcReferenceNumberField';
import AddressField from './AddressField';
import BarLicenseDetailsField from './BarLicenseDetailsField';
import SingleLineTextField from './SingleLineTextField';
import BulkEmailField from './BulkEmailField';
import ConsentField from './ConsentField';
import UsStateField from './UsStateField';
import PhoneNumberField from './PhoneNumberField';
import FileField from './FileField';
import AcademicProviderField from './AcademicProviderField';
import ExternalProviderField from './ExternalProviderField';
import SelfieField from './SelfieField';
import SmartAttributeField from './SmartAttributeField';
import IndividualizedAssessmentField from '@/fields/IndividualizedAssessmentField';
import IdScanField from '@/fields/IdScanField';
import LivenessCheckField from '@/fields/LivenessCheckField';
import BooleanField from '@/fields/BooleanField';
import EinField from '@/fields/EinField';
import BirthdayField from '@/fields/BirthdayField';
import DateField from '@/fields/DateField';
import SelectOptionField from '@/fields/SelectOptionField';
import IntegerField from '@/fields/IntegerField';
import SelectMultipleOptionsField from '@/fields/SelectMultipleOptionsField';
import EmailField from '@/fields/EmailField';
import StatementOfTruthField from '@/fields/StatementOfTruthField';
import InsurancePolicyNumberField from '@/fields/InsurancePolicyNumberField';
import FutureDateField from '@/fields/FutureDateField';
import CoiScanField from '@/fields/CoiScanField';

const types: Record<string, Field> = {
    'ssn': new SsnField(),
    'full-name': new FullNameField(),
    'past-name': new PastNameField(),
    'birthday': new BirthdayField(),
    'date': new DateField(),
    'cpr': new CprField(),
    'mmc-reference-number': new MmcReferenceNumberField(),
    'address': new AddressField(),
    'bar-license-details': new BarLicenseDetailsField(),
    'text': new SingleLineTextField(),
    'bulk-email': new BulkEmailField(),
    'consent': new ConsentField(),
    'us-state': new UsStateField(),
    'phone-number': new PhoneNumberField(),
    'file': new FileField(),
    'academic-provider': new AcademicProviderField(),
    'external-provider': new ExternalProviderField(),
    'selfie': new SelfieField(),
    'individualized-assessment': new IndividualizedAssessmentField(),
    'id-scan': new IdScanField(),
    'liveness-check': new LivenessCheckField(),
    'boolean': new BooleanField(),
    'ein': new EinField(),
    'integer': new IntegerField(),
    'string-enum': new SelectOptionField(),
    'string-enum-list': new SelectMultipleOptionsField(),
    'email': new EmailField(),
    'statement-of-truth': new StatementOfTruthField(),
    'future-date': new FutureDateField(),
    'insurance-policy-number': new InsurancePolicyNumberField(),
    'coi-scan': new CoiScanField(),
};

const smartAttributeTypes: Record<string, Field> = {};

export default function getField(type: string): Field | null {
    const smartAttribute = type.match(/^smart-attribute:(.+)$/);
    if (smartAttribute) {
        const smartAttributeName = smartAttribute[1];

        if (!smartAttributeTypes[smartAttributeName]) {
            smartAttributeTypes[smartAttributeName] = new SmartAttributeField(smartAttributeName);
        }

        return smartAttributeTypes[smartAttributeName];
    }

    return types[type] || null;
}
