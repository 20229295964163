<template>
    <IdScanError @submit="$emit('retry')" @back="$emit('back')">
        <div class="RetryDescription">
            <h3 class="RetryDescription__heading">
                We received your submission, but were unable to process your identity document
            </h3>

            <IdScanFailedPreview
                :title="title"
                :files="pages"
                solid
            />

            <p class="RetryDescription__description">
                To accurately verify your identity, the text on the image should be clear and readable.
                Please try retake the photo, holding the device steadier.
            </p>
        </div>
    </IdScanError>
</template>

<script lang="ts">
    import { Vue, Prop, Component } from 'vue-property-decorator';
    import IdScanFailedPreview from '@/components/fields/IdScan/IdScanFailedPreview.vue';
    import IdScanError from '@/components/fields/IdScan/IdScanError.vue';

    @Component({
        components: { IdScanError, IdScanFailedPreview },
    })
    export default class BlurryErrorScreen extends Vue {
        @Prop({ type: Object, default: () => null })
        private value!: any;

        private get title() {
            return this.pages.length > 1
                ? 'Photos are too blurry'
                : 'Photo is too blurry';
        }

        private get pages() {
            return this.value?.pages || [];
        }
    }
</script>
