<template>
    <div class="LargeFormatIdScanForm">
        <div class="LargeFormatIdScanForm__methods">
            <div class="LargeFormatIdScanForm__form">
                <RetryDescription />
            </div>
            <div class="LargeFormatIdScanForm__delegation">
                <h2 class="LargeFormatIdScanForm__title">
                    {{ text }}
                </h2>
                <p class="LargeFormatIdScanForm__subtitle">
                    By entering your phone number, you agree to receive a one-time text message with instructions for
                    secure ID verification.
                </p>

                <label class="LargeFormatIdScanForm__label" for="id-delegation-phone">Phone number</label>
                <PhoneDelegationInput
                    id="id-delegation-phone"
                    :value="delegationPhoneNumber"
                    :loading="delegationStatus === 'loading'"
                    :disabled="delegationsLeft <= 0"
                    start-button-label="Send 1-Time Text"
                    @input="changeDelegationPhoneNumber"
                    @start="sendMessage"
                />

                <a
                    v-if="isDesktopUploadEnabled"
                    href="#"
                    class="LargeFormatIdScanForm__uploadLink"
                    touch-action="auto"
                    @click="toggleDesktopUpload"
                >
                    I already have photos I can upload
                </a>

                <div v-if="delegationStatus === 'success'" class="LargeFormatIdScanForm__notice">
                    <h3><Icon name="alert" /> Waiting for your upload</h3>
                    <p>
                        You can stay on this page and continue when your upload is complete,
                        or finish verification on your mobile device.
                    </p>
                </div>
            </div>
        </div>
        <QuestionFormControls :has-submit="false" @back="onBack" />
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import PhoneNumber from '@/fields/interfaces/PhoneNumber';
    import FieldComponent from '@/fields/FieldComponent';
    import documents from '@/config/documents';
    import IdScanField from '@/fields/IdScanField';
    import { DocumentType } from '@evidentid/ido-lib/interfaces/Question';
    import PhoneDelegationInput from '@/components/forms/PhoneDelegationInput.vue';
    import QuestionFormControls from '@/components/forms/QuestionFormControls.vue';
    import Icon from '@/components/common/Icon.vue';
    import RetryDescription from './RetryDescription.vue';

    @Component({
        inheritAttrs: false,
        components: { RetryDescription, Icon, QuestionFormControls, PhoneDelegationInput },
    })
    export default class IdScanDelegationForm extends FieldComponent<any> {
        private toggleDesktopUpload(event: Event) {
            event.preventDefault();
            this.$emit('change', {
                ...this.value,
                useSinglePageUpload: true,
            });
        }

        private get isDesktopUploadEnabled() {
            return this.$store.state.featureFlags.idverify_document_upload_from_desktop_enabled;
        }

        private get documentType(): DocumentType {
            const field = this.field as IdScanField;
            return this.value?.type || field.getPossibleDocumentTypes(this.question)[0];
        }

        private get text() {
            return documents[this.documentType].delegationText;
        }

        private sendMessage() {
            this.$emit('delegate');
        }

        private changeDelegationPhoneNumber(phoneNumber: PhoneNumber | null) {
            this.$emit('delegationChange', phoneNumber);
        }

        private onBack() {
            this.$emit('back');
        }
    }
</script>
