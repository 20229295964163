<template>
    <div>
        <label class="LineInputBlock">
            <span>Enter first name<i class="fg-danger">*</i></span>
            <input
                v-model="firstName"
                type="text"
                @input="onChange"
            >
        </label>
        <label class="LineInputBlock">
            <span>Enter middle name</span>
            <input
                v-model="middleName"
                type="text"
                @input="onChange"
            >
        </label>
        <label class="LineInputBlock">
            <span>Enter last name<i class="fg-danger">*</i></span>
            <input
                v-model="lastName"
                type="text"
                @input="onChange"
            >
        </label>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import FieldComponent from '@/fields/FieldComponent';
    import FullNameValue from '@/fields/interfaces/FullName';

    @Component
    export default class FullName extends FieldComponent<FullNameValue> {
        private firstName: string = (this.value?.first) || '';
        private middleName: string = (this.value?.middle) || '';
        private lastName: string = (this.value?.last) || '';

        @Watch('value')
        private onValueChange(value: FullNameValue) {
            this.firstName = value?.first;
            this.middleName = value?.middle;
            this.lastName = value?.last;
        }

        private onChange() {
            this.$emit('change', {
                first: this.firstName,
                middle: this.middleName,
                last: this.lastName,
            });
        }
    }
</script>
