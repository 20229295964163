import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import AcademicProviderComponent from '@/components/fields/AcademicProvider.vue';
import AcademicProvider from '@/interfaces/AcademicProvider';
import { IdoStore } from '@/store/interfaces/IdoStore';

export default class AcademicProviderField extends Field<AcademicProvider> {
    public override component = AcademicProviderComponent;

    public isValid(_: Question, value: AcademicProvider): boolean {
        return Boolean(value && value.name && value.id);
    }

    public async encode(question: Question, value: AcademicProvider): Promise<AttributesValues> {
        return this._encode(question, value || null);
    }

    protected override prepareData(store: Pick<IdoStore, 'state' | 'dispatch'>) {
        return store.state.academicProviders
            ? null
            : store.dispatch('loadAcademicProviders');
    }
}
