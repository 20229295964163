import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import MmcReferenceNumberComponent from '@/components/fields/MmcReferenceNumber.vue';

export default class MmcReferenceNumberField extends Field<string> {
    public override component = MmcReferenceNumberComponent;

    public isValid(_: Question, value: string): boolean {
        return typeof value === 'string' && /^\d{7}$/.test(value.replace(/[-\s]+/g, ''));
    }

    public async encode(question: Question, value: string): Promise<AttributesValues> {
        return this._encode(question, value && value.replace(/\D/g, ''));
    }
}
