import { PrivacyPolicy } from '@evidentid/ido-lib/interfaces/Identity';

const defaultPrivacyPolicy: PrivacyPolicy = {
    headline: 'Your privacy is important to us!',
    message:
        `Often the response shared with the requesting third party will be a
        “Yes” or “No” regarding the authenticity of your submission.
        This is done to protect your personal information.`,
    url: 'https://www.evidentid.com/own-your-identity/',
    title: 'Learn more',
};

export { defaultPrivacyPolicy };
