/**
 * What is a minimum fresh delay,
 * when user is very active?
 */
export const minRefreshDelayTime = 2000; // 2s

/**
 * What is a maximum refresh delay,
 * for the time we treat user as active?
 */
export const maxRefreshDelayTime = 6000; // 6s

/**
 * After what inactivity time (in milliseconds),
 * we stop fetching the RPR.
 */
export const maxInactivityTime = 5 * 60 * 1000; // 5 min
