interface CprProviderConfiguration {
    id: string;
    name: string;
    isCertificateIdValid: (id: string) => boolean;
}

export const cprProvidersMap: { [key: string]: CprProviderConfiguration } = {
    american_red_cross: {
        id: 'american_red_cross',
        name: 'American Red Cross',
        isCertificateIdValid: (id: string) => id.length === 6,
    },
    american_heart_association: {
        id: 'american_heart_association',
        name: 'American Heart Association',
        isCertificateIdValid: (id: string) => id.length > 0,
    },
};

const cprProviders = [
    cprProvidersMap.american_red_cross,
    cprProvidersMap.american_heart_association,
];

cprProviders.sort((a, b) => a.name.localeCompare(b.name));

export default cprProviders;
