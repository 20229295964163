import Field from './Field';
import { AttributesValues } from '@/store/interfaces/RawIdoActions';
import Question from '@evidentid/ido-lib/interfaces/Question';
import PastNameComponent from '@/components/fields/PastName.vue';
import PastName from './interfaces/PastName';

export default class PastNameField extends Field<PastName> {
    public override component = PastNameComponent;

    public isValid(_: Question, value: PastName): boolean {
        return !value || Boolean(
            typeof value === 'object' &&
            (!value.has || (value.first.trim() && value.last.trim())),
        );
    }

    public async encode(question: Question, value: PastName): Promise<AttributesValues> {
        const firstName = (value && value.has && value?.first?.trim() || '');
        const middleName = (value && value.has && value?.middle?.trim() || '');
        const lastName = (value && value.has && value?.last?.trim() || '');

        return this._encode(question, {
            $objectType: 'Name',
            first: firstName,
            middle: middleName,
            last: lastName,
        });
    }
}
