<template>
    <div class="InterviewCard__controls">
        <Button type="secondary" @click="$emit('back')">
            {{ backLabel }}
        </Button>
        <template v-if="hasSubmit">
            <Button type="primary" :disabled="submitDisabled" @click="onSubmitClick">
                {{ submitText }}
            </Button>
        </template>
    </div>
</template>

<script lang="ts">
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import Button from '@/components/common/Button.vue';

    @Component({
        inheritAttrs: false,
        components: { Button },
    })
    export default class QuestionFormControls extends Vue {
        @Prop({ type: Boolean, default: false })
        private submitting!: boolean;

        @Prop({ type: Boolean, default: false })
        private valid!: boolean;

        @Prop({ type: Number, default: Infinity })
        private questionsLeft!: number;

        @Prop({ type: String, default: undefined })
        private submitLabel!: string | undefined;

        @Prop({ type: String, default: 'Do this later' })
        private backLabel!: string;

        @Prop({ type: Boolean, default: true })
        private hasSubmit!: boolean;

        private get submitDisabled() {
            return this.submitting || !this.valid;
        }

        private get submitText() {
            if (this.submitting) {
                return 'Submitting…';
            } else if (this.submitLabel) {
                return this.submitLabel;
            } else if (this.questionsLeft === 1) {
                return 'Submit and Complete';
            } else {
                return 'Submit';
            }
        }

        private onSubmitClick() {
            if (!this.submitting) {
                this.$emit('submit');
            }
        }
    }
</script>
