<template>
    <div class="Alert" :class="additionalClassName">
        <div v-if="icon" class="Alert__icon">
            <Spinner v-if="icon === 'spinner'" />
            <img v-else-if="icon === 'error'" :src="errorIconThickUrl">
            <img v-else-if="icon === 'list-finished'" :src="listFinishedUrl">
            <Icon v-else-if="icon" :name="icon" />
        </div>
        <div class="Alert__message">
            <slot />
        </div>
    </div>
</template>

<style lang="less">
    @import "~@evidentid/browser-framework/comps/Alert.less";
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import Spinner from '@/components/common/Spinner.vue';
    import Icon from '@/components/common/Icon.vue';
    import errorIconThickUrl from '@evidentid/assets-bridge/ErrorIconThick.svg';
    import listFinishedUrl from '@evidentid/assets-bridge/ListFinished.svg';

    export enum AlertType {
        success = 'success',
        error = 'error',
    }

    @Component({
        components: { Icon, Spinner },
    })
    export default class Alert extends Vue {
        private errorIconThickUrl = errorIconThickUrl;
        private listFinishedUrl = listFinishedUrl;

        @Prop({ type: String, default: null })
        private icon!: string | null;

        @Prop({ type: String, default: null })
        private type!: AlertType | null;

        private get additionalClassName() {
            return this.type ? `Alert--${this.type}` : undefined;
        }
    }
</script>
