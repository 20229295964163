import isEqual from 'lodash/isEqual';
import TrackerAdapter from '../TrackerAdapter';
import TrackerUserData from '../TrackerUserData';
import TrackerEvent from '../TrackerEvent';
import formatLogData from '@evidentid/logger/formatLogData';
import flattenObject from '@evidentid/universal-framework/flattenObject';
import diffObject from '@evidentid/universal-framework/diffObject';

export interface WoopraTrackerAdapterOptions {
    domain: string;
    cookieDomain?: string;
}

export default class WoopraTrackerAdapter<T extends TrackerUserData = TrackerUserData> implements TrackerAdapter<T> {
    private userData: T | null = null;
    private initialized: boolean = false;
    private options: WoopraTrackerAdapterOptions;

    private get woopra(): any {
        // eslint-disable-next-line
        // @ts-ignore: reaching global Woopra
        return window.woopra;
    }

    public constructor(options: WoopraTrackerAdapterOptions) {
        this.options = options;
    }

    public initialize() {
        this.initializeWoopraTrackingCode();

        // Build Woopra configuration
        const config: any = { domain: this.options.domain };
        if (this.options.cookieDomain) {
            config.cookie_domain = this.options.cookieDomain;
        }

        // Configure Woopra
        this.woopra.config(config);
        this.woopra.track();
        this.initialized = true;

        return Promise.resolve();
    }

    public isReady() {
        return this.initialized;
    }

    public track(event: TrackerEvent, timestamp: number) {
        const { name, ...eventData } = event;
        const data = {
            $time: timestamp,
            ...eventData,
        };
        this.woopra.track(name, formatLogData(flattenObject(data), ', '));
    }

    public setUserData(userData: T | null) {
        if (isEqual(this.userData, userData)) {
            // Do nothing, no user is set
            return;
        } else if (userData === null) {
            // Log out user
            this.userData = null;
            try {
                this.woopra.visitorData = {};
                this.woopra.reset();
            } catch (error) {
                console.error(error);
            }
            return;
        }

        const previousUserData = this.buildUserDataObject(this.userData);
        const nextUserData = this.buildUserDataObject(userData);
        const userDataDiff = diffObject(nextUserData, previousUserData);

        if (Object.keys(userDataDiff).length > 0) {
            this.woopra.identify(userDataDiff);
        }

        this.userData = userData;
    }

    private buildUserDataObject(userData: T | null) {
        if (userData === null) {
            return {};
        }

        return formatLogData(flattenObject({
            ...userData,
            name: userData.name || userData.id,
        }), ', ');
    }

    private initializeWoopraTrackingCode() {
        /* eslint-disable */
        // @ts-ignore: Woopra global library
        if (!window.woopra) {
            // @ts-ignore: Woopra tracking code
            (function() {
                // @ts-ignore: Woopra tracking code
                var t,i,e,n=window,o=document,a=arguments,s="script",r=["config","track","identify","visit","push","call","trackForm","trackClick"],c=function(){var t,i=this;for(i._e=[],t=0;r.length>t;t++)(function(t){i[t]=function(){return i._e.push([t].concat(Array.prototype.slice.call(arguments,0))),i}})(r[t])};for(n._w=n._w||{},t=0;a.length>t;t++)n._w[a[t]]=n[a[t]]=n[a[t]]||new c;i=o.createElement(s),i.async=1,i.src="//static.woopra.com/js/w.js",e=o.getElementsByTagName(s)[0],e.parentNode.insertBefore(i,e)
                // @ts-ignore: Woopra tracking code
            })("woopra");
        }
        /* eslint-enable */
    }
}
