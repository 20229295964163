import Question from '@evidentid/ido-lib/interfaces/Question';

const schemaByInputType: Record<string, string> = {
    HealthcareLicenseIdAndStateList: 'usMassageTherapistLicenseList',
    CosmetologistLicenseIdAndStateList: 'usCosmetologistLicenseList',
    EstheticianLicenseIdAndStateList: 'usEstheticianLicenseList',
    AdvancedPracticeRegisteredNurseLicenseIdAndStateList: 'usAdvancedPracticeRegisteredNurseLicenseList',
    CertifiedNursingAssistantLicenseIdAndStateList: 'usCertifiedNursingAssistantLicenseList',
    LicensedPracticalNurseLicenseIdAndStateList: 'usLicensedPracticalNurseLicenseList',
    RegisteredNurseLicenseIdAndStateList: 'usRegisteredNurseLicenseList',
    VeterinarianLicenseIdAndStateList: 'usVeterinarianLicenseList',
    NationalParkName: 'usNationalParkName',
    NursePractitionerLicenseIdAndStateList: 'usNursePractitionerLicenseList',
    PhysicianAssistantLicenseIdAndStateList: 'usPhysicianAssistantLicenseList',
    PhysicalTherapistLicenseIdAndStateList: 'usPhysicalTherapistLicenseList',
    LicensedMentalHealthCounselorLicenseIdAndStateList: 'usLicensedMentalHealthCounselorLicenseList',
    LicensedProfessionalCounselorLicenseIdAndStateList: 'usLicensedProfessionalCounselorLicenseList',
    SocialWorkerLicenseIdAndStateList: 'usSocialWorkerLicenseList',
    LicensedBarberOrHairDesignLicenseIdAndStateList: 'usLicensedBarberOrHairDesignLicenseList',
    LicensedManicuristOrNailTechLicenseIdAndStateList: 'usLicensedManicuristOrNailTechLicenseList',
    LicensedProfessionalClinicalCounselorLicenseIdAndStateList: 'usLicensedProfessionalClinicalCounselorLicenseIdAndStateList',
    LicensedClinicalSocialWorkerLicenseIdAndStateList: 'usLicensedClinicalSocialWorkerLicenseIdAndStateList',
    LicensedMarriageAndFamilyTherapistLicenseIdAndStateList: 'usLicensedMarriageAndFamilyTherapistLicenseIdAndStateList',
    PsychologistLicenseIdAndStateList: 'usPsychologistLicenseIdAndStateList',
};

export default function getSmartAttributeName(question: Question): string | null {
    const { inputType } = question.metadata || {};
    return (inputType && schemaByInputType[inputType]) || null;
}
